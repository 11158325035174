// data
import {
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
} from 'serializers/site'

// helpers
function getStyleValue(reduxModalSite: reduxModalStateSite, styleValue: string) {
    if (styleValue === 'minNav100Vh') {
        if (reduxModalSite.deviceType === 'is-mobile') {
            return `calc(100vh - ${reduxModalSite.isOpen ? 53 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px)`
        }
        return `calc(100vh - ${reduxModalSite.isOpen ? 30 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px)`
    }
    return styleValue
}

function getFormEditValue(reduxModalSite: reduxModalStateSite, styleValue: string) {
    if (typeof styleValue === 'string') {
        if (styleValue.includes('vh')) {
            const vhValue = Number(styleValue.split('vh')[0])
            if (reduxModalSite.specialSize === 'form-fullscreen') {
                return `calc((100vh - 45px) / (100 / ${vhValue}))`
            }
            if (reduxModalSite.specialSize === 'mixed-media') {
                return `calc((100vh - 98px) / (100 / ${vhValue}))`
            }
            if (reduxModalSite.specialSize === 'mixed-media-edit') {
                return `calc((100vh - 45px) / (100 / ${vhValue}))`
            }
            return `calc((100vh - 45px - 60px) / (100 / ${vhValue}))`
        } else if (styleValue === 'minNav100Vh') {
            if (reduxModalSite.specialSize === 'form-fullscreen') {
                return `calc(100vh - ${reduxModalSite.isOpen ? 30 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px - 45px)`
            }
            if (reduxModalSite.specialSize === 'mixed-media') {
                return `calc(100vh - 98px)`
            }
            if (reduxModalSite.specialSize === 'mixed-media-edit') {
                return `calc(100vh - 45px)`
            }
            return `calc(100vh - ${reduxModalSite.isOpen ? 30 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px - 45px - 60px)`
        }
        return styleValue
    }
    if (styleValue === 'minNav100Vh') {
        if (reduxModalSite.specialSize === 'mixed-media') {
            return `calc(100vh - 98px)`
        }
        if (reduxModalSite.specialSize === 'mixed-media-edit') {
            return `calc(100vh - 45px)`
        }
        return `calc(100vh - ${reduxModalSite.isOpen ? 30 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px)`
    }
    return styleValue
}

// main
export function getStylesNew(
    reduxModalSite: reduxModalStateSite,
    blockStyles: MainStyleSerializer,
    stylesEdit: MainStyleSerializer | undefined,
) {
    const respValue = reduxModalSite.respValue
    // @ts-ignore
    const valueResp: 'value' | 'value_MD' | 'value_SM' = `value${respValue}`
    if (!blockStyles || !blockStyles[valueResp]) {
        return undefined
    }
    const styles: CustomCSSProperties = {}
    if (reduxModalSite.edit) {
        if (stylesEdit) {
            Object.getOwnPropertyNames(stylesEdit[valueResp]).map((val) => {
                // @ts-ignore
                styles[val] = getFormEditValue(reduxModalSite, stylesEdit[valueResp][val])
                return false
            })
        } else {
            Object.getOwnPropertyNames(blockStyles[valueResp]).map((val) => {
                // @ts-ignore
                styles[val] = getFormEditValue(reduxModalSite, blockStyles[valueResp][val])
                return false
            })
        }
    } else {
        // @ts-ignore
        Object.getOwnPropertyNames(blockStyles[valueResp]).map((val) => {
            // @ts-ignore
            styles[val] = getStyleValue(reduxModalSite, blockStyles[valueResp][val])
        })
    }
    return styles
}

export function getStylesToRetrieveNew(
    reduxModalSite: reduxModalStateSite,
    blockStyles: MainStyleSerializer,
    stylesEdit: MainStyleSerializer | undefined,
    styleToRetrieve: string,
) {
    const respValue = reduxModalSite.respValue
    // @ts-ignore
    const valueResp: 'value' | 'value_MD' | 'value_SM' = `value${respValue}`
    if (!blockStyles) {
        return undefined
    }
    if (reduxModalSite.edit) {
        if (stylesEdit) {
            return getFormEditValue(reduxModalSite, stylesEdit[valueResp][styleToRetrieve])
        } else {
            return getFormEditValue(reduxModalSite, blockStyles[valueResp][styleToRetrieve])
        }
    } else {
        return getStyleValue(reduxModalSite, blockStyles[valueResp][styleToRetrieve])
    }
}
