// react components
import axios from 'axios'

// data
import {
    api_url_auth_settings_detail_site,
    api_url_auth_token_convert_site,
    api_url_auth_token_obtain_site,
    reduxAuthStateSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// constants
export const REDUX_AUTH_AFTER_AUTH_URL_SET_SITE = 'REDUX_AUTH_AFTER_AUTH_URL_SET_SITE'
export const REDUX_AUTH_FETCH_SETTINGS_SITE = 'REDUX_AUTH_FETCH_SETTINGS_SITE'
export const REDUX_AUTH_GOOGLE_AUTH_FULFILLED_SITE = 'REDUX_AUTH_GOOGLE_AUTH_FULFILLED_SITE'
export const REDUX_AUTH_GOOGLE_AUTH_REJECTED_SITE = 'REDUX_AUTH_GOOGLE_AUTH_REJECTED_SITE'
export const REDUX_AUTH_LOGIN_FULFILLED_SITE = 'REDUX_AUTH_LOGIN_FULFILLED_SITE'
export const REDUX_AUTH_LOGIN_REJECTED_SITE = 'REDUX_AUTH_LOGIN_REJECTED_SITE'
export const REDUX_AUTH_LOGOUT_SITE = 'REDUX_AUTH_LOGOUT_SITE'

export function reduxAuthAfterAuthUrlSetSite(url: string) {
    return {
        type: REDUX_AUTH_AFTER_AUTH_URL_SET_SITE,
        payload: url,
    }
}

export function reduxAuthFetchSettingsSite(
    reduxAuth: reduxAuthStateSite,
    refreshAxiosHeaders: any,
    reduxModalSite: reduxModalStateSite,
    dispatch2: any,
    portfolioId: number,
) {
    return function (dispatch: any) {
        if (!reduxAuth?.authenticated || !portfolioId) return
        const getUrl = getApiUrlSite(`${api_url_auth_settings_detail_site}?portfolio=${portfolioId}`, reduxModalSite)
        if (process.env.NODE_ENV === 'development') console.log(getUrl)
        axios({
            headers: refreshAxiosHeaders,
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response.data)
                dispatch({
                    type: REDUX_AUTH_FETCH_SETTINGS_SITE,
                    payload: response.data,
                })
            })
            .catch((error) => {
                axiosErrorHandlerSite({
                    apiUrl: getUrl,
                    component: 'reduxAuthFetchSettingsSite',
                    dispatch: dispatch2,
                    error,
                    reduxAuth,
                    reduxModalSite,
                    reference: 'get',
                })
            })
    }
}

export function reduxAuthLoginSite(
    username: string,
    password: string,
    reduxAuth: reduxAuthStateSite,
    reduxModalSite: reduxModalStateSite,
    dispatchVar: React.Dispatch<any>,
    setIsLoading: React.Dispatch<boolean>,
    setFields: any,
    fieldsInitial: any,
    portfolioId: number,
    setErrors: React.Dispatch<any>,
) {
    return function (dispatch: any) {
        const postUrl = getApiUrlSite(api_url_auth_token_obtain_site, reduxModalSite)
        if (process.env.NODE_ENV === 'development') console.log(postUrl)
        const formData: any = new FormData()
        formData.append('username', username.toLowerCase().trim())
        formData.append('password', password)
        formData.append('portfolioId', portfolioId)
        axios({
            data: formData,
            headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
            method: 'post',
            url: postUrl,
        })
            .then((response) => {
                setIsLoading(false)
                if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) reduxAuthLoginSite')
                setFields(fieldsInitial)
                dispatch({
                    type: REDUX_AUTH_LOGIN_FULFILLED_SITE,
                    payload: response.data,
                })
                window.location.reload()
            })
            .catch((error) => {
                dispatch({
                    type: REDUX_AUTH_LOGIN_REJECTED_SITE,
                    payload: error,
                })
                setErrors(error.response?.data)
                setIsLoading(false)
                if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) reduxAuthLoginSite')
                axiosErrorHandlerSite({
                    apiUrl: postUrl,
                    component: 'reduxAuthLoginSite',
                    dispatch: dispatchVar,
                    error,
                    reduxAuth,
                    reduxModalSite,
                    reference: `post ${username}`,
                    skipAlertStatusCode: [400, 401],
                    skipSendError: Boolean(error.response?.data?.password),
                    skipSendErrorStatusCode: [401],
                })
            })
    }
}

export function reduxAuthGoogleSite(
    idToken: string,
    refreshToken: string,
    reduxAuth: reduxAuthStateSite,
    reduxModalSite: reduxModalStateSite,
    dispatchVar: React.Dispatch<any>,
    setIsLoading: React.Dispatch<boolean>,
    portfolioId: number,
    setErrors: React.Dispatch<any>,
) {
    return function (dispatch: any) {
        const postUrl = getApiUrlSite(api_url_auth_token_convert_site, reduxModalSite)
        if (process.env.NODE_ENV === 'development') console.log(postUrl)
        const formData: any = new FormData()
        formData.append('provider', 'google')
        formData.append('token', idToken)
        formData.append('portfolioId', portfolioId)
        axios({
            data: formData,
            headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
            method: 'post',
            url: postUrl,
        })
            .then((response) => {
                setIsLoading(false)
                if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) reduxAuthLoginSite')
                dispatch({
                    type: REDUX_AUTH_GOOGLE_AUTH_FULFILLED_SITE,
                    payload: response.data,
                    refresh_token: refreshToken,
                })
                window.location.reload()
            })
            .catch((error) => {
                dispatch({
                    type: REDUX_AUTH_GOOGLE_AUTH_REJECTED_SITE,
                    payload: error,
                })
                setErrors(error)
                setIsLoading(false)
                if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) reduxAuthLoginSite')
                axiosErrorHandlerSite({
                    apiUrl: postUrl,
                    component: 'reduxAuthGoogleSite',
                    dispatch: dispatchVar,
                    error,
                    reduxAuth,
                    reduxModalSite,
                    reference: 'post',
                    skipAlertStatusCode: [403],
                    skipSendErrorStatusCode: [400, 403],
                })
            })
    }
}

export function reduxAuthLogoutSite() {
    return {
        type: REDUX_AUTH_LOGOUT_SITE,
    }
}
