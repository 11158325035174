// react components

// data
import {
    reduxModalStateSite,
} from 'data'

// serializers
import {
    MainStyleSerializer,
} from 'serializers/site'

// constants
export const REDUX_MODAL_CLEAR_SEARCH_SITE = 'REDUX_MODAL_CLEAR_SEARCH_SITE'
export const REDUX_MODAL_COOKIE_CONSENT_SITE = 'REDUX_MODAL_COOKIE_CONSENT_SITE'
export const REDUX_MODAL_ERROR_EVENT_HANDLER_SITE = 'REDUX_MODAL_ERROR_EVENT_HANDLER_SITE'
export const REDUX_MODAL_ERROR_SHOW_HIDE_SITE = 'REDUX_MODAL_ERROR_SHOW_HIDE_SITE'
export const REDUX_MODAL_GA_INIT_SITE = 'REDUX_MODAL_GA_INIT_SITE'
export const REDUX_MODAL_GTM_INIT_SITE = 'REDUX_MODAL_GTM_INIT_SITE'
export const REDUX_MODAL_LIGHTBOX_ON_NEXT_SITE = 'REDUX_MODAL_LIGHTBOX_ON_NEXT_SITE'
export const REDUX_MODAL_LIGHTBOX_ON_PREV_SITE = 'REDUX_MODAL_LIGHTBOX_ON_PREV_SITE'
export const REDUX_MODAL_LIGHTBOX_SHOW_HIDE_SITE = 'REDUX_MODAL_LIGHTBOX_SHOW_HIDE_SITE'
export const REDUX_MODAL_SET_ACTIVE_COLLAPSED_ID_SITE = 'REDUX_MODAL_SET_ACTIVE_COLLAPSED_ID_SITE'
export const REDUX_MODAL_SET_ACTIVE_DROPDOWN_ID_SITE = 'REDUX_MODAL_SET_ACTIVE_DROPDOWN_ID_SITE'
export const REDUX_MODAL_SET_CURRENCY_ID_SITE = 'REDUX_MODAL_SET_CURRENCY_ID_SITE'
export const REDUX_MODAL_SET_DEVICE_TYPE_SITE = 'REDUX_MODAL_SET_DEVICE_TYPE_SITE'
export const REDUX_MODAL_SET_EDIT_SITE = 'REDUX_MODAL_SET_EDIT_SITE'
export const REDUX_MODAL_SET_EDIT_STYLES_SITE = 'REDUX_MODAL_SET_EDIT_STYLES_SITE'
export const REDUX_MODAL_SET_LANGUAGE_ID_SITE = 'REDUX_MODAL_SET_LANGUAGE_ID_SITE'
export const REDUX_MODAL_SET_NAVBAR_HEIGHT_SITE = 'REDUX_MODAL_SET_NAVBAR_HEIGHT_SITE'
export const REDUX_MODAL_SET_NAVBAR_IS_TRANSPARENT_SITE = 'REDUX_MODAL_SET_NAVBAR_IS_TRANSPARENT_SITE'
export const REDUX_MODAL_SET_NAVBAR_OPEN_ID_SITE = 'REDUX_MODAL_SET_NAVBAR_OPEN_ID_SITE'
export const REDUX_MODAL_SET_NAVBAR_TYPE_SITE = 'REDUX_MODAL_SET_NAVBAR_TYPE_SITE'
export const REDUX_MODAL_SET_NAVBAR_WIDTH_SITE = 'REDUX_MODAL_SET_NAVBAR_WIDTH_SITE'
export const REDUX_MODAL_SET_ROOT_URL_SITE = 'REDUX_MODAL_SET_ROOT_URL_SITE'
export const REDUX_MODAL_SET_SPECIAL_SIZE_SITE = 'REDUX_MODAL_SET_SPECIAL_SIZE_SITE'
export const REDUX_MODAL_SET_STRIPE_PROMISE_SITE = 'REDUX_MODAL_SET_STRIPE_PROMISE_SITE'
export const REDUX_MODAL_SLAASK_INIT_SITE = 'REDUX_MODAL_SLAASK_INIT_SITE'
export const REDUX_MODAL_SLIDER_GET_DATA_SITE = 'REDUX_MODAL_SLIDER_GET_DATA_SITE'
export const REDUX_MODAL_SLIDER_ON_NEXT_SITE = 'REDUX_MODAL_SLIDER_ON_NEXT_SITE'
export const REDUX_MODAL_SLIDER_ON_PREV_SITE = 'REDUX_MODAL_SLIDER_ON_PREV_SITE'
export const REDUX_MODAL_SLIDER_SHOW_HIDE_SITE = 'REDUX_MODAL_SLIDER_SHOW_HIDE_SITE'

// actions
export function reduxModalClearSearchSite() {
    return {
        type: REDUX_MODAL_CLEAR_SEARCH_SITE,
    }
}

export function reduxModalCookieConsentSite(cookieConsent: reduxModalStateSite['cookieConsent']) {
    return {
        type: REDUX_MODAL_COOKIE_CONSENT_SITE,
        payload: cookieConsent,
    }
}

export function reduxModalErrorEventHandlerSite(
    error: any,
    component: string,
    info: string,
    skipAlert?: boolean,
) {
    return {
        type: REDUX_MODAL_ERROR_EVENT_HANDLER_SITE,
        payload: {
            component: component,
            error: error,
            info: info,
            skipAlert: skipAlert,
        },
    }
}

export function reduxModalErrorShowHideSite({
    header,
    isOpen,
    message,
    noMessage,
    subHeader,
}: {
    header?: string
    isOpen: boolean
    message?: string
    noMessage?: boolean
    subHeader?: string
}) {
    return {
        type: REDUX_MODAL_ERROR_SHOW_HIDE_SITE,
        payload: {
            header,
            isOpen,
            message,
            noMessage,
            subHeader,
        },
    }
}

export function reduxModalGaInitSite(ga4?: any) {
    return {
        type: REDUX_MODAL_GA_INIT_SITE,
        payload: ga4,
    }
}

export function reduxModalGtmInitSite(gtm?: any) {
    return {
        type: REDUX_MODAL_GTM_INIT_SITE,
        payload: gtm,
    }
}

export function reduxModalLightBoxOnNextSite() {
    return {
        type: REDUX_MODAL_LIGHTBOX_ON_NEXT_SITE,
    }
}

export function reduxModalLightBoxOnPrevSite() {
    return {
        type: REDUX_MODAL_LIGHTBOX_ON_PREV_SITE,
    }
}

export function reduxModalLightBoxShowHideSite(
    boolean: boolean,
    objectId?: any,
    items?: any[],
    allowDownload?: boolean,
) {
    return {
        type: REDUX_MODAL_LIGHTBOX_SHOW_HIDE_SITE,
        payload: {
            allowDownload: allowDownload,
            boolean: boolean,
            items: items,
            objectId: objectId,
        }
    }
}

export function reduxModalSetActiveCollapsedIdSite(id: string | undefined) {
    return {
        type: REDUX_MODAL_SET_ACTIVE_COLLAPSED_ID_SITE,
        payload: id,
    }
}

export function reduxModalSetActiveDropdownIdSite(id: number | undefined) {
    return {
        type: REDUX_MODAL_SET_ACTIVE_DROPDOWN_ID_SITE,
        payload: id,
    }
}

export function reduxModalSetCurrencyIdSite(currencyId: number | undefined) {
    return {
        type: REDUX_MODAL_SET_CURRENCY_ID_SITE,
        payload: currencyId,
    }
}

export function reduxModaSetDeviceTypeSite(deviceType: string) {
    return {
        type: REDUX_MODAL_SET_DEVICE_TYPE_SITE,
        payload: deviceType,
    }
}

export function reduxModalSetEditSite(boolean: boolean) {
    return {
        type: REDUX_MODAL_SET_EDIT_SITE,
        payload: boolean,
    }
}

export function reduxModalSetEditStylesSite(stylesObj: {
    id: number
    styles: MainStyleSerializer
}) {
    return {
        type: REDUX_MODAL_SET_EDIT_STYLES_SITE,
        payload: stylesObj,
    }
}

export function reduxModalSetLanguageIdSite(languageId: number) {
    let languageCodeToReturn = 'en'
    if (languageId === 2) {
        languageCodeToReturn = 'fr'
    }
    return {
        type: REDUX_MODAL_SET_LANGUAGE_ID_SITE,
        payload: {
            languageId: languageId,
            languageCode: languageCodeToReturn,
        },
    }
}

export function reduxModalSetNavbarHeightSite(height: number) {
    return {
        type: REDUX_MODAL_SET_NAVBAR_HEIGHT_SITE,
        payload: height,
    }
}

export function reduxModalSetNavbarIsTransparentSite(boolean: boolean) {
    return {
        type: REDUX_MODAL_SET_NAVBAR_IS_TRANSPARENT_SITE,
        payload: boolean,
    }
}

export function reduxModalSetNavbarOpenIdSite(parentNavbarId: number | undefined) {
    return {
        type: REDUX_MODAL_SET_NAVBAR_OPEN_ID_SITE,
        payload: parentNavbarId,
    }
}

export function reduxModalSetNavbarTypeSite(type: 'side' | 'top') {
    return {
        type: REDUX_MODAL_SET_NAVBAR_TYPE_SITE,
        payload: type,
    }
}

export function reduxModalSetNavbarWidthSite(width: number) {
    return {
        type: REDUX_MODAL_SET_NAVBAR_WIDTH_SITE,
        payload: width,
    }
}

export function reduxModalSlaaskInitSite(apiKey: string) {
    return {
        type: REDUX_MODAL_SLAASK_INIT_SITE,
        payload: apiKey,
    }
}

export function reduxModalSetRootUrlSite(rootUrl: string) {
    return {
        type: REDUX_MODAL_SET_ROOT_URL_SITE,
        payload: rootUrl,
    }
}

export function reduxModalSiteSetSpecialSizeSite(specialSize: reduxModalStateSite['specialSize']) {
    return {
        type: REDUX_MODAL_SET_SPECIAL_SIZE_SITE,
        payload: specialSize,
    }
}

export function reduxModalSetStripePromiseSite(token_stripe_public: string, token_stripe_public_test?: string) {
    const { loadStripe } = require('@stripe/stripe-js')
    return {
        type: REDUX_MODAL_SET_STRIPE_PROMISE_SITE,
        payload: loadStripe(process.env.NODE_ENV === 'production' ? token_stripe_public : (token_stripe_public_test || 'pk_test_hBDa6xYRCxSsbmmGLKsDis6C')),
    }
}

export function reduxModalSliderGetDataSite(
    count: any,
    next: any,
    previous: any,
    results: any,
    objectId?: any,
) {
    return {
        type: REDUX_MODAL_SLIDER_GET_DATA_SITE,
        count: count,
        next: next,
        previous: previous,
        results: results,
        objectId: objectId,
    }
}

export function reduxModalSliderOnNextSite() {
    return {
        type: REDUX_MODAL_SLIDER_ON_NEXT_SITE,
    }
}

export function reduxModalSliderOnPrevSite() {
    return {
        type: REDUX_MODAL_SLIDER_ON_PREV_SITE,
    }
}

export function reduxModalSliderDetailShowHideSite(
    boolean: any,
    listUrl?: any,
    objectId?: any,
) {
    return {
        type: REDUX_MODAL_SLIDER_SHOW_HIDE_SITE,
        boolean: boolean,
        listUrl: listUrl,
        objectId: objectId,
    }
}
