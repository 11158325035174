// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    useIonViewWillEnter,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'

// components
import {
    IconBlockSite,
    LoaderSite,
    SeoBlockSite,
    StaffHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    CheckoutResponseSite,
    CheckoutSite,
    ContentBlockSite,
    CustomPaymentConfirmBlockSite,
    FooterBlockSite,
    NotFoundPortfolioSite,
} from 'pages'

// serializers
import {
    PageDetailSiteSerializer,
    PortfolioPageContentListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    checkPorfolioUserGroups,
    getDataSite,
    getStylesToRetrieve,
    parseQuerySite,
} from 'services'

// props
type SlugPageSiteProps = {
    devId: string
    pageSlug?: string
}

// main
export const SlugPageSite: React.FC<SlugPageSiteProps> = React.memo(({
    devId,
    pageSlug,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [currencyId, setCurrencyId] = useState<number | undefined>(reduxModalSite.currencyId)
    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [isLoading, setIsLoading] = useState(false)
    const [languageId, setLanguageId] = useState<number>(reduxModalSite.languageId)
    const [locationSearch, setLocationSearch] = useState<string>(location.search)
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[] | undefined>(undefined)
    const [pageObject, setPageObject] = useState<PageDetailSiteSerializer>()

    const [fontFamily, setFontFamily] = useState<string>()
    const [linkColor, setLinkColor] = useState<string>()
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})

    useEffect(() => {
        getPortfolioData()
    }, [
        pageSlug,
    ])

    useEffect(() => {
        const parsed = parseQuerySite(location.search)
        if (parsed.query || parsed.filter_query) {
            getPortfolioData(Boolean(parsed.filter_query))
        } else if (locationSearch) {
            getPortfolioData(true)
        }
        setLocationSearch(location.search)
    }, [
        location.search,
    ])

    useEffect(() => {
        if (languageId !== reduxModalSite.languageId) {
            getPortfolioData()
            setLanguageId(reduxModalSite.languageId)
        } else if (currencyId !== reduxModalSite.currencyId) {
            getPortfolioData()
            setCurrencyId(reduxModalSite.currencyId)
        }
    }, [
        reduxModalSite.currencyId,
        reduxModalSite.languageId,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        if (getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'backgroundImage')) {
            tempPageStyles.backgroundImage = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'backgroundImage')
        } else {
            tempPageStyles.background = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'background')
        }
        tempPageStyles.color = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'color')
        if (reduxModalSite.navbarType === 'top') {
            tempPageStyles.paddingTop = `${reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight}px`
        } else if (reduxModalSite.navbarType === 'side') {
            tempPageStyles.paddingLeft = `${reduxModalSite.navbarWidth}px`
        }
        setFontFamily(getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'fontFamily'))
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'linkColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'linkColor'))
    }, [
        pageObject?.styles,
        reduxCacheSite.portfolio?.styles,
        reduxModalSite,
    ])

    useIonViewWillEnter(() => {
        scrollToTop()
    })

    let wto: any
    useEffect(() => {
        if (window.location.hash && pageContentItems?.length! > 0) {
            clearTimeout(wto)
            wto = setTimeout(() => {
                const anchor = window.location.hash.replace('#', '')
                console.log(anchor);
                const element = document.querySelector(`[data-section="${anchor}"]`)
                console.log(element);
                if (element) {
                    // @ts-ignore
                    scrollIntoView(element, {
                        align: {
                            left: 0,
                            leftOffset: 0,
                            top: 0,
                            topOffset: reduxModalSite.navbarHeight,
                        },
                    })
                }
            }, 100)
        }
    }, [
        pageContentItems,
        window.location.hash,
    ])

    useEffect(() => {
        if ((reduxCacheSite.portfolio?.id === 488) || (reduxCacheSite.portfolio?.id === 594 && deviceType === 'is-mobile')) {
            window.removeEventListener('scroll', handleScroll)
            window.addEventListener('scroll', handleScroll, true)
            return function cleanupListener() {
                try {
                    window.removeEventListener('scroll', handleScroll)
                } catch (error) {
                    dispatch(reduxModalErrorEventHandlerSite(
                        error,
                        'SlugPageSite',
                        'cleanupListener',
                    ))
                }
            }
        }
    }, [
        pageSlug,
        reduxCacheSite.portfolio?.id,
    ])

    function handleScroll() {
        try {
            const buttonElement = document.getElementById(`slug-page-scroll-button-${pageSlug || 'home'}`)
            const contentElem = document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`) && document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`)
            if (buttonElement && contentElem) {
                if (contentElem.scrollTop + contentElem.clientHeight >= contentElem.scrollHeight) {
                    buttonElement.style.opacity = '0'
                } else {
                    buttonElement.style.opacity = '1'
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'SlugPageSite',
                'handleScroll',
            ))
        }
    }

    const contentRef = useRef<HTMLIonContentElement>(null)
    const scrollDown = () => {
        try {
            const elem = document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`) && document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`)
            if (elem?.scrollTo) {
                elem.scrollTo({
                    top: elem.scrollTop + document.body.scrollHeight - (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight),
                    behavior: 'smooth'
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'SlugPageSite',
                'scrollToTop',
            ))
        }
    }
    const scrollToTop = (force?: boolean) => {
        try {
            if (history.action !== 'POP' || force) {
                // @ts-ignore
                if (document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`) && document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`).scrollTo) document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`).scrollTo(0, 0)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'SlugPageSite',
                'scrollToTop',
            ))
        }
    }

    function getPortfolioData(noScollTop?: boolean) {
        try {
            getDataSite({
                axiosCancelToken,
                component: 'SlugPageSite',
                detailId: undefined,
                devId,
                dispatch,
                errorStatus,
                location,
                noEmpty: noScollTop,
                pageSlug,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                scrollToTop: noScollTop ? undefined : scrollToTop,
                setAxiosCancelToken,
                setErrorStatus,
                setIsLoading,
                setPageContentItems,
                setPageObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'SlugPageSite',
                'getPortfolioData',
            ))
        }
    }

    if (errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent) {
        return (
            <NotFoundPortfolioSite statusCode={errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent} />
        )
    }
    if (pageObject?.is_private && !reduxAuth?.authenticated) {
        return (
            <NotFoundPortfolioSite statusCode={403} />
        )
    }
    if (pageObject?.is_private && reduxAuth?.authenticated && pageObject.portfolio_user_groups?.length! > 0 && !checkPorfolioUserGroups({ portfolioId: reduxCacheSite.portfolio?.id!, pageUserGroups: pageObject.portfolio_user_groups!, reduxAuth })) {
        return (
            <NotFoundPortfolioSite statusCode={403} />
        )
    }
    return (
        <IonPage
            className='slug-page-site'
            style={{
                fontFamily: fontFamily,
            }}
        >
            <SeoBlockSite data={pageObject?.seo_data} />
            <IonContent
                id={`mo-content-slug-id-site-${pageSlug || 'home'}`}
                className={`mo-hide-ion-content-scrollbar${reduxModalSite.isWindows}`}
                ref={contentRef}
            >
                {isLoading && (
                    <LoaderSite isOpen />
                )}
                <div
                    className={`slug-page-content-wrap lg-${reduxModalSite.languageId} ${deviceType}`}
                    style={{
                        background: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'background'),
                        color: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'color'),
                        fontFamily: fontFamily,
                        margin: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'margin'),
                    }}
                >
                    <div
                        id='slug-page-content-slug'
                        className={`slug-page-content ${isLoading ? 'opacity' : 'no-opacity'}`}
                        style={newPageStyles}
                    >
                        <div
                            className={`slug-page-content-list ${deviceType}`}
                            style={{
                                padding: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'padding'),
                            }}
                        >
                            {reduxCacheSite.portfolio && pageObject && pageContentItems?.filter(obj => obj.active === true).map((val) => (
                                <ContentBlockSite
                                    key={val.id}
                                    detailId={undefined}
                                    devId={devId}
                                    isEditHovered={false}
                                    linkColor={linkColor}
                                    object={val}
                                    pageSlug={pageSlug || reduxCacheSite.portfolio?.home_page_slug}
                                    stylesEdit={undefined}
                                />
                            ))}
                            {pageSlug && (reduxCacheSite.portfolio?.is_eshop || reduxCacheSite.portfolio?.is_donation) && (pageSlug === 'checkout') && (
                                <CheckoutSite />
                            )}
                            {pageSlug && (reduxCacheSite.portfolio?.is_eshop || reduxCacheSite.portfolio?.is_donation) && ['checkout-cancel', 'checkout-success'].includes(pageSlug) && (
                                <CheckoutResponseSite
                                    hasContent={Boolean(pageContentItems?.[0])}
                                    pageSlug={pageSlug}
                                    pageStyles={newPageStyles}
                                />
                            )}
                            {pageSlug && ['payment-success'].includes(pageSlug) && (
                                <CustomPaymentConfirmBlockSite />
                            )}
                        </div>
                        {!isLoading && reduxCacheSite.portfolio?.footer && pageObject && pageContentItems && (
                            <FooterBlockSite
                                linkColor={linkColor}
                                stylesEdit={undefined}
                            />
                        )}
                    </div>
                </div>
                {reduxCacheSite.portfolio?.id === 488 && (
                    <div
                        id={`slug-page-scroll-button-${pageSlug || 'home'}`}
                        className='slug-page-scroll-button'
                    >
                        <IconBlockSite
                            iconClass={`mo-new-icon-chevron-down-solid`}
                            onClick={scrollDown}
                        />
                    </div>
                )}
                {reduxCacheSite.portfolio?.id === 594 && deviceType === 'is-mobile' && (
                    <div
                        id={`slug-page-scroll-button-${pageSlug || 'home'}`}
                        className='slug-page-scroll-button'
                    >
                        <IconBlockSite
                            iconClass={`mo-new-icon-chevron-up-solid`}
                            onClick={() => scrollToTop(true)}
                        />
                    </div>
                )}
                <StaffHelperSite
                    detailId={undefined}
                    pageObject={pageObject}
                    pageSlug={pageSlug}
                />
            </IonContent>
        </IonPage>
    )
})
