// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDropzone,
} from 'react-dropzone'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldSerializer,
} from 'serializers/site'

// props
type TemplateBlock797ImageSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInputObj: FormFieldSerializer | undefined
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    pdfOk?: boolean
    pdfOnly?: boolean
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
}

// main
export const TemplateBlock797ImageSite: React.FC<TemplateBlock797ImageSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInputObj,
    handleInputChange,
    isEditHovered,
    pdfOk,
    pdfOnly,
    stylesNew,
    tabIndex,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [files, setFiles] = useState<any>([])
    let acceptObject = {}
    if (pdfOnly) {
        acceptObject = {
            'application/pdf': [],
        }
    } else if (pdfOk) {
        acceptObject = {
            'application/pdf': [],
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/svg+xml': [],
            'image/webp': [],
        }
    } else {
        acceptObject = {
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/webp': [],
        }
    }
    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptObject,
        maxSize: pdfOnly ? undefined : 20000000,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
            handleInputChange(formInputObj?.name!, acceptedFiles[0], formInputObj?.label!, formInputObj?.stripe_field_type)
        }
    })

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview))
    }, [files])

    return (
        <div
            id={blockId}
            className={`template-block-797${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={stylesNew}
        >
            <div {...getRootProps({ className: 't-797-dropzone-input' })}>
                {files[0]
                    ? (
                        pdfOnly
                            ? (
                                <div className='t-797-input'>
                                    <p className='mo-dropzone-helper-text'>PDF</p>
                                    <p className='mo-dropzone-helper-text mo-clamp-web5'>{files[0]?.preview}</p>
                                </div>
                            ) : (
                                <img
                                    alt=''
                                    className='t-797-preview-img'
                                    src={files[0]?.preview}
                                />
                            )
                    ) : (
                        <div className='t-797-input'>
                            <i className='m-icon mo-new-icon-cloud-upload-alt-solid' />
                            <p className='mo-dropzone-helper-text'>{reduxText[8695]}</p>
                        </div>
                    )}
                <input
                    {...getInputProps()}
                // tabIndex={tabIndex}
                />
            </div>
        </div>
    )
})
