// react components
import React from 'react'
import {
    IonButton,
} from '@ionic/react'

// props
type EcButtonSiteProps = {
    className?: string
    color?: 'primary' | 'secondary' | 'tertiary' | 'light' | 'medium' | 'dark'
    disabled?: boolean
    expand?: 'full' | 'block' | 'none'
    fill?: 'clear' | 'outline' | 'solid' | 'default'
    onClick?: () => void
    routerDirection?: 'none' | 'forward' | 'back' | 'root' | undefined
    routerLink?: string
    size?: 'small' | 'default' | 'large'
    text: string
}

// main
export const EcButtonSite: React.FC<EcButtonSiteProps> = React.memo(({
    className,
    color,
    disabled,
    expand,
    fill,
    onClick,
    routerDirection,
    routerLink,
    size,
    text,
}) => {

    return (
        <IonButton
            className={className}
            color={color || 'secondary'}
            disabled={disabled}
            expand={(expand === 'none') ? undefined : (expand || 'block')}
            fill={fill || 'solid'}
            onClick={onClick}
            routerDirection={routerDirection}
            routerLink={routerLink}
            size={size || 'default'}
        >
            {text}
        </IonButton>
    )
})
