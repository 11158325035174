// data
import {
    REDUX_TEXT_FETCH_MAIN_TEXT_SITE,
} from './mo_actions'
import {
    reduxTextStateSite,
} from './mo_state'

// main
const initialState: reduxTextStateSite = {
    data: {},
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_TEXT_FETCH_MAIN_TEXT_SITE: {
            return {
                ...state,
                data: action.payload,
            }
        }
        default:
            return state
    }
}
