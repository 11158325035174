// react components
import React from 'react'
import {
    useInView,
} from 'react-intersection-observer'
import {
    animated,
    config,
    useSpring,
} from 'react-spring'

// serializers
import {
    CustomCSSProperties,
} from 'serializers/site'

// props
type Animated780SiteProps = {
    blockId: string
    children: React.ReactNode
    className: string
    style: CustomCSSProperties | undefined
}

const AnimatedBox = animated.div

// main
export const Animated780Site: React.FC<Animated780SiteProps> = React.memo(({
    blockId,
    children,
    className,
    style,
}) => {

    const [ref, inView] = useInView({
        triggerOnce: false,
    })

    const animation = useSpring({
        ...style,
        config: style?.customAnimationConfig ? config[style.customAnimationConfig] : undefined,
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0px)' : `translateY(${Number(style?.customAnimationTranslateY) || 100}px)`,
    })

    return (
        <AnimatedBox
            id={blockId}
            className={`${className} animted-block`}
            style={animation}
            ref={ref}
        >
            {children}
        </AnimatedBox>
    )
})
