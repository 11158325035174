// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    Location,
} from 'history'

// data
import {
    api_url_portfolio_hub_content_detail_site,
    reduxAuthStateSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    parseQuerySite,
} from 'services'

// main
export function getHubContentDetailSite({
    axiosCancelToken,
    clusterIds,
    component,
    componentType,
    contentSlug,
    dispatch,
    location,
    noSearch,
    onFinish,
    portfolioId,
    reduxAuth,
    reduxModalSite,
    setAxiosCancelToken,
    setIsLoading,
    setPageContentHeader,
    setPageContentItems,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    clusterIds?: number[]
    component: string
    componentType: string
    contentSlug: string
    dispatch: React.Dispatch<any>
    location: Location
    noSearch?: boolean
    onFinish?: () => void
    portfolioId: number
    reduxAuth: reduxAuthStateSite
    reduxModalSite: reduxModalStateSite
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setIsLoading: React.Dispatch<boolean>
    setPageContentHeader: React.Dispatch<any>
    setPageContentItems: React.Dispatch<any>
}) {

    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    setIsLoading(true)
    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) getHubContentDetailSite')

    let pageContentApiUrl = getApiUrlSite(`${api_url_portfolio_hub_content_detail_site}${contentSlug}/?portfolio_id=${portfolioId}&component_type=${componentType}`, reduxModalSite)

    pageContentApiUrl += `&language_id=${reduxModalSite.languageId}`
    if (reduxModalSite.currencyId) pageContentApiUrl += `&currency_id=${reduxModalSite.currencyId}`

    if (!noSearch) {
        const searchParams = parseQuerySite(location.search)

        const search_query = searchParams.query
        if (search_query) pageContentApiUrl += `&search_query=${search_query}`

        const filter_query = searchParams.filter_query
        if (filter_query) pageContentApiUrl += `&filter_query=${filter_query}`
    }

    if (process.env.NODE_ENV === 'development') console.log(pageContentApiUrl)

    const formData: any = new FormData()
    if (clusterIds) {
        formData.append('ids_cluster', clusterIds?.join(','))
    }

    axios({
        cancelToken: source.token,
        data: formData,
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: clusterIds ? 'post' : 'get',
        url: pageContentApiUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            if (!response.data.is_private) {
                setPageContentItems([response.data.content])
                setPageContentHeader([response.data.header])
            }
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getHubContentDetailSite')
            if (onFinish) onFinish()
        })
        .catch((error) => {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getHubContentDetailSite')
            if (error.message === 'axios canceled') {
                if (process.env.NODE_ENV === 'development') console.log(error)
                return
            }
            axiosErrorHandlerSite({
                apiUrl: pageContentApiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'getHubContentDetailSite',
                skipAlertStatusCode: [403, 404],
                skipSendErrorStatusCode: [403, 404],
            })
        })
}
