// data
import {
    PLAYER_ON_DISMISS_SITE,
    PLAYER_ON_PLAY_PAUSE_INLINE_SITE,
    PLAYER_ON_PLAY_PAUSE_SITE,
    PLAYER_ON_READY_SITE,
    PLAYER_SET_CURRENT_TRACK_ALBUM_SITE,
    PLAYER_SET_CURRENT_TRACK_TRACK_SITE,
    PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM_SITE,
    PLAYER_SET_NEXT_TRACK_ARRAY_TRACK_SITE,
    PLAYER_SET_POSITION_SITE,
    reduxPlayerSetNextTrackArraySite,
} from './mo_actions'
import {
    reduxPlayerStateSite,
} from './mo_state'

// main
const initialState: reduxPlayerStateSite = {
    albumId: undefined,
    albumNextUrl: '',
    albumTrackArray: [],
    albumTrackIndex: undefined,
    currentTrack: {},
    currentTrackIndex: undefined,
    keepNextBlocks: undefined,
    originalTrackArray: [],
    originalTrackIndex: undefined,
    originalUrl: '',
    playerOrigin: '',
    playerPosition: '',
    playing: true,
    playingInlineId: undefined,
    trackArray: [],
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case PLAYER_ON_DISMISS_SITE: {
            return initialState
        }
        case PLAYER_ON_PLAY_PAUSE_INLINE_SITE: {
            return {
                ...state,
                keepNextBlocks: action.payload.keepNextBlocks,
                playingInlineId: action.payload.playingInlineId,
            }
        }
        case PLAYER_ON_PLAY_PAUSE_SITE: {
            return {
                ...state,
                playing: action.playOrPause,
            }
        }
        case PLAYER_ON_READY_SITE: {
            return {
                ...state,
                playing: true,
            }
        }
        case PLAYER_SET_CURRENT_TRACK_ALBUM_SITE:
        case PLAYER_SET_CURRENT_TRACK_TRACK_SITE: {

            // Album checks
            const checkIfInAlbum = state.albumTrackArray && state.albumTrackArray.findIndex((obj) => obj.id === action.currentTrack.id)
            let newAlbumTrackArray = []
            if (action.albumTrackArray && action.albumId === state.albumId) {
                if (state.albumTrackArray) newAlbumTrackArray = [...state.albumTrackArray, ...action.albumTrackArray]
            } else if (action.albumTrackArray && action.albumId !== state.albumId) {
                newAlbumTrackArray = action.albumTrackArray
            } else if (checkIfInAlbum && checkIfInAlbum > -1) {
                if (state.albumTrackArray) newAlbumTrackArray = state.albumTrackArray
            }
            const newAlbumTrackIndex: number = newAlbumTrackArray.findIndex((obj: any) => obj.id === action.currentTrack.id)

            // search next
            const newOriginalTrackArray = action.originalTrackArray || ((!state.originalTrackArray || state.originalTrackArray.length === 0) ? action.trackArray : state.originalTrackArray)
            const newOriginalTrackIndex: number = newOriginalTrackArray.findIndex((obj: any) => obj.id === (action.albumId ? action.albumId : action.currentTrack.id))
            if (action.originalUrl && ((newOriginalTrackIndex + 1) >= (newOriginalTrackArray.length - 1))) {
                action.dispatch2(reduxPlayerSetNextTrackArraySite(
                    action.originalUrl,
                    action.playerOrigin,
                    newOriginalTrackArray,
                    action.trackArray,
                    action.reduxAuth,
                    action.reduxModalSite,
                    action.dispatch2,
                ))
            }

            if (action.albumTrackArray) {
                const checkIfEmptyTrackArray = action.trackArray.length === 0
                return {
                    ...state,
                    albumId: action.albumId,
                    albumNextUrl: action.albumNextUrl,
                    albumTrackArray: action.albumTrackArray,
                    albumTrackIndex: newAlbumTrackIndex,
                    currentTrack: action.currentTrack,
                    currentTrackIndex: checkIfEmptyTrackArray ? newAlbumTrackIndex : action.currentTrackIndex,
                    originalTrackArray: checkIfEmptyTrackArray ? [action.albumObject] : newOriginalTrackArray,
                    originalTrackIndex: checkIfEmptyTrackArray ? 0 : newOriginalTrackIndex,
                    originalUrl: action.originalUrl,
                    playerOrigin: action.playerOrigin,
                    playerPosition: action.playerPosition,
                    trackArray: checkIfEmptyTrackArray ? action.albumTrackArray : action.trackArray,
                }
            }
            if (checkIfInAlbum && checkIfInAlbum > -1) {
                if (state.albumTrackArray && state.albumNextUrl && ((newAlbumTrackIndex + 1) >= (state.albumTrackArray.length - 1))) {
                    action.dispatch2(reduxPlayerSetNextTrackArraySite(
                        state.albumNextUrl,
                        action.playerOrigin,
                        newOriginalTrackArray,
                        action.trackArray,
                        action.reduxAuth,
                        action.reduxModalSite,
                        action.dispatch2,
                        state.albumId,
                        state.albumTrackArray,
                    ))
                }
                return {
                    ...state,
                    albumTrackIndex: newAlbumTrackIndex,
                    currentTrack: action.currentTrack,
                    currentTrackIndex: action.currentTrackIndex,
                    originalTrackArray: newOriginalTrackArray,
                    originalTrackIndex: action.originalTrackIndex || state.originalTrackIndex,
                    originalUrl: action.originalUrl,
                    playerOrigin: action.playerOrigin,
                    playerPosition: action.playerPosition,
                    trackArray: action.trackArray,
                }
            }
            return {
                ...state,
                albumId: undefined,
                albumNextUrl: '',
                albumTrackArray: [],
                albumTrackIndex: undefined,
                currentTrack: action.currentTrack,
                currentTrackIndex: action.currentTrackIndex,
                originalTrackArray: newOriginalTrackArray,
                originalTrackIndex: newOriginalTrackIndex,
                originalUrl: action.originalUrl,
                playerOrigin: action.playerOrigin,
                playerPosition: action.playerPosition,
                trackArray: action.trackArray,
            }
        }
        case PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM_SITE:
        case PLAYER_SET_NEXT_TRACK_ARRAY_TRACK_SITE: {
            if (action.nextType === 'album') {
                return {
                    ...state,
                    albumNextUrl: action.newAlbumNextUrl,
                    albumTrackArray: action.newAlbumTrackArray,
                    trackArray: action.newTrackArray,
                }
            } else {
                return {
                    ...state,
                    originalTrackArray: action.newOriginalTrackArray,
                    trackArray: action.newTrackArray,
                }
            }
        }
        case PLAYER_SET_POSITION_SITE: {
            return {
                ...state,
                playerPosition: action.payload,
            }
        }
        default:
            return state
    }
}
