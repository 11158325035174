// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
} from 'serializers/site'

// props
type TextInputSiteProps = {
    disabled?: boolean
    error: string | undefined
    label: string
    maxValue?: number
    minValue?: number
    name: string
    onChange: React.Dispatch<any>
    placeholder?: string
    styles?: CustomCSSProperties | undefined
    stylesLabel?: CustomCSSProperties | undefined
    stylesWrapper?: CustomCSSProperties | undefined
    type?: 'number' | 'time' | 'text' | 'tel' | 'url' | 'email' | 'search' | 'date' | 'password'
    value: string
}

// main
export const TextInputSite: React.FC<TextInputSiteProps> = React.memo(({
    disabled,
    error,
    label,
    maxValue,
    minValue,
    name,
    onChange,
    placeholder,
    styles,
    stylesLabel,
    stylesWrapper,
    type,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    function onInputChange(value: string | null | undefined) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TextInputSite',
                'onInputChange',
            ))
        }
    }

    return (
        <div
            className={`text-input-site ${deviceType}`}
            style={stylesWrapper}
        >
            {label && (
                <div className='tis-form-label' >
                    <label
                        className='tis-form-label-wrap'
                        htmlFor={name}
                    >
                        <span style={stylesLabel}>
                            {label}
                        </span>
                    </label>
                </div>
            )}
            <input
                className={`tis-input ${disabled ? 'disabled' : ''}`}
                disabled={disabled}
                max={maxValue ? `${maxValue}` : undefined}
                min={minValue ? `${minValue}` : undefined}
                name={name}
                onChange={(e) => onInputChange(e.target.value)}
                placeholder={placeholder}
                style={styles}
                type={type || 'text'}
                value={value}
            />
            {error && <span className='tis-error'>{error}</span>}
        </div>
    )
})
