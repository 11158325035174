// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDropzone,
} from 'react-dropzone'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// props
type ImageInput819SiteProps = {
    error: string | undefined
    label: string
    name: string
    onChange: any
    pdfOk?: boolean
    pdfOnly?: boolean
    value: any
}

// main
export const ImageInput819Site: React.FC<ImageInput819SiteProps> = React.memo(({
    error,
    label,
    name,
    onChange,
    pdfOk,
    pdfOnly,
    value,
}) => {

    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [files, setFiles] = useState<any>([])
    let acceptObject = {}
    if (pdfOnly) {
        acceptObject = {
            'application/pdf': [],
        }
    } else if (pdfOk) {
        acceptObject = {
            'application/pdf': [],
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/svg+xml': [],
            'image/webp': [],
        }
    } else {
        acceptObject = {
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/webp': [],
        }
    }
    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptObject,
        maxSize: pdfOnly ? undefined : 20000000,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
            onChange({
                name: name,
                value: acceptedFiles[0],
            })
        }
    })

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview))
    }, [files])

    return (
        <div className={`input-819 image-input-819 ${deviceType}`}>
            <span className={`input-819-label ${deviceType}`}>{label}</span>
            <div {...getRootProps({ className: 'ii-819-dropzone-input' })}>
                {(files[0] || value)
                    ? (
                        pdfOnly
                            ? (
                                <div className='ii-819-input'>
                                    <p className='mo-dropzone-helper-text'>PDF</p>
                                    <p className='mo-dropzone-helper-text mo-clamp-web5'>{files[0] ? files[0].preview : (value.preview || value)}</p>
                                </div>
                            ) : (
                                <img
                                    alt=''
                                    className='ii-819-preview-img'
                                    src={files[0] ? files[0].preview : (value.preview || value)}
                                />
                            )
                    ) : (
                        <div className='ii-819-input'>
                            <i className='m-icon mo-new-icon-cloud-upload-alt-solid' />
                            <p className='mo-dropzone-helper-text'>{reduxText[8695]}</p>
                        </div>
                    )}
                <input
                    {...getInputProps()}
                />
            </div>
            {error && <span className='input-819-error'>{error}</span>}
        </div>
    )
})
