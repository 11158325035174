// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LightboxCustomSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalLightBoxOnNextSite,
    reduxModalLightBoxOnPrevSite,
    reduxModalLightBoxShowHideSite,
} from 'data'

// main
export const LightboxSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const lightBox = reduxModalSite.lightBox

    if (lightBox?.isOpen) {
        const images = lightBox.images
        const imagesLength = images.length
        const photoIndex = lightBox.photoIndex
        const isSingle = imagesLength === 1
        return (
            <LightboxCustomSite
                discourageDownloads={lightBox.allowDownload ? false : true}
                mainSrc={images[photoIndex].get_image_site_hq}
                nextSrc={isSingle ? undefined : images[(Number(photoIndex) + 1) % imagesLength].get_image_site_hq}
                onCloseRequest={() => dispatch(reduxModalLightBoxShowHideSite(false))}
                onMoveNextRequest={() => dispatch(reduxModalLightBoxOnNextSite())}
                onMovePrevRequest={() => dispatch(reduxModalLightBoxOnPrevSite())}
                prevSrc={isSingle ? undefined : images[(Number(photoIndex) + Number(imagesLength) - 1) % imagesLength].get_image_site_hq}
            />
        )
    }
    return null
}
