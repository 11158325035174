// react components
import React, {
    SVGProps,
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    geoEquirectangular,
    geoPath,
} from 'd3-geo'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    media_url_site,
    media_version_site,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
} from 'services'

// props
type MapCountry = {
    countryCode: string
    cursor: CustomCSSProperties['cursor']
    isCountryActive: boolean
    marker?: {
        extraInfo?: any
        lat?: number
        lng?: number
    }
    svg: SVGProps<SVGPathElement>
}

type TemplateBlock799GeoJsonSiteProps = {
    height: number
    markers?: {
        extraInfo?: any
        lat?: number
        lng?: number
    }[]
    object: PortfolioPageContentListSiteSerializer
    onMarkerClick?: (e: any) => void
    styles: CustomCSSProperties | undefined
    width: number
}

// main
export const TemplateBlock799GeoJsonSite: React.FC<TemplateBlock799GeoJsonSiteProps> = React.memo(({
    height,
    markers,
    object,
    onMarkerClick,
    styles,
    width,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [mapCountries, setMapCountries] = useState<MapCountry[]>([])
    const [geoJson, setGeoJson] = useState<{
        type: string
        name: string
        crs: {
            type: string
            properties: {
                name: string
            }
        },
        features: {
            type: string
            properties: {
                ADMIN: string
                ISO_A3: string
                CONTINENT: string
                REGION_UN: string
                SUBREGION: string
                REGION_WB: string
                ISO_A2: string
            }
            geometry: {
                type: string
                coordinates: number[][][][]
            }
        }[]
    }>()

    const defaultColor = '#ddd'

    useEffect(() => {
        getGeoJsonData()
    }, [])

    useEffect(() => {
        constructContries()
    }, [
        geoJson,
        height,
        markers,
        object.data_json?.use_countries_2,
        styles,
        width,
    ])

    function getGeoJsonData() {
        try {
            const apiUrl = `${media_url_site}global/data/geojson-world.json${media_version_site}`
            axios({
                method: 'get',
                url: apiUrl,
            })
                .then((response) => {
                    setGeoJson(response.data)
                })
                .catch((error) => {
                    axiosErrorHandlerSite({
                        apiUrl: apiUrl,
                        component: 'TemplateBlock799GeoJsonSite',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'getGeoJsonData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799GeoJsonSite',
                'getGeoJsonData',
            ))
        }
    }

    function constructContries() {
        try {
            if (!markers || !geoJson) return
            const projection = geoEquirectangular().fitSize([width, height], geoJson as any)
            const geoPathGenerator = geoPath().projection(projection)

            if (object.data_json?.use_countries_2) {
                const activeCountriesGoupDict: any = {}
                markers.map(val => {
                    val.extraInfo?.countries_2?.map((val2: any) => {
                        activeCountriesGoupDict[val2.country] = val
                    })
                })

                const countries = geoJson.features.map((feature) => {
                    let svgProps: SVGProps<SVGPathElement> = {
                        d: geoPathGenerator(feature as any) || '',
                        stroke: defaultColor,
                        fill: defaultColor,
                    }

                    let isCountryActive = false

                    if (activeCountriesGoupDict[feature.properties.ISO_A2!]) {
                        isCountryActive = true
                        svgProps = {
                            ...svgProps,
                            stroke: styles?.svgStrokeColor || defaultColor,
                            fill: styles?.svgFillColor || defaultColor,
                        }
                    }

                    return {
                        marker: activeCountriesGoupDict[feature.properties.ISO_A2!],
                        countryCode: feature.properties.ISO_A2,
                        cursor: 'initial',
                        isCountryActive,
                        svg: svgProps,
                    } as MapCountry
                })
                setMapCountries(countries || [])

            } else {
                const activeCountriesDict: { [key: string]: any } =
                    markers.reduce((a, c) => {
                        return {
                            ...a,
                            [c.extraInfo.address_json[0].countryCode]: c,
                        }
                    }, {})

                const countries = geoJson.features.map((feature) => {
                    let svgProps: SVGProps<SVGPathElement> = {
                        d: geoPathGenerator(feature as any) || '',
                        stroke: defaultColor,
                        fill: defaultColor,
                    }

                    let isCountryActive = false

                    if (activeCountriesDict[feature.properties.ISO_A2!]) {
                        isCountryActive = true
                        svgProps = {
                            ...svgProps,
                            stroke: styles?.svgStrokeColor || defaultColor,
                            fill: styles?.svgFillColor || defaultColor,
                        }
                    }

                    return {
                        marker: activeCountriesDict[feature.properties.ISO_A2!],
                        countryCode: feature.properties.ISO_A2,
                        cursor: 'initial',
                        isCountryActive,
                        svg: svgProps,
                    } as MapCountry
                })
                setMapCountries(countries || [])
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799GeoJsonSite',
                'constructContries',
            ))
        }
    }

    function handleMouseLeaveCountry(country: MapCountry) {
        try {
            setMapCountries(mapCountries
                .map(m => {
                    if (isMatchCountry(m, country)) {
                        return {
                            ...m,
                            cursor: 'initial',
                            svg: {
                                ...m.svg,
                                stroke: styles?.svgStrokeColor || defaultColor,
                                fill: styles?.svgFillColor || defaultColor,
                            }
                        }
                    }
                    return m
                }))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799GeoJsonSite',
                'handleMouseOverCountry',
            ))
        }
    }

    function handleMouseOverCountry(country: MapCountry) {
        try {
            setMapCountries(mapCountries
                .map(m => {
                    if (isMatchCountry(m, country)) {
                        return {
                            ...m,
                            cursor: 'pointer',
                            svg: {
                                ...m.svg,
                                stroke: styles?.svgHoverStrokeColor || defaultColor,
                                fill: styles?.svgHoverFillColor || defaultColor,
                            }
                        }
                    }
                    return m
                }))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799GeoJsonSite',
                'handleMouseOverCountry',
            ))
        }
    }

    function isMatchCountry(source: MapCountry, target: MapCountry) {
        try {
            if (object.data_json?.use_countries_2) {
                return source.isCountryActive
                    && target.isCountryActive
                    && source.marker?.extraInfo?.id === target.marker?.extraInfo?.id
            }
            return source.isCountryActive
                && target.isCountryActive
                && source.countryCode === target.countryCode
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799GeoJsonSite',
                'handleMouseOverCountry',
            ))
        }
    }

    return (
        <svg
            className='t-799-geojson-map'
            width={width}
            height={height}
        >
            {mapCountries.map(country => {
                return (
                    <path
                        key={country.countryCode}
                        id={country.countryCode}
                        {...country.svg as any}
                        onClick={onMarkerClick ? () => onMarkerClick(country.marker) : undefined}
                        onMouseLeave={() => handleMouseLeaveCountry(country)}
                        onMouseOver={() => handleMouseOverCountry(country)}
                        style={{ cursor: country.cursor }}
                    />
                )
            })}
        </svg>
    )
})
