// react components
import thunk from 'redux-thunk'
import {
    applyMiddleware,
    createStore,
} from 'redux'
import {
    createLogger,
} from 'redux-logger'

// reducers
import createRootReducer from './mo_reducer'

// actions
import * as reduxTextSiteAction from './redux/text/mo_actions'
import * as reduxModalSiteAction from './redux/modal/mo_actions'

const ignored_actions_type_log = [
    reduxModalSiteAction.REDUX_MODAL_ERROR_SHOW_HIDE_SITE,
    reduxModalSiteAction.REDUX_MODAL_SET_ACTIVE_DROPDOWN_ID_SITE,
    reduxModalSiteAction.REDUX_MODAL_SET_NAVBAR_HEIGHT_SITE,
    reduxModalSiteAction.REDUX_MODAL_SET_NAVBAR_IS_TRANSPARENT_SITE,
    reduxModalSiteAction.REDUX_MODAL_SET_NAVBAR_OPEN_ID_SITE,
    reduxModalSiteAction.REDUX_MODAL_SET_NAVBAR_TYPE_SITE,
    reduxModalSiteAction.REDUX_MODAL_SET_ROOT_URL_SITE,
    reduxTextSiteAction.REDUX_TEXT_FETCH_MAIN_TEXT_SITE,
]

export default () => {

    let store
    // if (process.env.NODE_ENV === 'development') {
    if (process.env.NODE_ENV === 'test') {
        store = createStore(
            createRootReducer, {},
            applyMiddleware(
                thunk,
                createLogger({
                    predicate: (getState, action) => !ignored_actions_type_log.includes(action.type),
                }),
            ),
        )
    } else {
        store = createStore(
            createRootReducer, {},
            applyMiddleware(
                thunk,
            ),
        )
    }


    return store
}
