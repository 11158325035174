// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    Gallery,
    ListSpinnerSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    ImageHelper817Site,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getMixedContentDataSite,
    getPageContentDataSite,
} from 'services'

// props
type InfiniteRow817SiteProps = {
    detailId: string | undefined
    devId: string | undefined
    infiniteContentId: number
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageNumberNew: number
    pageSlug: string | undefined
    parentArray?: number[]
    parentDropdownId?: number
    stylesNew: any
}

// main
export const InfiniteRow817Site: React.FC<InfiniteRow817SiteProps> = React.memo(({
    detailId,
    devId,
    infiniteContentId,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    mainParentId,
    object,
    pageNumberNew,
    pageSlug,
    parentArray,
    parentDropdownId,
    stylesNew,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxFormSitehoveredBlockId = useSelector((state: defaultReduxState) => state.reduxFormSite.hoveredBlockId)
    const reduxFormSiteeditAutoSave = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSave)
    const reduxFormSiteeditAutoSaved = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSaved)

    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [isLoading, setIsLoading] = useState(true)
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[] | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)

    function getPortfolioData() {
        try {
            if (pageSlug === 'custom-page-detail' || !reduxCacheSite.portfolio) {
                getMixedContentDataSite({
                    component: 'InfiniteRow817Site',
                    dispatch,
                    infiniteContentId,
                    pageNumberNew,
                    reduxAuth,
                    reduxModalSite,
                    setIsLoading,
                    setItems: setPageContentItems,
                })
            } else if (reduxCacheSite.portfolio) {
                setDisableInfiniteScroll(true)
                getPageContentDataSite({
                    axiosCancelToken: undefined,
                    component: 'InfiniteRow817Site',
                    detailId,
                    devId,
                    dispatch,
                    errorStatus,
                    infiniteContentId,
                    location,
                    pageNumberNew,
                    pageSlug: pageSlug || reduxCacheSite.portfolio.home_page_slug!,
                    portfolioObject: reduxCacheSite.portfolio,
                    reduxAuth,
                    reduxCacheSite,
                    reduxModalSite,
                    setErrorStatus,
                    setIsLoading,
                    setPageContentItems,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'InfiniteRow817Site',
                'getPortfolioData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                getPortfolioData()
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'InfiniteRow817Site',
                'onSearchNext',
            ))
        }
    }

    const content = pageContentItems?.[0]?.content
    const contentIds = pageContentItems?.[0]?.content[0]?.content_ids || []

    return (
        <React.Fragment>
            {isLoading && (!pageContentItems || (pageContentItems?.length === 0)) && (
                <VisibilitySensor
                    active={!disableInfiniteScroll}
                    intervalDelay={1000}
                    offset={{ bottom: -1000 }}
                    onChange={onSearchNext}
                    partialVisibility
                >
                    <div className='infinite-scroll-helper-site-empty'>
                        <ListSpinnerSite isLoading />
                    </div>
                </VisibilitySensor>
            )}
            {contentIds.length > 0 && (
                <Gallery
                    // @ts-ignore
                    columns={stylesNew?.columnsNumber}
                    direction={stylesNew?.directionGallery}
                    limitNodeSearch={stylesNew?.columnsNumber}
                    margin={stylesNew?.marginGallery}
                    photos={contentIds}
                    // @ts-ignore
                    renderImage={(props) => (
                        <ImageHelper817Site
                            {...props}
                            columnsNumber={stylesNew?.columnsNumber}
                            content={content}
                            contentIds={contentIds}
                            detailId={detailId}
                            devId={devId}
                            isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                            isInDetailPage={isInDetailPage}
                            isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                            mainParentId={mainParentId || object.id}
                            object={object}
                            pageSlug={pageSlug}
                            parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                            parentDropdownId={parentDropdownId}
                            reduxFormSiteeditAutoSave={reduxFormSiteeditAutoSave}
                            reduxFormSiteeditAutoSaved={reduxFormSiteeditAutoSaved}
                            reduxFormSitehoveredBlockId={reduxFormSitehoveredBlockId}
                            withText={stylesNew?.withText}
                        />
                    )}
                />
            )}
            {object.infinite_list_page_size && contentIds.length >= object.infinite_list_page_size && (
                <InfiniteRow817Site
                    detailId={detailId}
                    devId={devId}
                    infiniteContentId={object.id!}
                    isInComponent={isInComponent}
                    isInDetailPage={isInDetailPage}
                    mainParentId={mainParentId}
                    object={object}
                    pageNumberNew={(pageNumberNew || 1) + 1}
                    pageSlug={pageSlug}
                    parentArray={parentArray}
                    parentDropdownId={parentDropdownId}
                    stylesNew={stylesNew}
                />
            )}
        </React.Fragment>
    )
})
