// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
} from 'serializers/site'

// props
type ImageHelperSiteProps = {
    allowRightClick?: boolean
    alt: string | undefined
    className?: string
    dominant_color: string | undefined
    id?: string
    normal?: boolean
    onClick?: () => void
    onEditHover?: (e: any) => void
    src: string | undefined
    styles?: CustomCSSProperties | undefined
}

// main
export const ImageHelperSite: React.FC<ImageHelperSiteProps> = React.memo(({
    allowRightClick,
    alt,
    className,
    dominant_color,
    id,
    normal,
    onClick,
    onEditHover,
    src,
    styles,
}) => {

    const dispatch = useDispatch()
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)
    const imageRef = useRef<HTMLImageElement>(null)

    useEffect(() => {
        if (reduxFormSitenavigationMode) setNewStyles(styles)
    }, [
        reduxFormSitenavigationMode,
        styles,
    ])

    const [newStyles, setNewStyles] = useState<CustomCSSProperties | undefined>(getInitialStyles())

    const newNormal = normal || (reduxFormSitenavigationMode === 'edit')

    function getInitialStyles() {
        try {
            let newStyles2: CustomCSSProperties = {}
            if (styles) {
                newStyles2 = Object.assign({}, styles)
                newStyles2.backgroundColor = dominant_color ? `rgb${dominant_color}` : '#2b2c31'
                newStyles2.objectPosition = '-100vw'
                return newStyles2
            }
            newStyles2.backgroundColor = dominant_color ? `rgb${dominant_color}` : '#2b2c31'
            newStyles2.objectPosition = '-100vw'
            return newStyles2
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ImageHelperSite',
                'getInitialStyles',
            ))
        }
    }

    function onHandleEditHover() {
        try {
            if (onEditHover) {
                const imageBounds = imageRef.current?.getBoundingClientRect()
                const parentBounds = imageRef.current?.parentElement?.getBoundingClientRect()
                if (imageBounds && parentBounds) {
                    const position = {
                        height: imageBounds.height,
                        left: imageBounds.left - parentBounds.left,
                        top: imageBounds.top - parentBounds.top,
                        width: imageBounds.width,
                    }
                    onEditHover(position)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ImageHelperSite',
                'onHandleEditHover',
            ))
        }
    }

    return (
        <img
            id={id}
            alt={alt || 'Just The Web'}
            className={`${className || ''}${src ? '' : ' visibility-hidden'}`}
            onClick={onClick}
            onContextMenu={allowRightClick ? undefined : (e) => e.preventDefault()}
            onDragStart={allowRightClick ? undefined : (e) => e.preventDefault()}
            onError={(e: any) => {
                e.target.alt = ''
                e.target.src = reduxText[7459]
            }}
            onLoad={newNormal ? undefined : () => setNewStyles(styles)}
            onMouseOver={onEditHover ? () => onHandleEditHover() : undefined}
            ref={imageRef}
            src={src}
            style={newNormal ? styles : newStyles}
        />
    )
})
