// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// components
import {
    ImageHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxSalesRemoveFromCartSite,
} from 'data'

// pages
import {
    PortfolioCheckoutSite,
} from 'pages'

// services
import {
} from 'services'

// main
export const CheckoutSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    if (!reduxCacheSite.portfolio?.is_eshop) return null

    return (
        <div className={`checkout-site ${deviceType}`}>
            <PortfolioCheckoutSite />
            <div className={`cs-content ${deviceType}`}>
                <div className={`cs-cart-list ${deviceType}`}>
                    <p className={`cs-cart-total ${deviceType}`}>
                        {reduxText[7465]}
                    </p>
                    {reduxSalesSite.salesLines.length === 0 && (
                        <p className={`cs-cart-empty ${deviceType}`}>
                            {reduxText[7466]}
                        </p>
                    )}
                    {reduxSalesSite.salesLines.map((val, i) => (
                        <div
                            key={`cs-lines-${i}`}
                            className={`cs-lines ${deviceType}`}
                        >
                            <Link
                                className={`cs-image-wrap ${deviceType}`}
                                to={val.absolute_url}
                            >
                                <ImageHelperSite
                                    alt={val.product.name}
                                    className={`cs-image ${deviceType}`}
                                    dominant_color={undefined}
                                    src={val.product.get_image_site_lq}
                                />
                            </Link>
                            <div className={`cs-info ${deviceType}`}>
                                <div className={`cs-info-wrap ${deviceType}`}>
                                    <span className={`cs-name ${deviceType}`}>
                                        {val.product.name}
                                    </span>
                                    {val.variant && (
                                        <span className={`cs-variant ${deviceType}`}>
                                            {val.variant.name}
                                        </span>
                                    )}
                                    {val.package_option_items?.map((val) => (
                                        <span
                                            key={val.id}
                                            className={`cs-variant ${deviceType}`}
                                        >
                                            {val.product?.name}
                                        </span>
                                    ))}
                                    <span className={`cs-price ${deviceType}`}>
                                        {val.qty} x {val.unitPrice} {val.currency_symbol}
                                    </span>
                                </div>
                                <span className={`cs-line-total ${deviceType}`}>
                                    {val.lineTotal} {val.currency_symbol}
                                </span>
                            </div>
                            <div className={`cs-actions ${deviceType}`}>
                                <i
                                    onClick={() => dispatch(reduxSalesRemoveFromCartSite(i, reduxCacheSite.portfolio!))}
                                    className={`mo-new-icon-trash-solid ${deviceType}`}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`cs-footer ${deviceType}`}>
                    <p>{reduxText[7467]}{' '}{Number(reduxSalesSite.subTotal.toFixed(2))} {reduxSalesSite.currency_symbol}</p>
                </div>
            </div>
        </div>
    )
}
