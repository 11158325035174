// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    useIonViewWillLeave,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    PlayerSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxPlayerOnPlayPauseInlineSite,
    reduxPlayerOnPlayPauseSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template782SiteSerializer,
} from 'serializers/site'

// services
import {
    getModalAbsoluteUrlSite,
    getResponsiveImageSite,
    getStylesToRetrieveNew,
    onClickIsModalSite,
} from 'services'

// props
type TemplateBlock782SiteProps = {
    blockId: string
    content: Template782SiteSerializer | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentObject?: PortfolioPageContentListSiteSerializer
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock782Site: React.FC<TemplateBlock782SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentObject,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxPlayerSiteplayingInlineId = useSelector((state: defaultReduxState) => state.reduxPlayerSite.playingInlineId)

    const deviceType = reduxModalSite.deviceType

    const [imageIsLoaded, setImageIsLoaded] = useState(false)
    const [imageHeight, setImageHeight] = useState<number>(0)
    const [imageWidth, setImageWidth] = useState<number>(0)
    const [isInView, setIsInView] = useState(true)
    const [locationPathname] = useState<string>(location.pathname)
    const [stylesNew, setStylesNew] = useState<{
        backgroundColor?: string
        objectPosition?: string
        position: string | undefined
        visibility: string | undefined
        zIndex: number | undefined
    } | undefined>(getInitialStyles())

    useEffect(() => {
        if (imageIsLoaded) setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        reduxPlayerSiteplayingInlineId,
        styles,
        stylesEdit,
    ])

    useEffect(() => {
        if (locationPathname === location.pathname) {
            setIsInView(true)
            const playing = getStylesToRetrieveNew(reduxModalSite, styles, stylesEdit, 'playing')
            if (playing === 'true' && content?.id) {
                if (process.env.NODE_ENV === 'development') console.log(location.pathname)
                dispatch(reduxPlayerOnPlayPauseInlineSite(content.id, true))
                dispatch(reduxPlayerOnPlayPauseSite(false))
            }
        }
    }, [
        location.pathname,
    ])

    const imageRef = useRef<HTMLImageElement>(null)

    useIonViewWillLeave(() => {
        setIsInView(false)
        dispatch(reduxPlayerOnPlayPauseInlineSite(undefined))
    })

    function applyStyles() {
        try {
            return {
                position: reduxPlayerSiteplayingInlineId === content?.id ? 'fixed' : undefined,
                visibility: reduxPlayerSiteplayingInlineId === content?.id ? 'hidden' : 'visible',
                zIndex: reduxPlayerSiteplayingInlineId === content?.id ? -99 : undefined,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock782Site',
                'applyStyles',
            ))
        }
    }

    function getInitialStyles() {
        try {
            const tempStyles: {
                backgroundColor?: string,
                objectPosition?: string,
                position: string | undefined,
                visibility: string | undefined,
                zIndex: number | undefined,
            } | undefined = applyStyles()
            tempStyles!.backgroundColor = content?.get_dominant_color ? `rgb${content?.get_dominant_color}` : '#2b2c31'
            tempStyles!.objectPosition = '-100vw'
            return tempStyles
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock782Site',
                'getInitialStyles',
            ))
        }
    }

    function openLightbox(
        is_playable: boolean | undefined,
        id: number | undefined,
    ) {
        try {
            if (!content) return
            if (object.action === 'play' && is_playable) {
                dispatch(reduxPlayerOnPlayPauseInlineSite(id))
                dispatch(reduxPlayerOnPlayPauseSite(false))
            } else {
                history.push(getModalAbsoluteUrlSite(content?.absolute_site_url!))
                onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, content, object, [content])
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock782Site',
                'openLightbox',
            ))
        }
    }

    let wto: any

    function setImageDimension() {
        try {
            clearTimeout(wto)
            wto = setTimeout(() => {
                if (imageRef?.current) {
                    setImageHeight(imageRef.current.clientHeight)
                    setImageWidth(imageRef.current.clientWidth)
                    setStylesNew(applyStyles())
                    setImageIsLoaded(true)
                }
            }, 100)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock782Site',
                'setImageDimension',
            ))
        }
    }

    return (
        <React.Fragment>
            {content && isInView && (reduxPlayerSiteplayingInlineId === content?.id) && (
                <div
                    id={blockId}
                    className={`template-block-782 ${deviceType}${getStylesToRetrieveNew(reduxModalSite, styles, stylesEdit, 'objectFit') === 'cover' ? ' cover' : ''}`}
                    style={{
                        height: imageHeight,
                        width: imageWidth,
                    }}
                >
                    <PlayerSite
                        controls={object.data_json?.video_no_controls ? false : true}
                        loop={getStylesToRetrieveNew(reduxModalSite, styles, stylesEdit, 'playing') === 'true' ? true : false}
                        muted={getStylesToRetrieveNew(reduxModalSite, styles, stylesEdit, 'playing') === 'true' ? true : false}
                        object={content}
                        playsinline={getStylesToRetrieveNew(reduxModalSite, styles, stylesEdit, 'playing') === 'true' ? true : false}
                    />
                </div>
            )}
            <img
                id={blockId}
                key={content?.id}
                alt={content?.name}
                className={`template-block-782${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
                onLoad={() => setImageDimension()}
                onClick={() => openLightbox(content?.is_playable, content?.id)}
                ref={imageRef}
                src={content ? getResponsiveImageSite(reduxModalSite, content, 'get_image_site_hq', 'get_image_site_hq') : undefined}
                // @ts-ignore
                style={stylesNew}
            />
            {parentObject && (
                <EditBlockSite
                    isInComponent={isInComponent}
                    mainParentId={mainParentId || parentObject.id!}
                    object={parentObject}
                    parentArray={parentArray}
                    parentStyles={parentStyles}
                />
            )}
        </React.Fragment>
    )
})
