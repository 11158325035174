// react components
import React, {
    useEffect,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesActivitySite,
} from 'data'

// props
type BillingHelper819SiteSiteProps = {
    test?: string
}

// main
export const BillingHelper819SiteSite: React.FC<BillingHelper819SiteSiteProps> = React.memo(({
    test,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const fields = reduxSalesSite.activityData?.contactInfo

    useEffect(() => {
        if (!reduxSalesSite.activityData?.contactInfo?.email) {
            dispatch(reduxSalesActivitySite({
                ...reduxSalesSite.activityData,
                contactInfo: {
                    ...reduxSalesSite.activityData?.contactInfo,
                    email: reduxAuth?.settings?.activity_parent?.email || '',
                    first_name: reduxAuth?.settings?.activity_parent?.first_name || '',
                    last_name: reduxAuth?.settings?.activity_parent?.last_name || '',
                }
            }))
        }
    }, [])

    function onSetContactInfo(value: any) {
        try {
            const toReturnData = {
                ...reduxSalesSite.activityData,
                contactInfo: {
                    ...reduxSalesSite.activityData?.contactInfo,
                    [value.target.name]: value.target.value,
                },
                errorMessage: '',
            }
            // @ts-ignore
            dispatch(reduxSalesActivitySite(toReturnData))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'BillingHelper819SiteSite',
                'onSetContactInfo',
            ))
        }
    }

    return (
        <div className={`t-819-contact-info ${deviceType}`}>
            <span className={`t-819-contact-info-title ${deviceType}`}>{reduxText[8743]}</span>
            <div className={`t-819-contact-info-inputs ${deviceType}`}>
                <div className={`t-819-contact-info-input-wrap ${deviceType}`}>
                    <span className={`t-819-contact-info-label ${deviceType}`}>{reduxText[8744]}</span>
                    <input
                        className={`t-819-contact-info-input ${deviceType}`}
                        name='first_name'
                        onChange={(e) => onSetContactInfo(e)}
                        value={fields?.first_name || ''}
                    />
                </div>
                <div className={`t-819-contact-info-input-wrap ${deviceType}`}>
                    <span className={`t-819-contact-info-label ${deviceType}`}>{reduxText[8745]}</span>
                    <input
                        className={`t-819-contact-info-input ${deviceType}`}
                        name='last_name'
                        onChange={(e) => onSetContactInfo(e)}
                        value={fields?.last_name || ''}
                    />
                </div>
            </div>
            <div className={`t-819-contact-info-email-input-wrap ${deviceType}`}>
                <span className={`t-819-contact-info-label ${deviceType}`}>{reduxText[8746]}</span>
                <input
                    className={`t-819-contact-info-input ${deviceType}`}
                    name='email'
                    onChange={(e) => onSetContactInfo(e)}
                    value={fields?.email || ''}
                />
            </div>
        </div>
    )
})
