// data
import {
    reduxModalStateSite,
} from 'data'

// main
export function getApiUrlSite(apiUrl: string, reduxModalSite: reduxModalStateSite) {
    return `${reduxModalSite.apiRootUrl}v1/5/${apiUrl}`
}

export function getApiUrlSiteV2(apiUrl: string, reduxModalSite: reduxModalStateSite) {
    return `${reduxModalSite.apiRootUrl}v2/5/${apiUrl}`
}
