// react components
import React from 'react'
import {
    isValid,
    parseISO,
} from 'date-fns'
import {
    ar,
    de,
    enGB,
    es,
    fr,
    it,
    nl,
    pl,
    pt,
    ru,
    tr,
} from 'date-fns/locale'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    languageIdObjectSite,
    reduxModalErrorEventHandlerSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldSerializer,
} from 'serializers/site'

// props
type TemplateBlock797DateTimeSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInputObj: FormFieldSerializer | undefined
    format: 'date' | 'datetime' | 'time'
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
    value: any
}

// main
export const TemplateBlock797DateTimeSite: React.FC<TemplateBlock797DateTimeSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInputObj,
    format,
    handleInputChange,
    isEditHovered,
    reduxModalSite,
    stylesNew,
    tabIndex,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function onInputChange(e: any) {
        try {
            let dateFormat
            if (e) {
                if (format === 'time') {
                    dateFormat = moment(e).format('HH:mm')
                } else if (format === 'datetime') {
                    dateFormat = moment(e).format()
                } else {
                    dateFormat = moment(e).format('YYYY-MM-DD')
                }
            } else {
                dateFormat = ''
            }
            handleInputChange(formInputObj?.name!, dateFormat, formInputObj?.label!, formInputObj?.stripe_field_type)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock797DateTimeSite',
                'onInputChange',
            ))
        }
    }

    const localeDict: any = {
        ar,
        de,
        en: enGB,
        es,
        fr,
        it,
        nl,
        pl,
        pt,
        ru,
        tr,
    }

    if (format === 'date') {
        return (
            <div style={stylesNew}>
                <DatePicker
                    id={blockId}
                    className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                    dateFormat={'dd/MM/yyyy'}
                    locale={localeDict[reduxModalSite.languageId ? languageIdObjectSite[reduxModalSite.languageId] : 'en']}
                    onChange={date => onInputChange(date)}
                    placeholderText={formInputObj?.placeholder === 'true' ? formInputObj?.label : ''}
                    popperClassName='di-popper'
                    selected={isValid(parseISO(value)) ? parseISO(value) : null}
                    tabIndex={tabIndex}
                />
            </div>
        )
    }

    if (format === 'datetime') {
        return (
            <div style={stylesNew}>
                <DatePicker
                    id={blockId}
                    className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                    dateFormat='dd/MM/yyyy HH:mm'
                    locale={localeDict[reduxModalSite.languageId ? languageIdObjectSite[reduxModalSite.languageId] : 'en']}
                    onChange={date => onInputChange(date)}
                    placeholderText={formInputObj?.placeholder === 'true' ? formInputObj?.label : ''}
                    popperClassName='di-popper'
                    selected={isValid(parseISO(value)) ? parseISO(value) : null}
                    showTimeSelect
                    tabIndex={tabIndex}
                    timeCaption={reduxText[9828]}
                    timeFormat='HH:mm'
                    timeIntervals={formInputObj?.data_json?.time_intervals || 30}
                    // @ts-ignore
                    style={stylesNew}
                />
            </div>
        )
    }

    if (format === 'time') {
        return (
            <div style={stylesNew}>
                <DatePicker
                    id={blockId}
                    className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                    dateFormat='HH:mm'
                    locale={localeDict[reduxModalSite.languageId ? languageIdObjectSite[reduxModalSite.languageId] : 'en']}
                    onChange={date => onInputChange(date)}
                    placeholderText={formInputObj?.placeholder === 'true' ? formInputObj?.label : ''}
                    popperClassName='di-popper'
                    selected={(value && !['Invalid date'].includes(value)) ? new Date(2024, 1, 1, value.split(':')[0], value.split(':')[1]) : null}
                    showTimeSelect
                    showTimeSelectOnly
                    tabIndex={tabIndex}
                    timeCaption={reduxText[9828]}
                    timeFormat='HH:mm'
                />
            </div>
        )
    }

    return null
})
