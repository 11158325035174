// react components
import {
    FC,
    memo,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    CourseHelper819Site,
} from 'pages'

// serializers
import {
    Activity819HelperSerializer,
} from 'serializers/site'

// props
type ActivityHelper819SiteProps = {
    object: Activity819HelperSerializer
}

// main
export const ActivityHelper819Site: FC<ActivityHelper819SiteProps> = memo(({
    object,
}) => {

    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)

    const [active, setActive] = useState<boolean>(false)
    const [totalAmount, setTotalAmount] = useState<number>(0)
    const [totalQty, setTotalQty] = useState<number>(0)

    const deviceType = reduxModalSite.deviceType

    const test = filterAndCalculate()

    function filterAndCalculate() {
        if (!reduxSalesSite.activityData?.registrations) return {
            filteredProperties: {},
            totalQty: 0,
            totalAmount: 0,
        }
        const filteredProperties: { [key: string]: any } = {}
        let totalQty = 0
        let totalAmount = 0
        const registrations = reduxSalesSite.activityData?.registrations

        for (const key in registrations) {
            if (key.startsWith(`${object.id}`)) {
                filteredProperties[key] = registrations[key]
                totalQty += registrations[key].qty
                totalAmount += registrations[key].qty * parseFloat(registrations[key].variant.unit_price!)
            }
        }

        return {
            filteredProperties,
            totalQty,
            totalAmount
        }
    }

    return (
        <div className={`activity-helper-819 ${deviceType}`}>
            <div
                className={`ah-819-activity-wrap ${deviceType}`}
                onClick={() => setActive(!active)}
            >
                <div className={`ah-819-name ${deviceType}`}>
                    <span>{object.name}</span>
                </div>
                <div className={`ah-819-action-wrap ${deviceType}`}>
                    <div className={`ah-819-price-wrap ${deviceType}`}>
                        {test.totalQty > 0 && (
                            <>
                                <span>Total: {test.totalAmount} €</span>
                                <span>Qté: {test.totalQty}</span>
                            </>
                        )}
                    </div>
                    <i className={`ah-819-action-icon mo-new-icon-chevron-right-solid${active ? ' active' : ''} ${deviceType}`}></i>
                </div>
            </div>
            {active && (
                <div className={`ah-819-course ${deviceType}`}>
                    {object.courses?.map(val => (
                        <CourseHelper819Site
                            key={val.id}
                            activity={object}
                            object={val}
                            setTotalAmount={setTotalAmount}
                            setTotalQty={setTotalQty}
                            totalAmount={totalAmount}
                            totalQty={totalQty}
                        />
                    ))}
                </div>
            )}
        </div>
    )
})
