// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    reduxAuthStateSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getAxiosHeadersSite,
    parseQuerySite,
} from 'services'


export function getMapListSite({
    addMarkers,
    apiUrl,
    axiosCancelToken,
    component,
    dispatch,
    location,
    reduxAuth,
    reduxModalSite,
    setAxiosCancelToken,
    setClusterIds,
    setIsLoading,
}: {
    addMarkers: React.Dispatch<any>
    apiUrl: string
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    dispatch: React.Dispatch<any>
    location: Location
    reduxAuth: reduxAuthStateSite
    reduxModalSite: reduxModalStateSite
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setClusterIds: React.Dispatch<any[]>
    setIsLoading: React.Dispatch<boolean>
}) {
    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    setIsLoading(true)
    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) getMapListSite')

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    let tempApiUrl = apiUrl

    const searchParams = parseQuerySite(location.search)

    const search_query = searchParams.query
    if (search_query) tempApiUrl += `&search_query=${search_query}`

    const filter_query = searchParams.filter_query
    if (filter_query) tempApiUrl += `&filter_query=${filter_query}`

    if (process.env.NODE_ENV === 'development') console.log(tempApiUrl, component)

    axios({
        cancelToken: source.token,
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: 'get',
        url: tempApiUrl,
    })
        .then((response) => {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getMapListSite')
            if (process.env.NODE_ENV === 'development') console.log(response)
            addMarkers(response.data)
            const newArray: any = []
            response.data.map((val: any) => {
                newArray.push(val.parent)
                return false
            })
            setClusterIds(newArray)

        })
        .catch((error) => {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getMapListSite')
            axiosErrorHandlerSite({
                apiUrl: tempApiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'getMapListSite',
            })
        })
}
