// react components
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import debounce from 'lodash.debounce'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import AsyncSelect from 'react-select/async'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSiteV2,
    getAxiosHeadersSite,
} from 'services'

// props
type LocationInput819SiteProps = {
    disabled?: boolean
    error: string | undefined
    label: string
    name: string
    onChange: any
    placeholder?: string
    value: any
}

// main
export const LocationInput819Site: React.FC<LocationInput819SiteProps> = React.memo(({
    disabled,
    error,
    label,
    name,
    onChange,
    placeholder,
    value,
}) => {

    useEffect(() => {
        setValue(value)
    }, [value])

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [newValue, setValue] = useState<any>(value)

    function onInputChange(value: any) {
        try {
            if (value === newValue) return
            setValue(value)
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'LocationInput819Site',
                'onInputChange',
            ))
        }
    }

    async function getOptions(inputValue: string) {
        const getUrl = getApiUrlSiteV2(`main/helper/googleplace/autocomplete/?search=${inputValue}`, reduxModalSite)
        if (process.env.NODE_ENV === 'development') console.log(getUrl)
        try {
            const response = await fetch(getUrl, reduxAuth?.axiosConfig)
            const json = await response.json()
            return json
        } catch (error) {
            axiosErrorHandlerSite({
                apiUrl: getUrl,
                component: 'LocationInput819Site',
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'getOptions',
            })
        }
    }

    const debouncedGetOptions = useCallback(
        debounce((inputValue: string, callback: (options: any) => void) => {
            getOptions(inputValue).then(callback)
        }, 500),
        []
    )

    const promiseOptions = (inputValue: string) =>
        new Promise(resolve => {
            debouncedGetOptions(inputValue, resolve)
        })

    function getPlaceDetail(e: any) {
        try {
            if (!e?.placeId) {
                onInputChange(e)
            } else {
                const getUrl = getApiUrlSiteV2(`main/helper/googleplace/detail/?place_id=${e.placeId}&description=${e.description}`, reduxModalSite)
                if (process.env.NODE_ENV === 'development') console.log(getUrl)
                axios({
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'get',
                    url: getUrl,
                })
                    .then((response) => {
                        onInputChange(response.data)
                    })
                    .catch((error) => {
                        axiosErrorHandlerSite({
                            apiUrl: getUrl,
                            component: 'LocationInput819Site',
                            dispatch,
                            error,
                            reduxAuth,
                            reduxModalSite,
                            reference: 'getPlaceDetail',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'LocationInput819Site',
                'getPlaceDetail',
            ))
        }
    }

    const lightCustomStyles = {
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? '#8ea1ff' : 'black',
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
        }),
    }

    return (
        <div className={`input-819 select-input-819 ${deviceType}`}>
            <span className={`input-819-label ${deviceType}`}>{label}</span>
            <AsyncSelect
                cacheOptions
                getOptionLabel={option => option.description}
                getOptionValue={(option: any) => option.placeId}
                isClearable
                isDisabled={disabled}
                // @ts-ignore
                loadOptions={promiseOptions}
                name={name}
                noOptionsMessage={(e: { inputValue: string }) => e.inputValue ? reduxText[9754] : reduxText[9755]}
                onChange={getPlaceDetail}
                placeholder={placeholder}
                styles={lightCustomStyles}
                value={newValue}
            />
            {error && <span className='input-819-error'>{error}</span>}
        </div>
    )
})
