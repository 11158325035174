// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    PageDetailSiteSerializer,
} from 'serializers/site'

// services
import {
    getHostOriginSite,
} from 'services'

// props
type StaffHelperSiteProps = {
    detailId: string | undefined
    pageObject: PageDetailSiteSerializer | undefined
    pageSlug: string | undefined
}

// main
export const StaffHelperSite: React.FC<StaffHelperSiteProps> = React.memo(({
    detailId,
    pageObject,
    pageSlug,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolio = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)

    if (!reduxAuth?.settings?.user?.is_staff || reduxFormSitenavigationMode) return null

    const hostOriginSite = getHostOriginSite()

    return (
        <div className='staff-helper-site'>
            <a
                className='main-icon-block-site'
                href={`https://app.justtheweb.io/form/website/${reduxCacheSiteportfolio?.id}/page-container/-/${pageSlug || reduxCacheSiteportfolio?.home_page_slug}/${detailId || ''}`}
                rel='noopener noreferrer'
                target='_blank'
            >
                <i className='main-icon mo-new-icon-pencil-alt-solid' />
            </a>
            {reduxAuth.settings.user.id === 1 && (
                <>
                    <a
                        className='main-icon-block-site ml'
                        href={`http://localhost:8100/form/website/${reduxCacheSiteportfolio?.id}/page-container/-/${pageSlug || reduxCacheSiteportfolio?.home_page_slug}/${detailId || ''}`}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <p>dev/form/website</p>
                    </a>
                    {hostOriginSite === 'prod'
                        ? (
                            <a
                                className='main-icon-block-site ml'
                                href={`http://localhost:8101/${reduxCacheSiteportfolio?.id}${window.location.pathname}${window.location.search}`}
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <p>dev/{reduxCacheSiteportfolio?.id}</p>
                            </a>
                        ) : (
                            <a
                                className='main-icon-block-site ml'
                                href={`http://${reduxCacheSiteportfolio?.hostname}${window.location.pathname.replace(`${reduxCacheSiteportfolio?.id}/`, '')}${window.location.search}`}
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <p>prod/{reduxCacheSiteportfolio?.id}</p>
                            </a>
                        )
                    }
                    {detailId && (
                        <a
                            className='main-icon-block-site ml'
                            href={`https://app.justtheweb.io/detail/${pageObject?.seo_data?.extra_data?.content_type_label_model}/${pageObject?.seo_data?.extra_data?.detail_instance_id}`}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            <p>prod/{pageObject?.seo_data?.extra_data?.content_type_label_model}</p>
                        </a>
                    )}
                    {detailId && (
                        <a
                            className='main-icon-block-site ml'
                            href={`http://localhost:8100/detail/${pageObject?.seo_data?.extra_data?.content_type_label_model}/${pageObject?.seo_data?.extra_data?.detail_instance_id}`}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            <p>dev/{pageObject?.seo_data?.extra_data?.content_type_label_model}</p>
                        </a>
                    )}
                </>
            )}
        </div>
    )
})
