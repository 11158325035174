// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesTicketSite,
} from 'data'

// pages
import {
    CheckboxInput816Site,
    DateTimeInput816Site,
    LocationInput816Site,
    PhoneNumberInput816Site,
    TextAreaInput816Site,
    TextInput816Site,
} from 'pages'

// serializers
import {
    Template816SiteSerializer,
    Variant816HelperSiteSerializer,
} from 'serializers/site'

// props
type FreeParticipantHelper816SiteProps = {
    content: Template816SiteSerializer | undefined
    index: number
    object: {
        index: number
        variant: Variant816HelperSiteSerializer
        qty: number
    }
}

// main
export const FreeParticipantHelper816Site: React.FC<FreeParticipantHelper816SiteProps> = React.memo(({
    content,
    index,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    function handleInputChange(e: any) {
        try {
            const toReturnData = {
                ...reduxSalesSite.ticketData,
                variants: {
                    ...reduxSalesSite.ticketData?.variants,
                    [object.index]: {
                        ...reduxSalesSite.ticketData?.variants?.[object.index],
                        participants: {
                            ...reduxSalesSite.ticketData?.variants?.[object.index]?.participants,
                            [index]: {
                                ...reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index],
                                [e.name]: e.value,
                            },
                        },
                    },
                },
            }
            // @ts-ignore
            dispatch(reduxSalesTicketSite(toReturnData))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'FreeParticipantHelper816Site',
                'handleInputChange',
            ))
        }
    }

    return (
        <div className={`t-816-participant ${deviceType}`}>
            <div className={`t-816-participant-wrap ${deviceType}`}>
                <div className={`t-816-participant-title ${deviceType}`}>
                    <i className={`t-816-icon mo-new-icon-user-solid ${deviceType}`} />
                    <span>{reduxText[8747]} {index + 1}</span>
                </div>
                <div className={`t-816-participant-inputs ${deviceType}`}>
                    {(content?.form_fields?.length! > 0)
                        ? (
                            <>
                                {content?.form_fields?.map(val => {
                                    if (val.field_type === 'text') {
                                        return (
                                            <TextInput816Site
                                                error={undefined}
                                                key={val.id}
                                                label={val.label!}
                                                name={val.name!}
                                                onChange={handleInputChange}
                                                value={reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.[val.name!] || ''}
                                            />
                                        )
                                    }
                                    if (val.field_type === 'textarea') {
                                        return (
                                            <TextAreaInput816Site
                                                error={undefined}
                                                key={val.id}
                                                label={val.label!}
                                                name={val.name!}
                                                onChange={handleInputChange}
                                                value={reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.[val.name!] || ''}
                                            />
                                        )
                                    }
                                    if (val.field_type === 'checkbox') {
                                        return (
                                            <CheckboxInput816Site
                                                error={undefined}
                                                key={val.id}
                                                label={val.label!}
                                                name={val.name!}
                                                onChange={handleInputChange}
                                                value={reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.[val.name!] || ''}
                                            />
                                        )
                                    }
                                    if (val.field_type === 'datetime') {
                                        return (
                                            <DateTimeInput816Site
                                                error={undefined}
                                                format='date'
                                                key={val.id}
                                                label={val.label!}
                                                name={val.name!}
                                                onChange={handleInputChange}
                                                value={reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.[val.name!] || ''}
                                            />
                                        )
                                    }
                                    if (val.field_type === 'location') {
                                        return (
                                            <LocationInput816Site
                                                error={undefined}
                                                key={val.id}
                                                label={val.label!}
                                                name={val.name!}
                                                onChange={handleInputChange}
                                                value={reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.[val.name!] || ''}
                                            />
                                        )
                                    }
                                    if (val.field_type === 'phonenumber') {
                                        return (
                                            <PhoneNumberInput816Site
                                                error={undefined}
                                                key={val.id}
                                                label={val.label!}
                                                name={val.name!}
                                                onChange={handleInputChange}
                                                value={reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.[val.name!] || ''}
                                            />
                                        )
                                    }
                                })}
                            </>
                        ) : (
                            <>
                                <TextInput816Site
                                    error={undefined}
                                    label={reduxText[8744]}
                                    name='first_name'
                                    onChange={handleInputChange}
                                    value={reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.first_name || ''}
                                />
                                <TextInput816Site
                                    error={undefined}
                                    label={reduxText[8745]}
                                    name='last_name'
                                    onChange={handleInputChange}
                                    value={reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.last_name || ''}
                                />
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
})
