// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Elements,
} from '@stripe/react-stripe-js'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesActivityResetSite,
    reduxSalesActivitySite,
} from 'data'

// pages
import {
    ActivityHelper819Site,
    BillingHelper819SiteSite,
    EditBlockSite,
    ParticipantHelper819Site,
    ReviewHelper819Site,
    StripeHelper819Site,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template819SiteSerializer,
} from 'serializers/site'

// services
import {
    validateEmailSite,
} from 'services'

// props
type TemplateBlock819SiteProps = {
    blockId: string
    content: Template819SiteSerializer | undefined
    isEditHovered: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: any
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock819Site: React.FC<TemplateBlock819SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [step, setStep] = useState<number>(1)

    const deviceType = reduxModalSite.deviceType

    useEffect(() => {
        dispatch(reduxSalesActivitySite({
            ...reduxSalesSite.activityData || {},
            purchased_registration_count: content?.purchased_registration_count || 0,
        }))
    }, [
        content?.purchased_registration_count,
    ])

    let isNextValid = false
    if (step === 1) {
        if (reduxSalesSite.activityData?.registrations && Object.values(reduxSalesSite.activityData.registrations).filter(obj => obj.qty > 0).length > 0) {
            isNextValid = true
        }
    } else if (step === 3) {
        isNextValid = Boolean(reduxSalesSite.activityData?.contactInfo?.email && reduxSalesSite.activityData?.contactInfo?.first_name && reduxSalesSite.activityData?.contactInfo?.last_name)
    }

    function onStepClick(step: number) {
        try {
            if (step === 2) {
                const toReturnData = reduxSalesSite.activityData
                const requiredFields = [
                    'address_json',
                    'custom_boolean_field_2',
                    'custom_foreign_key_2',
                    'custom_text_field_2',
                    'date_of_birth',
                    'email',
                    'first_name',
                    'last_name',
                    'phone_number',
                ]
                let canContinue = true
                reduxSalesSite.activityData?.registrations && Object.values(reduxSalesSite.activityData.registrations).filter(obj => obj.qty > 0).map((val) => {
                    if (!val.students) {
                        canContinue = false
                        // @ts-ignore
                        toReturnData.errorMessage = 'Veuillez remplir les informations des participants'
                    }
                })
                reduxSalesSite.activityData?.registrations && Object.values(reduxSalesSite.activityData.registrations).filter(obj => obj.qty > 0).map((val) => {
                    val.students && Object.getOwnPropertyNames(val.students).map((val2) => {
                        // @ts-ignore
                        if (!val.students![val2].fields?.same_info_as) {
                            requiredFields.map((val3) => {
                                // @ts-ignore
                                if (!val.students![val2].fields?.[val3]) {
                                    // @ts-ignore
                                    if (!toReturnData.registrations[val.activityCourseVariantId].students[val2]?.errors) {
                                        // @ts-ignore
                                        toReturnData.registrations[val.activityCourseVariantId].students[val2].errors = {}
                                    }
                                    // @ts-ignore
                                    toReturnData.registrations[val.activityCourseVariantId].students[val2].errors[val3] = 'Ce champ est requis'
                                    canContinue = false
                                    // @ts-ignore
                                    toReturnData.errorMessage = 'Veuillez remplir tous les champs obligatoires'
                                }
                            })
                            // @ts-ignore
                            Object.getOwnPropertyNames(val.students?.[val2].fields).map((val3) => {
                                if ([
                                    'custom_email_field_1',
                                    'email',
                                    // @ts-ignore
                                ].includes(val3) && val.students?.[val2].fields[val3]) {
                                    // @ts-ignore
                                    if (!validateEmailSite(val.students?.[val2].fields[val3])) {
                                        // @ts-ignore
                                        if (!toReturnData.registrations[val.activityCourseVariantId].students[val2]?.errors) {
                                            // @ts-ignore
                                            toReturnData.registrations[val.activityCourseVariantId].students[val2].errors = {}
                                        }
                                        // @ts-ignore
                                        toReturnData.registrations[val.activityCourseVariantId].students[val2].errors[val3] = 'Veuillez entrer une adresse email valide'
                                        canContinue = false
                                        // @ts-ignore
                                        toReturnData.errorMessage = 'Vérifier les adresses emails'
                                    }
                                }
                            })
                        }
                    })
                })
                if (canContinue) {
                    setStep(3)
                } else {
                    dispatch(reduxSalesActivitySite(toReturnData))
                }
            } else if (step === 3) {
                let canContinue = true
                const toReturnData = reduxSalesSite.activityData
                if (!validateEmailSite(reduxSalesSite.activityData?.contactInfo?.email!)) {
                    canContinue = false
                    // @ts-ignore
                    toReturnData.errorMessage = 'Veuillez entrer une adresse email valide'
                }
                if (canContinue) {
                    setStep(4)
                } else {
                    dispatch(reduxSalesActivitySite(toReturnData))
                }
            }

        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ParticipantHelper819Site',
                'onSetField',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-819${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
        >
            <div
                id='anchorToTickets'
                className={`t-819-block ${deviceType}`}
            >
                <div className={`t-819-navsteps ${deviceType}`}>
                    <button
                        className={`t-819-navstep${step === 1 ? ' current' : ''} ${deviceType}`}
                        onClick={() => setStep(1)}
                        type='button'
                    >
                        <i className={`t-819-icon mo-new-icon-layout-792 ${deviceType}`} />
                        <span className={`t-819-navtext ${deviceType}`}>Choix des activités</span>
                    </button>
                    <button
                        className={`t-819-navstep${step === 2 ? ' current' : ''} ${deviceType}`}
                        disabled={step < 2}
                        onClick={() => setStep(2)}
                        type='button'
                    >
                        <i className={`t-819-icon mo-new-icon-user-solid ${deviceType}`} />
                        <span className={`t-819-navtext ${deviceType}`}>{reduxText[8736]}</span>
                    </button>
                    <button
                        className={`t-819-navstep${step === 3 ? ' current' : ''} ${deviceType}`}
                        disabled={step < 3}
                        onClick={() => setStep(3)}
                        type='button'
                    >
                        <i className={`t-819-icon mo-new-icon-list-ul-solid ${deviceType}`} />
                        <span className={`t-819-navtext ${deviceType}`}>{reduxText[8737]}</span>
                    </button>
                    <button
                        className={`t-819-navstep${step === 4 ? ' current' : ''} ${deviceType}`}
                        disabled={step < 4}
                        type='button'
                    >
                        <i className={`t-819-icon mo-new-icon-check-circle-solid ${deviceType}`} />
                        <span className={`t-819-navtext ${deviceType}`}>{reduxText[8738]}</span>
                    </button>
                </div>
                {step === 1 && (
                    <div className={`t-819-step1 ${deviceType}`}>
                        <div className={`t-819-registrations ${deviceType}`}>
                            {content?.activities?.map(val => (
                                <ActivityHelper819Site
                                    key={val.id}
                                    object={val}
                                />
                            ))}
                        </div>
                        <div className={`t-819-total ${deviceType}`}>
                            {reduxSalesSite.activityData?.discount
                                ? (
                                    <>
                                        <p className={`t-819-total-wrap ${deviceType}`}>
                                            <span className={`t-819-total-text ${deviceType}`}>Soustotal:</span>
                                            <span className={`t-819-total-amount ${deviceType}`}>{Number(reduxSalesSite.activityData?.totalValue || 0)} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                                        </p>
                                        <p className={`t-819-total-wrap ${deviceType}`}>
                                            <span className={`t-819-total-text ${deviceType}`}>Réduction:</span>
                                            <span className={`t-819-total-amount ${deviceType}`}>{Number(reduxSalesSite.activityData?.discount) * 100}%</span>
                                        </p>
                                        <p className={`t-819-total-wrap ${deviceType}`}>
                                            <span className={`t-819-total-text ${deviceType}`}>Total:</span>
                                            <span className={`t-819-total-amount ${deviceType}`}>{Number(reduxSalesSite.activityData?.totalValue || 0) * Number(1 - reduxSalesSite.activityData?.discount)} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                                        </p>
                                    </>
                                ) : (
                                    <p className={`t-819-total-wrap ${deviceType}`}>
                                        <span className={`t-819-total-text ${deviceType}`}>Total:</span>
                                        <span className={`t-819-total-amount ${deviceType}`}>{Number(reduxSalesSite.activityData?.totalValue || 0)} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                                    </p>
                                )
                            }
                        </div>
                    </div>
                )}
                {step === 2 && reduxSalesSite.activityData?.registrations && (
                    <div className={`t-819-step2 ${deviceType}`}>
                        {Object.values(reduxSalesSite.activityData.registrations).filter(obj => obj.qty > 0).map((val, i) => (
                            <div
                                key={val.variant.id}
                                className={`t-819-variant-wrap ${deviceType}`}
                            >
                                <span className={`t-819-variant-name ${deviceType}`}>{val.variant.name}</span>
                                {[...Array(val.qty)].map((_, index) => (
                                    <ParticipantHelper819Site
                                        key={index}
                                        i={i}
                                        object={val}
                                        index={index}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                )}
                {step === 3 && (
                    <div className={`t-819-step3 ${deviceType}`}>
                        <BillingHelper819SiteSite />
                    </div>
                )}
                {step === 4 && (
                    <div className={`t-819-step4 ${deviceType}`}>
                        <ReviewHelper819Site />
                        <div className={`t-819-total ${deviceType}`}>
                            {reduxSalesSite.activityData?.discount
                                ? (
                                    <>
                                        <p className={`t-819-total-wrap ${deviceType}`}>
                                            <span className={`t-819-total-text ${deviceType}`}>Soustotal:</span>
                                            <span className={`t-819-total-amount ${deviceType}`}>{Number(reduxSalesSite.activityData?.totalValue || 0)} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                                        </p>
                                        <p className={`t-819-total-wrap ${deviceType}`}>
                                            <span className={`t-819-total-text ${deviceType}`}>Réduction:</span>
                                            <span className={`t-819-total-amount ${deviceType}`}>{Number(reduxSalesSite.activityData?.discount) * 100}%</span>
                                        </p>
                                        <p className={`t-819-total-wrap ${deviceType}`}>
                                            <span className={`t-819-total-text ${deviceType}`}>Total: </span>
                                            <span className={`t-819-total-amount ${deviceType}`}>{Number(reduxSalesSite.activityData?.totalValue || 0) * Number(1 - reduxSalesSite.activityData?.discount)} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                                        </p>
                                    </>
                                ) : (
                                    <p className={`t-819-total-wrap ${deviceType}`}>
                                        <span className={`t-819-total-text ${deviceType}`}>Total: </span>
                                        <span className={`t-819-total-amount ${deviceType}`}>{Number(reduxSalesSite.activityData?.totalValue || 0)} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                                    </p>
                                )
                            }
                        </div>
                    </div>
                )}
            </div>
            <div className={`t-819-button-next-wrap ${deviceType}`}>
                {step > 1 && (
                    <LinkHelperSite
                        action={undefined}
                        className={`t-819-button-previous ${deviceType}`}
                        onClick={() => setStep(step - 1)}
                        to={undefined}
                    >
                        <i className={`t-819-icon mo-new-icon-chevron-left-solid ${deviceType}`} />
                        <span>{reduxText[8741]}</span>
                    </LinkHelperSite>
                )}
                {step < 4 && (
                    <LinkHelperSite
                        action={undefined}
                        className={`t-819-button-next${(step === 2 || isNextValid) ? '' : ' disabled'} ${deviceType}`}
                        onClick={[2, 3].includes(step) ? () => onStepClick(step) : () => setStep(step + 1)}
                        to={undefined}
                    >
                        <span>{reduxText[8742]}</span>
                        <i className={`t-819-icon mo-new-icon-chevron-right-solid ${deviceType}`} />
                    </LinkHelperSite>
                )}
                {step === 4 && (
                    <Elements stripe={reduxModalSite.stripePromise}>
                        <StripeHelper819Site
                            content={content}
                        />
                    </Elements>
                )}
            </div>
            {reduxSalesSite.activityData?.errorMessage && (
                <p className={`t-819-error ${deviceType}`}>{reduxSalesSite.activityData.errorMessage}</p>
            )}
            <span
                className={`t-819-reset ${deviceType}`}
                onClick={() => {
                    dispatch(reduxSalesActivityResetSite())
                    setStep(1)
                }}
            >
                Réinitialiser l'inscription
            </span>
            <EditBlockSite
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
