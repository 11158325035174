// react components
import React, {
    useEffect,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const UserWaySite: React.FC = () => {

    const reduxCacheportfoliowidget_userway_key = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.widget_userway_key)

    if (reduxCacheportfoliowidget_userway_key) {
        return <UserWayHelper />
    }
    return null
}


const UserWayHelper: React.FC = () => {

    const reduxCacheportfoliowidget_userway_key = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.widget_userway_key)

    useEffect(() => {

        const script = document.createElement('script')
        script.src = 'https://cdn.userway.org/widget.js'
        script.setAttribute('data-account', reduxCacheportfoliowidget_userway_key!)

        document.head.appendChild(script)
    }, [])

    return null
}
