// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import Select from 'react-select'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// portfolio
import {
    CustomCSSProperties,
    MainIdNameSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getAxiosHeadersSite,
} from 'services'

// props
type SelectListInput819SiteProps = {
    apiUrl?: string | undefined
    disabled?: boolean
    error: string | undefined
    label: string
    multi?: boolean
    name: string
    onChange: any
    options?: MainIdNameSerializer[]
    placeholder?: string
    stylesLabel?: CustomCSSProperties | undefined
    value: any
}

// main
export const SelectListInput819Site: React.FC<SelectListInput819SiteProps> = React.memo(({
    apiUrl,
    disabled,
    error,
    label,
    multi,
    name,
    onChange,
    options,
    placeholder,
    stylesLabel,
    value,
}) => {

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setValue(value)
    }, [value])

    useEffect(() => {
        if (options) setNewOptions(options)
    }, [options])

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [newOptions, setNewOptions] = useState<MainIdNameSerializer[]>(options || [])
    const [newValue, setValue] = useState<any>(value)

    function onInputChange(value: any) {
        try {
            if (value === newValue) return
            setValue(value)
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'SelectListInput819Site',
                'onInputChange',
            ))
        }
    }

    function getData() {
        try {
            if (options) return
            if (!apiUrl) return
            const listArray: MainIdNameSerializer[] = []
            axios({
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'get',
                url: apiUrl,
            })
                .then((response) => {
                    if (Array.isArray(response.data)) {
                        response.data.map((val) => {
                            listArray.push(val)
                            return false
                        })
                        setNewOptions(listArray)
                    }
                })
                .catch((error) => {
                    axiosErrorHandlerSite({
                        apiUrl,
                        component: 'SelectListInput819Site',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'SelectListInput819Site',
                'getData',
            ))
        }
    }

    const lightCustomStyles = {
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? '#8ea1ff' : 'black',
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
        }),
    }

    return (
        <div className={`input-819 select-input-819 ${deviceType}`}>
            <span className={`input-819-label ${deviceType}`}>{label}</span>
            <Select
                closeMenuOnSelect={!multi}
                getOptionLabel={option => option.name}
                getOptionValue={option => `${option.id}`}
                isClearable
                isDisabled={disabled}
                isMulti={multi}
                name={name}
                menuPlacement='top'
                onChange={onInputChange}
                options={newOptions}
                placeholder={placeholder}
                styles={lightCustomStyles}
                value={newValue}
            />
            {error && <span className='input-819-error'>{error}</span>}
        </div>
    )
})
