// react components
import React, {
    useRef,
    useState,
} from 'react'
import {
    Button,
    Snackbar,
} from '@mui/material'
import ReactPlayer from 'react-player'
import moment from 'moment'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    EcPlayerTracklistSite,
    IconBlockSite,
    ImageHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    media_url_platform_site,
    media_version_site,
    reduxModalErrorEventHandlerSite,
    reduxPlayerOnDismissSite,
    reduxPlayerOnPlayPauseSite,
    reduxPlayerOnReadySite,
    reduxPlayerSetCurrentTrackSite,
    reduxPlayerSetPositionSite,
} from 'data'

// main
export const EcPlayerSite: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const contentRef = useRef<ReactPlayer>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxPlayerSite = useSelector((state: defaultReduxState) => state.reduxPlayerSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [duration, setDuration] = useState<number>(0)
    const [errorMessage, showErrorMessage] = useState(false)
    const [fullScreen] = useState(false)
    const [loop] = useState(false)
    const [played, setPlayed] = useState<number>(0)
    const [showTrackList, setShowTrackList] = useState(false)

    function onDuration(duration: number) {
        try {
            setDuration(duration)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onDuration',
            ))
        }
    }

    function onError(error: any) {
        console.log(error)
        // try {
        // 	axiosErrorHandlerSite(
        // 		error,
        // reduxAuth,
        // 		reduxModalSite,
        // 		'EcPlayerSite',
        // 		'',
        // 		'onError',
        // 		dispatch,
        // 	)
        // } catch (error) {
        // 	dispatch(reduxModalErrorEventHandlerSite(
        // 		error,
        // 		'EcPlayerSite',
        // 		'onError',
        // 		true,
        // 	))
        // }
    }

    function onNext() {
        try {
            if (!nextTrack) return
            // @ts-ignore
            dispatch(reduxPlayerSetCurrentTrackSite(
                nextTrack,
                reduxAuth,
                reduxModalSite,
                dispatch,
                reduxPlayerSite.playerPosition,
                reduxPlayerSite.playerOrigin,
                (reduxPlayerSite.albumTrackArray && reduxPlayerSite.albumTrackArray.findIndex((obj) => obj.id === (nextTrack && nextTrack.id)) > - 1) ? reduxPlayerSite.trackArray : reduxPlayerSite.originalTrackArray,
                reduxPlayerSite.originalUrl,
            ))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onNext',
            ))
        }
    }

    function onNextAlbumSkip() {
        try {
            if (nextAlbumSkipTrack) {
                // @ts-ignore
                dispatch(reduxPlayerSetCurrentTrackSite(
                    nextAlbumSkipTrack,
                    reduxAuth,
                    reduxModalSite,
                    dispatch,
                    reduxPlayerSite.playerPosition,
                    reduxPlayerSite.playerOrigin,
                    reduxPlayerSite.originalTrackArray,
                    reduxPlayerSite.originalUrl,
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onNextAlbumSkip',
            ))
        }
    }

    function onPlayPause() { // Play, Pause audio and video
        try {
            if (reactPlayerActionDisabled) {
                showErrorMessage(true)
            } else {
                dispatch(reduxPlayerOnPlayPauseSite(!reduxPlayerSite.playing))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onPlayPause',
            ))
        }
    }

    function onPrev() {
        try {
            if (!prevTrack) return
            // @ts-ignore
            dispatch(reduxPlayerSetCurrentTrackSite(
                prevTrack,
                reduxAuth,
                reduxModalSite,
                dispatch,
                reduxPlayerSite.playerPosition,
                reduxPlayerSite.playerOrigin,
                (reduxPlayerSite.albumTrackArray && reduxPlayerSite.albumTrackArray.findIndex((obj) => obj.id === prevTrack.id) > - 1) ? reduxPlayerSite.trackArray : reduxPlayerSite.originalTrackArray,
                reduxPlayerSite.originalUrl,
            ))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onPrev',
            ))
        }
    }

    function onPrevAlbumSkip() {
        try {
            if (prevAlbumSkipTrack) {
                // @ts-ignore
                dispatch(reduxPlayerSetCurrentTrackSite(
                    prevAlbumSkipTrack,
                    reduxAuth,
                    reduxModalSite,
                    dispatch,
                    reduxPlayerSite.playerPosition,
                    reduxPlayerSite.playerOrigin,
                    reduxPlayerSite.originalTrackArray,
                    reduxPlayerSite.originalUrl,
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onPrevAlbumSkip',
            ))
        }
    }

    function onProgress(value: any) { // Player progress data send to redux
        try {
            setPlayed(value.playedSeconds)
            // playerCheckDurationSite(this, player.getDuration(), state.currentTrackDuration)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onProgress',
            ))
        }
    }

    function onReady() { // Player ready send to redux
        try {
            dispatch(reduxPlayerOnReadySite())
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onReady',
            ))
        }
    }

    function onDismissPlayer() {
        try {
            dispatch(reduxPlayerOnDismissSite())
            // dispatch(reduxModalPlayerPositionSite(''))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerSite',
                'onDismissPlayer',
            ))
        }
    }

    // soundcloud config options
    const soundcloudConfig = {
        options: {
            auto_play: true,
        },
    }

    // vimeo config options
    const vimeoConfig = {
        playerOptions: { // https://help.vimeo.com/hc/en-us/articles/360001494447-Using-Player-Parameters
            autoplay: 1,
        },
    }

    // youtube config
    const youtubeConfig = {
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            color: 'white',
            rel: 0,
            showinfo: 0,
        },
    }

    const otherPlayers = [
        3,
        7,
        8,
        9,
        10,
        11,
        13,
        14,
        15,
        16,
        18,
        19,
        21,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
    ]

    const coverNeededBottomPlatform = [
        2,
        3,
        6,
        8,
        9,
        11,
        18,
        21,
    ]

    if (!reduxPlayerSite?.currentTrack?.id) return null

    const nextTrack = reduxPlayerSite.trackArray && reduxPlayerSite.trackArray[Number(reduxPlayerSite.currentTrackIndex || 0) + 1]
    const prevTrack = reduxPlayerSite.trackArray && reduxPlayerSite.trackArray[(reduxPlayerSite.currentTrackIndex || 0) - 1]
    const prevAlbumSkipTrack = reduxPlayerSite.originalTrackArray && reduxPlayerSite.originalTrackArray[Number(reduxPlayerSite.originalTrackIndex || 0) - 1]
    const nextAlbumSkipTrack = reduxPlayerSite.originalTrackArray && reduxPlayerSite.originalTrackArray[Number(reduxPlayerSite.originalTrackIndex || 0) + 1]
    const reactPlayerActionDisabled = Boolean(reduxPlayerSite.currentTrack.platform && otherPlayers.includes(reduxPlayerSite.currentTrack.platform))

    return (
        <div
            className={`mo-player-site ${fullScreen ? 'landscape' : reduxPlayerSite.playerPosition} ${deviceType}`}
            style={{
                bottom: (location.pathname.split('/')[1] === 'form') ? '0px' : undefined,
            }}
        >
            {reduxPlayerSite.playerPosition && (
                <div
                    className={`mo-player-site-block ${reduxPlayerSite.playerPosition} ${deviceType} platform-${reduxPlayerSite.currentTrack?.platform}`}
                    onClick={() => reduxPlayerSite.playerPosition === 'bottom' ? dispatch(reduxPlayerSetPositionSite('full')) : undefined}
                >
                    {/* Start iframe for Deezer player */}
                    {reduxPlayerSite.currentTrack?.platform === 3 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={`https://www.deezer.com/plugins/player?format=classic&autoplay=false&playlist=false&color=ff0000&layout=dark&size=medium&type=tracks&id=${reduxPlayerSite.currentTrack?.link_id}&app_id=1`}
                            title='Deezer Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Deezer player */}
                    {/* Start iframe for Giphy player */}
                    {reduxPlayerSite.currentTrack?.platform === 7 && (
                        <iframe
                            allowFullScreen
                            className='giphy-embed'
                            frameBorder='0'
                            src={`https://giphy.com/embed/${reduxPlayerSite.currentTrack?.link_id}`}
                            title='Giphy Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Giphy player */}
                    {/* Start iframe for Spotify player */}
                    {reduxPlayerSite.currentTrack?.platform === 8 && (
                        <iframe
                            allow='encrypted-media'
                            scrolling='no'
                            frameBorder='no'
                            src={(reduxPlayerSite.currentTrack?.link_id?.includes('episode') || reduxPlayerSite.currentTrack?.link_id?.includes('playlist') || reduxPlayerSite.currentTrack?.link_id?.includes('track')) ? `https://open.spotify.com/embed/${reduxPlayerSite.currentTrack?.link_id}` : `https://open.spotify.com/embed/track/${reduxPlayerSite.currentTrack?.link_id}`}
                            title='Spotify Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Spotify player */}
                    {/* Start iframe for Bandcamp player */}
                    {reduxPlayerSite.currentTrack?.platform === 9 && (
                        <iframe
                            id='zarmaaa'
                            seamless
                            src={reduxPlayerSite.currentTrack?.link_id && reduxPlayerSite.currentTrack.link_id.replace('artwork=small', 'artwork=small')}
                            title='Bandcamp Iframe'
                            style={{
                                border: '0',
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Bandcamp player */}
                    {/* Check */}
                    {reduxPlayerSite.currentTrack?.platform === 10 && (
                        <iframe
                            allowFullScreen
                            frameBorder='0'
                            scrolling='no'
                            src={`https://www.arte.tv/player/v3/index.php?json_url=https%3A%2F%2Fapi.arte.tv%2Fapi%2Fplayer%2Fv1%2Fconfig%2Ffr%2F${reduxPlayerSite.currentTrack?.link_id}%3Fautostart%3D1%26lifeCycle%3D1&amplang=fr_FR&ampembed=1&ampmute=0`}
                            title='To check Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* Check */}
                    {/* Start iframe for Pippa player */}
                    {reduxPlayerSite.currentTrack?.platform === 11 && (
                        <iframe
                            allow='autoplay'
                            frameBorder='0'
                            src={`https://player.pippa.io/${reduxPlayerSite.currentTrack?.link_id}?theme=default&cover=1&latest=1`}
                            title='Pippa player Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Pippa player */}
                    {/* Start iframe for Arte Audioblog player */}
                    {reduxPlayerSite.currentTrack?.platform === 13 && (
                        <iframe
                            src={`https://audioblog.arteradio.com/embed/${reduxPlayerSite.currentTrack?.link_id}`}
                            title='Arte Audioblog Iframe'
                            style={{
                                border: 'none',
                                height: '100%',
                                margin: '0',
                                padding: '0',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Arte Audioblog player */}
                    {/* Start iframe for Arte Radio player */}
                    {reduxPlayerSite.currentTrack?.platform === 14 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={`${reduxPlayerSite.currentTrack?.link_url}?embed`}
                            title='Arte Radio Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Arte Radio player */}
                    {/* Start iframe for France Culture player */}
                    {reduxPlayerSite.currentTrack?.platform === 15 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            title='France Culture Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France Culture player */}
                    {/* Start iframe for Apple player */}
                    {reduxPlayerSite.currentTrack?.platform === 16 && (
                        <iframe
                            allow='autoplay * encrypted-media *'
                            height='150'
                            sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation'
                            src={`https://embed.${reduxPlayerSite.currentTrack?.link_id}`}
                            title='Apple Iframe'
                            style={{
                                background: 'transparent',
                                height: '100%',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Apple player */}
                    {/* Start iframe for France Inter player */}
                    {reduxPlayerSite.currentTrack?.platform === 18 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            title='France Inter Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France Inter player */}
                    {/* Start iframe for France TV player */}
                    {reduxPlayerSite.currentTrack?.platform === 19 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            title='France TV Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France TV player */}
                    {/* Start iframe for Anchor player */}
                    {reduxPlayerSite.currentTrack?.platform === 21 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            title='Anchor Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Anchor player */}
                    {/* Start iframe for Imagotv player */}
                    {reduxPlayerSite.currentTrack?.platform === 23 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            title='Imagotv Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Imagotv player */}
                    {/* Start iframe for Rumble player */}
                    {reduxPlayerSite.currentTrack?.platform === 24 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            title='Rumble Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Rumble player */}
                    {/* Start iframe for Hearthis player */}
                    {reduxPlayerSite.currentTrack?.platform === 25 && (
                        <iframe
                            allow='autoplay'
                            frameBorder='0'
                            scrolling='no'
                            src={`${reduxPlayerSite.currentTrack?.link_id}transparent_black/?hcolor=&color=&style=2&block_size=2&block_space=1&background=1&waveform=0&cover=0&autoplay=0&css=%C2%AB`}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Hearthis Iframe'
                        />
                    )}
                    {/* End iframe for Hearthis player */}
                    {/* Start iframe for TF1 player */}
                    {reduxPlayerSite.currentTrack?.platform === 26 && (
                        <iframe
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            frameBorder='0'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='TF1 Iframe'
                        />
                    )}
                    {/* End iframe for TF1 player */}
                    {/* Start iframe for Kuula player */}
                    {reduxPlayerSite.currentTrack?.platform === 27 && (
                        <iframe
                            allow='xr-spatial-tracking; gyroscope; accelerometer'
                            allowFullScreen
                            frameBorder='0'
                            scrolling='no'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Kuula Iframe'
                        />
                    )}
                    {/* End iframe for Kuula player */}
                    {/* Start iframe for Matterport player */}
                    {reduxPlayerSite.currentTrack?.platform === 28 && (
                        <iframe
                            frameBorder='0'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Matterport Iframe'
                        />
                    )}
                    {/* End iframe for Matterport player */}
                    {/* Start iframe for VideoAsk player */}
                    {reduxPlayerSite.currentTrack?.platform === 29 && (
                        <iframe
                            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                            frameBorder='0'
                            src={reduxPlayerSite.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='VideoAsk Iframe'
                        />
                    )}
                    {/* End iframe for VideoAsk player */}
                    {/* Start iframe for others */}
                    {!otherPlayers.includes(reduxPlayerSite.currentTrack?.platform || 0) && reduxPlayerSite.currentTrack?.link_url && (
                        <ReactPlayer
                            controls
                            height='100%'
                            loop={loop}
                            onDuration={onDuration}
                            onEnded={nextTrack ? () => onNext() : undefined}
                            onError={(e: any) => onError(e)}
                            onPause={() => dispatch(reduxPlayerOnPlayPauseSite(false))}
                            onPlay={() => dispatch(reduxPlayerOnPlayPauseSite(true))}
                            onProgress={onProgress}
                            onReady={onReady}
                            // onStart={onStart}
                            playing={reduxPlayerSite.playing}
                            ref={contentRef}
                            url={(reduxPlayerSite.currentTrack?.platform && [17, 20].includes(reduxPlayerSite.currentTrack?.platform)) ? reduxPlayerSite.currentTrack?.link_id : reduxPlayerSite.currentTrack?.link_url}
                            width='100%'
                            config={{
                                // facebook: { facebookConfig },
                                soundcloud: soundcloudConfig,
                                vimeo: vimeoConfig,
                                youtube: youtubeConfig,
                            }}
                        />
                    )}
                    {/* End iframe for others */}
                    {reduxPlayerSite.playerPosition === 'bottom' && reduxPlayerSite.currentTrack.platform && coverNeededBottomPlatform.includes(reduxPlayerSite.currentTrack.platform) && (
                        <div className={`mo-player-site-cover ${deviceType}`}>
                            <ImageHelperSite
                                alt={reduxPlayerSite.currentTrack.name}
                                dominant_color={reduxPlayerSite.currentTrack.get_dominant_color}
                                src={reduxPlayerSite.currentTrack?.get_image_site_lq || reduxPlayerSite.currentTrack?.get_image_lq}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={`mo-player-site-info ${deviceType}`}>
                <div
                    className={`mo-player-site-info-name mo-clamp-web1 ${deviceType}`}
                    title={reduxPlayerSite.currentTrack.name}
                >
                    {reduxPlayerSite.currentTrack.name}
                </div>
            </div>
            <div className={`mo-player-site-action ${deviceType}`}>
                <div className={`mo-player-site-action-full ${deviceType}`}>
                    <IconBlockSite
                        className={`mo-player-site-action-icon-full album ${deviceType}`}
                        disabled={!Boolean(prevAlbumSkipTrack && reduxPlayerSite.albumTrackArray && reduxPlayerSite.albumTrackArray[(reduxPlayerSite.albumTrackIndex || 0) - 1])}
                        iconClass='mo-new-icon-fast-backward-solid'
                        onClick={onPrevAlbumSkip}
                    />
                    <IconBlockSite
                        className={`mo-player-site-action-icon-full ${deviceType}`}
                        disabled={!Boolean(prevTrack)}
                        iconClass='mo-new-icon-step-backward-solid'
                        onClick={onPrev}
                    />
                    <IconBlockSite
                        className={`mo-player-site-action-icon-full mo-player-site-action-icon-full-main ${deviceType}`}
                        iconClass={reduxPlayerSite.playing ? 'mo-new-icon-pause-circle-regular' : 'mo-new-icon-play-circle-solid'}
                        onClick={onPlayPause}
                    />
                    <IconBlockSite
                        className={`mo-player-site-action-icon-full ${deviceType}`}
                        disabled={!Boolean(nextTrack)}
                        iconClass='mo-new-icon-step-forward-solid'
                        onClick={onNext}
                    />
                    <IconBlockSite
                        className={`mo-player-site-action-icon-full album ${deviceType}`}
                        disabled={!Boolean(nextAlbumSkipTrack && reduxPlayerSite.albumTrackArray && reduxPlayerSite.albumTrackArray[Number(reduxPlayerSite.albumTrackIndex || 0) + 1])}
                        iconClass='mo-new-icon-fast-forward-solid'
                        onClick={onNextAlbumSkip}
                    />
                </div>
            </div>
            <div className={`mo-player-site-right ${deviceType}`}>
                <div className={`mo-player-site-range-duration ${deviceType}`}>
                    <span>{moment.utc(played * 1000).format('HH:mm:ss')}</span>
                    <span> / </span>
                    <span>{moment.utc(duration * 1000).format('HH:mm:ss')}</span>
                </div>
                <div className={`mo-player-site-platform-wrap ${deviceType}`}>
                    <p className={`mo-player-site-powered ${deviceType}`}>Powered by</p>
                    <ImageHelperSite
                        alt={reduxPlayerSite.currentTrack.name}
                        className={`mo-player-site-platform ${deviceType}`}
                        dominant_color={reduxPlayerSite.currentTrack?.get_dominant_color}
                        normal
                        src={`${media_url_platform_site}${reduxPlayerSite.currentTrack?.platform}.svg${media_version_site}`}
                    />
                </div>
                <div className={`mo-player-site-block-header ${deviceType}`}>
                    {(nextTrack || prevTrack) && (
                        <IconBlockSite
                            className={`mo-player-site-action-icon ${deviceType}`}
                            iconClass='mo-new-icon-list-ul-solid'
                            onClick={() => setShowTrackList(!showTrackList)}
                        />
                    )}
                    <IconBlockSite
                        className={`mo-player-site-action-icon ${deviceType}`}
                        iconClass={`mo-new-icon-chevron-up-solid ${(reduxPlayerSite.playerPosition === 'bottom' ? '' : 'rotate')}`}
                        onClick={() => dispatch(reduxPlayerSetPositionSite(reduxPlayerSite.playerPosition === 'bottom' ? 'full' : 'bottom'))}
                    />
                    <IconBlockSite
                        className={`mo-player-site-action-icon ${deviceType}`}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => onDismissPlayer()}
                    />
                </div>
            </div>
            <div className={`mo-player-minimize ${deviceType}`}>
                <IconBlockSite
                    className={`mo-player-site-action-icon ${deviceType}`}
                    iconClass={`mo-new-icon-chevron-up-solid ${(reduxPlayerSite.playerPosition === 'bottom' ? '' : 'rotate')}`}
                    onClick={() => dispatch(reduxPlayerSetPositionSite(reduxPlayerSite.playerPosition === 'bottom' ? 'full' : 'bottom'))}
                />
            </div>
            <div className={`mo-player-close ${deviceType}`}>
                <IconBlockSite
                    className={`mo-player-site-action-icon ${deviceType}`}
                    iconClass='mo-new-icon-times-solid'
                    onClick={() => onDismissPlayer()}
                />
            </div>
            <EcPlayerTracklistSite
                showTrackList={showTrackList}
                setShowTrackList={setShowTrackList}
            />
            <Snackbar
                action={
                    <Button
                        onClick={() => showErrorMessage(false)}
                        size='small'
                    >
                        {reduxText[7656]}
                    </Button>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                message={reduxText[7655]}
                onClose={() => showErrorMessage(false)}
                open={errorMessage}
            />
        </div>
    )
}
