// react components
import React from 'react'
import {
    IonSpinner,
} from '@ionic/react'

// props
type ListSpinnerSiteProps = {
    color?: 'tertiary' | 'primary' | 'secondary'
    isLoading?: boolean
}

// main
export const ListSpinnerSite: React.FC<ListSpinnerSiteProps> = React.memo(({
    color,
    isLoading,
}) => {

    return (
        <div
            className='list-spinner-site'
            style={{
                padding: isLoading ? '10px' : '0px',
            }}
        >
            {isLoading && (
                <IonSpinner
                    color={color || 'primary'}
                />
            )}
        </div>
    )
})
