// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template787SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
    onClickIsModalSite,
} from 'services'

// props
type TemplateBlock787SiteProps = {
    blockId: string
    content: Template787SiteSerializer | undefined
    contentArray?: Template787SiteSerializer[]
    isEditHovered: boolean
    isInComponent?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock787Site: React.FC<TemplateBlock787SiteProps> = React.memo(({
    blockId,
    content,
    contentArray,
    isEditHovered,
    isInComponent,
    isInVideoBlock,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxPlayerSiteplayingInlineId = useSelector((state: defaultReduxState) => state.reduxPlayerSite.playingInlineId)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock787Site',
                'applyStyles',
            ))
        }
    }

    if (isInVideoBlock && reduxPlayerSiteplayingInlineId === content?.id) return null

    return (
        <LinkHelperSite
            id={blockId}
            action={object.action}
            className={`template-block-787${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            linkUrl={content?.link_url}
            onClick={() => content ? onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, content, object, contentArray || [content], isInVideoBlock) : undefined}
            style={object.data_json?.iconFontLibrary ? undefined : stylesNew}
            textTag='span'
            to={content?.absolute_site_url}
        >
            {object.data_json?.iconFontLibrary
                ? <span
                    className={`t-787-icon ${object.data_json.iconFontLibrary}`}
                    style={stylesNew}
                >
                    {/* @ts-ignore */}
                    {content?.text}
                </span>
                : (
                    <i className={`t-787-icon mo-new-icon-${content?.text || 'times-solid'}`} />
                )
            }
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </LinkHelperSite>
    )
})
