// react components
import React from 'react'

// serializers
import {
    CustomCSSProperties,
} from 'serializers/site'

// props
type IconBlockSiteProps = {
    active?: boolean
    className?: string
    color?: 'light'
    disabled?: boolean
    edit?: boolean
    iconClass: string
    onClick?: (e: any) => void
    style?: CustomCSSProperties
    tooltipText?: string
}

// main
export const IconBlockSite: React.FC<IconBlockSiteProps> = React.memo(({
    active,
    className,
    color,
    disabled,
    edit,
    iconClass,
    onClick,
    style,
    tooltipText,
}) => {

    return (
        <button
            className={`main-icon-block-site${color ? ` ${color}` : ''}${className ? ` ${className}` : ''}`}
            disabled={edit || disabled}
            onClick={edit ? undefined : onClick}
            title={tooltipText}
        >
            <i
                className={`main-icon ${iconClass}${active ? ' color' : ''}`}
                style={style}
            />
        </button>
    )
})
