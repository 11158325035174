// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import VerificationInput from 'react-verification-input'

// components
import {
    IconBlockSite,
    LinkHelperSite,
    LoaderSite,
} from 'components'

// data
import {
    api_url_auth_password_confirm_site,
    api_url_auth_password_reset_site,
    api_url_auth_password_verification_code_site,
    defaultReduxState,
    reduxAuthLoginSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services 
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// props
type TemplateBlock806PasswordResetSiteProps = {
    blockId: string
    buttonStyles: any
    email: string
    object: PortfolioPageContentListSiteSerializer
    onHover: any
    setPanel: React.Dispatch<React.SetStateAction<'login' | 'password-reset' | 'signup'>>
    stylesNew: any
}

// main
export const TemplateBlock806PasswordResetSite: React.FC<TemplateBlock806PasswordResetSiteProps> = React.memo(({
    blockId,
    buttonStyles,
    email,
    object,
    onHover,
    setPanel,
    stylesNew,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    type errorsType = {
        email?: string
        non_field_errors?: string
        password?: string
        verification_code?: string
    }
    type fieldsType = {
        email: string
        password: string
        verification_code: string
    }
    const errorsInitial = {}
    const fieldsInitial = {
        email: email,
        password: '',
        verification_code: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [step, setStep] = useState<'email' | 'verification_code' | 'password'>('email')
    const [verificationUUID, setVerificationdUUID] = useState<string>()

    function handleInputChange(event: any) {
        try {
            const name = event.target.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.target.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806PasswordResetSite',
                'handleInputChange',
            ))
        }
    }

    function handleCodeInputChange(event: any) {
        try {
            setButtonDisabled(false)
            setFields({ ...fields, verification_code: event })
            setErrors({ ...errors, verification_code: '', non_field_errors: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806PasswordResetSite',
                'handleCodeInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            setButtonDisabled(true)
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) onSubmit')

            const formData: any = new FormData()

            if (step === 'email') {
                formData.append('username', fields.email.toLowerCase().trim())
                formData.append('portfolioId', reduxCacheSiteportfolioid)
                const postUrl = getApiUrlSite(api_url_auth_password_reset_site, reduxModalSite)
                if (process.env.NODE_ENV === 'development') console.log(postUrl)
                axios({
                    data: formData,
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        setStep('verification_code')
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock806PasswordResetSite',
                            dispatch,
                            error,
                            reduxAuth,
                            reduxModalSite,
                            reference: 'onSubmit',
                            skipAlertStatusCode: [400],
                            skipSendErrorStatusCode: [400],
                        })
                    })
            } else if (step === 'verification_code') {
                formData.append('username', fields.email.toLowerCase().trim())
                formData.append('verification_code', fields.verification_code)
                const postUrl = getApiUrlSite(api_url_auth_password_verification_code_site, reduxModalSite)
                if (process.env.NODE_ENV === 'development') console.log(postUrl)
                axios({
                    data: formData,
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        setStep('password')
                        setVerificationdUUID(response.data.verification_uuid)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock806PasswordResetSite',
                            dispatch,
                            error,
                            reduxAuth,
                            reduxModalSite,
                            reference: 'onSubmit',
                            skipAlertStatusCode: [400],
                            skipSendErrorStatusCode: [400],
                        })
                    })
            } else if (step === 'password') {
                formData.append('password', fields.password)
                formData.append('username', fields.email.toLowerCase().trim())
                formData.append('verification_uuid', verificationUUID!)
                const postUrl = getApiUrlSite(api_url_auth_password_confirm_site, reduxModalSite)
                if (process.env.NODE_ENV === 'development') console.log(postUrl)
                axios({
                    data: formData,
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        // @ts-ignore
                        dispatch(reduxAuthLoginSite(
                            fields.email,
                            fields.password,
                            reduxAuth,
                            reduxModalSite,
                            dispatch,
                            setIsLoading,
                            setFields,
                            fieldsInitial,
                            reduxCacheSiteportfolioid!,
                            setErrors,
                        ))
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock806PasswordResetSite',
                            dispatch,
                            error,
                            reduxAuth,
                            reduxModalSite,
                            reference: 'onSubmit',
                            skipAlertStatusCode: [400],
                            skipSendErrorStatusCode: [400],
                        })
                    })
            }
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806PasswordResetSite',
                'onSubmit',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-806 ${deviceType}`}
        >
            <div className={`t-806-input-wrap ${deviceType}`}>
                {step === 'email' && (
                    <React.Fragment>
                        <p
                            className={`t-806-label ${deviceType}`}
                            style={{ color: stylesNew.loginTextColor }}
                        >
                            {reduxText[8421]}
                        </p>
                        <input
                            className={`t-806-input ${deviceType}`}
                            disabled
                            name='email'
                            onChange={handleInputChange}
                            style={{
                                background: stylesNew.loginInputBackgroundColor,
                                color: stylesNew.loginInputColor,
                            }}
                            type='text'
                            value={fields.email}
                        />
                        {(errors.email) && (
                            <span className={`t-806-error ${deviceType}`}>{errors.email}</span>
                        )}
                    </React.Fragment>
                )}
                {step === 'verification_code' && (
                    <div className={`t-806-verification-wrap ${deviceType}`}>
                        <p
                            className={`t-806-label ${deviceType}`}
                            style={{ color: stylesNew.loginTextColor }}
                        >
                            {reduxText[8534]}
                        </p>
                        <div className={`t-806-verification-input ${deviceType}`}>
                            <VerificationInput
                                length={4}
                                placeholder=''
                                onChange={handleCodeInputChange}
                                validChars={'0123456789'}
                                value={fields.verification_code}
                            />
                            <IconBlockSite
                                iconClass='mo-new-icon-undo-alt-solid'
                                onClick={() => {
                                    setFields({ ...fields, verification_code: '' })
                                    setErrors({ ...errors, verification_code: '', non_field_errors: '' })
                                }}
                                style={{ color: stylesNew.loginTextColor }}
                                tooltipText=''
                            />
                        </div>
                        {(errors.verification_code) && (
                            <span className={`t-806-error ${deviceType}`}>{errors.verification_code}</span>
                        )}
                    </div>
                )}
                {step === 'password' && (
                    <React.Fragment>
                        <div className={`t-806-label-wrap ${deviceType}`}>
                            <p
                                className={`t-806-label ${deviceType}`}
                                style={{ color: stylesNew.loginTextColor }}
                            >
                                {reduxText[8535]}
                            </p>
                            <IconBlockSite
                                className={`t-806-icon ${deviceType}`}
                                iconClass={showPassword ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'}
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ color: stylesNew.loginTextColor }}
                            />
                        </div>
                        <input
                            className={`t-806-input ${deviceType}`}
                            name='password'
                            onChange={handleInputChange}
                            style={{
                                background: stylesNew.loginInputBackgroundColor,
                                color: stylesNew.loginInputColor,
                            }}
                            type={showPassword ? 'text' : 'password'}
                        />
                        {(errors.password) && (
                            <span className={`t-806-error ${deviceType}`}>{errors.password}</span>
                        )}
                    </React.Fragment>
                )}
            </div>
            <div className={`t-806-button-wrap ${deviceType}`}>
                <button
                    className={`t-806-button ${deviceType}`}
                    disabled={buttonDisabled || isLoading}
                    onClick={onSubmit}
                    onMouseLeave={() => onHover('leave')}
                    onMouseOver={() => onHover('over')}
                    style={buttonStyles}
                    type='button'
                >
                    {reduxText[8424]}
                </button>
                {(errors.non_field_errors) && (
                    <span className={`t-806-error ${deviceType}`}>{errors.non_field_errors}</span>
                )}
            </div>
            <div className={`t-806-link-wrap ${deviceType}`}>
                <LinkHelperSite
                    action='page_link'
                    className={`t-806-link ${deviceType}`}
                    onClick={() => setPanel('login')}
                    style={{ color: stylesNew.loginTextColor }}
                    to={undefined}
                >
                    {reduxText[8536]}
                </LinkHelperSite>
            </div>
            {isLoading && (
                <LoaderSite isOpen />
            )}
        </div>
    )
})
