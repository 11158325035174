// data
import {
    REDUX_CACHE_SET_PAGE_CONTENT_SITE,
    REDUX_CACHE_SET_PAGE_FONTS_SITE,
    REDUX_CACHE_SET_PAGE_SITE,
    REDUX_CACHE_SET_PORTFOLIO_SITE,
} from './mo_actions'
import {
    reduxCacheStateSite,
} from './mo_state'
import {
    REDUX_MODAL_SET_NAVBAR_HEIGHT_SITE,
} from '../modal/mo_actions'

// main
const initialState: reduxCacheStateSite = {
    hubContent: undefined,
    navbarHeightIsSet: false,
    pageContents: undefined,
    pageFonts: undefined,
    pages: undefined,
    portfolio: undefined,
    portfolioCurrentApiUrl: undefined,
    portfolios: undefined,
    privateContent: undefined,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_MODAL_SET_NAVBAR_HEIGHT_SITE: {
            return {
                ...state,
                navbarHeightIsSet: true,
            }
        }
        case REDUX_CACHE_SET_PAGE_CONTENT_SITE: {
            return {
                ...state,
                pageContents: {
                    ...state.pageContents,
                    [action.payload.apiUrl]: action.payload.pageContentResult,
                },
            }
        }
        case REDUX_CACHE_SET_PAGE_FONTS_SITE: {
            return {
                ...state,
                pageFonts: action.payload,
            }
        }
        case REDUX_CACHE_SET_PAGE_SITE: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.payload.apiUrl]: action.payload.pageResult,
                },
            }
        }
        case REDUX_CACHE_SET_PORTFOLIO_SITE: {
            return {
                ...state,
                portfolio: action.payload.portfolioResult,
                portfolios: {
                    ...state.portfolios,
                    [action.payload.apiUrl]: action.payload.portfolioResult,
                },
                portfolioCurrentApiUrl: action.payload.apiUrl,
            }
        }
        default:
            return state
    }
}
