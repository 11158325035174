// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    groupingIdsSite,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

import * as templateBlock from '../../index'

// props
type ImageHelper817SiteProps = {
    columnsNumber: number | undefined
    // direction: string
    // left?: number
    // margin?: string
    // photo: any
    // top?: number

    content: PortfolioPageContentListSiteSerializer[]
    contentIds: any
    detailId: string | undefined
    devId: string | undefined
    isInComponent: boolean | undefined
    isInDetailPage: boolean | undefined
    isInVideoBlock: boolean | undefined
    mainParentId: number | undefined
    object: PortfolioPageContentListSiteSerializer
    pageSlug: string | undefined
    parentArray: any
    parentDropdownId: number | undefined
    reduxFormSiteeditAutoSave: any
    reduxFormSiteeditAutoSaved: any
    reduxFormSitehoveredBlockId: any
    withText: string | undefined
}

export const ImageHelper817Site: React.FC<ImageHelper817SiteProps> = React.memo(({
    content,
    contentIds,
    detailId,
    devId,
    // direction,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    // left,
    mainParentId,
    // margin,
    object,
    pageSlug,
    parentArray,
    parentDropdownId,
    // photo,
    reduxFormSiteeditAutoSave,
    reduxFormSiteeditAutoSaved,
    reduxFormSitehoveredBlockId,
    // top,
    // withText,
    ...props
}) => {

    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    // if (direction === 'column') {
    //     // @ts-ignore
    //     props.position = 'absolute'
    //     // @ts-ignore
    //     props.left = left
    //     // @ts-ignore
    //     props.top = top
    // }

    // if (direction === 'row') {
    //     // @ts-ignore
    //     props.position = 'relative'
    // }

    // @ts-ignore
    const i = props.index

    return (
        <div className={`t-817-image-helper ${deviceType}`}>
            {content.filter(obj => obj.active === true).map((val) => {
                // @ts-ignore
                const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                return (
                    <Component
                        key={val.id}
                        blockId={contentBlockId}
                        content={groupingIdsSite.includes(val.layout!) ? val.content : (val.content[i] || contentIds[i])}
                        contentArray={contentIds}
                        detailId={detailId}
                        devId={devId}
                        hasMore={false}
                        inListIndex={i}
                        isEditHovered={reduxFormSitehoveredBlockId === val.id}
                        isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                        isInDetailPage={isInDetailPage}
                        isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                        mainParentId={mainParentId || object.id}
                        object={val}
                        pageSlug={pageSlug}
                        parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                        parentDropdownId={parentDropdownId}
                        parentStyles={object.styles}
                        styles={val.styles}
                        stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                    />
                )
            })}
        </div>
    )
})
