// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    PrivateSpacePageHelperSerializer,
    PortfolioPrivateSpaceListSiteSerializer,
} from 'serializers/site'

// props
type PrivateSideMenuTabWebProps = {
    object: PortfolioPrivateSpaceListSiteSerializer
    setTabObject: React.Dispatch<PrivateSpacePageHelperSerializer>
    tabSlug: string
}

// main
export const PrivateSideMenuTabWeb: React.FC<PrivateSideMenuTabWebProps> = React.memo(({
    object,
    setTabObject,
    tabSlug,
}) => {

    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    // const [isCollapsed, setIsCollapsed] = useState<boolean>((tabSlug !== object.page?.slug) && (tabSlug?.split('-')[0] !== object.page?.slug))

    // useEffect(() => {
    //     let shoulCollapse = (tabSlug !== object.slug) && (tabSlug?.split('-')[0] !== object.slug)
    //     if (shoulCollapse) {
    //         object.children?.map(val => {
    //             if (val.slug === tabSlug) shoulCollapse = false
    //         })
    //     }
    //     if (isCollapsed && !shoulCollapse) {
    //         setIsCollapsed(shoulCollapse)
    //     }
    // }, [
    //     tabSlug,
    // ])

    return (
        <div className={`private-side-menu-tab-site ${deviceType}`}>
            <div className={`psmts-space-wrap ${deviceType}`}>
                <p className={`psmts-space-wrap-name ${deviceType}`}>{object.name}</p>
                {object.pages?.map((val) => (
                    <>
                        <div className={`psmts-tab-item-wrap ${deviceType}`}>
                            {/* <i
                    className={`psmts-expand mo-new-icon-caret-right-solid${isCollapsed ? '' : ' open'}${object.children?.length! > 0 ? '' : ' hidden'} ${deviceType}`}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                /> */}
                            <LinkHelperSite
                                action='generic_link'
                                className={`psmts-tab-item ${deviceType} p-${reduxCacheSiteportfolioid}`}
                                to={`extra/private/${val.page?.slug}`}
                                onClick={() => setTabObject(object)}
                            >
                                {val.page?.name?.split('Private - ')[1]}
                            </LinkHelperSite>
                        </div>
                        {/* {object.children?.length! > 0 && (
                <div className={`psmts-children-wrap${isCollapsed ? '' : ' open'} ${deviceType}`}>
                    {object.children?.map((val) => (
                        <LinkHelperSite
                            key={val.id}
                            action='generic_link'
                            className={`psmts-tab-item-children ${deviceType} ${reduxCacheSiteportfolioid}`}
                            to={`extra/private/${val.slug}`}
                            onClick={() => setTabObject(val)}
                        >
                            {val.name}
                        </LinkHelperSite>
                    ))}
                </div>
            )} */}
                    </>
                ))}
            </div>
        </div>
    )
})
