export const groupingIdsSite = [
    780,
    784,
    790,
    793,
    796,
    803,
    811,
    817,
    824,
]
