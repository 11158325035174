// data
import {
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
} from 'serializers/site'

const propertyToExclude = [
    'blurBg',
    'burgerType',
    'centerMode',
    'defaultMargin',
    'fixedRatioPercentage',
    'imageOrientation',
    'length',
    'logo',
    'mode',
    'navMode',
    'navMode2',
    'placeholder',
    'seperation',
    'setter',
    'show',
    'showNavigationArrow',
    'showTitleOnTop',
    'socialColor',
    'variableWidth',
]

// helpers
function getStyleValue(reduxModalSite: reduxModalStateSite, styleValue: string) {
    if (styleValue === 'minNav100Vh') {
        if (reduxModalSite.deviceType === 'is-mobile') {
            return `calc(100vh - ${reduxModalSite.isOpen ? 53 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px)`
        }
        return `calc(100vh - ${reduxModalSite.isOpen ? 30 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px)`
    }
    return styleValue
}

function getFormEditValue(reduxModalSite: reduxModalStateSite, styleValue: string) {
    if (typeof styleValue === 'string') {
        if (styleValue.includes('vh')) {
            const vhValue = Number(styleValue.split('vh')[0])
            return `calc((100vh - 45px - 60px) / (100 / ${vhValue}))`
        } else if (styleValue === 'minNav100Vh') {
            return `calc(100vh - ${reduxModalSite.isOpen ? 30 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px - 45px - 60px)`
        }
        return styleValue
    }
    if (styleValue === 'minNav100Vh') {
        return `calc(100vh - ${reduxModalSite.isOpen ? 30 : (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight)}px)`
    }
    return styleValue
}

// main
export function getStyles(
    reduxModalSite: reduxModalStateSite,
    pageContentId: number | undefined,
    blockStyles: any,
    css_class: string,
) {
    const respValue = reduxModalSite.respValue
    if (!blockStyles || !blockStyles[css_class] || !blockStyles[css_class][`value${respValue}`]) {
        return undefined
    }
    const styles: CustomCSSProperties = {}
    if (reduxModalSite.edit) {
        if (reduxModalSite.stylesEditObj?.id && reduxModalSite.stylesEditObj.id === pageContentId) {
            // @ts-ignore
            Object.getOwnPropertyNames(reduxModalSite.stylesEditObj.styles[css_class][`value${respValue}`]).map((val) => {
                // @ts-ignore
                styles[val] = getFormEditValue(reduxModalSite, reduxModalSite.stylesEditObj.styles[css_class][`value${respValue}`][val])
                return false
            })
        } else {
            Object.getOwnPropertyNames(blockStyles[css_class][`value${respValue}`]).map((val) => {
                // @ts-ignore
                styles[val] = getFormEditValue(reduxModalSite, blockStyles[css_class][`value${respValue}`][val])
                return false
            })
        }
    } else {
        Object.getOwnPropertyNames(blockStyles[css_class][`value${respValue}`]).map((val) => {
            if (!propertyToExclude.includes(val)) {
                // @ts-ignore
                styles[val] = getStyleValue(reduxModalSite, blockStyles[css_class][`value${respValue}`][val])
            }
            return false
        })
    }
    return styles
}

export function getStylesToRetrieve(
    reduxModalSite: reduxModalStateSite,
    pageContentId: number | undefined,
    blockStyles: any,
    css_class: string,
    styleToRetrieve: string,
) {
    const respValue = reduxModalSite.respValue
    if (!blockStyles) {
        return undefined
    }
    if (!css_class) {
        if (blockStyles[`value${respValue}`]) {
            if (reduxModalSite.edit) {
                if (reduxModalSite.stylesEditObj?.id && reduxModalSite.stylesEditObj.id === pageContentId) {
                    // @ts-ignore
                    return getFormEditValue(reduxModalSite, reduxModalSite.stylesEditObj.styles[`value${respValue}`][styleToRetrieve])
                } else {
                    return getFormEditValue(reduxModalSite, blockStyles[`value${respValue}`][styleToRetrieve])
                }
            } else {
                return getStyleValue(reduxModalSite, blockStyles[`value${respValue}`][styleToRetrieve])
            }
        }
        return undefined
    }
    if (!blockStyles[css_class]) {
        return undefined
    }
    if (reduxModalSite.edit) {
        if (reduxModalSite.stylesEditObj?.id && reduxModalSite.stylesEditObj.id === pageContentId) {
            // @ts-ignore
            return getFormEditValue(reduxModalSite, reduxModalSite.stylesEditObj.styles[css_class][`value${respValue}`][styleToRetrieve])
        } else {
            return getFormEditValue(reduxModalSite, blockStyles[css_class][`value${respValue}`][styleToRetrieve])
        }
    } else {
        return getStyleValue(reduxModalSite, blockStyles[css_class][`value${respValue}`][styleToRetrieve])
    }
}

export function getStylesToRetrieve2(
    reduxModalSite: reduxModalStateSite,
    blockStyles: any,
    css_class: string,
    styleToRetrieve: string,
) {
    const respValue = reduxModalSite.respValue
    if (!blockStyles) {
        return undefined
    }
    if (!css_class) {
        if (blockStyles[`value${respValue}`]) {
            return getStyleValue(reduxModalSite, blockStyles[`value${respValue}`][styleToRetrieve])
        }
        return undefined
    }
    if (!blockStyles[css_class]) {
        return undefined
    }
    return getStyleValue(reduxModalSite, blockStyles[css_class][`value${respValue}`][styleToRetrieve])
}
