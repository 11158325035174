// serializers
import {
    MainFontSerializer,
    PageDetailSiteSerializer,
    PortfolioDetailSiteSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// constants
export const REDUX_CACHE_SET_PAGE_CONTENT_SITE = 'REDUX_CACHE_SET_PAGE_CONTENT_SITE'
export const REDUX_CACHE_SET_PAGE_FONTS_SITE = 'REDUX_CACHE_SET_PAGE_FONTS_SITE'
export const REDUX_CACHE_SET_PAGE_SITE = 'REDUX_CACHE_SET_PAGE_SITE'
export const REDUX_CACHE_SET_PORTFOLIO_SITE = 'REDUX_CACHE_SET_PORTFOLIO_SITE'

// actions
export function reduxCacheSetPageContentSite(apiUrl: string, pageContentResult: PortfolioPageContentListSiteSerializer[]) {
    return {
        type: REDUX_CACHE_SET_PAGE_CONTENT_SITE,
        payload: {
            apiUrl,
            pageContentResult,
        },
    }
}

export function reduxCacheSetPageFontsSite(fonts: MainFontSerializer[]) {
    return {
        type: REDUX_CACHE_SET_PAGE_FONTS_SITE,
        payload: fonts,
    }
}

export function reduxCacheSetPageSite(apiUrl: string, pageResult: PageDetailSiteSerializer) {
    return {
        type: REDUX_CACHE_SET_PAGE_SITE,
        payload: {
            apiUrl,
            pageResult,
        },
    }
}

export function reduxCacheSetPortfolioSite(apiUrl: string, portfolioResult: PortfolioDetailSiteSerializer) {
    return {
        type: REDUX_CACHE_SET_PORTFOLIO_SITE,
        payload: {
            apiUrl,
            portfolioResult,
        },
    }
}
