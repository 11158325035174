// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    useIonViewWillEnter,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    LoaderSite,
    SeoBlockSite,
    StaffHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    ContentBlockSite,
    FooterBlockSite,
    NotFoundPortfolioSite,
} from 'pages'

// serializers
import {
    PageDetailSiteSerializer,
    PortfolioPageContentListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getDataSite,
    getStylesToRetrieve,
} from 'services'

// props
type DetailPageSiteProps = {
    detailId: string
    devId: string
    pageSlug: string
}

// main
export const DetailPageSite: React.FC<DetailPageSiteProps> = React.memo(({
    detailId,
    devId,
    pageSlug,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [currencyId, setCurrencyId] = useState<number | undefined>(reduxModalSite.currencyId)
    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [isLoading, setIsLoading] = useState(false)
    const [languageId, setLanguageId] = useState<number>(reduxModalSite.languageId)
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[] | undefined>(undefined)
    const [pageObject, setPageObject] = useState<PageDetailSiteSerializer>()

    const [fontFamily, setFontFamily] = useState<string>()
    const [linkColor, setLinkColor] = useState<string>()
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})

    useEffect(() => {
        getPortfolioData()
    }, [
        detailId,
        pageSlug,
    ])

    useIonViewWillEnter(() => {
        scrollToTop()
    })

    useEffect(() => {
        if (languageId !== reduxModalSite.languageId) {
            getPortfolioData()
            setLanguageId(reduxModalSite.languageId)
        } else if (currencyId !== reduxModalSite.currencyId) {
            getPortfolioData()
            setCurrencyId(reduxModalSite.currencyId)
        }
    }, [
        reduxModalSite.currencyId,
        reduxModalSite.languageId,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        if (getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'backgroundImage')) {
            tempPageStyles.backgroundImage = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'backgroundImage')
        } else {
            tempPageStyles.background = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'background')
        }
        tempPageStyles.color = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'color')
        if (reduxModalSite.navbarType === 'top') {
            tempPageStyles.paddingTop = `${reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight}px`
        } else if (reduxModalSite.navbarType === 'side') {
            tempPageStyles.paddingLeft = `${reduxModalSite.navbarWidth}px`
        }
        setFontFamily(getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'fontFamily'))
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'linkColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'linkColor'))
    }, [
        pageObject?.styles,
        reduxCacheSite.portfolio?.styles,
        reduxModalSite,
    ])

    const contentRef = useRef<HTMLIonContentElement>(null)
    const scrollToTop = (force?: boolean) => {
        try {
            if (history.action !== 'POP' || force) {
                // @ts-ignore
                if (document.getElementById('mo-content-detail-id-site') && document.getElementById('mo-content-detail-id-site').scrollTo) document.getElementById('mo-content-detail-id-site').scrollTo(0, 0)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'DetailPageSite',
                'scrollToTop',
            ))
        }
    }

    function getPortfolioData() {
        try {
            getDataSite({
                axiosCancelToken,
                component: 'DetailPageSite',
                detailId,
                devId,
                dispatch,
                errorStatus,
                location,
                pageSlug,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                scrollToTop,
                setAxiosCancelToken,
                setErrorStatus,
                setIsLoading,
                setPageContentItems,
                setPageObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'DetailPageSite',
                'getPortfolioData',
            ))
        }
    }

    if (errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent) {
        return (
            <NotFoundPortfolioSite statusCode={errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent} />
        )
    }
    return (
        <IonPage
            className='slug-page-site'
            style={{
                fontFamily: fontFamily,
            }}
        >
            <SeoBlockSite data={pageObject?.seo_data} />
            <IonContent
                id='mo-content-detail-id-site'
                className={`mo-hide-ion-content-scrollbar${reduxModalSite.isWindows}`}
                ref={contentRef}
            >
                {isLoading && (
                    <LoaderSite isOpen />
                )}
                <div
                    className={`slug-page-content-wrap lg-${reduxModalSite.languageId} ${deviceType}`}
                    style={{
                        background: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'background'),
                        color: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'color'),
                        fontFamily: fontFamily,
                        margin: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'margin'),
                    }}
                >
                    <div
                        className={`slug-page-content ${isLoading ? 'opacity' : 'no-opacity'}`}
                        style={newPageStyles}
                    >
                        <div
                            className={`slug-page-content-list ${deviceType}`}
                            style={{
                                padding: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'padding'),
                            }}
                        >
                            {reduxCacheSite.portfolio && pageObject && pageContentItems?.filter(obj => obj.active === true).map((val) => (
                                <ContentBlockSite
                                    key={val.id}
                                    detailId={detailId}
                                    devId={devId}
                                    isEditHovered={false}
                                    isInDetailPage
                                    linkColor={linkColor}
                                    object={val}
                                    pageSlug={pageSlug}
                                    stylesEdit={undefined}
                                />
                            ))}
                        </div>
                        {!isLoading && reduxCacheSite.portfolio?.footer && pageObject && pageContentItems && (
                            <FooterBlockSite
                                linkColor={linkColor}
                                stylesEdit={undefined}
                            />
                        )}
                    </div>
                </div>
                <StaffHelperSite
                    detailId={detailId}
                    pageObject={pageObject}
                    pageSlug={pageSlug}
                />
            </IonContent>
        </IonPage>
    )
})
