// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// props
type ReviewHelper816SiteProps = {
    test?: string
}

// main
export const ReviewHelper816Site: React.FC<ReviewHelper816SiteProps> = React.memo(({
    test,
}) => {

    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    return (
        <div className={`t-816-review ${deviceType}`}>
            <span className={`t-816-review-title ${deviceType}`}>{reduxText[8738]}</span>
            <span className={`t-816-review-sub-title ${deviceType}`}>{reduxText[8748]}</span>
            {reduxSalesSite.ticketData?.variants && Object.values(reduxSalesSite.ticketData.variants).filter(obj => obj.qty > 0).map((val) => (
                [...Array(val.qty)].map((_, index) => (
                    <div
                        key={`${val.variant.id}-${index}`}
                        className={`t-816-review-ticket-wrap ${deviceType}`}
                    >
                        <span className={`t-816-review-ticket-participant ${deviceType}`}>{val.participants?.[index]?.firstName} {val.participants?.[index]?.lastName}</span>
                        <div className={`t-816-review-ticket-variant ${deviceType}`}>
                            <span className={`t-816-review-ticket-variant-name ${deviceType}`}>{val.variant.name}</span>
                            <span className={`t-816-review-ticket-variant-price ${deviceType}`}>{val.variant.unit_price}  {reduxCacheSite.portfolio?.currency?.symbol}</span>
                        </div>
                    </div>
                ))
            ))}
        </div>
    )
})
