// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// props
type CheckboxInput819SiteProps = {
    children?: any
    disabled?: boolean
    error: string | undefined
    label: string
    name: string
    onChange: any
    value: any
}

// main
export const CheckboxInput819Site: React.FC<CheckboxInput819SiteProps> = React.memo(({
    children,
    disabled,
    error,
    label,
    name,
    onChange,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    function onInputChange(targetValue: boolean) {
        try {
            onChange({
                name: name,
                value: targetValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TextInput819Site',
                'onInputChange',
            ))
        }
    }

    return (
        <div className={`input-819 checkbox-input-819 ${deviceType}`}>
            <div className='ci-819-wrap'>
                <span className={`input-819-label ${deviceType}`}>{children || label}</span>
                <input
                    checked={value || false}
                    className={`ci-819-input ${deviceType}`}
                    disabled={disabled}
                    name={name}
                    onChange={(e) => onInputChange(e.target.checked)}
                    type='checkbox'
                />
            </div>
            {error && <span className='input-819-error'>{error}</span>}
        </div>
    )
})
