// services
import {
    parseQuerySite,
} from 'services'

// main
export function getModalAbsoluteUrlSite(absoluteUrl: string) {
    let newabsoluteUrl = window.location.pathname
    const absoluteUrlSplit = absoluteUrl.split('/')

    newabsoluteUrl += `?modal_page=${absoluteUrlSplit[0]}`
    if (absoluteUrlSplit[1]) newabsoluteUrl += `&modal_detail_id=${absoluteUrlSplit[1]}`

    const locationSearch = parseQuerySite(window.location.search)
    if (locationSearch.query) newabsoluteUrl += `&query=${locationSearch.query}`
    if (locationSearch.filter_query) newabsoluteUrl += `&filter_query=${locationSearch.filter_query}`
    if (locationSearch.tab) newabsoluteUrl += `&tab=${locationSearch.tab}`

    return newabsoluteUrl
}
