// react components
import React, {
    useEffect,
    useState,
} from 'react'
import WebFont from 'webfontloader'


const createFontQuery = (fontFamilyName: any, stylesArray: any) => {
    const stylesString = stylesArray.join(',')
    const fontQuery = `${fontFamilyName}:${stylesString}`
    if (stylesString.length === 0) return false
    return fontQuery
}

const checkLoadedFonts = (family: any, fonts: any) => {
    const familyName = family.split(':')[0]
    const styles = family.split(':')[1].split(',')

    const stylesToLoad: any = []
    styles.forEach((style: any, index: any) => {
        const weight = style.charAt(0)
        const classification = style.charAt(3) || 'n'
        const styleToCheck = `${classification}${weight}`
        if (fonts && (!(`${familyName} ${styleToCheck}` in fonts) || (`${familyName} ${styleToCheck}` in fonts && fonts[(`${familyName} ${styleToCheck}`)].subset))) {
            stylesToLoad.push(styles[index])
        }
    })
    const fontQuery = (createFontQuery(familyName, stylesToLoad))
    return fontQuery
}

// props
type FontLoaderProps = {
    classes?: any
    customUrls?: any
    debug?: any
    fontFamilies?: any
    fontIsLoaded?: any
    fontIsLoading?: any
    fontLoadFailed?: any
    fontProvider?: any
    fontdeckId?: any
    fonts?: any
    monotypeLoadAllFonts?: any
    monotypeProjectId?: any
    monotypeVersion?: any
    onActive?: any
    onInactive?: any
    onLoading?: any
    text?: any
    timeout?: any
    typekitAPI?: any
    typekitId?: any
}

// main
export const FontLoader: React.FC<FontLoaderProps> = React.memo(({
    classes,
    customUrls,
    debug,
    fontFamilies,
    fontIsLoaded,
    fontIsLoading,
    fontLoadFailed,
    fontProvider,
    fontdeckId,
    fonts,
    monotypeLoadAllFonts,
    monotypeProjectId,
    monotypeVersion,
    onActive,
    onInactive,
    onLoading,
    text,
    timeout,
    typekitAPI,
    typekitId,
}) => {

    const [stateFontFamilies, setStateFontFamilies] = useState<string>()

    useEffect(() => {
        let stylesToState: any = ''
        fontFamilies.map((val: any) => {
            stylesToState += val
            return false
        })
        if (stylesToState !== stateFontFamilies) {
            setStateFontFamilies(stylesToState)
            const stylesToLoad: any = []
            if (fontFamilies) {
                fontFamilies.forEach((family: any) => {
                    const inactiveFonts = checkLoadedFonts(family, fonts)
                    if (inactiveFonts) stylesToLoad.push(inactiveFonts)
                    // stylesToLoad.push(inactiveFonts)
                })

                // if (stylesToLoad && stylesToLoad.length > 0) {
                // 	loadFonts(fontProvider, stylesToLoad)
                // }

                if (fontFamilies && fontFamilies.length > 0) {
                    loadFonts(fontProvider, fontFamilies)
                }
            } else {
                loadFonts(fontProvider)
            }
        }
    }, [
        fontFamilies,
    ])

    function loadFonts(fontProvider: any, stylesToLoad?: any) {
        WebFont.load({
            [fontProvider]: {
                families: stylesToLoad,
                id: typekitId || fontdeckId,
                projectId: monotypeProjectId,
                version: 2,
                loadAllFonts: monotypeLoadAllFonts,
                api: typekitAPI || '',
                urls: customUrls || {},
                text,
            },
            loading: () => {
                if (debug) console.info('…Loading WebFonts')
                if (onLoading) onLoading()
            },
            active: () => {
                if (debug) console.info('WebFonts are Active!')
                if (onActive) onActive()
            },
            inactive: () => {
                if (debug) console.warn('WebFonts Failed to Load 😱')
                if (onInactive) onInactive()
            },
            fontloading: (familyName: any, fvd: any) => {
                if (debug) console.info(`${familyName} ${fvd} is Loading`)
                if (fontIsLoading) fontIsLoading()
            },
            fontactive: (familyName: any, fvd: any) => {
                if (debug) console.info(`${familyName} ${fvd} is Active`)
                if (fontIsLoaded) fontIsLoaded(familyName, fvd, text)
            },
            fontinactive: (familyName: any, fvd: any) => {
                if (debug) console.info(`${familyName} ${fvd} Failed to Load`)
                if (fontLoadFailed) fontLoadFailed(familyName, fvd)
            },
            classes,
            timeout: timeout,
        })
    }

    return null
})
