// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxModalSetNavbarOpenIdSite,
    reduxSalesCartToggleSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

// props
type TemplateBlock792SiteProps = {
    blockId: string
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock792Site: React.FC<TemplateBlock792SiteProps> = React.memo(({
    blockId,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSiteSalesLinesLength = useSelector((state: defaultReduxState) => state.reduxSalesSite.salesLines.length)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        if (reduxFormSitenavigationMode === 'edit') {
            return
        }
        dispatch(reduxModalSetNavbarOpenIdSite(undefined))
    }, [
        location,
    ])

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock792Site',
                'applyStyles',
            ))
        }
    }

    function onCartClick() {
        try {
            if (reduxFormSitenavigationMode) return
            // if (['edit', 'navMixed', 'preview'].includes(reduxFormSitenavigationMode!)) return
            dispatch(reduxSalesCartToggleSite(true))
            dispatch(reduxModalSetNavbarOpenIdSite(undefined))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock792Site',
                'onCartClick',
            ))
        }
    }

    return (
        <button
            id={blockId}
            className={`template-block-792${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            onClick={onCartClick}
            type='button'
            style={stylesNew}
        >
            {reduxSalesSiteSalesLinesLength > 0 && (
                <span className={`t-792-badge ${deviceType}`}>{reduxSalesSiteSalesLinesLength}</span>
            )}
            <i className={`t-792-icon mo-new-icon-models-shop ${deviceType}`} />
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </button>
    )
})
