// react components
import React, {
    useEffect,
} from 'react'
import {
    Device,
} from '@capacitor/device'
import GA4React from 'ga-4-react'
import ReactGA from 'react-ga'
// @ts-ignore
import TagManager from 'react-gtm-module'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router'

// components
import {
    FontLoader,
} from 'components'

// data
import {
    defaultReduxState,
    languageIdObjectSite,
    languageObjectSite,
    reduxAuthFetchSettingsSite,
    reduxModalGaInitSite,
    reduxModalGtmInitSite,
    reduxModalSetLanguageIdSite,
    reduxModalSetRootUrlSite,
    reduxModalSetStripePromiseSite,
    reduxTextFetchMainTextSite,
} from 'data'

// serializer
import {
    MainFontSerializer,
} from 'serializers/site'

// services
import {
    deleteCookieSite,
    getCookieSite,
    getGoogleFontsSite,
    getHostOriginSite,
    removeParamsFromUrlSite,
    setCookieSite,
} from 'services'

// main
export const GlobalHelperSite: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSitepagefonts = useSelector((state: defaultReduxState) => state.reduxCacheSite.pageFonts)
    const reduxCacheSiteportfolio = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)

    useEffect(() => {
        const setLanguageCode = async () => {
            const hostOriginSite = getHostOriginSite()
            let urlLang = undefined
            if (hostOriginSite === 'prod') {
                urlLang = languageObjectSite[window.location.pathname.split('/')[1]]
            } else {
                urlLang = languageObjectSite[window.location.pathname.split('/')[2]]
            }
            if (!getCookieSite({ name: 'mo_reduxModalLanguageId', format: 'string' }) || (urlLang && (urlLang !== Number(getCookieSite({ name: 'mo_reduxModalLanguageId', format: 'string' }))))) {
                if (urlLang) {
                    dispatch(reduxModalSetLanguageIdSite(urlLang))
                } else {
                    try {
                        const deviceLanguageCode = await Device.getLanguageCode()
                        if (languageObjectSite[deviceLanguageCode.value]) {
                            dispatch(reduxModalSetLanguageIdSite(languageObjectSite[deviceLanguageCode.value]))
                        } else if (!reduxModalSite.languageId) {
                            dispatch(reduxModalSetLanguageIdSite(1))
                        }
                    } catch (error) {
                        if (!reduxModalSite.languageId) {
                            dispatch(reduxModalSetLanguageIdSite(1))
                        }
                        // dispatch(reduxModalErrorEventHandlerSite(
                        // 	error,
                        // 	'GlobalHelperSite',
                        // 	'setLanguageCode',
                        // 	true,
                        // ))
                    }
                }
            }
        }
        setLanguageCode()
    }, [])

    useEffect(() => {
        let languageCode = 'en'
        if (reduxModalSite.languageId === 2) {
            languageCode = 'fr'
        }
        // @ts-ignore
        dispatch(reduxTextFetchMainTextSite(reduxAuth, reduxModalSite, dispatch, languageCode, reduxModalSite.languageId))
    }, [reduxModalSite.languageId])

    useEffect(() => {
        const hostOriginSite = getHostOriginSite()
        if (hostOriginSite === 'prod') {
            if (reduxCacheSiteportfolio?.is_multi_language) {
                const currentUrlLang = window.location.pathname.split('/')[1]
                const newUrlLang = languageIdObjectSite[reduxModalSite.languageId]
                const urlParams = window.location.search
                const hash = window.location.hash
                if (languageObjectSite[newUrlLang] && languageObjectSite[currentUrlLang]) {
                    if (currentUrlLang) {
                        const remainingUrl = window.location.pathname.split(`/${currentUrlLang}/`)[1]
                        history.replace(`/${newUrlLang}/${remainingUrl ? remainingUrl : ''}${urlParams || ''}${hash || ''}`)
                    }
                } else if (languageObjectSite[newUrlLang]) {
                    const remainingUrl = window.location.pathname
                    history.replace(`/${newUrlLang}${remainingUrl ? remainingUrl : ''}${urlParams ? `${remainingUrl === '/' ? '' : '/'}${urlParams}` : ''}${hash || ''}`)
                }
                if (languageObjectSite[newUrlLang]) {
                    dispatch(reduxModalSetRootUrlSite(`/${newUrlLang}/`))
                }
            } else {
                dispatch(reduxModalSetRootUrlSite('/'))
            }
        } else {
            const devId = window.location.pathname.split('/')[1]
            if (reduxCacheSiteportfolio?.is_multi_language) {
                const currentUrlLang = window.location.pathname.split('/')[2]
                const newUrlLang = languageIdObjectSite[reduxModalSite.languageId]
                const urlParams = window.location.search
                const hash = window.location.hash
                if (languageObjectSite[newUrlLang] && languageObjectSite[currentUrlLang]) {
                    if (currentUrlLang) {
                        const remainingUrl = window.location.pathname.split(`/${currentUrlLang}/`)[1]
                        history.replace(`/${devId}/${newUrlLang}/${remainingUrl ? remainingUrl : ''}${urlParams || ''}${hash || ''}`)
                    }
                } else if (languageObjectSite[newUrlLang]) {
                    const remainingUrl = window.location.pathname.split(`/${devId}/`)[1]
                    history.replace(`/${devId}/${newUrlLang}/${remainingUrl ? remainingUrl : ''}${urlParams || ''}${hash || ''}`)
                }
                if (languageObjectSite[newUrlLang]) {
                    dispatch(reduxModalSetRootUrlSite(`/${devId}/${newUrlLang}/`))
                }
            } else {
                dispatch(reduxModalSetRootUrlSite(`/${devId}/`))
            }
        }
        removeParamsFromUrlSite('fbclid')
    }, [
        reduxCacheSiteportfolio?.is_multi_language,
        reduxModalSite.languageId,
    ])

    useEffect(() => {
        if (reduxCacheSiteportfolio) {
            if (reduxCacheSiteportfolio.is_eshop || reduxCacheSiteportfolio.id === 615) {
                setCookieSite({ name: 'mo_reduxSalesSite', value: reduxSalesSite })
            } else {
                deleteCookieSite('mo_reduxSalesSite')
            }
        }
    }, [
        reduxCacheSiteportfolio?.is_eshop,
        reduxSalesSite,
    ])

    useEffect(() => {
        if (process.env.NODE_ENV === 'production' && reduxModalSite.cookieConsent?.isAccepted && reduxCacheSiteportfolio?.google_analytics && !reduxModalSite.gaInit) {
            if (reduxCacheSiteportfolio?.google_analytics.substring(0, 3) === 'UA-') {
                ReactGA.initialize(reduxCacheSiteportfolio?.google_analytics)
                dispatch(reduxModalGaInitSite())
                ReactGA.pageview(window.location.pathname)
            } else if (reduxCacheSiteportfolio?.google_analytics.substring(0, 2) === 'G-') {
                const ga4react = new GA4React(reduxCacheSiteportfolio?.google_analytics)
                ga4react.initialize().then((ga4) => {
                    ga4.pageview(window.location.pathname)
                    dispatch(reduxModalGaInitSite(ga4))
                }, (err) => {
                    console.error(err)
                })
            }
        }
        if (reduxModalSite.cookieConsent?.isAccepted && reduxCacheSiteportfolio?.google_tag_manager && !reduxModalSite.gtmInit) {
            if (reduxCacheSiteportfolio?.google_tag_manager.substring(0, 4) === 'GTM-') {
                TagManager.initialize({
                    gtmId: reduxCacheSiteportfolio?.google_tag_manager
                })
                dispatch(reduxModalGtmInitSite())
            }
        }
    }, [
        reduxCacheSiteportfolio?.google_analytics,
        reduxCacheSiteportfolio?.google_tag_manager,
        reduxModalSite.cookieConsent,
    ])

    useEffect(() => {
        if (reduxAuth?.axiosConfig?.headers && reduxModalSite.portfolio?.id) {
            // @ts-ignore
            dispatch(reduxAuthFetchSettingsSite(
                reduxAuth,
                reduxAuth.axiosConfig?.headers,
                reduxModalSite,
                dispatch,
                reduxModalSite.portfolio.id,
            ))
        }
    }, [
        reduxAuth?.axiosConfig?.headers,
        reduxAuth?.settings?.id,
        reduxModalSite.portfolio?.id,
    ])

    useEffect(() => {
        if (reduxAuth?.access && Object.keys(reduxAuth.access).length) {
            setCookieSite({ name: 'mo_reduxAuthAccessSite', value: reduxAuth.access })
        } else {
            deleteCookieSite('mo_reduxAuthAccessSite')
        }
    }, [reduxAuth?.access])

    useEffect(() => {
        if (reduxAuth?.afterAuthUrl) {
            setCookieSite({ name: 'mo_reduxAfterAuthUrlSite', value: reduxAuth.afterAuthUrl })
        } else {
            deleteCookieSite('mo_reduxAfterAuthUrlSite')
        }
    }, [reduxAuth?.afterAuthUrl])

    useEffect(() => {
        if (reduxAuth?.authenticated) {
            setCookieSite({ name: 'mo_reduxAuthAuthenticatedSite', value: reduxAuth.authenticated })
        } else {
            deleteCookieSite('mo_reduxAuthAuthenticatedSite')
        }
    }, [reduxAuth?.authenticated])

    useEffect(() => {
        if (reduxAuth?.axiosConfig && Object.keys(reduxAuth.axiosConfig).length) {
            setCookieSite({ name: 'mo_reduxAuthAxiosConfigSite', value: reduxAuth.axiosConfig })
        } else {
            deleteCookieSite('mo_reduxAuthAxiosConfigSite')
        }
    }, [reduxAuth?.axiosConfig])

    useEffect(() => {
        if (reduxAuth?.settings?.id) {
            const newReduxAuthSettings: any = {}

            const newUser: any = {}
            newUser.id = reduxAuth.settings?.user?.id
            if (reduxAuth.settings?.user?.is_staff) {
                newUser.is_staff = reduxAuth.settings.user.is_staff
            }
            newUser.last_login = reduxAuth.settings?.user?.last_login
            newUser.user_portfolios = reduxAuth.settings?.user?.user_portfolios

            newReduxAuthSettings.id = reduxAuth.settings?.id
            newReduxAuthSettings.user = newUser
            setCookieSite({ name: 'mo_reduxAuthSettingsSite', value: newReduxAuthSettings })
        } else {
            deleteCookieSite('mo_reduxAuthSettingsSite')
        }
    }, [reduxAuth?.settings])

    useEffect(() => {
        if (reduxCacheSiteportfolio?.token_stripe_public) {
            dispatch(reduxModalSetStripePromiseSite(reduxCacheSiteportfolio?.token_stripe_public, reduxCacheSiteportfolio?.token_stripe_public_test))
        }
    }, [reduxCacheSiteportfolio?.token_stripe_public])

    if (reduxCacheSiteportfolio?.fonts) {  // TODO check for optimisation of render
        const newFontArray: MainFontSerializer[] = []
        reduxCacheSiteportfolio.fonts.map((val) => {
            newFontArray.push(val)
        })
        if (reduxCacheSitepagefonts) {
            reduxCacheSitepagefonts.map((val) => {
                newFontArray.push(val)
            })
        }

        const fontFamilies = getGoogleFontsSite(newFontArray)

        return (
            <>
                {fontFamilies.custom?.length > 0 && (
                    <FontLoader
                        customUrls={fontFamilies.customUrls}
                        fontFamilies={fontFamilies.custom}
                        fontProvider='custom'
                        onActive={() => {
                            document.body.classList.add('webfonts-loaded')
                        }}
                    />
                )}
                {fontFamilies.google?.length > 0 && (
                    <FontLoader
                        fontProvider='google'
                        fontFamilies={fontFamilies.google}
                        onActive={() => {
                            document.body.classList.add('webfonts-loaded')
                        }}
                    />
                )}
            </>
        )
    }

    return null
}
