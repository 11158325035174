// data
import {
    reduxModalStateSite,
} from 'data'

// main
export function getResponsiveImageSite(
    reduxModalSite: reduxModalStateSite,
    object: {
        get_image_site_hq?: string,
        get_image_site_lq?: string,
        get_image_site_mq?: string,
        logo_hq?: string,
    },
    hqField?: 'get_image_site_hq' | 'get_image_site_lq' | 'get_image_site_mq' | 'logo_hq',
    lqfield?: 'get_image_site_hq' | 'get_image_site_lq' | 'get_image_site_mq' | 'logo_hq',
    mqfield?: 'get_image_site_hq' | 'get_image_site_lq' | 'get_image_site_mq' | 'logo_hq',
) {
    if (reduxModalSite.deviceType === 'is-mobile') {
        return (lqfield && object[lqfield]) || object.get_image_site_lq || object.get_image_site_mq || object.get_image_site_hq
    }
    if (reduxModalSite.deviceType === 'is-tablet') {
        return (mqfield && object[mqfield]) || object.get_image_site_mq || object.get_image_site_hq
    }
    if (hqField) {
        return object[hqField] || object.get_image_site_hq
    }
    return object.get_image_site_hq
}
