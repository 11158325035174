// react components
import React, {
    useEffect,
} from 'react'
import {
    debounce
} from 'debounce'
import {
    isMobileOnly,
} from 'react-device-detect'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModaSetDeviceTypeSite,
    reduxModalErrorEventHandlerSite,
    reduxModalSetNavbarHeightSite,
    reduxModalSetNavbarWidthSite,
} from 'data'

// pages
import {
    NavbarBlockSite,
} from 'pages'

// main
export const NavbarWrapperSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    let wto: any
    let wto1: any
    let wto2: any

    useEffect(() => {
        setHeight()
        clearTimeout(wto)
        wto = setTimeout(() => {
            setHeight()
        }, 100)
        clearTimeout(wto1)
        wto1 = setTimeout(() => {
            setHeight()
        }, 1000)
        clearTimeout(wto2)
        wto2 = setTimeout(() => {
            setHeight()
        }, 2000)
    }, [
        reduxCacheSite.portfolio,
        reduxModalSite.navbarType,
    ])

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setHeight()
            dispatch(reduxModaSetDeviceTypeSite(isMobileOnly ? 'is-mobile' : (window.innerWidth <= 767.99 ? 'is-mobile' : (window.innerWidth <= 991.99 ? 'is-tablet' : 'is-web'))))
        }, 100)
        window.addEventListener('resize', debouncedHandleResize)
        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })

    function setHeight() {
        try {
            if (reduxModalSite.navbarType === 'top') {
                // @ts-ignore
                const newNavbarSectionHeight = document.querySelector(`[id^="navbar-section"]`)?.offsetHeight || 0
                if (newNavbarSectionHeight && newNavbarSectionHeight !== reduxModalSite.navbarHeight) {
                    dispatch(reduxModalSetNavbarHeightSite(newNavbarSectionHeight))
                    return
                }
            } else {
                // @ts-ignore
                const newNavbarSectionWidth = document.querySelector(`[id^="navbar-section"]`)?.offsetWidth || 0
                if (newNavbarSectionWidth && newNavbarSectionWidth !== reduxModalSite.navbarWidth) {
                    dispatch(reduxModalSetNavbarWidthSite(newNavbarSectionWidth))
                    return
                }
            }
            if (reduxCacheSite.portfolio && !reduxCacheSite.portfolio.navbar && !reduxCacheSite.navbarHeightIsSet) {
                dispatch(reduxModalSetNavbarHeightSite(0))
                return
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'NavbarWrapperSite',
                'setHeight',
            ))
        }
    }

    if (reduxCacheSite.portfolio?.coming_soon) return null

    if (reduxCacheSite.portfolio?.navbar) {
        return (
            <NavbarBlockSite
                linkColor={undefined}
                stylesEdit={undefined}
            />
        )
    }

    return null
}
