// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    // FacebookMessengerIcon,
    // FacebookMessengerShareButton,
    FacebookShareButton,
    HatenaIcon,
    HatenaShareButton,
    InstapaperIcon,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    // PinterestIcon,
    // PinterestShareButton,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    VKIcon,
    VKShareButton,
    ViberIcon,
    ViberShareButton,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
} from 'react-share';

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template818SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

// props
type TemplateBlock818SiteProps = {
    blockId: string
    content: Template818SiteSerializer | undefined
    isEditHovered: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock818Site: React.FC<TemplateBlock818SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])


    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock818Site',
                'applyStyles',
            ))
        }
    }

    const iconProps = {
        round: stylesNew?.isRound === 'false' ? false : true,
        size: stylesNew?.iconSize || 32,
    }

    const properties = {
        subject: content?.text2,
        body: content?.text3,
    }

    const buttonDict: any = {
        'email': (
            <EmailShareButton
                body={content?.text3}
                subject={content?.text2}
                url={window.location.href}
            >
                <EmailIcon {...iconProps} />
            </EmailShareButton>
        ),
        'facebook': (
            <FacebookShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <FacebookIcon {...iconProps} />
            </FacebookShareButton>
        ),
        // 'facebookmessenger': (
        //     <FacebookMessengerShareButton
        //     title={content?.text3}
        //     url={window.location.href}
        // >
        //         <FacebookMessengerIcon {...iconProps} />
        //     </FacebookMessengerShareButton>
        // ),
        'hatena': (
            <HatenaShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <HatenaIcon {...iconProps} />
            </HatenaShareButton>
        ),
        'instapaper': (
            <InstapaperShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <InstapaperIcon {...iconProps} />
            </InstapaperShareButton>
        ),
        'line': (
            <LineShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <LineIcon {...iconProps} />
            </LineShareButton>
        ),
        'linkedin': (
            <LinkedinShareButton
                summary={content?.text3}
                url={window.location.href}
            >
                <LinkedinIcon {...iconProps} />
            </LinkedinShareButton>
        ),
        'livejournal': (
            <LivejournalShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <LivejournalIcon {...iconProps} />
            </LivejournalShareButton>
        ),
        'mailru': (
            <MailruShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <MailruIcon {...iconProps} />
            </MailruShareButton>
        ),
        'ok': (
            <OKShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <OKIcon {...iconProps} />
            </OKShareButton>
        ),
        // 'pinterest': (
        //     <PinterestShareButton
        // title={content?.text3}
        // url={window.location.href}
        // >
        //         <PinterestIcon {...iconProps} />
        //     </PinterestShareButton>
        // ),
        'pocket': (
            <PocketShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <PocketIcon {...iconProps} />
            </PocketShareButton>
        ),
        'reddit': (
            <RedditShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <RedditIcon {...iconProps} />
            </RedditShareButton>
        ),
        'telegram': (
            <TelegramShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <TelegramIcon {...iconProps} />
            </TelegramShareButton>
        ),
        'tumblr': (
            <TumblrShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <TumblrIcon {...iconProps} />
            </TumblrShareButton>
        ),
        'twitter': (
            <TwitterShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <TwitterIcon {...iconProps} />
            </TwitterShareButton>
        ),
        'vk': (
            <VKShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <VKIcon {...iconProps} />
            </VKShareButton>
        ),
        'viber': (
            <ViberShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <ViberIcon {...iconProps} />
            </ViberShareButton>
        ),
        'weibo': (
            <WeiboShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <WeiboIcon {...iconProps} />
            </WeiboShareButton>
        ),
        'whatsapp': (
            <WhatsappShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <WhatsappIcon {...iconProps} />
            </WhatsappShareButton>
        ),
        'workplace': (
            <WorkplaceShareButton
                title={content?.text3}
                url={window.location.href}
            >
                <WorkplaceIcon {...iconProps} />
            </WorkplaceShareButton>
        ),
    }

    const socialString = content?.text

    return (
        <div
            id={blockId}
            className={`template-block-818${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={{
                justifyContent: stylesNew?.justifyContent || 'flex-start',
            }}
        >
            {socialString?.split(',').map((social) => (
                <div
                    key={social}
                    className={`t-818-button ${deviceType}`}
                    style={{
                        padding: stylesNew?.padding || 0,
                    }}
                >
                    {buttonDict[social]}
                </div>
            ))}
            <EditBlockSite
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
