// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import VerificationInput from 'react-verification-input'

// components
import {
    GoogleLoginButtonSite,
    IconBlockSite,
    LoaderSite,
} from 'components'

// data
import {
    api_url_auth_user_form_confirm_site,
    api_url_auth_user_form_create_site,
    defaultReduxState,
    reduxAuthLoginSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services 
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
} from 'services'

// props
type TemplateBlock806SignupSiteProps = {
    blockId: string
    buttonStyles: any
    email: string
    object: PortfolioPageContentListSiteSerializer
    onHover: any
    setPanel: React.Dispatch<React.SetStateAction<'login' | 'password-reset' | 'signup'>>
    stylesNew: any
}

// main
export const TemplateBlock806SignupSite: React.FC<TemplateBlock806SignupSiteProps> = React.memo(({
    blockId,
    buttonStyles,
    email,
    object,
    onHover,
    setPanel,
    stylesNew,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    type errorsType = {
        detail?: string
        email?: string
        non_field_errors?: string
        password?: string
        username?: string
        verification_code?: string
    }
    type fieldsType = {
        password: string
        username: string
        verification_code?: string
    }
    const errorsInitial = {}
    const fieldsInitial = {
        password: '',
        username: email,
        verification_code: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [step, setStep] = useState<'signup' | 'verification_code'>('signup')

    function handleInputChange(event: any) {
        try {
            const name = event.target.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.target.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806SignupSite',
                'handleInputChange',
            ))
        }
    }

    function handleCodeInputChange(event: any) {
        try {
            setButtonDisabled(false)
            setFields({ ...fields, verification_code: event })
            setErrors({ ...errors, verification_code: '', non_field_errors: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806SignupSite',
                'handleCodeInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            setButtonDisabled(true)
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) onSubmit')
            const formData: any = new FormData()
            if (step === 'signup') {
                formData.append('email', fields.username.toLowerCase().trim())
                formData.append('password', fields.password)
                formData.append('portfolioId', reduxCacheSiteportfolioid)
                formData.append('username', fields.username.toLowerCase().trim())
                const createUrl = getApiUrlSite(api_url_auth_user_form_create_site, reduxModalSite)
                if (process.env.NODE_ENV === 'development') console.log(createUrl)
                axios({
                    data: formData,
                    method: 'post',
                    url: createUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        setStep('verification_code')
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        let skipSendError = false
                        if (error.response?.status === 400 && error.response?.data?.email) skipSendError = true
                        if (error.response?.status === 400 && error.response?.data?.password) skipSendError = true
                        if (error.response?.status === 400 && error.response?.data?.username) skipSendError = true
                        if (error.response?.status === 403) skipSendError = true
                        // const formFieldsToSend = Object.assign({}, fields)
                        // delete formFieldsToSend.password
                        axiosErrorHandlerSite({
                            apiUrl: createUrl,
                            component: 'TemplateBlock806SignupSite',
                            dispatch,
                            error,
                            reduxAuth,
                            reduxModalSite,
                            reference: 'onSubmit',
                            skipAlert: skipSendError,
                            skipAlertStatusCode: [400],
                            skipSendError: skipSendError,
                        })
                    })
            } else if (step === 'verification_code') {
                formData.append('username', fields.username.toLowerCase().trim())
                formData.append('verification_code', fields.verification_code)
                const postUrl = getApiUrlSite(api_url_auth_user_form_confirm_site, reduxModalSite)
                if (process.env.NODE_ENV === 'development') console.log(postUrl)
                axios({
                    data: formData,
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        setFields(fieldsInitial)
                        // @ts-ignore
                        dispatch(reduxAuthLoginSite(
                            fields.username,
                            fields.password,
                            reduxAuth,
                            reduxModalSite,
                            dispatch,
                            setIsLoading,
                            setFields,
                            fieldsInitial,
                            reduxCacheSiteportfolioid!,
                            setErrors,
                        ))
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock806SignupSite',
                            dispatch,
                            error,
                            reduxAuth,
                            reduxModalSite,
                            reference: 'onSubmit',
                            skipAlertStatusCode: [400],
                            skipSendErrorStatusCode: [400],
                        })
                    })
            }
        } catch (error) {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806SignupSite',
                'onSubmit',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-806 ${deviceType}`}
        >

            {step === 'signup' && (
                <React.Fragment>
                    {!object.data_json?.hide_google_button && (
                        <React.Fragment>
                            <div className={`t-806-google ${deviceType}`}>
                                <GoogleLoginButtonSite
                                    setIsLoading={setIsLoading}
                                />
                            </div>
                            <div className={`t-806-separation-block ${deviceType}`}>
                                <div className={`t-806-separation-line ${deviceType}`}>
                                    <hr style={{ borderColor: stylesNew.loginTextColor }} />
                                </div>
                                <div className={`t-806-separation-text ${deviceType}`}>
                                    <p style={{ color: stylesNew.loginTextColor }}>{reduxText[8419]}</p>
                                </div>
                                <div className={`t-806-separation-line ${deviceType}`}>
                                    <hr style={{ borderColor: stylesNew.loginTextColor }} />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div className={`t-806-input-wrap ${deviceType}`}>
                        <p
                            className={`t-806-label ${deviceType}`}
                            style={{ color: stylesNew.loginTextColor }}
                        >
                            {reduxText[8421]}
                        </p>
                        <input
                            className={`t-806-input ${deviceType}`}
                            disabled
                            name='username'
                            onChange={handleInputChange}
                            style={{
                                background: stylesNew.loginInputBackgroundColor,
                                color: stylesNew.loginInputColor,
                            }}
                            type='text'
                            value={fields.username}
                        />
                        {(errors.username || errors.email) && (
                            <span className={`t-806-error ${deviceType}`}>{errors.username || errors.email}</span>
                        )}
                    </div>
                    <div className={`t-806-input-wrap ${deviceType}`}>
                        <div className={`t-806-label-wrap ${deviceType}`}>
                            <p
                                className={`t-806-label ${deviceType}`}
                                style={{ color: stylesNew.loginTextColor }}
                            >
                                {reduxText[8756]}
                            </p>
                            <IconBlockSite
                                className={`t-806-icon ${deviceType}`}
                                iconClass={showPassword ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'}
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ color: stylesNew.loginTextColor }}
                            />
                        </div>
                        <input
                            className={`t-806-input ${deviceType}`}
                            name='password'
                            onChange={handleInputChange}
                            style={{
                                background: stylesNew.loginInputBackgroundColor,
                                color: stylesNew.loginInputColor,
                            }}
                            type={showPassword ? 'text' : 'password'}
                        />
                        {errors.password && (
                            <span className={`t-806-error ${deviceType}`}>{errors.password}</span>
                        )}
                    </div>
                </React.Fragment>
            )}
            {step === 'verification_code' && (
                <div className={`t-806-verification-wrap ${deviceType}`}>
                    <p
                        className={`t-806-label ${deviceType}`}
                        style={{ color: stylesNew.loginTextColor }}
                    >
                        {reduxText[8534]}
                    </p>
                    <div className={`t-806-verification-input ${deviceType}`}>
                        <VerificationInput
                            length={4}
                            placeholder=''
                            onChange={handleCodeInputChange}
                            validChars={'0123456789'}
                            value={fields.verification_code}
                        />
                        <IconBlockSite
                            iconClass='mo-new-icon-undo-alt-solid'
                            onClick={() => {
                                setFields({ ...fields, verification_code: '' })
                                setErrors({ ...errors, verification_code: '', non_field_errors: '' })
                            }}
                            style={{ color: stylesNew.loginTextColor }}
                            tooltipText=''
                        />
                    </div>
                    {(errors.verification_code) && (
                        <span className={`t-806-error ${deviceType}`}>{errors.verification_code}</span>
                    )}
                </div>
            )}
            <div className={`t-806-button-wrap ${deviceType}`}>
                <button
                    className={`t-806-button ${deviceType}`}
                    disabled={buttonDisabled || isLoading}
                    onClick={onSubmit}
                    onMouseLeave={() => onHover('leave')}
                    onMouseOver={() => onHover('over')}
                    style={buttonStyles}
                    type='button'
                >
                    {reduxText[8424]}
                </button>
                {(errors.non_field_errors || errors?.detail) && (
                    <span className={`t-806-error ${deviceType}`}>{`${errors.non_field_errors || errors?.detail}`}</span>
                )}
            </div>
            {isLoading && (
                <LoaderSite isOpen />
            )}
        </div>
    )
})
