// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    ContentBlockSite,
    EditBlockSite,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
    Template800SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesToRetrieve2,
} from 'services'

// props
type TemplateBlock800SiteProps = {
    blockId: string
    content: Template800SiteSerializer
    detailId: string | undefined
    devId: string | undefined
    isEditHovered: boolean
    linkColor: string | undefined
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageSlug: string | undefined
    parentArray?: number[]
    parentStyles?: any
}

// main
export const TemplateBlock800Site: React.FC<TemplateBlock800SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    devId,
    isEditHovered,
    linkColor,
    mainParentId,
    object,
    pageSlug,
    parentArray,
    parentStyles,
}) => {

    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    return (
        <div
            id={blockId}
            className={`template-block-800${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={{
                background: getStylesToRetrieve2(reduxModalSite, content.styles, '', 'background'),
                color: getStylesToRetrieve2(reduxModalSite, content.styles, '', 'color'),
                fontFamily: getStylesToRetrieve2(reduxModalSite, content.styles, '', 'fontFamily'),
                padding: getStylesToRetrieve2(reduxModalSite, content.styles, '', 'padding'),
            }}
        >
            {content.content?.filter((obj) => obj.active === true).map((val) => (
                <ContentBlockSite
                    key={val.id}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={false}
                    linkColor={linkColor}
                    object={val}
                    pageSlug={pageSlug}
                    stylesEdit={undefined}
                />
            ))}
            <EditBlockSite
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
