// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// props
type TextAreaInput819SiteProps = {
    directionRtl?: boolean
    disabled?: boolean
    error: string | undefined
    label: string
    name: string
    onChange: any
    placeholder?: string
    value: string
}

// main
export const TextAreaInput819Site: React.FC<TextAreaInput819SiteProps> = React.memo(({
    directionRtl,
    disabled,
    error,
    label,
    name,
    onChange,
    placeholder,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    function onInputChange(value: string | null | undefined) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TextAreaInput819Site',
                'onInputChange',
            ))
        }
    }

    return (
        <div className={`input-819 text-area-input-819 ${deviceType}`}>
            <span className={`input-819-label ${deviceType}`}>{label}</span>
            <TextareaAutosize
                className={`tai-819-input${directionRtl ? ' rtl' : ''} ${deviceType}`}
                disabled={disabled}
                name={name}
                onChange={(e) => onInputChange(e.target.value)}
                placeholder={placeholder}
                rows={1}
                value={value}
            />
            {error && <span className='input-819-error'>{error}</span>}
        </div>
    )
})
