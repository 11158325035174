// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    Location,
} from 'history'

// data
import {
    reduxAuthStateSite,
    reduxCacheStateSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getPageContentDataSite,
    getPageDataSite,
    getPortfolioDataSite,
} from 'services'

// main
export function getDataSite({
    axiosCancelToken,
    component,
    detailId,
    devId,
    dispatch,
    errorStatus,
    forceRefresh,
    location,
    noEmpty,
    pageSlug,
    reduxAuth,
    reduxCacheSite,
    reduxModalSite,
    scrollToTop,
    setAxiosCancelToken,
    setErrorStatus,
    setIsLoading,
    setPageContentItems,
    setPageObject,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    detailId: string | undefined
    devId: string | undefined
    dispatch: React.Dispatch<any>
    errorStatus: errorStatusTypeSite
    forceRefresh?: boolean
    location: Location
    noEmpty?: boolean
    pageSlug: string | undefined
    reduxAuth: reduxAuthStateSite
    reduxCacheSite: reduxCacheStateSite
    reduxModalSite: reduxModalStateSite
    scrollToTop: any | undefined
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setErrorStatus: React.Dispatch<errorStatusTypeSite>
    setIsLoading: React.Dispatch<boolean>
    setPageContentItems: React.Dispatch<any>
    setPageObject: React.Dispatch<any>
}) {
    if (!noEmpty) {
        setIsLoading(true)
        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) getDataSite')
    }
    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (reduxCacheSite.portfolio) {

        const newPageSlug = pageSlug || reduxCacheSite.portfolio.home_page_slug

        if (reduxCacheSite.portfolio.id && newPageSlug) {

            getPortfolioDataSite({  // need on language switch TODO
                axiosCancelToken: source,
                backgroundLoad: true,
                component,
                detailId: undefined,
                devId,
                dispatch,
                errorStatus,
                location,
                pageSlug: undefined,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                scrollToTop,
                setErrorStatus,
                setIsLoading,
                setPageContentItems,
                setPageObject,
            })

            if (reduxCacheSite.portfolio.url_redirect?.find((obj: any) => obj.old_url === newPageSlug)) {
                setIsLoading(false)
                if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getDataSite')
                return
            }

            getPageDataSite({
                axiosCancelToken: source,
                component,
                detailId,
                dispatch,
                errorStatus,
                pageSlug: newPageSlug,
                portfolioObject: reduxCacheSite.portfolio,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                scrollToTop,
                setErrorStatus,
                setIsLoading,
                setPageObject,
            })

            getPageContentDataSite({
                axiosCancelToken: source,
                component,
                detailId,
                devId,
                dispatch,
                errorStatus,
                forceRefresh,
                location,
                noEmpty,
                pageSlug: newPageSlug,
                portfolioObject: reduxCacheSite.portfolio,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                setErrorStatus,
                setIsLoading,
                setPageContentItems,
            })
        } else {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getDataSite')
        }
    } else {
        getPortfolioDataSite({
            axiosCancelToken: source,
            backgroundLoad: false,
            component,
            detailId,
            devId,
            dispatch,
            errorStatus,
            location,
            pageSlug,
            reduxAuth,
            reduxCacheSite,
            reduxModalSite,
            scrollToTop,
            setErrorStatus,
            setIsLoading,
            setPageContentItems,
            setPageObject,
        })
    }
}
