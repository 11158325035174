// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Gallery,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    ImageHelper784Site,
    InfiniteColumn784Site,
    InfiniteRow784Site,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

// props
type TemplateBlock784SiteProps = {
    blockId: string
    content: PortfolioPageContentListSiteSerializer[]
    detailId: string | undefined
    devId: string | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageNumberNew?: number
    pageSlug: string | undefined
    parentArray?: number[]
    parentDropdownId?: number
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock784Site: React.FC<TemplateBlock784SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    devId,
    isEditHovered,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    mainParentId,
    object,
    pageNumberNew,
    pageSlug,
    parentArray,
    parentDropdownId,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxFormSitehoveredBlockId = useSelector((state: defaultReduxState) => state.reduxFormSite.hoveredBlockId)
    const reduxFormSiteparentContent = useSelector((state: defaultReduxState) => state.reduxFormSite.parentContent)
    const reduxFormSiteeditAutoSave = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSave)
    const reduxFormSiteeditAutoSaved = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSaved)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [newContent, setNewContent] = useState<PortfolioPageContentListSiteSerializer[]>(content || [])
    const [newContentIds, setNewContentIds] = useState<PortfolioPageContentListSiteSerializer['content_ids']>(content[0]?.content_ids || [])

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<{
        columnsNumber: number | undefined,
        directionGallery: string | undefined,
        marginGallery: number | undefined,
        styles: CustomCSSProperties | undefined,
        withText: string | undefined,
    } | undefined>(applyStyles())

    useEffect(() => {
        setNewContent(content)
        setNewContentIds(content[0]?.content_ids || [])
    }, [
        content,
    ])

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                columnsNumber: Number(aStyles?.columnsNumber) || undefined,
                directionGallery: aStyles?.directionGallery,
                marginGallery: Number(aStyles?.marginGallery) || 0,
                styles: aStyles,
                withText: aStyles?.withText,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock784Site',
                'applyStyles',
            ))
        }
    }

    return (
        <React.Fragment>
            <div
                id={blockId}
                className={`template-block-784${reduxFormSiteparentContent === object.id ? ' active' : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                style={stylesNew?.styles}
            >
                {newContentIds!.length > 0 && (
                    <Gallery
                        // @ts-ignore
                        columns={stylesNew?.columnsNumber}
                        direction={stylesNew?.directionGallery}
                        limitNodeSearch={stylesNew?.columnsNumber}
                        margin={stylesNew?.marginGallery}
                        photos={newContentIds!}
                        // @ts-ignore
                        renderImage={(props) => (
                            <ImageHelper784Site
                                {...props}
                                columnsNumber={stylesNew?.columnsNumber}
                                content={newContent}
                                contentIds={newContentIds}
                                detailId={detailId}
                                devId={devId}
                                isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                                isInDetailPage={isInDetailPage}
                                isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                                mainParentId={mainParentId || object.id}
                                object={object}
                                pageSlug={pageSlug}
                                parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                                parentDropdownId={parentDropdownId}
                                reduxFormSiteeditAutoSave={reduxFormSiteeditAutoSave}
                                reduxFormSiteeditAutoSaved={reduxFormSiteeditAutoSaved}
                                reduxFormSitehoveredBlockId={reduxFormSitehoveredBlockId}
                                withText={stylesNew?.withText}
                            />
                        )}
                    />
                )}
                {stylesNew?.directionGallery === 'column' && object.infinite_list_page_size && newContentIds!.length >= object.infinite_list_page_size && (
                    <InfiniteColumn784Site
                        content={newContent}
                        contentIds={newContentIds}
                        detailId={detailId}
                        devId={devId}
                        infiniteContentId={object.id!}
                        isInComponent={isInComponent}
                        isInDetailPage={isInDetailPage}
                        mainParentId={mainParentId}
                        object={object}
                        pageNumberNew={(pageNumberNew || 1) + 1}
                        pageSlug={pageSlug}
                        parentArray={parentArray}
                        parentDropdownId={parentDropdownId}
                        setContent={setNewContent}
                        setContentIds={setNewContentIds}
                        stylesNew={stylesNew}
                    />
                )}
                {stylesNew?.directionGallery === 'row' && object.infinite_list_page_size && newContentIds!.length >= object.infinite_list_page_size && (
                    <InfiniteRow784Site
                        detailId={detailId}
                        devId={devId}
                        infiniteContentId={object.id!}
                        isInComponent={isInComponent}
                        isInDetailPage={isInDetailPage}
                        mainParentId={mainParentId}
                        object={object}
                        pageNumberNew={(pageNumberNew || 1) + 1}
                        pageSlug={pageSlug}
                        parentArray={parentArray}
                        parentDropdownId={parentDropdownId}
                        stylesNew={stylesNew}
                    />
                )}
            </div>
        </React.Fragment>
    )
})
