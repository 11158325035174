// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    ContentBlockSite,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
} from 'serializers/site'

// services
import {
    getHostOriginSite,
} from 'services'

// props
type FooterBlockSiteProps = {
    linkColor: string | undefined
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const FooterBlockSite: React.FC<FooterBlockSiteProps> = React.memo(({
    linkColor,
    stylesEdit,
}) => {

    const location = useLocation()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)

    if (!reduxCacheSite.portfolio?.footer?.id) return null

    return (
        <ContentBlockSite
            detailId={undefined}
            devId={getHostOriginSite() === 'prod' ? undefined : location.pathname.split('/')[1]}
            isEditHovered={false}
            linkColor={linkColor}
            object={reduxCacheSite.portfolio.footer}
            pageSlug={undefined}
            stylesEdit={stylesEdit}
        />
    )
})
