// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    api_url_portfolio_hub_content_filter_site,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    HubFilterInputSite,
} from 'pages'

// serializers
import {
    PortfolioHubContentFilterListSiteSerializer,
} from 'serializers/site'
// services
import {
    getApiUrlSite,
} from 'services'

// props
type HubFilterSiteProps = {
    filterInfo: {
        filterSet: PortfolioHubContentFilterListSiteSerializer['filters'],
        tabSlug: string
    }
    languageId: number
    onChange: (e: any) => void
    portfolioId: number
}

// main
export const HubFilterSite: React.FC<HubFilterSiteProps> = React.memo(({
    filterInfo,
    languageId,
    onChange,
    portfolioId,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [filters, setFilters] = useState<any>({})

    function onFilterChange(e: any) {
        try {
            const newFilters: any = filters
            newFilters[e.name] = e.value
            setFilters(newFilters)
            const filterString = Object.getOwnPropertyNames(newFilters).filter((obj) => (Array.isArray(newFilters[obj]) && newFilters[obj].length > 0)).map((val) => {
                const filterValues = newFilters[val].map((val2: any) => {
                    return val2.id
                }).join(';')
                return `${val}-${filterValues}`
            }).join(',')

            onChange(filterString)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'HubFilterSite',
                'onFilterChange',
            ))
        }
    }

    return (
        <div
            id={`hub-filter-site-${filterInfo.tabSlug}`}
            className={`hub-filter-site ${deviceType}`}
        >
            {filterInfo.filterSet?.map((val) => (
                <HubFilterInputSite
                    key={val.id}
                    apiUrl={getApiUrlSite(`${api_url_portfolio_hub_content_filter_site}${filterInfo.tabSlug}/?portfolio_id=${portfolioId}&language_id=${languageId}&content_type_id=${val.content_type}&name_id=${val.name_id}`, reduxModalSite)}
                    label={val.name!}
                    multi
                    name={val.name_id!}
                    onChange={(e: any) => onFilterChange(e)}
                />
            ))}
        </div>
    )
})
