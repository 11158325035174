// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    ListSpinnerSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getMixedContentDataSite,
    getPageContentDataSite,
} from 'services'

// props
type InfiniteColumn817SiteProps = {
    content: PortfolioPageContentListSiteSerializer[]
    contentIds: PortfolioPageContentListSiteSerializer['content_ids']
    detailId: string | undefined
    devId: string | undefined
    infiniteContentId: number
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageNumberNew: number
    pageSlug: string | undefined
    parentArray?: number[]
    parentDropdownId?: number
    setContent: React.Dispatch<PortfolioPageContentListSiteSerializer[]>
    setContentIds: React.Dispatch<PortfolioPageContentListSiteSerializer['content_ids']>
    stylesNew: any
}

// main
export const InfiniteColumn817Site: React.FC<InfiniteColumn817SiteProps> = React.memo(({
    content,
    contentIds,
    detailId,
    devId,
    infiniteContentId,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    mainParentId,
    object,
    pageNumberNew,
    pageSlug,
    parentArray,
    parentDropdownId,
    setContent,
    setContentIds,
    stylesNew,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [isLoading, setIsLoading] = useState(true)
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[] | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)

    function getPortfolioData() {
        try {
            if (pageSlug === 'custom-page-detail' || !reduxCacheSite.portfolio) {
                getMixedContentDataSite({
                    component: 'InfiniteColumn817Site',
                    dispatch,
                    infiniteContentId,
                    pageNumberNew,
                    reduxAuth,
                    reduxModalSite,
                    setIsLoading,
                    setItems: (e: any) => {
                        setPageContentItems(e)
                        setContentIds([...contentIds!, ...e?.[0]?.content[0]?.content_ids || []])
                    },
                })
            } else if (reduxCacheSite.portfolio) {
                setDisableInfiniteScroll(true)
                getPageContentDataSite({
                    axiosCancelToken: undefined,
                    component: 'InfiniteColumn817Site',
                    detailId,
                    devId,
                    dispatch,
                    errorStatus,
                    infiniteContentId,
                    location,
                    pageNumberNew,
                    pageSlug: pageSlug || reduxCacheSite.portfolio.home_page_slug!,
                    portfolioObject: reduxCacheSite.portfolio,
                    reduxAuth,
                    reduxCacheSite,
                    reduxModalSite,
                    setErrorStatus,
                    setIsLoading,
                    setPageContentItems: (e: any) => {
                        setPageContentItems(e)
                        setContentIds([...contentIds!, ...e?.[0]?.content[0]?.content_ids || []])
                    },
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'InfiniteColumn817Site',
                'getPortfolioData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                getPortfolioData()
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'InfiniteColumn817Site',
                'onSearchNext',
            ))
        }
    }

    const newContentIds = pageContentItems?.[0]?.content[0]?.content_ids || []

    return (
        <React.Fragment>
            {isLoading && (!pageContentItems || (pageContentItems?.length === 0)) && (
                <VisibilitySensor
                    active={!disableInfiniteScroll}
                    intervalDelay={1000}
                    offset={{ bottom: -1000 }}
                    onChange={onSearchNext}
                    partialVisibility
                >
                    <div className='infinite-scroll-helper-site-empty'>
                        <ListSpinnerSite isLoading />
                    </div>
                </VisibilitySensor>
            )}
            {object.infinite_list_page_size && newContentIds.length >= object.infinite_list_page_size && (
                <InfiniteColumn817Site
                    content={content}
                    contentIds={contentIds}
                    detailId={detailId}
                    devId={devId}
                    infiniteContentId={object.id!}
                    isInComponent={isInComponent}
                    isInDetailPage={isInDetailPage}
                    isInVideoBlock={isInVideoBlock}
                    mainParentId={mainParentId}
                    object={object}
                    pageNumberNew={(pageNumberNew || 1) + 1}
                    pageSlug={pageSlug}
                    parentArray={parentArray}
                    parentDropdownId={parentDropdownId}
                    setContent={setContent}
                    setContentIds={setContentIds}
                    stylesNew={stylesNew}
                />
            )}
        </React.Fragment>
    )
})
