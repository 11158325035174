// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxModalSliderDetailShowHideSite,
} from 'data'

// pages
import {
    ComingSoonSite,
    SlugPageSite,
} from 'pages'

// services
import {
    parseQuerySite,
    stringifyQuery,
} from 'services'

// props
type MatchParams = {
    devId: string
}

// main
export const Param0WrapperSite: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const reduxCacheSiteportfoliocoming_soon = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.coming_soon)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [locationKeys, setLocationKeys] = useState<any[]>([])

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }
            if (history.action === 'POP') {

                let listUrl = `${location.pathname}`  // Keep quotes
                const parsed = parseQuerySite(location.search)
                delete parsed.modal_page
                delete parsed.modal_detail_id
                if (parsed && Object.keys(parsed).length > 0 && parsed.constructor === Object) {
                    listUrl += `?${stringifyQuery(parsed)}`
                }

                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)
                    if (parseQuerySite(location.search)?.modal_page) {
                        dispatch(reduxModalSliderDetailShowHideSite(
                            true,
                            listUrl,
                            parseQuerySite(location.search)?.modal_detail_id,
                        ))
                    }
                } else {
                    setLocationKeys((keys) => [location.key, ...keys])
                    if (parseQuerySite(location.search)?.modal_page) {
                        dispatch(reduxModalSliderDetailShowHideSite(
                            true,
                            listUrl,
                            parseQuerySite(location.search)?.modal_detail_id,
                        ))
                    }
                }
            }
        })
    }, [
        locationKeys,
    ])

    if (reduxCacheSiteportfoliocoming_soon) {
        return (
            <ComingSoonSite />
        )
    }

    const searchParams = parseQuerySite(location.search)
    const modal_page = searchParams.modal_page
    const modal_detail_id = searchParams.modal_detail_id

    if (!reduxModalSite.isOpen && !reduxModalSite.noRedirect && modal_page) {
        return <Redirect to={`${reduxModalSite.rootUrl}${modal_page}${modal_detail_id ? `/${modal_detail_id}` : ''}`} />
    }

    return (
        <SlugPageSite devId={params.devId} />
    )
}
