// react components
import React, {
    useEffect,
    useState,
} from 'react'
import moment from 'moment'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template798SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

// props
type TemplateBlock798SiteProps = {
    blockId: string
    content: Template798SiteSerializer
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock798Site: React.FC<TemplateBlock798SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<{
        styles: CustomCSSProperties | undefined,
        stylesCredit: CustomCSSProperties | undefined,
        stylesPowered: CustomCSSProperties | undefined,
        // @ts-ignore
    }>(applyStyles())

    useEffect(() => {
        // @ts-ignore
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                styles: aStyles,
                stylesCredit: {
                    padding: aStyles?.creditPadding,
                    textAlign: aStyles?.creditTextAlign,
                },
                stylesPowered: {
                    padding: aStyles?.poweredPadding,
                    textAlign: aStyles?.poweredTextAlign,
                },
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock798Site',
                'applyStyles',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-798${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={stylesNew.styles}
        >
            <span
                className={`t-798-copyright ${deviceType}`}
                style={stylesNew.stylesCredit}
            >
                {reduxText[7498]}{' '}{moment().format('YYYY')} {content.text || reduxCacheSite.portfolio?.name}.{' '}{content.text2 || reduxText[7499]}
            </span>
            <a
                className={`t-798-justtheweb ${deviceType}`}
                href={reduxCacheSite.portfolio?.sites?.includes(11) ? 'https://www.whattheweb.org/' : 'https://www.justtheweb.io/'}
                target='_blank'
                rel='noopener noreferrer'
                style={stylesNew.stylesPowered}
            >
                Powered by {reduxCacheSite.portfolio?.sites?.includes(11) ? 'What The Web' : 'Just The Web'}
            </a>
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
