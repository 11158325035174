// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// components
import {
    ImageHelperSite,
    LinkHelperSite,
} from 'components'

// services
import {
    getStylesToRetrieve,
} from 'services'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesCartToggleSite,
    reduxSalesRemoveFromCartSite,
} from 'data'

// main
export const CartPopUpBlockSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [fontFamily, setFontFamily] = useState<string>()

    useEffect(() => {
        setFontFamily(getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'fontFamily'))
    }, [
        reduxCacheSite.portfolio?.styles,
        reduxModalSite,
    ])

    function handleClose() {
        try {
            dispatch(reduxSalesCartToggleSite(false))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'CartPopUpBlockSite',
                'handleClose',
            ))
        }
    }

    if (!reduxCacheSite.portfolio?.is_eshop) return null

    const canCheckout = process.env.NODE_ENV === 'production' ? (Boolean(reduxCacheSite.portfolio.token_stripe_public) || Boolean(reduxCacheSite.portfolio.token_paypal_client_id)) : true

    return (
        <div
            className={`cart-popup-block ${reduxSalesSite.isCartOpen ? 'open' : ''} ${deviceType}`}
            style={{
                fontFamily: fontFamily,
            }}
        >
            <div
                className={`cpb-backdrop ${deviceType}`}
                onClick={handleClose}
            />
            <i
                className={`cpb-close mo-new-icon-times-solid ${deviceType}`}
                onClick={handleClose}
            />
            {reduxSalesSite.isCartOpen && (
                <div className={`cpb-content ${deviceType}`}>
                    <div className={`cpb-cart-list ${deviceType}`}>
                        <p className={`cpb-cart-total ${deviceType}`}>
                            {reduxText[7465]}
                        </p>
                        {reduxSalesSite.salesLines.length === 0 && (
                            <p className={`cpb-cart-empty ${deviceType}`}>
                                {reduxText[7466]}
                            </p>
                        )}
                        {reduxSalesSite.salesLines.map((val, i) => (
                            <div
                                key={`cpb-lines-${i}`}
                                className={`cpb-lines ${deviceType}`}
                            >
                                <Link
                                    className={`cpb-image-wrap ${deviceType}`}
                                    onClick={handleClose}
                                    to={val.absolute_url}
                                >
                                    <ImageHelperSite
                                        alt={val.product.name}
                                        className={`cpb-image ${deviceType}`}
                                        dominant_color={undefined}
                                        src={val.product.get_image_site_lq}
                                    />
                                </Link>
                                <div className={`cpb-info ${deviceType}`}>
                                    <div className={`cpb-info-wrap ${deviceType}`}>
                                        <span className={`cpb-name ${deviceType}`}>
                                            {val.product.name}
                                        </span>
                                        {val.variant && (
                                            <span className={`cpb-variant ${deviceType}`}>
                                                {val.variant.name}
                                            </span>
                                        )}
                                        {val.package_option_items?.map((val) => (
                                            <span
                                                key={val.id}
                                                className={`cpb-variant ${deviceType}`}
                                            >
                                                {val.product?.name}
                                            </span>
                                        ))}
                                        <span className={`cpb-price ${deviceType}`}>
                                            {val.qty} x {val.unitPrice} {val.currency_symbol}
                                        </span>
                                    </div>
                                    <span className={`cpb-line-total ${deviceType}`}>
                                        {val.lineTotal} {val.currency_symbol}
                                    </span>
                                </div>
                                <div className={`cpb-actions ${deviceType}`}>
                                    <i
                                        onClick={() => dispatch(reduxSalesRemoveFromCartSite(i, reduxCacheSite.portfolio!))}
                                        className={`mo-new-icon-trash-solid ${deviceType}`}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={`cpb-footer ${deviceType}`}>
                        <p className={`cpb-subtotal ${deviceType}`}>
                            {reduxText[7467]}{' '}{Number(reduxSalesSite.subTotal.toFixed(2))} {reduxSalesSite.currency_symbol}
                        </p>
                        {(Number(reduxSalesSite.subTotal) > 0) && (
                            <LinkHelperSite
                                action='page_link'
                                className={`cpb-checkout ${deviceType}`}
                                onClick={() => canCheckout ? handleClose() : undefined}
                                to='checkout'
                            >
                                {canCheckout ? reduxText[7468] : reduxText[7768]}
                            </LinkHelperSite>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
