// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useLocation,
    useParams,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    languageObjectSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    ComingSoonSite,
    NotFoundPortfolioSite,
} from 'pages'

// serializers
import {
    PageDetailSiteSerializer,
    PortfolioPageContentListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getDataSite,
    getHostOriginSite,
} from 'services'

// props
type MatchParams = {
    devId: string
    param1: string
}

type NotFoundWrapperSiteProps = {
    devId: string
}

// main
export const NotFoundWrapperSite: React.FC<NotFoundWrapperSiteProps> = React.memo(({
    devId,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxCacheSiteportfoliourl_redirect = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.url_redirect)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    const [isLoading, setIsLoading] = useState(false)
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[] | undefined>(undefined)
    const [pageObject, setPageObject] = useState<PageDetailSiteSerializer>()
    /* eslint-enable  @typescript-eslint/no-unused-vars */

    const newDevId = devId || params.devId

    useEffect(() => {
        getPortfolioData()
    }, [
        newDevId,
    ])

    function getPortfolioData() {
        try {
            getDataSite({
                axiosCancelToken,
                component: 'NotFoundWrapperSite',
                detailId: undefined,
                devId: newDevId,
                dispatch,
                errorStatus,
                location,
                pageSlug: undefined,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                scrollToTop: undefined,
                setAxiosCancelToken,
                setErrorStatus,
                setIsLoading,
                setPageContentItems,
                setPageObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'NotFoundWrapperSite',
                'getPortfolioData',
            ))
        }
    }

    if (reduxCacheSite.portfolio?.coming_soon) {
        return (
            <ComingSoonSite />
        )
    }

    if (reduxCacheSiteportfoliourl_redirect?.length! > 0) {
        const hostOriginSite = getHostOriginSite()
        let redirectUrl = ''
        let remainingUrl = ''
        if (languageObjectSite[params.param1]) {
            const urlLang = params.param1
            remainingUrl = `${location.pathname.split(`/${params.param1}/`)[1]}${location.search}`
            const redirectObj = reduxCacheSiteportfoliourl_redirect!.find((obj) => obj.old_url === remainingUrl || `${obj.old_url}/` === remainingUrl)
            if (redirectObj) {
                if (hostOriginSite === 'prod') {
                    redirectUrl = `/${urlLang}/${redirectObj.new_url}`
                } else {
                    redirectUrl = `/${params.devId}/${urlLang}/${redirectObj.new_url}`
                }
            }
        } else {
            if (hostOriginSite === 'prod') {
                remainingUrl = `${location.pathname.substring(1)}${location.search}`
            } else {
                remainingUrl = `${location.pathname.split(params.devId)[1]?.substring(1)}${location.search}`
            }
            const redirectObj = reduxCacheSiteportfoliourl_redirect!.find((obj) => obj.old_url === remainingUrl || `${obj.old_url}/` === remainingUrl)
            if (redirectObj) {
                if (redirectObj) {
                    if (hostOriginSite === 'prod') {
                        redirectUrl = `/${redirectObj.new_url}`
                    } else {
                        redirectUrl = `/${params.devId}/${redirectObj.new_url}`
                    }
                }
            }
        }

        if (redirectUrl) {
            return <Redirect to={redirectUrl} />
        }
    }

    return (
        <NotFoundPortfolioSite statusCode={errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent} />
    )
})
