// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useIonViewWillLeave,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    ImageHelperSite,
    PlayerSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxPlayerOnPlayPauseInlineSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template809SiteSerializer,
} from 'serializers/site'

// services
import {
    getResponsiveImageSite,
    getStylesNew,
} from 'services'

// props
type TemplateBlock809SiteProps = {
    blockId: string
    content: Template809SiteSerializer | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock809Site: React.FC<TemplateBlock809SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [playing, setPlaying] = useState(false)
    const [started, setStarted] = useState(false)
    const [isInView, setIsInView] = useState(true)
    const [locationPathname] = useState<string>(location.pathname)
    const [stylesNew, setStylesNew] = useState<{
        alignItems: string | undefined,
        backgroundColor?: string | undefined,
        color: string | undefined,
        fontFamily: string | undefined,
        fontSize: string | number | undefined,
        fontWeight?: string | number | undefined,
        justifyContent: string | undefined,
        letterSpacing: string | number | undefined,
        textTransform: string | undefined,
        padding: string | number | undefined,
    } | undefined>(applyStyles())

    useEffect(() => {
        if (locationPathname === location.pathname) {
            setIsInView(true)
        }
    }, [
        location.pathname,
    ])

    useIonViewWillLeave(() => {
        setIsInView(false)
        dispatch(reduxPlayerOnPlayPauseInlineSite(undefined))
    })

    useEffect(() => {
        const tempStyles = applyStyles()
        setStylesNew(tempStyles)
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                alignItems: aStyles?.alignItems,
                backgroundColor: aStyles?.backgroundColor,
                color: aStyles?.color,
                fontFamily: aStyles?.fontFamily,
                fontSize: aStyles?.fontSize,
                fontWeight: aStyles?.fontWeight,
                justifyContent: aStyles?.justifyContent,
                letterSpacing: aStyles?.letterSpacing,
                textTransform: aStyles?.textTransform,
                padding: aStyles?.padding,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock809Site',
                'applyStyles',
            ))
        }
    }

    return (
        <React.Fragment>
            <div
                id={blockId}
                className={`template-block-809${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            >
                {content && isInView && (
                    <PlayerSite
                        controls={false}
                        loop={true}
                        muted={true}
                        object={content}
                        playing={playing}
                        playsinline={true}
                    />
                )}
                <ImageHelperSite
                    id={blockId}
                    alt={content?.name}
                    className={`t-809-image${started ? ' hidden' : ''} ${deviceType}`}
                    dominant_color={content?.get_dominant_color}
                    src={content ? getResponsiveImageSite(reduxModalSite, content, 'get_image_site_hq', 'get_image_site_hq') : undefined}
                />
                <div
                    className={`t-809-name-wrap ${deviceType}`}
                    onMouseLeave={() => setPlaying(false)}
                    onMouseOver={() => {
                        setPlaying(true)
                        setStarted(true)
                    }}
                    style={{
                        alignItems: stylesNew?.alignItems,
                        backgroundColor: stylesNew?.backgroundColor,
                        justifyContent: stylesNew?.justifyContent,
                        padding: stylesNew?.padding,
                    }}
                >
                    <span
                        className={`t-809-name ${deviceType}`}
                        style={{
                            color: stylesNew?.color,
                            fontFamily: stylesNew?.fontFamily,
                            fontSize: stylesNew?.fontSize,
                            fontWeight: stylesNew?.fontWeight,
                            letterSpacing: stylesNew?.letterSpacing,
                            // @ts-ignore
                            textTransform: stylesNew?.textTransform,
                        }}
                    >
                        {content?.name}
                    </span>
                </div>
            </div>
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </React.Fragment>
    )
})
