import moment from 'moment'
import 'moment/locale/ar'
import 'moment/locale/br'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/pl'
import 'moment/locale/pt'
import 'moment/locale/ru'
import 'moment/locale/tr'

// data
import {
    languageIdObjectSite,
    reduxModalStateSite,
} from 'data'

// main
export function getDateWithTypeSite(
    reduxModalSite: reduxModalStateSite,
    date: string | undefined,
    date_type: string,
    date_format?: string,
    date_format_y?: string,
    date_format_m?: string,
    date_format_d?: string,
) {

    moment.locale('en') // default the locale to English
    const localDate = moment(date)

    if (reduxModalSite.languageId) {
        localDate.locale(languageIdObjectSite[reduxModalSite.languageId] || 'en')
    }

    if (!date) return
    if (date_format) {
        return `${moment(localDate).format(date_format)}`
    }
    if (date_type === 'y') {
        if (date_format_y) {
            return `${moment(localDate).format(date_format_y)}`
        }
        return `${moment(localDate).format('YYYY')}`
    }
    if (date_type === 'm') {
        if (date_format_m) {
            return `${moment(localDate).format(date_format_m)}`
        }
        return `${moment(localDate).format('MMMM YYYY')}`
    }
    if (date_type === 'd') {
        if (date_format_d) {
            return `${moment(localDate).format(date_format_d)}`
        }
        return `${moment(localDate).format('ddd D MMM YYYY')}`
    }
    if (date_type === 't') {
        return `${moment(localDate).format('llll')}`
    }
    if (date_type === 'day') {
        return `${moment(localDate).format('dddd')}`
    }
    if (date_type === 'day2') {
        return `${moment(localDate).format('D')}`
    }
    if (date_type === 'month2') {
        return `${moment(localDate).format('MMM')}`
    }
    if (date_type === 'day-month') {
        return `${moment(localDate).format('D MMMM')}`
    }
    if (date_type === 'time') {
        return `${moment(localDate).format('HH:mm')}`
    }
    return `${moment(localDate).format('MMMM YYYY')}`
}
