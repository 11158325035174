// react components
import React from 'react'

// pages
import {
    Free816Site,
    Sales816Site,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template816SiteSerializer,
} from 'serializers/site'

// props
type TemplateBlock816SiteProps = {
    blockId: string
    detailId: string | undefined
    content: Template816SiteSerializer | undefined
    isEditHovered: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: any
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock816Site: React.FC<TemplateBlock816SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    isEditHovered,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    if (content?.show_free_subscription_form) {
        return (
            <Free816Site
                blockId={blockId}
                content={content}
                detailId={detailId}
                isEditHovered={isEditHovered}
                mainParentId={mainParentId}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                styles={styles}
                stylesEdit={stylesEdit}
            />
        )
    }

    return (
        <Sales816Site
            blockId={blockId}
            content={content}
            isEditHovered={isEditHovered}
            mainParentId={mainParentId}
            object={object}
            parentArray={parentArray}
            parentStyles={parentStyles}
            styles={styles}
            stylesEdit={stylesEdit}
        />
    )
})
