// react components
import React from 'react'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// props
type EditBlockSiteProps = {
    customPosition?: any
    hideButtons?: boolean
    isInComponent?: boolean
    mainParentId: number
    object: PortfolioPageContentListSiteSerializer
    parentArray: number[] | undefined
    parentStyles: any
    resetCustomPosition?: any
}

// main
export const EditBlockSite: React.FC<EditBlockSiteProps> = React.memo(({
    customPosition,
    hideButtons,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    resetCustomPosition,
}) => {

    return null
})
