// data
import {
    media_url_social_network_site,
    media_version_site,
} from 'data'

// main
export function getSocialIcon(social_network: number, color: string | undefined) {
    if (['black', 'white'].includes(color!)) {
        return `${media_url_social_network_site}${social_network}_${color}.svg${media_version_site}`
    }
    return `${media_url_social_network_site}${social_network}.svg${media_version_site}`
}
