// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_auth_user_form_last_login_site,
    reduxAuthFetchSettingsSite,
    reduxAuthStateSite,
    reduxModalErrorEventHandlerSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
} from 'services'

// main
export function setLastLoginSite({
    dispatch,
    reduxAuth,
    reduxModalSite,
}: {
    dispatch: React.Dispatch<any>
    reduxAuth: reduxAuthStateSite
    reduxModalSite: reduxModalStateSite
}) {
    try {
        if (!reduxAuth?.settings?.user?.id) return
        const putUrl = getApiUrlSite(api_url_auth_user_form_last_login_site, reduxModalSite)
        axios({
            headers: reduxAuth.axiosConfig?.headers,
            method: 'put',
            url: putUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log('setLastLoginSite', response.status)
                dispatch(reduxAuthFetchSettingsSite(
                    reduxAuth,
                    reduxAuth.axiosConfig?.headers,
                    reduxModalSite,
                    dispatch,
                    reduxModalSite.portfolio?.id!,
                ))
            })
            .catch((error) => {
                axiosErrorHandlerSite({
                    apiUrl: putUrl,
                    component: '',
                    dispatch,
                    error,
                    reduxAuth,
                    reduxModalSite,
                    reference: 'setLastLoginSite',
                })
            })
    } catch (error) {
        dispatch(reduxModalErrorEventHandlerSite(
            error,
            '',
            'setLastLoginSite',
        ))
    }
}
