// react components
import React from 'react'
import {
    Helmet,
} from 'react-helmet'

// data
import {
    media_url_site,
    media_version_site,
} from 'data'

// serializers
import {
    SeoBlockSiteSerializer,
} from 'serializers/site'

// props
type SeoBlockSiteProps = {
    data: SeoBlockSiteSerializer | undefined
}

// main
export const SeoBlockSite: React.FC<SeoBlockSiteProps> = React.memo(({
    data,
}) => {
    if (!data) return null

    return (
        <Helmet defer={false}>
            <meta charSet='utf-8' />
            <title>{data.title}</title>
            <link rel='apple-touch-icon' href={data.favicon || `${media_url_site}favicon/favicon.ico${media_version_site}`} />
            <link rel='icon' href={data.favicon || `${media_url_site}favicon/favicon.ico${media_version_site}`} />
            <link rel='mask-icon' href={data.favicon || `${media_url_site}favicon/favicon.ico${media_version_site}`} />
            <link rel='shortcut icon' href={data.favicon || `${media_url_site}favicon/favicon.ico${media_version_site}`} />
            <meta name='description' content={data.description} />
            <meta name='Resource-type' content={data.resource_type} />
            <meta name='keywords' content={data.keywords?.map(val => val.name).join(',')} />
            <link rel='image_src' href={data.image} />
            {/* Facebook */}
            <meta property='fb:app_id' content={data.fb_app_id} />
            <meta property='og:description' content={data.description} />
            <meta property='og:image' content={data.image} />
            <meta property='og:title' content={data.title} />
            <meta property='og:type' content='website' />
            <meta property='og:site_name' content={data.site_name} />
            <meta name='my:fb' content='on' />
            <meta name='my:google' content='on' />
            <meta name='my:tw' content='on' />
            <meta name='my:in' content='on' />
            {/* Twitter */}
            <meta name='twitter:card' content='summary' />
            {/* <meta name='twitter:site' content={data.twitter_site} /> */}
            <meta name='twitter:title' content={data.title} />
            <meta name='twitter:description' content={data.description} />
            <meta name='twitter:image' content={data.image} />
            {/* Google */}
            <script type='application/ld+json'>
                {`{
                        '@context': '//schema.org/',
                        '@type': 'WebSite',
                        'name': ${data.title},
                        'image': ${data.image},
                        'description': ${data.description}
                        }`}
            </script>
        </Helmet>
    )
})
