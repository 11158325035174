// react components
import {
    isMobileOnly,
    isWindows,
} from 'react-device-detect'

// data
import {
    REDUX_CACHE_SET_PORTFOLIO_SITE,
} from './../cache/mo_actions'
import {
    REDUX_MODAL_CLEAR_SEARCH_SITE,
    REDUX_MODAL_COOKIE_CONSENT_SITE,
    REDUX_MODAL_ERROR_EVENT_HANDLER_SITE,
    REDUX_MODAL_ERROR_SHOW_HIDE_SITE,
    REDUX_MODAL_GA_INIT_SITE,
    REDUX_MODAL_GTM_INIT_SITE,
    REDUX_MODAL_LIGHTBOX_ON_NEXT_SITE,
    REDUX_MODAL_LIGHTBOX_ON_PREV_SITE,
    REDUX_MODAL_LIGHTBOX_SHOW_HIDE_SITE,
    REDUX_MODAL_SET_ACTIVE_COLLAPSED_ID_SITE,
    REDUX_MODAL_SET_ACTIVE_DROPDOWN_ID_SITE,
    REDUX_MODAL_SET_CURRENCY_ID_SITE,
    REDUX_MODAL_SET_DEVICE_TYPE_SITE,
    REDUX_MODAL_SET_EDIT_SITE,
    REDUX_MODAL_SET_EDIT_STYLES_SITE,
    REDUX_MODAL_SET_LANGUAGE_ID_SITE,
    REDUX_MODAL_SET_NAVBAR_HEIGHT_SITE,
    REDUX_MODAL_SET_NAVBAR_IS_TRANSPARENT_SITE,
    REDUX_MODAL_SET_NAVBAR_OPEN_ID_SITE,
    REDUX_MODAL_SET_NAVBAR_TYPE_SITE,
    REDUX_MODAL_SET_NAVBAR_WIDTH_SITE,
    REDUX_MODAL_SET_ROOT_URL_SITE,
    REDUX_MODAL_SET_SPECIAL_SIZE_SITE,
    REDUX_MODAL_SET_STRIPE_PROMISE_SITE,
    REDUX_MODAL_SLAASK_INIT_SITE,
    REDUX_MODAL_SLIDER_GET_DATA_SITE,
    REDUX_MODAL_SLIDER_ON_NEXT_SITE,
    REDUX_MODAL_SLIDER_ON_PREV_SITE,
    REDUX_MODAL_SLIDER_SHOW_HIDE_SITE,
} from './mo_actions'
import {
    reduxModalStateSite,
} from './mo_state'
import {
    REDUX_FORM_SET_NAVIGATION_MODE_SITE,
} from '../form/mo_actions'

// services
import {
    deleteCookieSite,
    getCookieSite,
    setCookieSite,
} from '../../../../services/site/cookies'
import {
    parseQuerySite,
} from '../../../../services/site/mo_parseQuery'

let apiUrlTemp: string
if (process.env.NODE_ENV !== 'production') {
    apiUrlTemp = 'http://localhost:8000/'
    // apiUrlTemp = 'https://api.justtheweb.io/'
} else {
    apiUrlTemp = 'https://api.justtheweb.io/'
}

let deviceType: any = isMobileOnly ? 'is-mobile' : (window.innerWidth <= 767.99 ? 'is-mobile' : (window.innerWidth <= 991.99 ? 'is-tablet' : 'is-web'))
const forcedDeviceType: any = parseQuerySite(window.location.search).forcedDeviceType
if (['is-mobile', 'is-tablet', 'is-web'].includes(forcedDeviceType)) {
    deviceType = forcedDeviceType
}

// main
const initialState: reduxModalStateSite = {
    activeCollapsedId: undefined,
    activeDropdownId: undefined,
    apiRootUrl: getCookieSite({ name: 'mo_reduxModalApiRootUrl', format: 'string' }) || `${apiUrlTemp}en/`,
    deviceType: deviceType,
    isWindows: isWindows ? ' is-windows' : '',
    currencyId: Number(getCookieSite({ name: 'mo_reduxModalCurrencyId', format: 'string' })) || undefined,
    languageId: Number(getCookieSite({ name: 'mo_reduxModalLanguageId', format: 'string' })) || 1,
    respValue: (deviceType === 'is-mobile') ? '_SM' : (deviceType === 'is-tablet' ? '_MD' : ''),
    rootUrl: '/',

    cookieConsent: getCookieSite({ name: 'mo_reduxModalCookieConsent', format: 'json' }),

    edit: false,
    specialSize: undefined,
    stylesEditObj: undefined,

    navbarHeight: 0,
    navbarOpenId: undefined,
    navbarIsTransparent: false,
    navbarType: 'top',
    navbarWidth: 0,

    gaInit: undefined,
    gtmInit: undefined,
    slaaskApiKey: undefined,

    data: [],
    isOpen: false,
    listUrl: undefined,
    objectId: undefined,
    slideIndex: 0,
    noRedirect: false,

    lightBox: {
        allowDownload: false,
        isOpen: false,
        images: [],
        photoIndex: 0,
    },
    error: {
        header: '',
        isOpen: false,
        noMessage: false,
        subHeader: '',
    },
    errorEventHandler: {
        component: '',
        error: undefined,
        info: '',
        skipAlert: undefined,
    },

    portfolio: undefined,

    searchClear: undefined,

    stripePromise: null,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_CACHE_SET_PORTFOLIO_SITE: {
            return {
                ...state,
                portfolio: action.payload.portfolioResult,
            }
        }
        case REDUX_FORM_SET_NAVIGATION_MODE_SITE: {
            if (action.payload === 'edit') {
                return {
                    ...state,
                    specialSize: undefined,
                }
            }
            return state
        }
        case REDUX_MODAL_CLEAR_SEARCH_SITE:
            return {
                ...state,
                searchClear: Math.random(),
            }
        case REDUX_MODAL_COOKIE_CONSENT_SITE:
            setCookieSite({ name: 'mo_reduxModalCookieConsent', value: action.payload })
            return {
                ...state,
                cookieConsent: action.payload,
            }
        case REDUX_MODAL_ERROR_EVENT_HANDLER_SITE: {
            return {
                ...state,
                errorEventHandler: action.payload,
            }
        }
        case REDUX_MODAL_ERROR_SHOW_HIDE_SITE: {
            return {
                ...state,
                error: action.payload,
            }
        }
        case REDUX_MODAL_GA_INIT_SITE: {
            return {
                ...state,
                gaInit: action.payload || true,
            }
        }
        case REDUX_MODAL_GTM_INIT_SITE: {
            return {
                ...state,
                gtmInit: action.payload || true,
            }
        }
        case REDUX_MODAL_LIGHTBOX_ON_NEXT_SITE: {
            if (state.lightBox) {
                return {
                    ...state,
                    lightBox: {
                        ...state.lightBox,
                        objectId: state.lightBox.images[(state.lightBox.photoIndex + 1) % state.lightBox.images.length].id,
                        photoIndex: (state.lightBox.photoIndex + 1) % state.lightBox.images.length,
                    }
                }
            }
            return state

        }
        case REDUX_MODAL_LIGHTBOX_ON_PREV_SITE: {
            if (state.lightBox) {
                return {
                    ...state,
                    lightBox: {
                        ...state.lightBox,
                        objectId: state.lightBox.images[(state.lightBox.photoIndex + state.lightBox.images.length - 1) % state.lightBox.images.length].id,
                        photoIndex: (state.lightBox.photoIndex + state.lightBox.images.length - 1) % state.lightBox.images.length,
                    }
                }
            }
            return state

        }
        case REDUX_MODAL_LIGHTBOX_SHOW_HIDE_SITE: {
            let photoIndex = 0
            if (action.payload.items) {
                photoIndex = action.payload.items.findIndex((object: any) => object.id === parseInt(action.payload.objectId, 10))
            }
            return {
                ...state,
                lightBox: {
                    ...state.lightBox,
                    allowDownload: action.payload.allowDownload,
                    images: action.payload.items,
                    isOpen: action.payload.boolean,
                    objectId: action.payload.objectId,
                    photoIndex: photoIndex,
                }
            }
        }
        case REDUX_MODAL_SET_ACTIVE_COLLAPSED_ID_SITE: {
            return {
                ...state,
                activeCollapsedId: action.payload,
            }
        }
        case REDUX_MODAL_SET_ACTIVE_DROPDOWN_ID_SITE: {
            return {
                ...state,
                activeDropdownId: action.payload,
            }
        }
        case REDUX_MODAL_SET_CURRENCY_ID_SITE: {
            if (action.payload) {
                setCookieSite({ name: 'mo_reduxModalCurrencyId', value: action.payload })
            } else {
                deleteCookieSite('mo_reduxModalCurrencyId')
            }
            return {
                ...state,
                currencyId: action.payload,
            }
        }
        case REDUX_MODAL_SET_DEVICE_TYPE_SITE: {
            return {
                ...state,
                deviceType: action.payload,
                respValue: action.payload === 'is-mobile' ? '_SM' : (action.payload === 'is-tablet' ? '_MD' : ''),
            }
        }
        case REDUX_MODAL_SET_EDIT_STYLES_SITE: {
            return {
                ...state,
                stylesEditObj: action.payload,
            }
        }
        case REDUX_MODAL_SET_LANGUAGE_ID_SITE: {
            setCookieSite({ name: 'mo_reduxModalApiRootUrl', value: `${apiUrlTemp}${action.payload.languageCode}/` })
            setCookieSite({ name: 'mo_reduxModalLanguageId', value: action.payload.languageId })
            return {
                ...state,
                apiRootUrl: `${apiUrlTemp}${action.payload.languageCode}/`,
                languageId: action.payload.languageId,
            }
        }
        case REDUX_MODAL_SET_NAVBAR_HEIGHT_SITE: {
            return {
                ...state,
                navbarHeight: action.payload,
            }
        }
        case REDUX_MODAL_SET_NAVBAR_OPEN_ID_SITE: {
            return {
                ...state,
                navbarOpenId: (action.payload === state.navbarOpenId) ? undefined : action.payload,
            }
        }
        case REDUX_MODAL_SET_NAVBAR_IS_TRANSPARENT_SITE: {
            return {
                ...state,
                navbarIsTransparent: action.payload,
            }
        }
        case REDUX_MODAL_SET_NAVBAR_TYPE_SITE: {
            return {
                ...state,
                navbarType: action.payload,
            }
        }
        case REDUX_MODAL_SET_NAVBAR_WIDTH_SITE: {
            return {
                ...state,
                navbarWidth: action.payload,
            }
        }
        case REDUX_MODAL_SET_ROOT_URL_SITE: {
            return {
                ...state,
                rootUrl: action.payload,
            }
        }
        case REDUX_MODAL_SET_SPECIAL_SIZE_SITE: {
            return {
                ...state,
                specialSize: action.payload,
            }
        }
        case REDUX_MODAL_SET_STRIPE_PROMISE_SITE: {
            return {
                ...state,
                stripePromise: action.payload,
            }
        }
        case REDUX_MODAL_SLAASK_INIT_SITE: {
            return {
                ...state,
                slaaskApiKey: action.payload,
            }
        }
        case REDUX_MODAL_SLIDER_GET_DATA_SITE: {
            const toReturnData: any = []
            action.results.map((val: any) => {
                if (val.absolute_site_url) toReturnData.push(val)
                return false
            })
            let slideIndex = state.slideIndex
            if (action.objectId) {
                slideIndex = toReturnData.findIndex((object: any) => object.id === parseInt(action.objectId, 10))
            }
            return {
                ...state,
                data: toReturnData,
                slideIndex: slideIndex,
            }
        }
        case REDUX_MODAL_SLIDER_ON_NEXT_SITE: {
            return {
                ...state,
                objectId: state.data[(state.slideIndex + 1) % state.data.length].id,
                slideIndex: (state.slideIndex + 1) % state.data.length,
            }
        }
        case REDUX_MODAL_SLIDER_ON_PREV_SITE: {
            return {
                ...state,
                objectId: state.data[(state.slideIndex + state.data.length - 1) % state.data.length].id,
                slideIndex: (state.slideIndex + state.data.length - 1) % state.data.length,
            }
        }
        case REDUX_MODAL_SLIDER_SHOW_HIDE_SITE: {
            const slideIndex = state.data.findIndex((object: any) => object.id === parseInt(action.objectId, 10))
            return {
                ...state,
                isOpen: action.boolean,
                listUrl: action.listUrl,
                objectId: action.objectId,
                slideIndex: slideIndex,
                noRedirect: true,
            }
        }
        case REDUX_MODAL_SET_EDIT_SITE: {
            return {
                ...state,
                edit: action.payload,
            }
        }
        default:
            return state
    }
}
