// react components
import moment from 'moment'

// data
import {
    reduxAuthStateSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    setLastLoginSite,
} from 'services'

// main
export function getAxiosHeadersSite(
    reduxAuth: reduxAuthStateSite,
    reduxModalSite: reduxModalStateSite,
    dispatch: React.Dispatch<any>,
) {
    if (reduxAuth?.authenticated && (!reduxAuth.settings?.user?.last_login || (moment(reduxAuth.settings?.user?.last_login).add(5, 'minutes').utc().format() < moment.utc().format()))) {
        setLastLoginSite({
            dispatch,
            reduxAuth,
            reduxModalSite,
        })
    }
    return reduxAuth?.axiosConfig?.headers
}
