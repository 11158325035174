// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    AlertSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// services
import {
    axiosErrorHandlerSiteErrorBoundarySite,
} from 'services'

// main
export const ErrorEventHandlerSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        if (reduxModalSite.errorEventHandler?.error) {
            if (!reduxModalSite.errorEventHandler?.skipAlert) setShowAlert(true)
            if (process.env.NODE_ENV === 'development') console.log(reduxModalSite.errorEventHandler.error, reduxModalSite.errorEventHandler.info)
            if (!reduxModalSite.errorEventHandler.skipAlert) {
                axiosErrorHandlerSiteErrorBoundarySite(
                    reduxModalSite.errorEventHandler.error.toString(),
                    reduxAuth,
                    reduxModalSite,
                    reduxModalSite.errorEventHandler.component || '',
                    reduxModalSite.errorEventHandler.info || '',
                )
            }
        }
    }, [reduxModalSite.errorEventHandler?.error])

    function onAlertDismiss() {
        dispatch(reduxModalErrorEventHandlerSite(undefined, '', ''))
        setShowAlert(false)
    }

    if (showAlert) {
        return (
            <AlertSite
                buttons={[{ text: 'OK' }]}
                header='Oops! Something went wrong' // TO_LATER_TEXT
                isOpen
                isErrorAlert
                onDidDismiss={onAlertDismiss}
            />
        )
    }
    return null
}
