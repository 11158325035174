// react components
import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template808SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

// props
type TemplateBlock808SiteProps = {
    blockId: string
    content: Template808SiteSerializer | undefined
    isEditHovered: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: any
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock808Site: React.FC<TemplateBlock808SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxCacheSiteportfoliosearch_pages = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.search_pages)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const inputRef = useRef<HTMLInputElement>(null)

    const [showInput, setShowInput] = useState(false)

    const [stylesNew, setStylesNew] = useState<{
        hiddenInput: boolean,
        iconStyle: CustomCSSProperties | undefined,
        inputStyle: CustomCSSProperties | undefined,
        wrapStyle: CustomCSSProperties | undefined,
    } | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    useLayoutEffect(() => {
        if (showInput) {
            inputRef.current?.focus()
        }
    }, [showInput])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                hiddenInput: aStyles?.show === 'true' ? false : true,
                inputStyle: {
                    background: aStyles?.background,
                    borderColor: aStyles?.borderColor,
                    borderRadius: aStyles?.borderRadius,
                    borderStyle: aStyles?.borderStyle,
                    borderWidth: aStyles?.borderWidth,
                    color: aStyles?.color,
                },
                iconStyle: {
                    color: aStyles?.color,
                },
                wrapStyle: {
                    padding: aStyles?.padding,
                },
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock808Site',
                'applyStyles',
            ))
        }
    }

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                onSearch()
                setShowInput(false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock808Site',
                'onSearchKeyPress',
            ))
        }
    }

    function onSearch() {
        try {
            if (inputRef.current?.value) {
                if (content?.search_page_url) {
                    history.push(`${reduxModalSite.rootUrl || '/'}${content.search_page_url}?query=${inputRef.current?.value}`)
                } else if (reduxCacheSiteportfoliosearch_pages?.filter(obj => obj.is_search_page)[0]) {
                    history.push(`${reduxModalSite.rootUrl || '/'}${reduxCacheSiteportfoliosearch_pages.filter(obj => obj.is_search_page)[0].slug}?query=${inputRef.current?.value}`)
                } else {
                    history.push(`${reduxModalSite.rootUrl || '/'}extra/hub/search?query=${inputRef.current?.value}`)
                }
                inputRef.current.value = ''
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock808Site',
                'onSearch',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-808${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={stylesNew?.wrapStyle}
        >
            {stylesNew?.hiddenInput
                ? (
                    <React.Fragment>
                        {showInput && (
                            <input
                                ref={inputRef}
                                className={`t-808-input${showInput ? ' active' : ''} ${deviceType}`}
                                onKeyPress={(e) => onSearchKeyPress(e)}
                                placeholder={reduxText[8254]}
                                style={stylesNew?.inputStyle}
                            />
                        )}
                        <i
                            className={`t-808-icon ${showInput ? 'mo-new-icon-times-solid' : 'mo-new-icon-search-solid'} ${deviceType}`}
                            onClick={() => setShowInput(!showInput)}
                            style={stylesNew?.iconStyle}
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <input
                            ref={inputRef}
                            className={`t-808-input active ${deviceType}`}
                            onKeyPress={(e) => onSearchKeyPress(e)}
                            placeholder={reduxText[8254]}
                            style={stylesNew?.inputStyle}
                        />
                        <i
                            className={`t-808-icon mo-new-icon-search-solid ${deviceType}`}
                            onClick={onSearch}
                            style={stylesNew?.iconStyle}
                        />
                    </React.Fragment>
                )}
            <EditBlockSite
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
