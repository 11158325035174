// react components
import React from 'react'
import {
    Button,
    Snackbar,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxFormSetIsSuccessToastOpenSite,
} from 'data'

// main
export const FormSuccessToastSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <Snackbar
            action={
                <Button
                    onClick={() => dispatch(reduxFormSetIsSuccessToastOpenSite(false))}
                    size='small'
                >
                    {reduxText[4742]}
                </Button>
            }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={3000}
            className='t-796-snackbar'
            message={reduxFormSite.isSuccessToastMessage || reduxText[9819]}
            onClose={() => dispatch(reduxFormSetIsSuccessToastOpenSite(false))}
            open={reduxFormSite.isSuccessToastOpen || false}
        />
    )
}
