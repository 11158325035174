// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    api_url_portfolio_hub_content_list_site,
    reduxAuthStateSite,
    reduxCacheStateSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    PortfolioDetailSiteSerializer,
} from 'serializers/site'


// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// main
export function getHubContentListDataSite({
    axiosCancelToken,
    component,
    dispatch,
    portfolioObject,
    reduxAuth,
    reduxCacheSite,
    reduxModalSite,
    setHasSearchPage,
    setHubObject,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    dispatch: React.Dispatch<any>
    portfolioObject: PortfolioDetailSiteSerializer
    reduxAuth: reduxAuthStateSite
    reduxCacheSite: reduxCacheStateSite
    reduxModalSite: reduxModalStateSite
    setHasSearchPage: React.Dispatch<boolean>
    setHubObject: any
}) {

    if (reduxCacheSite.hubContent) {
        setHubObject(reduxCacheSite.hubContent)
    }

    let pageApiUrl = getApiUrlSite(`${api_url_portfolio_hub_content_list_site}?portfolio_id=${portfolioObject.id}`, reduxModalSite)
    pageApiUrl += `&language_id=${reduxModalSite.languageId}`
    if (process.env.NODE_ENV === 'development') console.log(pageApiUrl)
    axios({
        // cancelToken: axiosCancelToken?.token,
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: 'get',
        url: pageApiUrl,
    })
        .then((pageResponse) => {
            if (process.env.NODE_ENV === 'development') console.log(pageResponse)
            setHubObject(pageResponse.data)
            const has_search = pageResponse.data.filter((obj: any) => obj.slug === 'search')
            setHasSearchPage(has_search.length > 0)
            // dispatch(reduxCacheSetPageSite(pageResponse.data))
        })
        .catch((error) => {
            axiosErrorHandlerSite({
                apiUrl: pageApiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'getHubContentListDataSite',
            })
        })
}
