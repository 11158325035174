// react components
import React from 'react'
import {
    Elements,
} from '@stripe/react-stripe-js'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    TemplateBlock783StripeSite,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template783SiteSerializer,
    formInfoTypeSite,
    formValueTypeSite,
} from 'serializers/site'

// props
type TemplateBlock783StripeWrapperSiteProps = {
    blockId: string
    content: Template783SiteSerializer | undefined
    contentArray: Template783SiteSerializer[] | undefined
    formInfo: formInfoTypeSite
    formValue: formValueTypeSite
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    setFormValue: React.Dispatch<formValueTypeSite> | undefined
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock783StripeWrapperSite: React.FC<TemplateBlock783StripeWrapperSiteProps> = React.memo(({
    blockId,
    content,
    contentArray,
    formInfo,
    formValue,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    setFormValue,
    styles,
    stylesEdit,
}) => {

    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    return (
        <Elements stripe={reduxModalSite.stripePromise}>
            <TemplateBlock783StripeSite
                blockId={blockId}
                content={content}
                contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
                formInfo={formInfo}
                formValue={formValue}
                isEditHovered={isEditHovered}
                isInComponent={isInComponent}
                mainParentId={mainParentId}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                setFormValue={setFormValue}
                styles={styles}
                stylesEdit={stylesEdit}
            />
        </Elements>
    )
})
