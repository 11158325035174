// react components
import React, {
    useEffect,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    api_url_portfolio_payment_confirm_form,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    parseQuerySite,
    removeParamsFromUrlSite,
} from 'services'

// main
export const CustomPaymentConfirmBlockSite: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    useEffect(() => {
        const transactionId = parseQuerySite(location.search)?.transaction_id
        if (transactionId) {
            confirmPayment(transactionId)
        }
    }, [])

    function confirmPayment(transactionId: string) {
        try {
            const formData = new FormData()
            formData.append('transaction_id', transactionId)

            const postUrl = getApiUrlSite(api_url_portfolio_payment_confirm_form, reduxModalSite)
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    removeParamsFromUrlSite('transaction_id')
                })
                .catch((error) => {
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'CustomPaymentConfirmBlockSite',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'confirmPayment',
                        skipAlert: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'CustomPaymentConfirmBlockSite',
                'confirmPayment',
            ))
        }
    }

    return null
}
