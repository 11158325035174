// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonModal,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    api_url_portfolio_contact_form,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    TemplateBlock783HelperSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template783SiteSerializer,
    formInfoTypeSite,
    formValueTypeSite,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    getStylesNew,
    validateEmailSite,
} from 'services'

// props
type TemplateBlock783BanquePostaleSiteProps = {
    blockId: string
    content: Template783SiteSerializer | undefined
    contentArray: Template783SiteSerializer[] | undefined
    formInfo: formInfoTypeSite
    formValue: formValueTypeSite
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    setFormValue: React.Dispatch<formValueTypeSite> | undefined
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock783BanquePostaleSite: React.FC<TemplateBlock783BanquePostaleSiteProps> = React.memo(({
    blockId,
    content,
    contentArray,
    formInfo,
    formValue,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    setFormValue,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<{
        buttonStyle: CustomCSSProperties | undefined,
        hoverStyleBackground: string | undefined,
        hoverStyleBorderColor: string | undefined,
        hoverStyleColor: string | undefined,
        hasHoverStyle: boolean,
    } | undefined>(applyStyles())
    const [newStyles, setNewStyles] = useState<any>(stylesNew?.buttonStyle)
    const [showPaymentButton, setShowPaymentButton] = useState(false)
    const [paymentFormData, setPaymentFormData] = useState<any>()

    useEffect(() => {
        const tempStyles = applyStyles()
        setStylesNew(tempStyles)
        setNewStyles(applyStyles()?.buttonStyle)
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const buttonStyle = getStylesNew(reduxModalSite, styles, stylesEdit)
            const hoverStyleBackground = buttonStyle?.hoverBackground
            const hoverStyleBorderColor = buttonStyle?.hoverBorderColor
            const hoverStyleColor = buttonStyle?.hoverColor
            const hasHoverStyle = Boolean(hoverStyleBackground || hoverStyleBorderColor || hoverStyleColor)
            return {
                buttonStyle: buttonStyle,
                hoverStyleBackground: hoverStyleBackground,
                hoverStyleBorderColor: hoverStyleBorderColor,
                hoverStyleColor: hoverStyleColor,
                hasHoverStyle: hasHoverStyle,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783HelperSite',
                'applyStyles',
            ))
        }
    }

    function onHover(direction: string) {
        try {
            if (stylesNew?.hasHoverStyle) {
                if (direction === 'over') {
                    if (stylesNew.buttonStyle) {
                        const newnewStyle = Object.assign({}, stylesNew.buttonStyle)
                        if (stylesNew.hoverStyleBackground) newnewStyle.background = stylesNew.hoverStyleBackground
                        if (stylesNew.hoverStyleBorderColor) newnewStyle.borderColor = stylesNew.hoverStyleBorderColor
                        if (stylesNew.hoverStyleColor) newnewStyle.color = stylesNew.hoverStyleColor
                        setNewStyles(newnewStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                } else {
                    if (stylesNew.buttonStyle) {
                        setNewStyles(stylesNew.buttonStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783HelperSite',
                'onHover',
            ))
        }
    }

    function submitBookingForm() {
        try {
            const formData = new FormData()
            const postUrl = getApiUrlSite(api_url_portfolio_contact_form, reduxModalSite)
            formData.append('block_id', JSON.stringify({ key: 'block_id', label: 'block_id', value: `${formInfo?.id}` }))
            formData.append('from', JSON.stringify({ key: 'from', label: 'From', value: window.location.href }))
            const errorsData: string[] = []
            let validMailError = false
            formInfo?.fields?.map((val) => {
                if (val.required && !formValue?.fields[val.name!]?.value) {
                    errorsData.push(val.name!)
                } else if (val.field_type === 'email' && formValue?.fields[val.name!] && !validateEmailSite(formValue.fields[val.name!].value!)) {
                    setFormValue!({
                        ...formValue!,
                        errors: {
                            [val.name!]: reduxText[7490],
                            main_error: reduxText[7534],
                        },
                        helpers: {
                            ...formValue!.helpers,
                            buttonDisabled: true,
                        }
                    })
                    validMailError = true
                    errorsData.push(val.name!)
                }
                formData.append(val.name!, JSON.stringify(formValue?.fields[val.name!]))
                return false
            })
            if (validMailError) return
            if (errorsData.length > 0) {
                const newErrors: {
                    [key: string]: string
                } = {}
                newErrors.main_error = reduxText[7534]
                errorsData.map((val2) => {
                    newErrors[val2] = reduxText[7489]
                    return false
                })
                setFormValue!({
                    ...formValue!,
                    errors: newErrors,
                    helpers: {
                        ...formValue!.helpers,
                        buttonDisabled: true,
                    }
                })
            } else {
                setFormValue!({
                    ...formValue!,
                    helpers: {
                        ...formValue!.helpers,
                        buttonDisabled: true,
                        isLoading: true,
                    }
                })
                axios({
                    data: formData,
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        if (response.data.banque_postale_go) {
                            setFormValue!({
                                ...formValue!,
                                helpers: {
                                    ...formValue!.helpers,
                                    isLoading: false,
                                }
                            })
                            setShowPaymentButton(true)
                            setPaymentFormData(response.data)
                        } else if (response.data.redirect_to_success) {
                            setFormValue!({
                                ...formValue!,
                                helpers: {
                                    ...formValue!.helpers,
                                    isLoading: false,
                                }
                            })
                            history.push(`${reduxModalSite.rootUrl}payment-success?custom_message_id=${response.data.custom_message_id}`)
                        } else {
                            setFormValue!({
                                ...formValue!,
                                errors: {},
                                fields: {},
                                helpers: {
                                    ...formValue!.helpers,
                                    buttonDisabled: true,
                                    isLoading: false,
                                    isSuccess: true,
                                    isSuccessText: content?.text2,
                                }
                            })
                        }
                    })
                    .catch((error) => {
                        if (process.env.NODE_ENV === 'development') console.log(error)
                        setFormValue!({
                            ...formValue!,
                            errors: error.response?.data,
                            helpers: {
                                ...formValue!.helpers,
                                buttonDisabled: true,
                                isLoading: false,
                            }
                        })
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock783StripeSite',
                            dispatch,
                            error,
                            formFields: JSON.stringify(formValue?.fields),
                            reduxAuth,
                            reduxModalSite,
                            reference: 'submitBookingForm',
                            skipAlertStatusCode: [400],
                        })
                    })
            }
        } catch (error) {
            setFormValue!({
                ...formValue!,
                helpers: {
                    ...formValue!.helpers,
                    isLoading: false,
                }
            })
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783StripeSite',
                'submitBookingForm',
            ))
        }
    }

    return (
        <React.Fragment>
            <TemplateBlock783HelperSite
                blockId={blockId}
                content={content}
                contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
                formInfo={formInfo}
                formValue={formValue}
                isEditHovered={isEditHovered}
                isInComponent={isInComponent}
                mainParentId={mainParentId}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                setFormValue={setFormValue}
                styles={styles}
                stylesEdit={stylesEdit}
                submitPayment={submitBookingForm}
            />
            <IonModal
                // @ts-ignore
                cssClass='template-block-783-banque-postal-modal'
                isOpen={showPaymentButton}
                onDidDismiss={() => setShowPaymentButton(false)}
            >
                <IonContent className={`mo-hide-ion-content-scrollbar ${deviceType}${reduxModalSite.isWindows}`}>
                    <div className='t-783-content'>
                        <p className='t-783-text'>{reduxText[8629]}</p>
                        {paymentFormData && (
                            <form
                                action='https://scelliuspaiement.labanquepostale.fr/vads-payment/'
                                className='t-783-form'
                                method='POST'
                            >
                                <input type='hidden' name='vads_action_mode' value={paymentFormData.vads_action_mode} />
                                <input type='hidden' name='vads_amount' value={paymentFormData.vads_amount} />
                                <input type='hidden' name='vads_capture_delay' value={paymentFormData.vads_capture_delay} />
                                <input type='hidden' name='vads_ctx_mode' value={paymentFormData.vads_ctx_mode} />
                                <input type='hidden' name='vads_currency' value={paymentFormData.vads_currency} />
                                <input type='hidden' name='vads_cust_email' value={paymentFormData.vads_cust_email} />
                                <input type='hidden' name='vads_cust_first_name' value={paymentFormData.vads_cust_first_name} />
                                <input type='hidden' name='vads_cust_last_name' value={paymentFormData.vads_cust_last_name} />
                                <input type='hidden' name='vads_language' value={paymentFormData.vads_language} />
                                <input type='hidden' name='vads_order_id' value={paymentFormData.vads_order_id} />
                                <input type='hidden' name='vads_page_action' value={paymentFormData.vads_page_action} />
                                <input type='hidden' name='vads_payment_config' value={paymentFormData.vads_payment_config} />
                                <input type='hidden' name='vads_redirect_success_timeout' value={paymentFormData.vads_redirect_success_timeout} />
                                <input type='hidden' name='vads_site_id' value={paymentFormData.vads_site_id} />
                                <input type='hidden' name='vads_trans_date' value={paymentFormData.vads_trans_date} />
                                <input type='hidden' name='vads_trans_id' value={paymentFormData.vads_trans_id} />
                                <input type='hidden' name='vads_url_cancel' value={paymentFormData.vads_url_cancel} />
                                <input type='hidden' name='vads_url_error' value={paymentFormData.vads_url_error} />
                                <input type='hidden' name='vads_url_refused' value={paymentFormData.vads_url_refused} />
                                <input type='hidden' name='vads_url_success' value={paymentFormData.vads_url_success} />
                                <input type='hidden' name='vads_version' value={paymentFormData.vads_version} />
                                <input type='hidden' name='signature' value={paymentFormData.signature} />
                                <input
                                    className={`template-block-783 ${deviceType}`}
                                    name='payer'
                                    onMouseLeave={() => onHover('leave')}
                                    onMouseOver={() => onHover('over')}
                                    style={newStyles}
                                    type='submit'
                                    value={reduxText[8628]}
                                />
                            </form>
                        )}
                    </div>
                </IonContent>
            </IonModal>
        </React.Fragment>
    )
})
