// react components
import React, {
    useRef,
} from 'react'
import ReactPlayer from 'react-player'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    Template782SiteSerializer,
} from 'serializers/site'

// props
type PlayerSiteProps = {
    controls: boolean
    loop: boolean
    muted: boolean
    object: Template782SiteSerializer
    playing?: boolean
    playsinline?: boolean
}

// main
export const PlayerSite: React.FC<PlayerSiteProps> = React.memo(({
    controls,
    loop,
    muted,
    object,
    playing = true,
    playsinline,
}) => {

    const dispatch = useDispatch()
    const contentRef = useRef<ReactPlayer>(null)

    function onError(error: any) {
        try {
            console.log(error)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PlayerSite',
                'onError',
            ))
        }
    }

    // soundcloud config options
    const soundcloudConfig = {
        options: {
            auto_play: true,
        },
    }

    // vimeo config options
    const vimeoConfig = {
        playerOptions: { // https://help.vimeo.com/hc/en-us/articles/360001494447-Using-Player-Parameters
            autoplay: 1,
        },
    }

    // youtube config
    const youtubeConfig = {
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            color: 'white',
            rel: 0,
            showinfo: 0,
        },
    }

    const otherPlayers = [
        3,
        7,
        8,
        9,
        10,
        11,
        13,
        14,
        15,
        16,
        18,
        19,
        21,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
    ]

    return (
        <div className='player-site'>
            {/* Start iframe for Deezer player */}
            {object.platform === 3 && (
                <iframe
                    frameBorder='no'
                    scrolling='no'
                    src={`https://www.deezer.com/plugins/player?format=classic&autoplay=false&playlist=false&color=ff0000&layout=dark&size=medium&type=tracks&id=${object.link_id}&app_id=1`}
                    title='Deezer Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Deezer player */}
            {/* Start iframe for Giphy player */}
            {object.platform === 7 && (
                <iframe
                    allowFullScreen
                    className='giphy-embed'
                    frameBorder='0'
                    src={`https://giphy.com/embed/${object.link_id}`}
                    title='Giphy Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Giphy player */}
            {/* Start iframe for Spotify player */}
            {object.platform === 8 && (
                <iframe
                    allow='encrypted-media'
                    scrolling='no'
                    frameBorder='no'
                    src={(object.link_id?.includes('episode') || object.link_id?.includes('playlist') || object.link_id?.includes('track')) ? `https://open.spotify.com/embed/${object.link_id}` : `https://open.spotify.com/embed/track/${object.link_id}`}
                    title='Spotify Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Spotify player */}
            {/* Start iframe for Bandcamp player */}
            {object.platform === 9 && (
                <iframe
                    id='zarmaaa'
                    seamless
                    src={object.link_id && object.link_id.replace('artwork=small', 'artwork=small')}
                    title='Bandcamp Iframe'
                    style={{
                        border: '0',
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Bandcamp player */}
            {/* Check */}
            {object.platform === 10 && (
                <iframe
                    allowFullScreen
                    frameBorder='0'
                    scrolling='no'
                    src={`https://www.arte.tv/player/v3/index.php?json_url=https%3A%2F%2Fapi.arte.tv%2Fapi%2Fplayer%2Fv1%2Fconfig%2Ffr%2F${object.link_id}%3Fautostart%3D1%26lifeCycle%3D1&amplang=fr_FR&ampembed=1&ampmute=0`}
                    title='To check Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* Check */}
            {/* Start iframe for Pippa player */}
            {object.platform === 11 && (
                <iframe
                    allow='autoplay'
                    frameBorder='0'
                    src={`https://player.pippa.io/${object.link_id}?theme=default&cover=1&latest=1`}
                    title='Pippa player Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Pippa player */}
            {/* Start iframe for Arte Audioblog player */}
            {object.platform === 13 && (
                <iframe
                    src={`https://audioblog.arteradio.com/embed/${object.link_id}`}
                    title='Arte Audioblog Iframe'
                    style={{
                        border: 'none',
                        height: '100%',
                        margin: '0',
                        padding: '0',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Arte Audioblog player */}
            {/* Start iframe for Arte Radio player */}
            {object.platform === 14 && (
                <iframe
                    frameBorder='no'
                    scrolling='no'
                    src={`${object.link_url}?embed`}
                    title='Arte Radio Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Arte Radio player */}
            {/* Start iframe for France Culture player */}
            {object.platform === 15 && (
                <iframe
                    frameBorder='no'
                    scrolling='no'
                    src={object.link_id}
                    title='France Culture Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for France Culture player */}
            {/* Start iframe for Apple player */}
            {object.platform === 16 && (
                <iframe
                    allow='autoplay * encrypted-media *'
                    height='150'
                    sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation'
                    src={`https://embed.${object.link_id}`}
                    title='Apple Iframe'
                    style={{
                        background: 'transparent',
                        height: '100%',
                        overflow: 'hidden',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Apple player */}
            {/* Start iframe for France Inter player */}
            {object.platform === 18 && (
                <iframe
                    frameBorder='no'
                    scrolling='no'
                    src={object.link_id}
                    title='France Inter Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for France Inter player */}
            {/* Start iframe for France TV player */}
            {object.platform === 19 && (
                <iframe
                    frameBorder='0'
                    scrolling='no'
                    src={object.link_id}
                    title='France TV Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for France TV player */}
            {/* Start iframe for Anchor player */}
            {object.platform === 21 && (
                <iframe
                    frameBorder='0'
                    scrolling='no'
                    src={object.link_id}
                    title='Anchor Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Anchor player */}
            {/* Start iframe for Imagotv player */}
            {object.platform === 23 && (
                <iframe
                    frameBorder='0'
                    scrolling='no'
                    src={object.link_id}
                    title='Imagotv Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Imagotv player */}
            {/* Start iframe for Rumble player */}
            {object.platform === 24 && (
                <iframe
                    frameBorder='0'
                    scrolling='no'
                    src={object.link_id}
                    title='Rumble Iframe'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            )}
            {/* End iframe for Rumble player */}
            {/* Start iframe for Hearthis player */}
            {object.platform === 25 && (
                <iframe
                    allow='autoplay'
                    frameBorder='0'
                    scrolling='no'
                    src={`${object.link_id}transparent_black/?hcolor=&color=&style=2&block_size=2&block_space=1&background=1&waveform=0&cover=0&autoplay=0&css=%C2%AB`}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    title='Hearthis Iframe'
                />
            )}
            {/* End iframe for Hearthis player */}
            {/* Start iframe for TF1 player */}
            {object.platform === 26 && (
                <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    frameBorder='0'
                    src={object.link_id}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    title='TF1 Iframe'
                />
            )}
            {/* End iframe for TF1 player */}
            {/* Start iframe for Kuula player */}
            {object.platform === 27 && (
                <iframe
                    allow='xr-spatial-tracking; gyroscope; accelerometer'
                    allowFullScreen
                    frameBorder='0'
                    scrolling='no'
                    src={object.link_id}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    title='Kuula Iframe'
                />
            )}
            {/* End iframe for Kuula player */}
            {/* Start iframe for Matterport player */}
            {object.platform === 28 && (
                <iframe
                    frameBorder='0'
                    src={object.link_id}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    title='Matterport Iframe'
                />
            )}
            {/* End iframe for Matterport player */}
            {/* Start iframe for VideoAsk player */}
            {object.platform === 29 && (
                <iframe
                    allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                    frameBorder='0'
                    src={object.link_id}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    title='VideoAsk Iframe'
                />
            )}
            {/* End iframe for VideoAsk player */}
            {/* Start iframe for others */}
            {!otherPlayers.includes(object.platform || 0) && object.link_url && (
                <ReactPlayer
                    controls={controls}
                    height='100%'
                    loop={loop}
                    muted={muted}
                    onError={(e: any) => onError(e)}
                    playing={playing}
                    playsinline={playsinline}
                    ref={contentRef}
                    url={(object.platform && [17, 20].includes(object.platform)) ? object.link_id : (object.platform === 1 ? `${object.link_url}&vq=hd1080` : object.link_url)}
                    width='100%'
                    config={{
                        // facebook: { facebookConfig },
                        soundcloud: soundcloudConfig,
                        vimeo: vimeoConfig,
                        youtube: youtubeConfig,
                    }}
                />
            )}
            {/* End iframe for others */}
        </div>
    )
})
