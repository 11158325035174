// react components
import React from 'react'
import {
    IonModal,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxModalSliderDetailShowHideSite,
} from 'data'

// pages
import {
    DetailModalSite,
} from 'pages'

// services
import {
    parseQuerySite,
} from 'services'

// main
export const DetailModalWrapperSite: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const searchParams = parseQuerySite(location.search)
    const modal_page = searchParams.modal_page
    const modal_detail_id = searchParams.modal_detail_id

    return (
        <IonModal
            backdropDismiss={false}
            // @ts-ignore
            cssClass='detail-modal-wrapper-site'
            isOpen={Boolean(reduxModalSite.isOpen && modal_page)}
            onDidDismiss={() => dispatch(reduxModalSliderDetailShowHideSite(false))}
        >
            {reduxModalSite.isOpen && modal_page && (
                <DetailModalSite
                    detailId={modal_detail_id || undefined}
                    pageSlug={modal_page}
                />
            )}
        </IonModal>
    )
}
