// react components
import React from 'react'
import {
    IonPage,
} from '@ionic/react'

// components
import {
    AlertSite,
} from 'components'

// data
import {
    MainReduxContainerSite,
    reduxAuthStateSite,
    reduxModalStateSite,
    view_url_portfolio_home_site,
} from 'data'

// services
import {
    axiosErrorHandlerSiteErrorBoundarySite,
} from 'services'

// props
type ErrorBoundarySiteProps = {
    reduxAuth: reduxAuthStateSite
    reduxModalSite: reduxModalStateSite
    children: React.ReactNode
}

type ErrorBoundarySiteState = {
    errorInfo: any
}

// main
class ErrorBoundarySiteToConnect extends React.Component<ErrorBoundarySiteProps, ErrorBoundarySiteState> {

    componentDidCatch(error: any, errorInfo: any) {
        if (process.env.NODE_ENV === 'development') console.log(error, errorInfo)
        // Catch errors in any components below and re-render with error message
        this.setState({
            errorInfo: errorInfo,
        })
        // You can also log error messages to an error reporting service here
        axiosErrorHandlerSiteErrorBoundarySite(
            `${error.toString()} - ${errorInfo.componentStack}`,
            this.props.reduxAuth,
            this.props.reduxModalSite,
            'ErrorBoundarySiteToConnect',
            '',
        )
    }

    render() {
        if (this.state && this.state.errorInfo) {
            return (
                <IonPage className='mo-error-boundary-site'>
                    {/* TO_LATER_TEXT */}
                    <a href={view_url_portfolio_home_site} className='eb-go-home'>Go back to home page</a>
                    <AlertSite
                        buttons={[{ text: 'OK' }]}
                        header='Oops! Something went wrong'
                        isErrorAlert
                        isOpen
                        onDidDismiss={() => process.env.NODE_ENV === 'development' && console.log('closing ErrorBoundary')}
                    />
                </IonPage>
            )
        }
        return (this.props.children)
    }
}

export const ErrorBoundarySite = MainReduxContainerSite(ErrorBoundarySiteToConnect)
