// react components
import React, {
    useEffect,
    useRef,
} from 'react'
import L from 'leaflet'
import {
    FeatureGroup,
    Map,
    TileLayer,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    leaflet_attribution_mapbox_site,
    leaflet_url_mapbox_dark_site,
    leaflet_url_mapbox_light_site,
    leaflet_url_mapbox_outdoors_site,
    leaflet_url_mapbox_satellite_site,
    leaflet_url_mapbox_streets_site,
    media_url_site,
    media_version_site,
    reduxModalErrorEventHandlerSite,
} from 'data'

// props
type TemplateBlock799LeafletSiteProps = {
    center: {
        lat: number
        lng: number
    }
    icon: string | undefined
    markers?: {
        extraInfo?: any
        lat?: number
        lng?: number
    }[]
    onMarkerClick: (e: any) => void
    stylesMap: {
        mapProvider: string | undefined;
        mapStyles: string | undefined;
        mapZoom: string | undefined;
    } | undefined
    zoom: number | undefined
}

// main
export const TemplateBlock799LeafletSite: React.FC<TemplateBlock799LeafletSiteProps> = React.memo(({
    center,
    icon,
    markers,
    onMarkerClick,
    stylesMap,
    zoom,
}) => {

    // consts
    const mapStyles: any = {
        'leaflet-dark': leaflet_url_mapbox_dark_site,
        'leaflet-light': leaflet_url_mapbox_light_site,
        'leaflet-outdoor': leaflet_url_mapbox_outdoors_site,
        'leaflet-satellite': leaflet_url_mapbox_satellite_site,
        'leaflet-streets': leaflet_url_mapbox_streets_site,
    }

    const dispatch = useDispatch()

    const clusterRef = useRef(null)
    const groupRef = useRef<FeatureGroup>(null)
    const mapRef = useRef<Map>(null)

    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    useEffect(() => {
        addMarkers(markers)
    }, [
        markers,
    ])

    const customMarker = L.icon({
        iconUrl: icon || `${media_url_site}global/map/marker.svg${media_version_site}`,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
    })

    function addMarkers(data: {
        extraInfo?: any
        lat?: number
        lng?: number
    }[] | undefined) {
        try {
            if (clusterRef?.current) {
                // @ts-ignore
                clusterRef.current?.leafletElement.clearLayers()
            }
            const markers = L.markerClusterGroup()
            let hasMarkers = false
            data?.map((val) => {
                if (val.lat && val.lng) {
                    hasMarkers = true
                    let customMarker2 = undefined
                    if (val.extraInfo?.icon) {
                        customMarker2 = L.icon({
                            iconUrl: val.extraInfo.icon,
                            iconSize: [30, 30],
                            iconAnchor: [15, 30],
                        })
                    }
                    const marker = L.marker(
                        [val.lat, val.lng],
                        {
                            icon: customMarker2 || customMarker,
                        },
                    ).on(
                        'click',
                        () => onMarkerClick(val),
                    )
                    // @ts-ignore
                    marker.mo_id = val.parent
                    markers.addLayer(marker)
                }
                return false
            })
            // @ts-ignore
            clusterRef?.current?.leafletElement?.addLayers(markers)
            if (hasMarkers) handleFitBounds(data)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799LeafletSite',
                'addMarkers',
            ))
        }
    }

    function handleFitBounds(data: {
        extraInfo?: any
        lat?: number
        lng?: number
    }[] | undefined) {
        try {
            if (data?.length! > 0) {
                const map = mapRef?.current?.leafletElement
                const group = groupRef?.current?.leafletElement
                if (group) map?.fitBounds(group?.getBounds())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799LeafletSite',
                'handleFitBounds',
            ))
        }
    }

    return (
        <Map
            boxZoom
            center={center}
            minZoom={1}
            preferCanvas
            ref={mapRef}
            scrollWheelZoom={false}
            zoom={zoom}
            zoomControl={deviceType === 'is-web'}
        >
            <TileLayer
                attribution={leaflet_attribution_mapbox_site}
                tileSize={512}
                url={mapStyles[stylesMap?.mapStyles!] || leaflet_url_mapbox_light_site}
                zoomOffset={-1}
            />
            <FeatureGroup ref={groupRef}>
                <MarkerClusterGroup
                    chunkedLoading
                    maxClusterRadius={40}
                    onMarkerClick={onMarkerClick}
                    ref={clusterRef}
                    removeOutsideVisibleBounds
                />
            </FeatureGroup>
        </Map>
    )
})

// //Example values of min & max latlng values
// var lat_min = 1.3049337
// var lat_max = 1.3053515
// var lng_min = 103.2103116
// var lng_max = 103.8400188

// map.setCenter(new google.maps.LatLng(
//   ((lat_max + lat_min) / 2.0),
//   ((lng_max + lng_min) / 2.0)
// ))
// map.fitBounds(new google.maps.LatLngBounds(
//   //bottom left
//   new google.maps.LatLng(lat_min, lng_min),
//   //top right
//   new google.maps.LatLng(lat_max, lng_max)
// ))
