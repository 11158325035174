// react components
import React, {
    useState,
} from 'react'
import {
    Elements,
} from '@stripe/react-stripe-js'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    BillingHelper816Site,
    EditBlockSite,
    ParticipantHelper816Site,
    ReviewHelper816Site,
    StripeHelper816Site,
    VariantHelper816Site,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template816SiteSerializer,
} from 'serializers/site'

// props
type Sales816SiteProps = {
    blockId: string
    content: Template816SiteSerializer | undefined
    isEditHovered: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: any
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const Sales816Site: React.FC<Sales816SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [step, setStep] = useState<number>(1)

    const deviceType = reduxModalSite.deviceType

    if (!content) return null

    let isNextValid = false
    if (step === 1) {
        if (reduxSalesSite.ticketData?.variants && Object.values(reduxSalesSite.ticketData.variants).filter(obj => obj.qty > 0).length > 0) {
            isNextValid = true
        }
    } else if (step === 2) {
        if (content.form_fields?.length! > 0) {
            const filteredFields = content.form_fields?.filter(field => field.required === true)
            let totalParticipants = 0
            const fieldCounts: { [key: string]: number } = {}
            filteredFields?.forEach(field => {
                fieldCounts[field.name!] = 0
            })
            reduxSalesSite.ticketData?.variants && Object.values(reduxSalesSite.ticketData.variants).filter(obj => obj.qty > 0).map((val) => {
                [...Array(val.qty)].map((_) => {
                    totalParticipants += 1
                })
                val.participants && Object.values(val.participants).map((val2) => {
                    filteredFields?.forEach(field => {
                        const fieldName = field.name
                        if (val2[fieldName!]) {
                            fieldCounts[fieldName!] += 1
                        }
                    })
                })
            })
            const totalFilledFields = Object.values(fieldCounts).reduce(
                (acc, count) => acc + count,
                0
            )
            isNextValid = totalParticipants * filteredFields?.length! === totalFilledFields
        } else {
            let totalParticipants = 0
            let totalParticipantsfirst_name = 0
            let totalParticipantslast_name = 0
            reduxSalesSite.ticketData?.variants && Object.values(reduxSalesSite.ticketData.variants).filter(obj => obj.qty > 0).map((val) => {
                [...Array(val.qty)].map((_) => {
                    totalParticipants += 1
                })
                val.participants && Object.values(val.participants).map((val2) => {
                    if (val2.first_name) totalParticipantsfirst_name += 1
                    if (val2.last_name) totalParticipantslast_name += 1
                })
            })
            isNextValid = (totalParticipants * 2) === (totalParticipantsfirst_name + totalParticipantslast_name)
        }
    } else if (step === 3) {
        if (object.id === 308983) {  // TODO dynamic
            isNextValid = Boolean(reduxSalesSite.ticketData?.contact_info?.email && reduxSalesSite.ticketData?.contact_info?.first_name && reduxSalesSite.ticketData?.contact_info?.last_name && reduxSalesSite.ticketData?.contact_info?.agree)
        } else {
            isNextValid = Boolean(reduxSalesSite.ticketData?.contact_info?.email && reduxSalesSite.ticketData?.contact_info?.first_name && reduxSalesSite.ticketData?.contact_info?.last_name)
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-816${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
        >
            <div
                id='anchorToTickets'
                className={`t-816-block ${deviceType}`}
            >
                <div className={`t-816-navsteps ${deviceType}`}>
                    <button
                        className={`t-816-navstep${step === 1 ? ' current' : ''} ${deviceType}`}
                        onClick={() => setStep(1)}
                        type='button'
                    >
                        <i className={`t-816-icon mo-new-icon-layout-792 ${deviceType}`} />
                        <span className={`t-816-navtext ${deviceType}`}>{reduxText[8735]}</span>
                    </button>
                    <button
                        className={`t-816-navstep${step === 2 ? ' current' : ''} ${deviceType}`}
                        disabled={step < 2}
                        onClick={() => setStep(2)}
                        type='button'
                    >
                        <i className={`t-816-icon mo-new-icon-user-solid ${deviceType}`} />
                        <span className={`t-816-navtext ${deviceType}`}>{reduxText[8736]}</span>
                    </button>
                    <button
                        className={`t-816-navstep${step === 3 ? ' current' : ''} ${deviceType}`}
                        disabled={step < 3}
                        onClick={() => setStep(3)}
                        type='button'
                    >
                        <i className={`t-816-icon mo-new-icon-list-ul-solid ${deviceType}`} />
                        <span className={`t-816-navtext ${deviceType}`}>{reduxText[8737]}</span>
                    </button>
                    <button
                        className={`t-816-navstep${step === 4 ? ' current' : ''} ${deviceType}`}
                        disabled={step < 4}
                        type='button'
                    >
                        <i className={`t-816-icon mo-new-icon-check-circle-solid ${deviceType}`} />
                        <span className={`t-816-navtext ${deviceType}`}>{reduxText[8738]}</span>
                    </button>
                </div>
                {step === 1 && (
                    <div className={`t-816-step1 ${deviceType}`}>
                        <div className={`t-816-variants ${deviceType}`}>
                            {content?.variants?.map((val, i) => (
                                <VariantHelper816Site
                                    key={val.id}
                                    index={i}
                                    object={val}
                                    product={content}
                                />
                            ))}
                        </div>
                        <div className={`t-816-total ${deviceType}`}>
                            <span className={`t-816-total-text ${deviceType}`}>{reduxText[8739]}</span>
                            <span className={`t-816-total-amount ${deviceType}`}>{Number(reduxSalesSite.ticketData?.totalValue || 0)} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                        </div>
                    </div>
                )}
                {step === 2 && reduxSalesSite.ticketData?.variants && (
                    <div className={`t-816-step2 ${deviceType}`}>
                        {Object.values(reduxSalesSite.ticketData.variants).filter(obj => obj.qty > 0).map((val) => (
                            <div
                                key={val.variant.id}
                                className={`t-816-variant-wrap ${deviceType}`}
                            >
                                <span className={`t-816-variant-name ${deviceType}`}>{val.variant.name}</span>
                                {[...Array(val.qty)].map((_, index) => (
                                    <ParticipantHelper816Site
                                        key={index}
                                        content={content}
                                        index={index}
                                        object={val}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                )}
                {step === 3 && (
                    <div className={`t-816-step3 ${deviceType}`}>
                        <BillingHelper816Site objectId={object.id!} />
                    </div>
                )}
                {step === 4 && (
                    <div className={`t-816-step4 ${deviceType}`}>
                        <ReviewHelper816Site />
                        <div className={`t-816-total ${deviceType}`}>
                            <span className={`t-816-total-text ${deviceType}`}>{reduxText[8740]}</span>
                            <span className={`t-816-total-amount ${deviceType}`}>{Number(reduxSalesSite.ticketData?.totalValue || 0)} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className={`t-816-button-next-wrap ${deviceType}`}>
                {step > 1 && (
                    <LinkHelperSite
                        action={undefined}
                        className={`t-816-button-previous ${deviceType}`}
                        onClick={() => setStep(step - 1)}
                        to={undefined}
                    >
                        <i className={`t-816-icon mo-new-icon-chevron-left-solid ${deviceType}`} />
                        <span>{reduxText[8741]}</span>
                    </LinkHelperSite>
                )}
                {step < 4 && (
                    <LinkHelperSite
                        action={undefined}
                        className={`t-816-button-next${isNextValid ? '' : ' disabled'} ${deviceType}`}
                        onClick={() => setStep(step + 1)}
                        to={undefined}
                    >
                        <span>{reduxText[8742]}</span>
                        <i className={`t-816-icon mo-new-icon-chevron-right-solid ${deviceType}`} />
                    </LinkHelperSite>
                )}
                {step === 4 && (
                    <Elements stripe={reduxModalSite.stripePromise}>
                        <StripeHelper816Site />
                    </Elements>
                )}
            </div>
            <EditBlockSite
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
