// react components
import React, {
    useState,
} from 'react'
import {
    useStripe,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LoaderSite,
} from 'components'

// data
import {
    api_url_portfolio_sales_stripe_create,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// props
type StripeHelper816SiteProps = {
    setMainErrors?: React.Dispatch<any>
}

// main
export const StripeHelper816Site: React.FC<StripeHelper816SiteProps> = React.memo(({
    setMainErrors,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)
    const stripe = useStripe()

    const deviceType = reduxModalSite.deviceType
    const contact_info = reduxSalesSite.ticketData?.contact_info

    const [isLoading, setIsLoading] = useState(false)

    function handleStripe() {
        try {
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) handleStripe')
            const sales_order_lines: any = []
            reduxSalesSite.ticketData?.variants && Object.values(reduxSalesSite.ticketData.variants).filter(obj => obj.qty > 0).map((val) => {
                [...Array(val.qty)].map((_, index) => {
                    sales_order_lines.push({
                        package_option_items: [],
                        extra_data_json: val.participants?.[index],
                        product: reduxSalesSite.ticketData?.product?.id,
                        product_variant: val.variant.id,
                        quantity: 1,
                        unit_price: val.variant.unit_price,
                    })
                })
            })
            const formData = {
                billing_first_name: contact_info?.first_name || '',
                billing_last_name: contact_info?.last_name || '',
                customer_email: contact_info?.email || '',
                customer_first_name: contact_info?.first_name || '',
                customer_last_name: contact_info?.last_name || '',
                portfolio: reduxCacheSite.portfolio?.id,
                sales_order_lines: sales_order_lines,
            }
            let postUrl = getApiUrlSite(api_url_portfolio_sales_stripe_create, reduxModalSite)
            postUrl += `?is_service=true`
            if (reduxModalSite.currencyId) {
                postUrl += `&currency_id=${reduxModalSite.currencyId}`
            }
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleStripe')
                    stripe!.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: response.data.stripe_session_id,
                    }).then((result: any) => {
                        if (process.env.NODE_ENV === 'development') console.log(result)
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                    })
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleStripe')
                    // setMainErrors(error?.response?.data)
                    // thisState.setState({
                    // 	tabValue: 'payment-error',  // TODO
                    // })
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'StripeHelper816Site',
                        dispatch,
                        error,
                        formFields: JSON.stringify(formData),
                        reduxAuth,
                        reduxModalSite,
                        reference: 'handleStripe',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'StripeHelper816Site',
                'handleStripe',
            ))
        }
    }

    if (Number(reduxSalesSite.ticketData?.totalValue) === 0) {
        return (
            <React.Fragment>
                <button
                    className={`t-816-button-next disabled ${deviceType}`}
                    disabled
                >
                    <span>{reduxText[7466]}</span>
                </button>
                {isLoading && (
                    <LoaderSite isOpen />
                )}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <button
                className={`t-816-button-next ${deviceType}`}
                onClick={handleStripe}
            >
                <span>Valider et payer</span>
                <i className={`t-816-icon mo-new-icon-chevron-right-solid ${deviceType}`} />
            </button>
            {isLoading && (
                <LoaderSite isOpen />
            )}
        </React.Fragment>
    )
})
