// react components
import React, {
    useEffect,
} from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useLocation
} from 'react-router-dom'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const MatomoTrackerSite: React.FC = () => {

    const location = useLocation()
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)

    useEffect(() => {
        if (reduxCacheSiteportfolioid === 630) {  // TODO dynamic

            // Ensure Matomo is defined on the window object
            const _paq = (window as any)._paq = (window as any)._paq || [];
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);

            // Dynamically insert the Matomo script
            (function () {
                const u = "//kifkif.org/analytics/";
                _paq.push(['setTrackerUrl', u + 'matomo.php']);
                _paq.push(['setSiteId', '2']);
                const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
                g.async = true; g.src = u + 'matomo.js'; s.parentNode?.insertBefore(g, s);
            })();

            return () => {
                (window as any)._paq = []
            };
        }
    }, [
        location,
        reduxCacheSiteportfolioid,
    ])

    return null
}
