// react components
import React, {
    useEffect,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const SlaaskSite: React.FC = () => {

    const reduxModalSiteslaaskApiKey = useSelector((state: defaultReduxState) => state.reduxModalSite.slaaskApiKey)

    if (reduxModalSiteslaaskApiKey) {
        return <SlaaskHelper />
    }
    return null

}


const SlaaskHelper: React.FC = () => {

    const reduxModalSiteslaaskApiKey = useSelector((state: defaultReduxState) => state.reduxModalSite.slaaskApiKey)

    useEffect(() => {

        const script = document.createElement('script')
        script.src = 'https://cdn.slaask.com/chat.js'

        // default async to true if not set with custom attributes
        if (!script.hasAttribute('async')) {
            // @ts-ignore
            script.async = 1
        }

        /* eslint-disable */
        script.onload = () => {
            // @ts-ignore
            _slaask.init(reduxModalSiteslaaskApiKey)
            // _slaask.show()
        }
        /* eslint-enable */

        script.onerror = (e) => {
            if (process.env.NODE_ENV === 'development') console.log(e)
        }

        document.body.appendChild(script)
    }, [])

    return null
}
