import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import Photo, { photoPropType } from './Photo';
import { computeColumnLayout } from './layouts/columns';
import { computeRowLayout } from './layouts/justified';
import { findIdealNodeSearch } from './utils/findIdealNodeSearch';

export const Gallery = React.memo(function Gallery({
    photos,
    onClick,
    direction,
    margin,
    limitNodeSearch,
    targetRowHeight,
    columns,
    renderImage,
}) {
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerWidthInit, setIsContainerWidthInit] = useState(false);
    const galleryEl = useRef(null);

    useLayoutEffect(() => {
        let animationFrameID = null;
        const observer = new ResizeObserver(entries => {
            // only do something if width changes
            const newWidth = entries[0].contentRect.width;
            if (containerWidth !== newWidth) {
                // put in an animation frame to stop "benign errors" from
                // ResizObserver https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
                animationFrameID = window.requestAnimationFrame(() => {
                    setContainerWidth(Math.floor(newWidth));
                    if (newWidth > 0) setIsContainerWidthInit(true)
                });
            }
        });
        observer.observe(galleryEl.current);
        return () => {
            observer.disconnect();
            window.cancelAnimationFrame(animationFrameID);
        };
    });

    const handleClick = (event, { index }) => {
        onClick(event, {
            index,
            photo: photos[index],
            previous: photos[index - 1] || null,
            next: photos[index + 1] || null,
        });
    };

    // no containerWidth until after first render with refs, skip calculations and render nothing
    if (!containerWidth && !isContainerWidthInit) return <div ref={galleryEl}>&nbsp;</div>;  // TODO for image click and go to next page
    // subtract 1 pixel because the browser may round up a pixel
    const width = containerWidth - 1;
    let galleryStyle, thumbs;

    if (direction === 'row') {
        // allow user to calculate limitNodeSearch from containerWidth
        if (typeof limitNodeSearch === 'function') {
            limitNodeSearch = limitNodeSearch(containerWidth);
        }
        if (typeof targetRowHeight === 'function') {
            targetRowHeight = targetRowHeight(containerWidth);
        }
        // set how many neighboring nodes the graph will visit
        if (limitNodeSearch === undefined) {
            limitNodeSearch = 2;
            if (containerWidth >= 450) {
                limitNodeSearch = findIdealNodeSearch({ containerWidth, targetRowHeight });
            }
        }

        galleryStyle = { display: 'flex', flexWrap: 'wrap', flexDirection: 'row' };
        thumbs = computeRowLayout({ containerWidth: width, limitNodeSearch, targetRowHeight, margin, photos });
    }
    if (direction === 'column') {
        // allow user to calculate columns from containerWidth
        if (typeof columns === 'function') {
            columns = columns(containerWidth);
        }
        // set default breakpoints if user doesn't specify columns prop
        if (columns === undefined) {
            columns = 1;
            if (containerWidth >= 500) columns = 2;
            if (containerWidth >= 900) columns = 3;
            if (containerWidth >= 1500) columns = 4;
        }
        galleryStyle = { position: 'relative' };
        thumbs = computeColumnLayout({ containerWidth: width, columns, margin, photos });
        galleryStyle.height = thumbs[thumbs.length - 1].containerHeight;
    }

    const renderComponent = renderImage || Photo;
    return (
        <div className="react-photo-gallery--gallery">
            <div ref={galleryEl} style={galleryStyle}>
                {thumbs.map((thumb, index) => {
                    const { left, top, containerHeight, ...photo } = thumb;
                    return renderComponent({
                        left,
                        top,
                        key: thumb.key || thumb.src,
                        containerHeight,
                        index,
                        margin,
                        direction,
                        onClick: onClick ? handleClick : null,
                        photo,
                    });
                })}
            </div>
        </div>
    );
});

Gallery.propTypes = {
    photos: PropTypes.arrayOf(photoPropType).isRequired,
    direction: PropTypes.string,
    onClick: PropTypes.func,
    columns: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
    targetRowHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
    limitNodeSearch: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
    margin: PropTypes.number,
    renderImage: PropTypes.func,
};

Gallery.defaultProps = {
    margin: 2,
    direction: 'row',
    targetRowHeight: 300,
};
export { Photo };
