// react components
import axios from 'axios'

// data
import {
    api_url_portfolio_sales_detail,
    reduxAuthStateSite,
    reduxModalStateSite,
    reduxSalesStateSite,
} from 'data'

// serializers
import {
    PortfolioDetailSiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
} from 'services'

// constants
export const REDUX_SALES_ACTIVITY_INSTALLMENT_SITE = 'REDUX_SALES_ACTIVITY_INSTALLMENT_SITE'
export const REDUX_SALES_ACTIVITY_PAYMENT_METHOD_SITE = 'REDUX_SALES_ACTIVITY_PAYMENT_METHOD_SITE'
export const REDUX_SALES_ACTIVITY_RESET_SITE = 'REDUX_SALES_ACTIVITY_RESET_SITE'
export const REDUX_SALES_ACTIVITY_SITE = 'REDUX_SALES_ACTIVITY_SITE'
export const REDUX_SALES_ADD_TO_CART_SITE = 'REDUX_SALES_ADD_TO_CART_SITE'
export const REDUX_SALES_CANCEL_SITE = 'REDUX_SALES_CANCEL_SITE'
export const REDUX_SALES_CART_TOGGLE_SITE = 'REDUX_SALES_CART_TOGGLE_SITE'
export const REDUX_SALES_REMOVE_FROM_CART_SITE = 'REDUX_SALES_REMOVE_FROM_CART_SITE'
export const REDUX_SALES_RESET_SITE = 'REDUX_SALES_RESET_SITE'
export const REDUX_SALES_SET_INFO_SITE = 'REDUX_SALES_SET_INFO_SITE'
export const REDUX_SALES_SHIPPING_COST_SITE = 'REDUX_SALES_SHIPPING_COST_SITE'
export const REDUX_SALES_SUCCESS_SITE = 'REDUX_SALES_SUCCESS_SITE'
export const REDUX_SALES_TICKET_SITE = 'REDUX_SALES_TICKET_SITE'

// actions
export function reduxSalesActivityResetSite() {
    return {
        type: REDUX_SALES_ACTIVITY_RESET_SITE,
    }
}

export function reduxSalesActivityInstallmentSite(payload: boolean) {
    return {
        type: REDUX_SALES_ACTIVITY_INSTALLMENT_SITE,
        payload,
    }
}

export function reduxSalesActivityPaymentMethodSite(payload: string) {
    return {
        type: REDUX_SALES_ACTIVITY_PAYMENT_METHOD_SITE,
        payload,
    }
}

export function reduxSalesActivitySite(payload: reduxSalesStateSite['activityData']) {
    return {
        type: REDUX_SALES_ACTIVITY_SITE,
        payload: payload,
    }
}

export function reduxSalesAddToCartSite(
    qty: number,
    unitPrice: any,
    product: any,
    variant: any,
    absolute_url: any,
    portfolioObject: PortfolioDetailSiteSerializer,
    packageOptions?: any[],
    currency?: number
) {
    return {
        type: REDUX_SALES_ADD_TO_CART_SITE,
        absolute_url: absolute_url,
        boolean: true,
        currency: currency,
        packageOptions: packageOptions,
        portfolioObject: portfolioObject,
        product: product,
        qty: qty,
        unitPrice: unitPrice,
        variant: variant,
    }
}

export function reduxSalesCancelSite() {
    return {
        type: REDUX_SALES_CANCEL_SITE,
    }
}

export function reduxSalesCartToggleSite(boolean: boolean) {
    return {
        type: REDUX_SALES_CART_TOGGLE_SITE,
        boolean: boolean,
    }
}

export function reduxSalesRemoveFromCartSite(
    index: number,
    portfolioObject: PortfolioDetailSiteSerializer,
) {
    return {
        type: REDUX_SALES_REMOVE_FROM_CART_SITE,
        index: index,
        portfolioObject: portfolioObject,
    }
}

export function reduxSalesResetSite() {
    return {
        type: REDUX_SALES_RESET_SITE,
    }
}

export function reduxSalesSetInfoSite(billing_info: any, contact_info: any, shipping_info: any) {
    return {
        type: REDUX_SALES_SET_INFO_SITE,
        billing_info: billing_info,
        contact_info: contact_info,
        shipping_info: shipping_info,
    }
}

export function reduxSalesSuccessSite(transactionId: string, reduxModalSite: reduxModalStateSite, reduxAuth: reduxAuthStateSite) {
    return function (dispatch: any) {
        const getUrl = getApiUrlSite(`${api_url_portfolio_sales_detail}${transactionId}/`, reduxModalSite) // Keep the last '/' for Safari !!!!!!!
        axios.get(getUrl)
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                dispatch({
                    type: REDUX_SALES_SUCCESS_SITE,
                    payload: response.data,
                })
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') console.log(error)
                axiosErrorHandlerSite({
                    apiUrl: getUrl,
                    component: 'reduxSalesSuccessSite',
                    dispatch,
                    error,
                    reduxAuth,
                    reduxModalSite,
                    reference: 'reduxSalesSuccessSite',
                })
            })
    }
}

export function reduxSalesShippingCostSite(cost: number, service: number) {
    return {
        type: REDUX_SALES_SHIPPING_COST_SITE,
        payload: {
            shippingService: service,
            shippingTotal: cost,
        },
    }
}

export function reduxSalesTicketSite(payload: reduxSalesStateSite['ticketData']) {
    return {
        type: REDUX_SALES_TICKET_SITE,
        payload: payload,
    }
}
