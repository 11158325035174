// react components
import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    errorMessagesSite,
    languageIdObjectSite,
    reduxModalErrorEventHandlerSite,
    reduxModalErrorShowHideSite,
} from 'data'

// main
export const ErrorAlertSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    function onAlertDismiss() {
        try {
            dispatch(reduxModalErrorShowHideSite({ isOpen: false }))
        } catch (error) {
            dispatch(reduxModalErrorShowHideSite({ isOpen: false }))
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ErrorAlertSite',
                'onAlertDismiss',
            ))
        }
    }

    if (reduxModalSite.error.isOpen) {

        const currentLanguage = errorMessagesSite[languageIdObjectSite[reduxModalSite.languageId] || errorMessagesSite['en']]

        return (
            <Dialog
                className='mo-dialog-class-site'
                classes={{ paper: 'mo-dialog-wrapper fix-width' }}
                onClose={onAlertDismiss}
                open
            >
                <DialogTitle className='mo-dialog-header'>
                    {reduxModalSite.error.header}{reduxModalSite.error.subHeader ? ` - ${reduxModalSite.error.subHeader}` : ''}
                    <div className='mo-dialog-header-close-wrap'>
                        <button
                            className='mo-dialog-header-close'
                            onClick={onAlertDismiss}
                        >
                            <i className='main-icon mo-new-icon-times-solid' />
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent className='mo-dialog-content'>
                    {reduxModalSite.error.message && (
                        <>
                            <p className='pre-line'>{reduxModalSite.error.message}</p>
                            <br />
                        </>
                    )}
                    <p className='pre-line'>
                        {!reduxModalSite.error.noMessage && (
                            <>
                                <p>{currentLanguage.title}</p>
                                <ul>
                                    <li>{currentLanguage.steps[0]}</li>
                                    {reduxAuth?.authenticated && (
                                        <li>{currentLanguage.steps[1]}</li>
                                    )}
                                    <li>{currentLanguage.steps[2]}</li>
                                </ul>
                            </>
                        )}
                    </p>
                </DialogContent>
                <DialogActions className='mo-dialog-footer'>
                    <Button
                        onClick={onAlertDismiss}
                        size='small'
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return null
}
