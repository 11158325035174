// react components
import React, {
    useEffect,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesTicketSite,
} from 'data'

// pages
import {
    CheckboxInput816Site,
    TextInput816Site,
} from 'pages'

// props
type BillingHelper816SiteProps = {
    objectId: number
    test?: string
}

// main
export const BillingHelper816Site: React.FC<BillingHelper816SiteProps> = React.memo(({
    objectId,
    test,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const agree = reduxSalesSite.ticketData?.contact_info?.agree || ''
    const email = reduxSalesSite.ticketData?.contact_info?.email || ''
    const first_name = reduxSalesSite.ticketData?.contact_info?.first_name || ''
    const last_name = reduxSalesSite.ticketData?.contact_info?.last_name || ''

    useEffect(() => {
        if (!reduxSalesSite.ticketData?.contact_info?.email) {
            dispatch(reduxSalesTicketSite({
                ...reduxSalesSite.ticketData,
                contact_info: {
                    ...reduxSalesSite.ticketData?.contact_info,
                    email: reduxAuth?.settings?.activity_parent?.email || '',
                    first_name: reduxAuth?.settings?.activity_parent?.first_name || '',
                    last_name: reduxAuth?.settings?.activity_parent?.last_name || '',
                }
            }))
        }
    }, [])

    function handleInputChange(e: any) {
        try {
            const toReturnData = {
                ...reduxSalesSite.ticketData,
                contact_info: {
                    ...reduxSalesSite.ticketData?.contact_info,
                    [e.name]: e.value,
                },
            }
            // @ts-ignore
            dispatch(reduxSalesTicketSite(toReturnData))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'BillingHelper816Site',
                'handleInputChange',
            ))
        }
    }

    return (
        <div className={`t-816-contact-info ${deviceType}`}>
            <span className={`t-816-contact-info-title ${deviceType}`}>{reduxText[8743]}</span>
            <TextInput816Site
                error={undefined}
                label={reduxText[8744]}
                name='first_name'
                onChange={handleInputChange}
                value={first_name}
            />
            <TextInput816Site
                error={undefined}
                label={reduxText[8745]}
                name='last_name'
                onChange={handleInputChange}
                value={last_name}
            />
            <TextInput816Site
                error={undefined}
                label={reduxText[8746]}
                name='email'
                onChange={handleInputChange}
                value={email}
            />
            {/* TODO Dynamic */}
            {objectId === 308983 && (
                <CheckboxInput816Site
                    error={undefined}
                    label='Sortie de cours autorisé'
                    name='agree'
                    onChange={handleInputChange}
                    value={agree}
                >
                    <span>J'ai pris connaissance du </span>
                    <a
                        href={`${reduxModalSite.rootUrl || '/'}reglement-de-lassociation`}
                        rel='noopener noreferrer'
                        target='_blank'
                        style={{
                            textDecoration: 'underline',
                        }}

                    >
                        règlement de l'AGSL
                    </a>
                </CheckboxInput816Site>
            )}
        </div>
    )
})
