// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const ComingSoonSite: React.FC = () => {

    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)

    return (
        <IonPage>
            <IonContent>
                <div className='tt-coming-soon'>
                    <div className='tt-cs-wrap'>
                        {reduxCacheSite.portfolio?.get_image_mq && (
                            <ImageHelperSite
                                alt={reduxCacheSite.portfolio?.name}
                                className='tt-cs-image'
                                dominant_color={undefined}
                                src={reduxCacheSite.portfolio?.get_image_mq}
                            />
                        )}
                        <div className='tt-cs-text'>
                            <span>WEBSITE</span>
                            <span>COMING SOON</span>
                        </div>
                        <span className='tt-cs-name'>{reduxCacheSite.portfolio?.name}</span>
                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                            <span className='tt-cs-description'>{reduxCacheSite.portfolio?.description}</span>
                        </Linkify>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
