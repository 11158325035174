// main
export function removeParamsFromUrlSite(param: string) {
    if (window.location.search.indexOf(`${param}=`) !== -1) {
        let replace = ''
        try {
            const url = new URL(`${window.location}`)
            url.searchParams.delete(param)
            replace = url.href
        } catch (ex) {
            const regExp = new RegExp(`[?&]${param}=.*$`)
            replace = window.location.search.replace(regExp, '')
            replace = window.location.pathname + replace + window.location.hash
        }
        window.history.replaceState(null, '', replace)
    }
}
