export function setCookieSite({
    name,
    value,
}: {
    name: string
    value: any
}) {
    let value2 = value
    if (typeof value === 'object') {
        value2 = JSON.stringify(value)
    }
    try {
        localStorage.setItem(name, value2)
    } catch {
        return
    }
}
