// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesAddToCartSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template802SiteSerializer,
} from 'serializers/site'

// services
import {
    getTextSite,
    getStylesNew,
} from 'services'

// props
type TemplateBlock802SiteProps = {
    blockId: string
    content: Template802SiteSerializer | undefined
    isEditHovered: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: any
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock802Site: React.FC<TemplateBlock802SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    useEffect(() => {
        setQty(1)
        setVariant(content?.variants?.[0]?.id)
        setVariantObject(content?.variants?.[0])
    }, [
        content?.id,
    ])

    const [qty, setQty] = useState<number>(1)
    const [stockMaxValue, setStockMaxValue] = useState<number>()
    const [variant, setVariant] = useState<any>(content?.variants?.[0]?.id)
    const [variantObject, setVariantObject] = useState<any>(content?.variants?.[0])

    const [stylesNew, setStylesNew] = useState<{
        buttonStyle: CustomCSSProperties | undefined,
        hasHoverStyle: boolean,
        hoverStyleBackground: string | undefined,
        hoverStyleBorderColor: string | undefined,
        hoverStyleColor: string | undefined,
        qytInputStyle: CustomCSSProperties | undefined,
        showQty: 'true' | 'false' | undefined,
        variantStyle: CustomCSSProperties | undefined,
        // @ts-ignore
    }>(applyStyles())
    const [newStyles, setNewStyles] = useState<any>(stylesNew?.buttonStyle)

    useEffect(() => {
        const tempStyles = applyStyles()
        // @ts-ignore
        setStylesNew(tempStyles)
        setNewStyles(applyStyles()?.buttonStyle)
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const buttonStyle = getStylesNew(reduxModalSite, styles, stylesEdit)
            const hoverStyleBackground = buttonStyle?.hoverBackground
            const hoverStyleBorderColor = buttonStyle?.hoverBorderColor
            const hoverStyleColor = buttonStyle?.hoverColor
            const hasHoverStyle = Boolean(hoverStyleBackground || hoverStyleBorderColor || hoverStyleColor)
            return {
                buttonStyle: buttonStyle,
                hoverStyleBackground: hoverStyleBackground,
                hoverStyleBorderColor: hoverStyleBorderColor,
                hoverStyleColor: hoverStyleColor,
                hasHoverStyle: hasHoverStyle,
                qytInputStyle: {
                    background: buttonStyle?.qtyInputBackground,
                    borderColor: buttonStyle?.qtyInputBorderColor,
                    borderRadius: buttonStyle?.qtyInputBorderRadius,
                    borderStyle: buttonStyle?.qtyInputBorderStyle,
                    borderWidth: buttonStyle?.qtyInputBorderWidth,
                    color: buttonStyle?.qtyInputColor,
                    fontFamily: buttonStyle?.qtyInputFontFamily,
                    fontSize: buttonStyle?.qtyInputFontSize,
                    margin: buttonStyle?.qtyInputMargin,
                    padding: buttonStyle?.qtyInputPadding,
                    textAlign: buttonStyle?.qtyInputTextAlign,
                    width: buttonStyle?.qtyInputWidth,
                },
                showQty: buttonStyle?.qtyInputShow,
                variantStyle: {
                    background: buttonStyle?.variantBackground,
                    borderColor: buttonStyle?.variantBorderColor,
                    borderRadius: buttonStyle?.variantBorderRadius,
                    borderStyle: buttonStyle?.variantBorderStyle,
                    borderWidth: buttonStyle?.variantBorderWidth,
                    color: buttonStyle?.variantColor,
                    fontFamily: buttonStyle?.variantFontFamily,
                    fontSize: buttonStyle?.variantFontSize,
                    margin: buttonStyle?.variantMargin,
                    padding: buttonStyle?.variantPadding,
                    width: buttonStyle?.variantWidth,
                },
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock802Site',
                'applyStyles',
            ))
        }
    }

    useEffect(() => {
        if (variantObject) {
            if (!variantObject.is_qty_available) {
                setQty(1)
                setStockMaxValue(1)
            } else if (variantObject.has_stock_management) {
                if (qty > variantObject.stock) {
                    setQty(variantObject.stock)
                }
                setStockMaxValue(variantObject.stock)
            } else {
                setStockMaxValue(undefined)
            }
        } else if (content?.has_stock_management) {
            if (qty > (content?.stock || 0)) {
                setQty(content?.stock || 0)
            }
            setStockMaxValue(content?.stock)
        } else {
            setStockMaxValue(undefined)
        }
    }, [
        variantObject,
    ])

    function onAddToCart() {
        try {
            if (reduxCacheSite.portfolio) {
                dispatch(reduxSalesAddToCartSite(
                    qty,
                    variantObject?.unit_price || content?.unit_price,
                    content,
                    variantObject,
                    content?.absolute_site_url,
                    reduxCacheSite.portfolio,
                    undefined,
                    reduxModalSite.currencyId,
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock802Site',
                'onAddToCart',
            ))
        }
    }

    function onHover(direction: string) {
        try {
            if (stylesNew?.hasHoverStyle) {
                if (direction === 'over') {
                    if (stylesNew.buttonStyle) {
                        const newnewStyle = Object.assign({}, stylesNew.buttonStyle)
                        if (stylesNew.hoverStyleBackground) newnewStyle.background = stylesNew.hoverStyleBackground
                        if (stylesNew.hoverStyleBorderColor) newnewStyle.borderColor = stylesNew.hoverStyleBorderColor
                        if (stylesNew.hoverStyleColor) newnewStyle.color = stylesNew.hoverStyleColor
                        setNewStyles(newnewStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                } else {
                    if (stylesNew.buttonStyle) {
                        setNewStyles(stylesNew.buttonStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock802Site',
                'onHover',
            ))
        }
    }

    let variantStockOk = true
    if (content?.variants?.[0] && content.variants[0].stock_status?.id !== 1) {
        variantStockOk = false
    }
    let uniqueProductInCart = false
    if (content?.is_unique && reduxSalesSite.salesLines.find((line) => line.product.id === content?.id)) {
        uniqueProductInCart = true
    }

    return (
        <div
            id={blockId}
            className={`template-block-802${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
        >
            {content?.price_on_request && (
                <LinkHelperSite
                    action={undefined}
                    className={`t-802-button extra-button ${deviceType}`}
                    onClick={() => undefined}
                    style={newStyles}
                    to={undefined}
                >
                    {reduxText[8528]}
                </LinkHelperSite>
            )}
            {content?.has_sales_permission && content?.variants && content?.variants.length > 0 && (
                <select
                    className={`t-802-variants ${deviceType}`}
                    name='variant'
                    onChange={(e) => {
                        setVariant(e.target.value)
                        setVariantObject(content?.variants?.[content?.variants.findIndex((object) => object.id === Number(e.target.value))])
                    }}
                    style={stylesNew.variantStyle}
                    value={variant}
                >
                    {content?.variants.map((val) => (
                        <option
                            key={val.id}
                            disabled={val.stock_status?.id !== 1}
                            value={val.id}
                        >
                            {val.name}{' '}
                            ({val.price_on_request
                                ? (
                                    reduxText[7494]
                                ) : (
                                    `${val.unit_price} ${reduxCacheSite.portfolio?.currency?.symbol || reduxCacheSite.portfolio?.currency?.iso_code}${val.stock_status?.id !== 1 ? ` - ${val.stock_status?.name}` : ''}`
                                )})
                        </option>
                    ))}
                </select>
            )}
            {!content?.price_on_request && (variantStockOk && content?.stock_status?.id === 1) && content?.has_sales_permission && (
                <div className={`t-802-actions ${deviceType}`}>
                    {(stylesNew.showQty !== 'false' && content?.is_qty_available) && (
                        <input
                            max={stockMaxValue}
                            min={1}
                            name='qty'
                            onChange={(e) => setQty(Number(e.target.value) > 0 ? Number(e.target.value) : 1)}
                            style={stylesNew.qytInputStyle}
                            type='number'
                            value={qty.toString() || ''}
                        />
                    )}
                    <LinkHelperSite
                        action={undefined}
                        className={`t-802-button ${deviceType}`}
                        disabled={uniqueProductInCart}
                        onClick={() => onAddToCart()}
                        onCustomMouseLeave={() => onHover('leave')}
                        onCustomMouseOver={() => onHover('over')}
                        style={newStyles}
                        to={undefined}
                    >
                        {uniqueProductInCart ? reduxText[9675] : (!content?.text ? reduxText[8612] : getTextSite(content?.text, reduxModalSite, reduxText))}
                    </LinkHelperSite>
                </div>
            )}
            {!content?.has_sales_permission && content?.external_link && (
                <LinkHelperSite
                    action='href'
                    className={`t-802-button extra-button ${deviceType}`}
                    onClick={() => undefined}
                    onCustomMouseLeave={() => onHover('leave')}
                    onCustomMouseOver={() => onHover('over')}
                    style={newStyles}
                    to={content?.external_link}
                >
                    {content?.text2 || content?.external_link}
                </LinkHelperSite>
            )}
            {content?.stock_status && content.stock_status.id !== 1 && (
                <LinkHelperSite
                    action={undefined}
                    className={`t-802-button extra-button ${deviceType}`}
                    onClick={() => undefined}
                    onCustomMouseLeave={() => onHover('leave')}
                    onCustomMouseOver={() => onHover('over')}
                    style={newStyles}
                    to={undefined}
                >
                    {content.stock_status.name}
                </LinkHelperSite>
            )}
            <EditBlockSite
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
