// react components
import React, {
    // ComponentProps,
} from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    errorMessagesSite,
    languageIdObjectSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// props
type AlertSiteProps = {
    buttons: {
        // color?: ComponentProps<typeof Button>['color']
        color?: string
        text: string
        handler?: () => void
    }[]
    header: string
    isErrorAlert?: boolean
    isOpen: boolean
    message?: string | undefined
    onDidDismiss: () => void
}

// main
export const AlertSite: React.FC<AlertSiteProps> = React.memo(({
    buttons,
    header,
    isErrorAlert,
    isOpen,
    message,
    onDidDismiss,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    function onButtonClick(e?: any) {
        try {
            if (e) e()
            onDidDismiss()
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'AlertSite',
                'onButtonClick',
            ))
        }
    }

    function onClose() {
        try {
            onDidDismiss()
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'AlertSite',
                'onClose',
            ))
        }
    }

    if (isOpen) {

        const currentLanguage = errorMessagesSite[languageIdObjectSite[reduxModalSite.languageId] || errorMessagesSite['en']]

        return (
            <Dialog
                className='mo-dialog-class-site'
                classes={{ paper: 'mo-dialog-wrapper fix-width' }}
                onClose={onClose}
                open
            >
                <DialogTitle className='mo-dialog-header'>{header}</DialogTitle>
                <DialogContent className='mo-dialog-content'>
                    {isErrorAlert
                        ? (
                            <>
                                <p>{currentLanguage.title}</p>
                                <ul>
                                    <li>{currentLanguage.steps[0]}</li>
                                    {reduxAuth?.authenticated && (
                                        <li>{currentLanguage.steps[1]}</li>
                                    )}
                                    <li>{currentLanguage.steps[2]}</li>
                                </ul>
                            </>
                        ) : (
                            <p className='pre-line'>{message}</p>
                        )
                    }
                </DialogContent>
                <DialogActions className='mo-dialog-footer'>
                    {buttons.map((val, i) => (
                        <Button
                            key={i}
                            onClick={() => onButtonClick(val.handler)}
                        >
                            {val.text}
                        </Button>
                    ))}
                </DialogActions>
            </Dialog>
        )
    }

    return null
})
