// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    media_url_site,
    media_version_site,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
} from 'services'

// props
type MapCountry = {
    countryCode: string
    cursor: CustomCSSProperties['cursor']
    isCountryActive: boolean
    marker?: {
        extraInfo?: any
        lat?: number
        lng?: number
    }
    svg: {
        id: string
        ISO_A2: string
        fill: string,
        paths: {
            id: string
            d: string
        }[]
        stroke: string,
    }
}

type TemplateBlock799SvgSiteProps = {
    height: number
    markers?: {
        extraInfo?: any
        lat?: number
        lng?: number
    }[]
    object: PortfolioPageContentListSiteSerializer
    onMarkerClick?: (e: any) => void
    styles: CustomCSSProperties | undefined
    width: number
}

// main
export const TemplateBlock799SvgSite: React.FC<TemplateBlock799SvgSiteProps> = React.memo(({
    height,
    markers,
    object,
    onMarkerClick,
    styles,
    width,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [mapCountries, setMapCountries] = useState<MapCountry[]>([])
    const [geoJson, setGeoJson] = useState<{
        country: {
            id: string
            ISO_A2: string
            paths: {
                id: string
                d: string
            }[]
        }[]
        lakes: {
            d: string
        }[]
    }>()

    const defaultColor = '#ddd'

    useEffect(() => {
        getGeoJsonData()
    }, [])

    useEffect(() => {
        constructContries()
    }, [
        geoJson,
        height,
        markers,
        object.data_json?.use_countries_2,
        styles,
        width,
    ])

    function getGeoJsonData() {
        try {
            const apiUrl = `${media_url_site}global/data/svg-autha-graph.json${media_version_site}`
            axios({
                method: 'get',
                url: apiUrl,
            })
                .then((response) => {
                    setGeoJson(response.data)
                })
                .catch((error) => {
                    axiosErrorHandlerSite({
                        apiUrl: apiUrl,
                        component: 'TemplateBlock799SvgSite',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'getGeoJsonData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799SvgSite',
                'getGeoJsonData',
            ))
        }
    }

    function constructContries() {
        try {
            if (!markers || !geoJson) return

            if (object.data_json?.use_countries_2) {
                const activeCountriesGoupDict: any = {}
                markers.map(val => {
                    val.extraInfo?.countries_2?.map((val2: any) => {
                        activeCountriesGoupDict[val2.country] = val
                    })
                })

                const countries = geoJson.country.map((val) => {
                    let svgProps = {
                        fill: defaultColor,
                        paths: val.paths,
                        stroke: defaultColor,
                    }

                    let isCountryActive = false

                    if (activeCountriesGoupDict[val.ISO_A2!]) {
                        isCountryActive = true
                        svgProps = {
                            ...svgProps,
                            fill: styles?.svgFillColor || defaultColor,
                            stroke: styles?.svgStrokeColor || defaultColor,
                        }
                    }

                    return {
                        countryCode: val.ISO_A2,
                        cursor: 'initial',
                        isCountryActive,
                        marker: activeCountriesGoupDict[val.ISO_A2!],
                        svg: svgProps,
                    } as MapCountry
                })
                setMapCountries(countries || [])

            } else {
                const activeCountriesDict: { [key: string]: any } =
                    markers.reduce((a, c) => {
                        return {
                            ...a,
                            [c.extraInfo.address_json[0].countryCode]: c,
                        }
                    }, {})

                const countries = geoJson.country.map((val) => {
                    let svgProps = {
                        paths: val.paths,
                        stroke: defaultColor,
                        fill: defaultColor,
                    }

                    let isCountryActive = false

                    if (activeCountriesDict[val.ISO_A2!]) {
                        isCountryActive = true
                        svgProps = {
                            ...svgProps,
                            stroke: styles?.svgStrokeColor || defaultColor,
                            fill: styles?.svgFillColor || defaultColor,
                        }
                    }

                    return {
                        marker: activeCountriesDict[val.ISO_A2!],
                        countryCode: val.ISO_A2,
                        cursor: 'initial',
                        isCountryActive,
                        svg: svgProps,
                    } as MapCountry
                })
                setMapCountries(countries || [])
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799SvgSite',
                'constructContries',
            ))
        }
    }

    function handleMouseLeaveCountry(country: MapCountry) {
        try {
            setMapCountries(mapCountries
                .map(m => {
                    if (isMatchCountry(m, country)) {
                        return {
                            ...m,
                            cursor: 'initial',
                            svg: {
                                ...m.svg,
                                stroke: styles?.svgStrokeColor || defaultColor,
                                fill: styles?.svgFillColor || defaultColor,
                            }
                        }
                    }
                    return m
                }))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799SvgSite',
                'handleMouseOverCountry',
            ))
        }
    }

    function handleMouseOverCountry(country: MapCountry) {
        try {
            setMapCountries(mapCountries
                .map(m => {
                    if (isMatchCountry(m, country)) {
                        return {
                            ...m,
                            cursor: 'pointer',
                            svg: {
                                ...m.svg,
                                stroke: styles?.svgHoverStrokeColor || defaultColor,
                                fill: styles?.svgHoverFillColor || defaultColor,
                            }
                        }
                    }
                    return m
                }))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799SvgSite',
                'handleMouseOverCountry',
            ))
        }
    }

    function isMatchCountry(source: MapCountry, target: MapCountry) {
        try {
            if (object.data_json?.use_countries_2) {
                return source.isCountryActive
                    && target.isCountryActive
                    && source.marker?.extraInfo?.id === target.marker?.extraInfo?.id
            }
            return source.isCountryActive
                && target.isCountryActive
                && source.countryCode === target.countryCode
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799SvgSite',
                'handleMouseOverCountry',
            ))
        }
    }

    return (
        <svg
            className='t-799-svg-map'
            version='1.1'
            viewBox='0 0 360 155.885'
        >
            <g>
                <g className='country'>
                    {mapCountries.map((country, i) => {
                        return (
                            <g
                                key={i}
                                id={country.countryCode}
                                onClick={onMarkerClick ? () => onMarkerClick(country.marker) : undefined}
                                onMouseLeave={() => handleMouseLeaveCountry(country)}
                                onMouseOver={() => handleMouseOverCountry(country)}
                                style={{ cursor: country.cursor }}
                            >
                                {country.svg.paths.map(((path, j) => (
                                    <path
                                        key={j}
                                        id={path.id}
                                        d={path.d}
                                        fill={country.svg.fill}
                                        stroke={country.svg.stroke}
                                        strokeWidth={0.3}
                                    />
                                )))}
                            </g>
                        )
                    })}
                </g>
                <g className='lakes'>
                    {geoJson?.lakes?.map((lake, i) => {
                        return (
                            <path
                                key={i}
                                d={lake.d}
                                fill='#ffffff'
                                stroke='none'
                            />
                        )
                    })}
                </g>
            </g>
        </svg>
    )
})
