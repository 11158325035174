// react components
import React, {
    useEffect,
    useState,
} from 'react'
import Tooltip from '@mui/material/Tooltip'
import Linkify from 'react-linkify'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxModalSetActiveDropdownIdSite,
    reduxModalSetCurrencyIdSite,
    reduxModalSetLanguageIdSite,
    reduxSalesResetSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    FormFieldSerializer,
    MainIdNameSerializer,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template779SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
    getTextSite,
    onClickIsModalSite,
} from 'services'

// props
type TemplateBlock779SiteProps = {
    activeTabId?: number
    blockId: string
    collapseIsOpen?: boolean
    content: Template779SiteSerializer | Template779SiteSerializer[] | undefined
    contentArray?: Template779SiteSerializer[]
    formInputObj?: FormFieldSerializer
    isEditHovered: boolean
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInVideoBlock?: boolean
    linkColor: string | undefined
    listIndex?: number
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    onClick?: () => void
    parentArray?: number[]
    parentDropdownId?: number
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock779Site: React.FC<TemplateBlock779SiteProps> = React.memo(({
    activeTabId,
    blockId,
    collapseIsOpen,
    content,
    contentArray,
    formInputObj,
    listIndex,
    isEditHovered,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    linkColor,
    mainParentId,
    object,
    onClick,
    parentArray,
    parentDropdownId,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    if (Array.isArray(content)) {
        return (
            <React.Fragment>
                {content.map((item, i) => (
                    <TemplateBlock779HelperSite
                        key={item.id}
                        activeTabId={activeTabId}
                        blockId={`${blockId}-${item.id}`}
                        collapseIsOpen={collapseIsOpen}
                        content={item}
                        contentArray={contentArray || content}
                        listIndex={i}
                        isEditHovered={isEditHovered}
                        isInComponent={isInComponent}
                        isInDetailPage={isInDetailPage}
                        isInVideoBlock={isInVideoBlock}
                        linkColor={linkColor}
                        mainParentId={mainParentId}
                        object={object}
                        onClick={onClick}
                        parentArray={parentArray}
                        parentDropdownId={parentDropdownId}
                        parentStyles={parentStyles}
                        styles={styles}
                        stylesEdit={stylesEdit}
                    />
                ))}
            </React.Fragment>
        )
    }
    if (object.action === 'search' && Array.isArray(content?.text)) {
        return (
            <React.Fragment>
                {content?.text.map((item, i) => (
                    <TemplateBlock779HelperSite
                        key={item.id}
                        activeTabId={activeTabId}
                        blockId={blockId}
                        collapseIsOpen={collapseIsOpen}
                        content={content}
                        contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
                        formInputObj={formInputObj}
                        isEditHovered={isEditHovered}
                        isInComponent={isInComponent}
                        isInDetailPage={isInDetailPage}
                        isInVideoBlock={isInVideoBlock}
                        linkColor={linkColor}
                        listIndex={listIndex}
                        mainParentId={mainParentId}
                        object={object}
                        onClick={onClick}
                        parentArray={parentArray}
                        parentDropdownId={parentDropdownId}
                        parentStyles={parentStyles}
                        searchText={item.name}
                        searchTo={`${content?.absolute_site_url}${item.id}`}
                        styles={styles}
                        stylesEdit={stylesEdit}
                    />
                ))}
            </React.Fragment>
        )
    }
    return (
        <TemplateBlock779HelperSite
            activeTabId={activeTabId}
            blockId={blockId}
            collapseIsOpen={collapseIsOpen}
            content={content}
            contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
            formInputObj={formInputObj}
            listIndex={listIndex}
            isEditHovered={isEditHovered}
            isInComponent={isInComponent}
            isInDetailPage={isInDetailPage}
            isInVideoBlock={isInVideoBlock}
            linkColor={linkColor}
            mainParentId={mainParentId}
            object={object}
            onClick={onClick}
            parentArray={parentArray}
            parentDropdownId={parentDropdownId}
            parentStyles={parentStyles}
            styles={styles}
            stylesEdit={stylesEdit}
        />
    )
})

// helpers

// props
type TemplateBlock779HelperSiteProps = {
    activeTabId?: number
    blockId: string
    collapseIsOpen?: boolean
    content: Template779SiteSerializer | undefined
    contentArray: Template779SiteSerializer[] | undefined
    formInputObj?: FormFieldSerializer
    isEditHovered: boolean
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInVideoBlock?: boolean
    linkColor: string | undefined
    listIndex?: number
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    onClick?: () => void
    parentArray?: number[]
    parentDropdownId?: number
    parentStyles?: any
    searchText?: string
    searchTo?: string
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
const TemplateBlock779HelperSite: React.FC<TemplateBlock779HelperSiteProps> = React.memo(({
    activeTabId,
    blockId,
    collapseIsOpen,
    content,
    contentArray,
    formInputObj,
    isEditHovered,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    linkColor,
    listIndex,
    mainParentId,
    object,
    onClick,
    parentArray,
    parentDropdownId,
    parentStyles,
    searchText,
    searchTo,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxPlayerSiteplayingInlineId = useSelector((state: defaultReduxState) => state.reduxPlayerSite.playingInlineId)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType
    // @ts-ignore
    const isRichText = (content?.text_short_mode === 2 && content?.text_html) || content?.text?.is_rich
    const isRichTextStyle = content?.text_short_mode === 2

    const [stylesNormal, setStylesNormal] = useState<{
        clamp: string | undefined,
        linkColor: string | undefined,
        seperation: string | undefined,
        styles: CustomCSSProperties | undefined,
        stylesActive: {
            background: string | undefined,
            borderColor: string | undefined,
            color: string | undefined,
            fontWeight: string | undefined,
            textDecorationColor: string | undefined,
            textDecorationLine: string | undefined,
            textDecorationStyle: string | undefined,
            textDecorationThickness: string | undefined,
            textUnderlineOffset: string | undefined,
        } | undefined,
        textGradient: string | undefined,
        transition: string | undefined,
    } | undefined>(isRichTextStyle ? undefined : applyStylesNormal())
    const [stylesRich, setStylesRich] = useState<{
        clamp: string | undefined,
        defaultMargin: string | undefined,
        styles: CustomCSSProperties | undefined,
        // @ts-ignore
    } | undefined>(isRichTextStyle ? applyStylesRich() : undefined)
    const [text, setText] = useState<{
        normal: string | MainIdNameSerializer[] | {
            date: string
            date_format?: string | undefined
            date_format_d?: string | undefined
            date_format_m?: string | undefined
            date_format_y?: string | undefined
            date_type_choice: string
        } | {
            time_only: boolean
            date_only: boolean
            end_date: string
            start_date: string
        } | undefined,
        rich: string | undefined,
    }>()
    const [tooltipText, setTooltipText] = useState<string | undefined>('Copy')

    useEffect(() => {
        if (isRichText) {
            // @ts-ignore
            setStylesRich(applyStylesRich())
            if (!isRichTextStyle) {
                setStylesNormal(applyStylesNormal())
            }
        } else {
            setStylesNormal(applyStylesNormal())
        }
    }, [
        content?.text_html,
        content?.text_short_mode,
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    useEffect(() => {
        setText({
            normal: searchText || getTextSite(content?.text, reduxModalSite, reduxText, stylesNormal?.seperation),
            // @ts-ignore
            rich: content?.text?.is_rich ? content.text?.value : content?.text_html,
        })
    }, [
        content?.text,
        content?.text_html,
        reduxModalSite,
        stylesNormal?.seperation,
    ])

    function applyStylesNormal() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit) || {}
            if (content?.styles_extra) {
                if (!aStyles.color && content?.styles_extra.color) {
                    aStyles.color = content.styles_extra.color
                }
            }
            return {
                clamp: `${Number(aStyles?.WebkitLineClamp) > 0 ? ' clamp' : ''}`,
                linkColor: aStyles?.linkColor,
                seperation: aStyles?.seperation,
                styles: aStyles,
                stylesActive: !object.action ? undefined : {
                    background: aStyles?.hoverBackground,
                    borderColor: aStyles?.hoverBorderColor,
                    color: aStyles?.hoverColor,
                    fontWeight: aStyles?.hoverFontWeight,
                    opacity: aStyles?.hoverOpacity,
                    textDecorationColor: aStyles?.hoverTextDecorationColor,
                    textDecorationLine: aStyles?.hoverTextDecorationLine,
                    textDecorationStyle: aStyles?.hoverTextDecorationStyle,
                    textDecorationThickness: aStyles?.hoverTextDecorationThickness,
                    textUnderlineOffset: aStyles?.hoverTextUnderlineOffset,
                },
                textGradient: aStyles?.WebkitBackgroundClip === 'text' ? ' text-gradient' : '',
                transition: `${object.action ? ' transition' : ''}`,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock779HelperSite',
                'applyStylesNormal',
            ))
        }
    }

    function applyStylesRich() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                clamp: `${Number(aStyles?.WebkitLineClamp) > 0 ? ' clamp' : ''}`,
                defaultMargin: `${aStyles?.defaultMargin === 'true' ? ' default-margin' : ''}`,
                styles: {
                    background: aStyles?.background,
                    borderColor: aStyles?.borderColor,
                    borderRadius: aStyles?.borderRadius,
                    borderStyle: aStyles?.borderStyle,
                    borderWidth: aStyles?.borderWidth,
                    color: aStyles?.color,
                    fontFamily: aStyles?.fontFamily,
                    fontWeight: aStyles?.fontWeight,
                    letterSpacing: aStyles?.letterSpacing,
                    lineHeight: aStyles?.lineHeight,
                    margin: aStyles?.margin,
                    maxWidth: aStyles?.maxWidth,
                    padding: aStyles?.padding,
                    textAlign: aStyles?.textAlign,
                    width: aStyles?.width,
                },
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock779HelperSite',
                'applyStylesRich',
            ))
        }
    }

    // @ts-ignore
    if (isInDetailPage && !text?.normal && !text?.rich && !formInputObj?.label && text?.normal !== 0) return null
    if (isInVideoBlock && reduxPlayerSiteplayingInlineId === content?.id) return null
    if (formInputObj?.placeholder === 'true' && formInputObj?.field_type !== 'checkbox') return null
    if (content?.is_hidden && !reduxFormSitenavigationMode) return null

    if (isRichText && text?.rich) {
        return (
            <div
                id={blockId}
                className={`template-block-779-rich${stylesRich?.clamp}${stylesNormal?.transition}${stylesRich?.defaultMargin}${stylesNormal?.textGradient}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                style={isRichTextStyle ? stylesRich?.styles : stylesNormal?.styles}
            >
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank' style={{ color: stylesNormal?.linkColor || linkColor }}>{decoratedText}</a>}>
                    <div dangerouslySetInnerHTML={{ __html: text?.rich }} />
                </Linkify>
                <EditBlockSite
                    hideButtons={Boolean(parentDropdownId)}
                    isInComponent={isInComponent}
                    mainParentId={mainParentId || object.id!}
                    object={object}
                    parentArray={parentArray}
                    parentStyles={parentStyles}
                />
            </div>
        )
    }

    function onLinkClick() {
        try {
            if (onClick) {
                onClick()
            } else if (object.action === 'language_switch') {
                dispatch(reduxModalSetLanguageIdSite(content?.id!))
            } else if (object.action === 'currency_switch') {
                dispatch(reduxModalSetCurrencyIdSite(content?.id!))
                dispatch(reduxSalesResetSite())
            } else if (content && contentArray) {
                onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, content, object, contentArray, isInVideoBlock)
            }
            if (object.action && parentDropdownId) {
                dispatch(reduxModalSetActiveDropdownIdSite(parentDropdownId))
            } else if (reduxModalSite.activeDropdownId) {
                dispatch(reduxModalSetActiveDropdownIdSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock779HelperSite',
                'onLinkClick',
            ))
        }
    }

    if (object.data_json?.copyAction) {
        return (
            <LinkHelperSite
                id={blockId}
                style={formInputObj?.styles_779 ? getStylesNew(reduxModalSite, formInputObj?.styles_779, undefined) : stylesNormal?.styles}
                // @ts-ignore
                styleActive={stylesNormal?.stylesActive}
                textTag={object.data_json?.textTag || 'span'}
                onClick={() => {
                    setTooltipText('✔ Copied')
                    navigator.clipboard.writeText(text?.normal ? `${text.normal}` : '')
                }}
            >
                <Tooltip
                    onClose={() => setTooltipText('Copy')}
                    title={tooltipText}
                >
                    <span>{text?.normal ? `${text.normal}` : ''}</span>
                </Tooltip>
                <EditBlockSite
                    hideButtons={Boolean(parentDropdownId)}
                    isInComponent={isInComponent}
                    mainParentId={mainParentId || object.id!}
                    object={object}
                    parentArray={parentArray}
                    parentStyles={parentStyles}
                />
            </LinkHelperSite>
        )
    }

    return (
        <LinkHelperSite
            id={blockId}
            action={object.action}
            className={`template-block-779${stylesNormal?.clamp}${stylesNormal?.transition}${stylesNormal?.textGradient}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            contentSource={object.content_source}
            isActive={(object.id === reduxModalSite.activeDropdownId) || (object.id === activeTabId)}
            linkUrl={content?.link_url}
            onClick={onLinkClick}
            parentDropdownId={parentDropdownId}
            style={formInputObj?.styles_779 ? getStylesNew(reduxModalSite, formInputObj?.styles_779, undefined) : stylesNormal?.styles}
            // @ts-ignore
            styleActive={stylesNormal?.stylesActive}
            target={object.data_json?.hrefTarget}
            textTag={object.data_json?.textTag || 'span'}
            to={searchTo || content?.absolute_site_url}
        >
            {object.action && ['modal_link', 'generic_link', 'href', 'page_link', 'purchase_link', 'search'].includes(object.action)
                ? (
                    text?.normal ? `${text.normal}` : ''
                ) : (
                    formInputObj?.label
                        ? (
                            (formInputObj?.label_rich && <span dangerouslySetInnerHTML={{ __html: formInputObj.label_rich }} />) || formInputObj?.label
                        ) : (
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank' style={{ color: stylesNormal?.linkColor || linkColor }}>{decoratedText}</a>}>
                                {/* @ts-ignore */}
                                {text?.normal === '--index--' ? ((listIndex || 0) + 1) : ((collapseIsOpen && [330973, 324517].includes(object.id!)) ? '-' : text?.normal)}
                                {/* TODO dynamic */}
                            </Linkify>
                        )
                )
            }
            <EditBlockSite
                hideButtons={Boolean(parentDropdownId)}
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </LinkHelperSite>
    )
})
