// react components
import {
    combineReducers,
} from 'redux'
import {
    connect,
} from 'react-redux'

// reducers
import reduxAuthSite from './redux/auth/mo_reducer'
import reduxCacheSite from './redux/cache/mo_reducer'
import reduxFormSite from './redux/form/mo_reducer'
import reduxModalSite from './redux/modal/mo_reducer'
import reduxPlayerSite from './redux/player/mo_reducer'
import reduxSalesSite from './redux/sales/mo_reducer'
import reduxText from './redux/text/mo_reducer'

const rootReducer = combineReducers({
    reduxAuth: reduxAuthSite,
    reduxCacheSite: reduxCacheSite,
    reduxFormSite: reduxFormSite,
    reduxModalSite: reduxModalSite,
    reduxPlayerSite: reduxPlayerSite,
    reduxSalesSite: reduxSalesSite,
    reduxText: reduxText,
})

export default rootReducer

// containers
function mapStateToProps(state: any) {
    return {
        reduxAuth: state.reduxAuthSite,
        reduxCacheSite: state.reduxCacheSite,
        reduxFormSite: state.reduxFormSite,
        reduxModalSite: state.reduxModalSite,
        reduxSalesSite: state.reduxSalesSite,
        reduxText: state.reduxText.data,
    }
}

export const MainReduxContainerSite = connect(mapStateToProps)
