// react components
import ReactDOM from 'react-dom'
// import { createRoot } from 'react-dom/client'  // need update from leaflet first
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

// services
import {
    getHostOriginSite,
} from 'services'

import {
    App,
    AppProd,
} from './App'
import reportWebVitals from './reportWebVitals'

const hostOriginSite = getHostOriginSite()

// const container = document.getElementById('root')
// const root = createRoot(container!)
if (hostOriginSite === 'prod') {
    // root.render(<AppProd />)
    ReactDOM.render(<AppProd />, document.getElementById('root'))
} else {
    // root.render(<App />)
    ReactDOM.render(<App />, document.getElementById('root'))
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
