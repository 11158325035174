// data
import {
    REDUX_SALES_ACTIVITY_INSTALLMENT_SITE,
    REDUX_SALES_ACTIVITY_PAYMENT_METHOD_SITE,
    REDUX_SALES_ACTIVITY_RESET_SITE,
    REDUX_SALES_ACTIVITY_SITE,
    REDUX_SALES_ADD_TO_CART_SITE,
    REDUX_SALES_CANCEL_SITE,
    REDUX_SALES_CART_TOGGLE_SITE,
    REDUX_SALES_REMOVE_FROM_CART_SITE,
    REDUX_SALES_RESET_SITE,
    REDUX_SALES_SET_INFO_SITE,
    REDUX_SALES_SHIPPING_COST_SITE,
    REDUX_SALES_SUCCESS_SITE,
    REDUX_SALES_TICKET_SITE,
} from './mo_actions'
import {
    reduxSalesStateSite,
} from './mo_state'

// services
import {
    getCookieSite,
} from '../../../../services/site/cookies'

// main
const initialState: reduxSalesStateSite = getCookieSite({ name: 'mo_reduxSalesSite', format: 'json' }) || {
    activityData: {},
    addedProduct: {
        absolute_url: '',
        currency: '',
        currency_symbol: '',
        lineTotal: 0,
        lineWeight: 0,
        package_option_items: undefined,
        product: '',
        qty: '',
        unitPrice: '',
        variant: '',
    },
    billing_info: {
        address: '',
        city: '',
        country: '',
        first_name: '',
        last_name: '',
        same_as_shipping: true,
        zip_code: '',
    },
    contact_info: {
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
    },
    shipping_info: {
        address: '',
        city: '',
        country: '',
        zip_code: '',
    },
    currency: '',
    currency_symbol: '',
    isCartOpen: false,
    salesLines: [],
    shippingService: undefined,
    shippingTotal: undefined,
    soldResponse: undefined,
    subTotal: 0,
    ticketData: {},
    weightTotal: 0,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_SALES_ACTIVITY_SITE: {
            const totals = Object.values(action.payload.registrations || {}).reduce((accumulator, registrationData) => {
                // @ts-ignore
                const qty = registrationData.qty
                // @ts-ignore
                const unitPrice = Number(registrationData.variant.unit_price)
                const value = qty * unitPrice
                // @ts-ignore
                const totalQty = accumulator.totalQty + qty // Accumulate totalQty

                return {
                    // @ts-ignore
                    totalValue: accumulator.totalValue + value,
                    totalQty: totalQty,
                }
            }, { totalValue: 0, totalQty: 0 }) // Initialize totalValue and totalQty in accumulator
            return {
                ...state,
                activityData: {
                    ...action.payload,
                    // @ts-ignore
                    discount: (totals.totalQty + action.payload.purchased_registration_count) >= 3 ? 0.1 : 0,
                    // @ts-ignore
                    totalValue: totals.totalValue,
                    // @ts-ignore
                    totalQty: totals.totalQty,
                },
            }
        }
        case REDUX_SALES_ACTIVITY_RESET_SITE: {
            return {
                ...state,
                activityData: {},
            }
        }
        case REDUX_SALES_ACTIVITY_PAYMENT_METHOD_SITE: {
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    paymentMethod: action.payload,
                },
            }
        }
        case REDUX_SALES_ACTIVITY_INSTALLMENT_SITE: {
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    isInstallment: action.payload,
                },
            }
        }
        case REDUX_SALES_ADD_TO_CART_SITE: {
            let newSalesLines: reduxSalesStateSite['salesLines'] = []
            const existingProduct = state.salesLines.find(obj => ((obj.product.id === action.product.id) && (obj.variant?.id === action.variant?.id)))
            const newQty = Number(action.qty) + (existingProduct?.qty || 0)
            let currency = action.portfolioObject.currency.iso_code
            let currency_symbol = action.portfolioObject.currency.symbol
            if (action.currency) {
                currency = action.portfolioObject.currencies.find((obj: any) => obj.id === action.currency).iso_code
                currency_symbol = action.portfolioObject.currencies.find((obj: any) => obj.id === action.currency).symbol
            }
            const salesLine = {
                absolute_url: action.absolute_url,
                currency: currency,
                currency_symbol: currency_symbol,
                lineTotal: newQty * action.unitPrice,
                lineWeight: newQty * action.product.packaging_weight,
                package_option_items: action.packageOptions,
                product: action.product,
                qty: newQty,
                unitPrice: action.unitPrice,
                variant: action.variant,
            }
            if (existingProduct) {
                state.salesLines.map(val => {
                    if ((val.product.id === action.product.id) && (val.variant?.id === action.variant?.id)) {
                        newSalesLines.push(salesLine)
                    } else {
                        newSalesLines.push(val)
                    }
                })
            } else {
                newSalesLines = state.salesLines
                newSalesLines.push(salesLine)
            }
            let subTotal = 0
            newSalesLines.map((val: any) => {
                subTotal += (val.qty * val.unitPrice)
                return false
            })
            let weightTotal = 0
            newSalesLines.map((val) => {
                weightTotal += val.lineWeight
                return false
            })
            if (action.portfolioObject.is_shipping_activated) {
                return {
                    ...state,
                    addedProduct: salesLine,
                    currency: currency,
                    currency_symbol: currency_symbol,
                    isCartOpen: true,
                    salesLines: newSalesLines,
                    shipping_info: {
                        ...state.shipping_info,
                        country: '',
                    },
                    shippingService: undefined,
                    shippingTotal: undefined,
                    soldResponse: undefined,
                    subTotal: subTotal,
                    weightTotal: weightTotal,
                }
            }
            return {
                ...state,
                addedProduct: salesLine,
                currency: currency,
                currency_symbol: currency_symbol,
                isCartOpen: true,
                salesLines: newSalesLines,
                soldResponse: undefined,
                subTotal: subTotal,
                weightTotal: weightTotal,
            }
        }
        case REDUX_SALES_CANCEL_SITE: {
            return {
                ...state,
                isCartOpen: false,
            }
        }
        case REDUX_SALES_CART_TOGGLE_SITE: {
            return {
                ...state,
                isCartOpen: action.boolean,
            }
        }
        case REDUX_SALES_REMOVE_FROM_CART_SITE: {
            const newSalesLines: any = []
            state.salesLines.map((val: any, i: any) => {
                if (action.index !== i) newSalesLines.push(val)
                return false
            })
            let subTotal = 0
            newSalesLines.map((val: any) => {
                subTotal += (val.qty * val.unitPrice)
                return false
            })
            let weightTotal = 0
            newSalesLines.map((val: any) => {
                weightTotal += (val.lineWeight)
                return false
            })
            if (action.portfolioObject.is_shipping_activated) {
                return {
                    ...state,
                    salesLines: newSalesLines,
                    subTotal: subTotal,
                    weightTotal: weightTotal,
                    shipping_info: {
                        ...state.shipping_info,
                        country: '',
                    },
                    shippingService: undefined,
                    shippingTotal: undefined,
                }
            }
            return {
                ...state,
                salesLines: newSalesLines,
                subTotal: subTotal,
                weightTotal: weightTotal,
            }
        }
        case REDUX_SALES_RESET_SITE: {
            return {
                ...state,
                addedProduct: {
                    absolute_url: '',
                    currency: '',
                    lineTotal: 0,
                    product: '',
                    qty: '',
                    unitPrice: '',
                    variant: '',
                },
                billing_info: {
                    address: '',
                    city: '',
                    country: '',
                    first_name: '',
                    last_name: '',
                    same_as_shipping: true,
                    zip_code: '',
                },
                contact_info: {
                    email: '',
                    first_name: '',
                    last_name: '',
                    phone: '',
                },
                shipping_info: {
                    address: '',
                    city: '',
                    country: '',
                    zip_code: '',
                },
                currency: '',
                isCartOpen: false,
                salesLines: [],
                soldResponse: undefined,
                shippingService: undefined,
                shippingTotal: undefined,
                subTotal: 0,
            }
        }
        case REDUX_SALES_SET_INFO_SITE: {
            return {
                ...state,
                billing_info: action.billing_info,
                contact_info: action.contact_info,
                shipping_info: action.shipping_info,
            }
        }
        case REDUX_SALES_SHIPPING_COST_SITE: {
            return {
                ...state,
                shippingService: action.payload.shippingService,
                shippingTotal: action.payload.shippingTotal,
            }
        }
        case REDUX_SALES_SUCCESS_SITE: {
            return {
                ...state,
                activityData: {},
                addedProduct: {
                    absolute_url: '',
                    currency: '',
                    currency_symbol: '',
                    lineTotal: 0,
                    lineWeight: 0,
                    package_option_items: undefined,
                    product: '',
                    qty: '',
                    unitPrice: '',
                    variant: '',
                },
                billing_info: {
                    address: '',
                    city: '',
                    country: '',
                    first_name: '',
                    last_name: '',
                    same_as_shipping: true,
                    zip_code: '',
                },
                contact_info: {
                    email: '',
                    first_name: '',
                    last_name: '',
                    phone: '',
                },
                shipping_info: {
                    address: '',
                    city: '',
                    country: '',
                    zip_code: '',
                },
                currency: '',
                isCartOpen: false,
                salesLines: [],
                shippingService: undefined,
                shippingTotal: undefined,
                soldResponse: action.payload,
                subTotal: 0,
                ticketData: {},
                weightTotal: 0,
            }
        }
        case REDUX_SALES_TICKET_SITE: {
            const totalValue = Object.values(action.payload.variants).reduce((accumulator, variantData) => {
                // @ts-ignore
                const qty = variantData.qty
                // @ts-ignore
                const unitPrice = Number(variantData.variant.unit_price)
                const value = qty * unitPrice
                // @ts-ignore
                return accumulator + value
            }, 0)
            return {
                ...state,
                ticketData: {
                    ...action.payload,
                    totalValue: totalValue,
                },
            }
        }
        default:
            return state
    }
}
