// react components
import { jwtDecode } from 'jwt-decode'
import localforage from 'localforage'
import {
    googleLogout,
} from '@react-oauth/google'

// data
import {
    REDUX_AUTH_AFTER_AUTH_URL_SET_SITE,
    REDUX_AUTH_FETCH_SETTINGS_SITE,
    REDUX_AUTH_GOOGLE_AUTH_FULFILLED_SITE,
    REDUX_AUTH_GOOGLE_AUTH_REJECTED_SITE,
    REDUX_AUTH_LOGIN_FULFILLED_SITE,
    REDUX_AUTH_LOGIN_REJECTED_SITE,
    REDUX_AUTH_LOGOUT_SITE,
} from './mo_actions'
import {
    reduxAuthStateSite,
} from './mo_state'

// services
import {
    deleteCookieSite,
    getCookieSite,
} from '../../../../services/site/cookies'

// main
const initialState: reduxAuthStateSite = {
    access: getCookieSite({ name: 'mo_reduxAuthAccessSite', format: 'json' }) || {},
    afterAuthUrl: getCookieSite({ name: 'mo_reduxAfterAuthUrlSite', format: 'string' }) || '',
    authenticated: getCookieSite({ name: 'mo_reduxAuthAuthenticatedSite', format: 'boolean' }) || false,
    axiosConfig: getCookieSite({ name: 'mo_reduxAuthAxiosConfigSite', format: 'json' }) || {},
    settings: getCookieSite({ name: 'mo_reduxAuthSettingsSite', format: 'json' }) || undefined,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_AUTH_AFTER_AUTH_URL_SET_SITE:
            return {
                ...state,
                afterAuthUrl: action.payload,
            }
        case REDUX_AUTH_FETCH_SETTINGS_SITE:
            return {
                ...state,
                settings: action.payload,
            }
        case REDUX_AUTH_LOGIN_FULFILLED_SITE:
            return {
                ...state,
                access: {
                    mo_token_type: 'django',
                    token: action.payload.access,
                    ...jwtDecode<any>(action.payload.access),
                },
                authenticated: true,
                axiosConfig: {
                    headers: {
                        Authorization: `JWT ${action.payload.access}`,
                    },
                },
                settings: action.payload.settings,
                errors: {},
            }
        case REDUX_AUTH_LOGIN_REJECTED_SITE:
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                settings: {},
            }
        case REDUX_AUTH_GOOGLE_AUTH_FULFILLED_SITE:
            return {
                ...state,
                access: {
                    mo_token_type: 'google',
                    token: action.payload.access,
                    ...jwtDecode<any>(action.payload.access),
                },
                authenticated: true,
                axiosConfig: {
                    headers: {
                        Authorization: `JWT ${action.payload.access}`,
                    },
                },
                settings: action.payload.settings,
                errors: action.payload.response || { non_field_errors: action.payload.statusText },
                googleAuthError: false,
            }
        case REDUX_AUTH_GOOGLE_AUTH_REJECTED_SITE:
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                settings: {},
            }
        case REDUX_AUTH_LOGOUT_SITE:
            if (state.access?.mo_token_type === 'google') {
                googleLogout()
            }
            deleteCookieSite('mo_reduxAuthAccessSite')
            deleteCookieSite('mo_reduxAfterAuthUrlSite')
            deleteCookieSite('mo_reduxAuthAuthenticatedSite')
            deleteCookieSite('mo_reduxAuthAxiosConfigSite')
            deleteCookieSite('mo_reduxAuthSettingsSite')
            localStorage.clear()
            localforage.clear().then(() => {
                window.location.replace('/')
            }).catch((err) => {
                console.log(err)
            })
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                errors: {},
                settings: {},
            }
        default:
            return state
    }
}
