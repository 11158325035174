// react components
import axios from 'axios'

// data
import {
    api_url_main_text_site,
    reduxAuthStateSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
} from 'services'

// constants
export const REDUX_TEXT_FETCH_MAIN_TEXT_SITE = 'REDUX_TEXT_FETCH_MAIN_TEXT_SITE'

// actions
export function reduxTextFetchMainTextSite(
    reduxAuth: reduxAuthStateSite,
    reduxModalSite: reduxModalStateSite,
    dispatchVar: React.Dispatch<any>,
    languadeCode: string,
    languageId: number,
) {
    return function (dispatch: any) {
        const getUrl = `https://api.justtheweb.io/${languadeCode}/v1/5/${api_url_main_text_site}?language_id=${languageId}`
        axios({
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                dispatch({
                    type: REDUX_TEXT_FETCH_MAIN_TEXT_SITE,
                    payload: response.data,
                })
            })
            .catch((error) => {
                axiosErrorHandlerSite({
                    apiUrl: getUrl,
                    component: 'reduxTextFetchMainTextSite',
                    dispatch: dispatchVar,
                    error,
                    reduxAuth,
                    reduxModalSite,
                    reference: 'get',
                    skipSendErrorStatusCode: [403],
                })
            })
    }
}
