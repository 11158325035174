// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_project_mixed_media_list_site,
    reduxAuthStateSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// main
export function getMixedContentDataSite({
    component,
    dispatch,
    infiniteContentId,
    pageNumberNew,
    reduxAuth,
    reduxModalSite,
    setIsLoading,
    setItems,
}: {
    component: string
    dispatch: React.Dispatch<any>
    infiniteContentId: number
    pageNumberNew: number
    reduxAuth: reduxAuthStateSite
    reduxModalSite: reduxModalStateSite
    setIsLoading: React.Dispatch<boolean>
    setItems: React.Dispatch<PortfolioPageContentListSiteSerializer[]>
}) {

    setIsLoading(true)
    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) getMixedContentDataSite')
    let getUrl = getApiUrlSite(`${api_url_project_mixed_media_list_site}?infinite_content_id=${infiniteContentId}`, reduxModalSite)
    getUrl += `&language_id=${reduxModalSite.languageId}`
    if (reduxModalSite.currencyId) getUrl += `&currency_id=${reduxModalSite.currencyId}`
    getUrl += `&page_number=${pageNumberNew}`
    if (process.env.NODE_ENV === 'development') console.log(getUrl)
    axios({
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: 'get',
        url: getUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            setItems(response.data.results)
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getMixedContentDataSite')
        })
        .catch((error) => {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getMixedContentDataSite')
            axiosErrorHandlerSite({
                apiUrl: getUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'getMixedContentDataSite',
            })
        })
}
