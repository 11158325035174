// react components
import React from 'react'
import {
    IonContent,
    IonFooter,
    IonHeader,
    IonItem,
    IonLabel,
    IonModal,
    IonThumbnail,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    EcButtonSite,
    ImageHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxPlayerSetCurrentTrackSite,
} from 'data'

// props
type EcPlayerTracklistSiteProps = {
    showTrackList: boolean
    setShowTrackList: React.Dispatch<boolean>
}

// main
export const EcPlayerTracklistSite: React.FC<EcPlayerTracklistSiteProps> = React.memo(({
    showTrackList,
    setShowTrackList,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxPlayerSite = useSelector((state: defaultReduxState) => state.reduxPlayerSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    function onNext(customTrack: any) {
        try {
            // @ts-ignore
            dispatch(reduxPlayerSetCurrentTrackSite(
                customTrack,
                reduxAuth,
                reduxModalSite,
                dispatch,
                reduxPlayerSite.playerPosition,
                reduxPlayerSite.playerOrigin,
                (reduxPlayerSite.albumTrackArray && reduxPlayerSite.albumTrackArray.findIndex((obj) => obj.id === (customTrack && customTrack.id)) > - 1) ? reduxPlayerSite.trackArray : reduxPlayerSite.originalTrackArray,
                reduxPlayerSite.originalUrl,
            ))
            setShowTrackList(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'EcPlayerTracklistSite',
                'onNext',
            ))
        }
    }

    return (
        <IonModal
            // @ts-ignore
            cssClass='mo-player-track-list-site'
            isOpen={showTrackList}
            onDidDismiss={() => setShowTrackList(false)}
        >
            <IonHeader className='mptls-header'>
                <IonToolbar>
                    <IonTitle class='mptls-title'>{`${reduxText[8607]} (${reduxPlayerSite.trackArray?.length || 0})`}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className={`mo-hide-ion-content-scrollbar ${deviceType}${reduxModalSite.isWindows}`}>
                {reduxPlayerSite.trackArray?.map((val) => {
                    return (
                        <IonItem
                            key={val.id}
                            button
                            onClick={() => onNext(val)}
                        >
                            <IonThumbnail>
                                <ImageHelperSite
                                    alt={val.name}
                                    className={`eci-thumb-site ${deviceType}`}
                                    dominant_color={val.get_dominant_color}
                                    src={val.get_image_site_lq}
                                />
                            </IonThumbnail>

                            <IonLabel>
                                <p className={`mptls-trackname ${reduxPlayerSite.currentTrack?.id === val.id ? 'mo-color' : ''} ${deviceType}`}>
                                    {val.name}
                                </p>
                            </IonLabel>
                        </IonItem>
                    )
                })}
            </IonContent>
            <IonFooter className='mptls-footer'>
                <EcButtonSite
                    fill='outline'
                    onClick={() => setShowTrackList(false)}
                    text={reduxText[8353]}
                />
            </IonFooter>
        </IonModal>
    )
})
