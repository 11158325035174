// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    useIonViewWillEnter,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    IconBlockSite,
    LoaderSite,
    SeoBlockSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    ContentBlockSite,
    NotFoundPortfolioSite,
    PrivateSideMenuTabWeb,
} from 'pages'

// serializers
import {
    PageDetailSiteSerializer,
    PortfolioHubContentListSiteSerializer,
    PortfolioPageContentListSiteSerializer,
    PortfolioPrivateSpaceListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    checkPorfolioUserGroups,
    getDataSite,
    getPrivateSpaceListDataSite,
    getStylesToRetrieve,
    parseQuerySite,
} from 'services'

// props
type PrivateSlugPageSiteProps = {
    devId: string
    pageSlug?: string
}

// main
export const PrivateSlugPageSite: React.FC<PrivateSlugPageSiteProps> = React.memo(({
    devId,
    pageSlug,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxFormSiterefreshPrivateDetailPageSite = useSelector((state: defaultReduxState) => state.reduxFormSite.refresh.PrivateDetailPageSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [currencyId, setCurrencyId] = useState<number | undefined>(reduxModalSite.currencyId)
    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [isLoading, setIsLoading] = useState(false)
    const [languageId, setLanguageId] = useState<number>(reduxModalSite.languageId)
    const [locationSearch, setLocationSearch] = useState<string>(location.search)
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[] | undefined>(undefined)
    const [pageObject, setPageObject] = useState<PageDetailSiteSerializer>()
    const [privateSpaceList, setPrivateSpaceList] = useState<PortfolioPrivateSpaceListSiteSerializer[]>([])

    const [fontFamily, setFontFamily] = useState<string>()
    const [linkColor, setLinkColor] = useState<string>()
    const [menuOpen, setMenuOpen] = useState(false)
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})
    const [tabObject, setTabObject] = useState<PortfolioHubContentListSiteSerializer>()
    const [refreshNumber, setRefreshNumber] = useState(reduxFormSiterefreshPrivateDetailPageSite)

    useEffect(() => {
        getPortfolioData()
    }, [
        pageSlug,
    ])

    useEffect(() => {
        const parsed = parseQuerySite(location.search)
        if (parsed.query || parsed.filter_query) {
            getPortfolioData(Boolean(parsed.filter_query))
        } else if (locationSearch) {
            getPortfolioData(true)
        }
        setLocationSearch(location.search)
    }, [
        location.search,
    ])

    useEffect(() => {
        if (languageId !== reduxModalSite.languageId) {
            getPortfolioData()
            setLanguageId(reduxModalSite.languageId)
        } else if (currencyId !== reduxModalSite.currencyId) {
            getPortfolioData()
            setCurrencyId(reduxModalSite.currencyId)
        }
    }, [
        reduxModalSite.currencyId,
        reduxModalSite.languageId,
    ])

    useEffect(() => {
        if (reduxCacheSite.portfolio) {
            getPrivateSpaceListDataSite({
                component: 'PrivateDetailPageSite',
                dispatch,
                portfolioObject: reduxCacheSite.portfolio,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                setPrivateSpaceList,
            })
        }
    }, [
        reduxCacheSite.portfolio?.id,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        if (getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'backgroundImage')) {
            tempPageStyles.backgroundImage = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'backgroundImage')
        } else {
            tempPageStyles.background = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'background')
        }
        tempPageStyles.color = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'color')
        if (reduxModalSite.navbarType === 'top') {
            tempPageStyles.paddingTop = `${reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight}px`
        } else if (reduxModalSite.navbarType === 'side') {
            tempPageStyles.paddingLeft = `${reduxModalSite.navbarWidth}px`
        }
        setFontFamily(getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'fontFamily'))
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'linkColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'linkColor'))
    }, [
        pageObject?.styles,
        reduxCacheSite.portfolio?.styles,
        reduxModalSite,
    ])

    useEffect(() => {
        if (reduxFormSiterefreshPrivateDetailPageSite !== refreshNumber) {
            setRefreshNumber(reduxFormSiterefreshPrivateDetailPageSite)
            getPortfolioData(undefined, true)
        } else {
            getPortfolioData()
        }
    }, [
        reduxFormSiterefreshPrivateDetailPageSite,
    ])

    useEffect(() => {
        if (deviceType !== 'is-web') {
            setMenuOpen(false)
        }
    }, [
        location,
    ])

    useIonViewWillEnter(() => {
        scrollToTop()
    })

    const scrollToTop = (force?: boolean) => {
        try {
            if (history.action !== 'POP' || force) {
                // @ts-ignore
                if (document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`) && document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`).scrollTo) document.getElementById(`mo-content-slug-id-site-${pageSlug || 'home'}`).scrollTo(0, 0)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateSlugPageSite',
                'scrollToTop',
            ))
        }
    }

    function getPortfolioData(noScollTop?: boolean, forceRefresh?: boolean) {
        try {
            getDataSite({
                axiosCancelToken,
                component: 'PrivateSlugPageSite',
                detailId: undefined,
                devId,
                dispatch,
                errorStatus,
                forceRefresh,
                location,
                noEmpty: noScollTop,
                pageSlug,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                scrollToTop: noScollTop ? undefined : scrollToTop,
                setAxiosCancelToken,
                setErrorStatus,
                setIsLoading,
                setPageContentItems,
                setPageObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateSlugPageSite',
                'getPortfolioData',
            ))
        }
    }

    if (errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent) {
        return (
            <NotFoundPortfolioSite statusCode={errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent} />
        )
    }
    if (pageObject?.is_private && !reduxAuth?.authenticated) {
        return (
            <NotFoundPortfolioSite statusCode={403} />
        )
    }
    if (pageObject?.is_private && reduxAuth?.authenticated && pageObject.portfolio_user_groups?.length! > 0 && !checkPorfolioUserGroups({ portfolioId: reduxCacheSite.portfolio?.id!, pageUserGroups: pageObject.portfolio_user_groups!, reduxAuth })) {
        return (
            <NotFoundPortfolioSite statusCode={403} />
        )
    }
    return (
        <IonPage>
            <SeoBlockSite data={pageObject?.seo_data} />
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxModalSite.isWindows} ${deviceType}`}>
                {isLoading && (
                    <LoaderSite isOpen />
                )}
                <div
                    className={`private-slug-page-site lg-${reduxModalSite.languageId} ${deviceType}`}
                    style={newPageStyles}
                >
                    <div
                        className={`psps-content ${deviceType}`}
                        style={{
                            fontFamily: fontFamily,
                            height: `calc(100vh - ${reduxModalSite.navbarHeight}px)`,
                        }}
                    >
                        <div
                            className={`psps-left mo-hidden-vertical-scrollbar${reduxModalSite.isWindows}${menuOpen ? ' open' : ''} ${deviceType}`}
                            style={{
                                height: deviceType === 'is-mobile' ? `calc(100vh - ${reduxModalSite.navbarHeight}px - 50px)` : `calc(100vh - ${reduxModalSite.navbarHeight}px)`,
                            }}
                        >
                            {privateSpaceList?.map((val) => (
                                <PrivateSideMenuTabWeb
                                    key={val.id}
                                    object={val}
                                    setTabObject={setTabObject}
                                    tabSlug={pageSlug!}
                                />
                            ))}
                        </div>
                        <div
                            className={`psps-right mo-hidden-vertical-scrollbar${reduxModalSite.isWindows} ${deviceType}`}
                            style={{
                                height: `calc(100vh - ${reduxModalSite.navbarHeight}px)`,
                            }}
                        >
                            <div
                                className={`psps-header${reduxModalSite.isWindows} ${deviceType}`}
                            >
                                <IconBlockSite
                                    className={`hps-icon ${deviceType}`}
                                    color='light'
                                    iconClass='mo-new-icon-list-ul-solid'
                                    onClick={() => setMenuOpen(!menuOpen)}
                                />
                            </div>
                            <div
                                className={`private-slug-page-content-wrap lg-${reduxModalSite.languageId} ${deviceType}`}
                                style={{
                                    background: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'background'),
                                    color: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'color'),
                                    fontFamily: fontFamily,
                                    margin: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'margin'),
                                }}
                            >
                                <div
                                    id='private-slug-page-content-slug'
                                    className={`private-slug-page-content ${isLoading ? 'opacity' : 'no-opacity'}`}
                                >
                                    <div
                                        className={`private-slug-page-content-list ${deviceType}`}
                                        style={{
                                            padding: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'padding'),
                                        }}
                                    >
                                        {reduxCacheSite.portfolio && pageObject && pageContentItems?.filter(obj => obj.active === true).map((val) => (
                                            <ContentBlockSite
                                                key={val.id}
                                                detailId={undefined}
                                                devId={devId}
                                                isEditHovered={false}
                                                linkColor={linkColor}
                                                object={val}
                                                pageSlug={pageSlug || reduxCacheSite.portfolio?.home_page_slug}
                                                stylesEdit={undefined}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
})
