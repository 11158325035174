export const languageObjectSite: {
    [key: string]: number
} = {
    'ar': 19,
    'br': 60,
    'de': 149,
    'en': 1,
    'es': 409,
    'fr': 2,
    'it': 199,
    'nl': 112,
    'pl': 348,
    'pt': 349,
    'ru': 364,
    'tr': 444,
}

export const languageIdObjectSite: {
    [key: number]: string
} = {
    1: 'en',
    2: 'fr',
    19: 'ar',
    60: 'br',
    112: 'nl',
    149: 'de',
    199: 'it',
    348: 'pl',
    349: 'pt',
    364: 'ru',
    409: 'es',
    444: 'tr',
}

export const errorMessagesSite: any = {
    'ar': {
        title: 'إذا استمرت هذه المشكلة، حاول:',
        steps: [
            'قم بتحديث الصفحة.',
            'تسجيل الخروج وإعادة تسجيل الدخول.',
            'اتصل بفريق الدعم لدينا.'
        ]
    },
    'br': {
        title: 'Mard eo pad an fazi-mañ, klaskit:',
        steps: [
            'Freskaat ar bajenn.',
            'Digouezhet ha kevrennañ en-dro.',
            'Mont e darempred gant hor skipailh skoazell.'
        ]
    },
    'de': {
        title: 'Wenn dieser Fehler weiterhin auftritt, versuchen Sie Folgendes:',
        steps: [
            'Aktualisieren Sie die Seite.',
            'Melden Sie sich ab und wieder an.',
            'Kontaktieren Sie unser Support-Team.'
        ]
    },
    'en': {
        title: 'If you keep seeing this error, try:',
        steps: [
            'Refresh the page.',
            'Log out and log in again.',
            'Contact our support team.'
        ]
    },
    'es': {
        title: 'Si sigues viendo este error, intenta lo siguiente:',
        steps: [
            'Refresca la página.',
            'Cierra sesión y vuelve a iniciar sesión.',
            'Contacta con nuestro equipo de soporte.'
        ]
    },
    'fr': {
        title: 'Si cette erreur persiste, essayez les solutions suivantes :',
        steps: [
            'Actualisez la page.',
            'Déconnectez-vous et reconnectez-vous.',
            'Contactez notre équipe de support.'
        ]
    },
    'it': {
        title: 'Se continui a vedere questo errore, prova a:',
        steps: [
            'Aggiorna la pagina.',
            'Disconnettiti e accedi di nuovo.',
            'Contatta il nostro team di supporto.'
        ]
    },
    'nl': {
        title: 'Als je deze fout blijft zien, probeer het volgende:',
        steps: [
            'Ververs de pagina.',
            'Log uit en log weer in.',
            'Neem contact op met ons supportteam.'
        ]
    },
    'pl': {
        title: 'Jeśli ten błąd nadal się pojawia, spróbuj:',
        steps: [
            'Odśwież stronę.',
            'Wyloguj się i zaloguj ponownie.',
            'Skontaktuj się z naszym zespołem wsparcia.'
        ]
    },
    'pt': {
        title: 'Se continuar a ver este erro, tente:',
        steps: [
            'Atualize a página.',
            'Saia e faça login novamente.',
            'Contacte a nossa equipe de suporte.'
        ]
    },
    'ru': {
        title: 'Если вы продолжаете видеть эту ошибку, попробуйте:',
        steps: [
            'Обновите страницу.',
            'Выйдите и снова войдите в систему.',
            'Свяжитесь с нашей службой поддержки.'
        ]
    },
    'tr': {
        title: 'Bu hatayı görmeye devam ediyorsanız, şunları deneyin:',
        steps: [
            'Sayfayı yenileyin.',
            'Çıkış yapın ve tekrar giriş yapın.',
            'Destek ekibimizle iletişime geçin.'
        ]
    }
}

// !!!!!!!!!!! NEED TO UPDATE getDateWithTypeSite ON CHANGE (motherbase-justtheweb-web/src/services/site/mo_getDateWithType.ts) !!!!!!!!!!!

// !!!!!!!!!!! NEED TO UPDATE getScheduleSite ON CHANGE (motherbase-justtheweb-web/src/services/site/mo_getSchedule.ts) !!!!!!!!!!!

// !!!!!!!!!!! NEED TO UPDATE TemplateBlock797DateTimeSiteProps ON CHANGE (/Users/aupourbau/workspace/projects/motherbase--portfolio/src/pages/site/templates/block/797/mo_797DateTime.tsx) !!!!!!!!!!!

// !!!!!!!!!!! NEED TO UPDATE SEO ON CHANGE (motherbase--seo/src/seo/views/main.py) !!!!!!!!!!!

// !!!!!!!!!!! NEED TO UPDATE TEXT (https://api.justtheweb.io/en/admin/main/text/?components__id__exact=1020) !!!!!!!!!!!
