// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    groupingIdsSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

import * as templateBlock from './index'

// props
type TemplateBlock793SiteProps = {
    blockId: string
    content: PortfolioPageContentListSiteSerializer[]
    detailId: string | undefined
    devId: string | undefined
    inListIndex?: number
    isEditHovered: boolean
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInLayoutTypePr?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageSlug: string | undefined
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock793Site: React.FC<TemplateBlock793SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    devId,
    inListIndex,
    isEditHovered,
    isInComponent,
    isInDetailPage,
    isInLayoutTypePr,
    isInVideoBlock,
    mainParentId,
    object,
    pageSlug,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxFormSitehoveredBlockId = useSelector((state: defaultReduxState) => state.reduxFormSite.hoveredBlockId)
    const reduxFormSiteparentContent = useSelector((state: defaultReduxState) => state.reduxFormSite.parentContent)
    const reduxFormSiteeditAutoSave = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSave)
    const reduxFormSiteeditAutoSaved = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSaved)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<{
        left: string | number | undefined,
        margin: string | number | undefined,
        right: string | number | undefined,
        width: string | number | undefined,
    } | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                left: aStyles?.left,
                margin: aStyles?.margin,
                right: aStyles?.right,
                width: aStyles?.width,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock793Site',
                'applyStyles',
            ))
        }
    }

    const dropdownTextComponent: PortfolioPageContentListSiteSerializer[] = []
    const componentsArray: PortfolioPageContentListSiteSerializer[] = []
    content?.map((val, i) => {
        if (i > 0) {
            componentsArray.push(val)
        } else {
            dropdownTextComponent.push(val)
        }
    })

    return (
        <div
            id={blockId}
            className={`template-block-793${reduxFormSiteparentContent === object.id ? ' active' : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={{
                margin: stylesNew?.margin,
                width: stylesNew?.width,
            }}
        >
            {dropdownTextComponent.filter(obj => obj.active === true).map((val) => {
                // @ts-ignore
                const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                return (
                    <Component
                        key={val.id}
                        blockId={contentBlockId}
                        content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                        detailId={detailId}
                        devId={devId}
                        disableInfiniteScroll={true}
                        hasMore={false}
                        inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                        isEditHovered={reduxFormSitehoveredBlockId === val.id}
                        isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                        isInDetailPage={isInDetailPage}
                        isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                        mainParentId={mainParentId || object.id}
                        object={val}
                        onSearchNext={undefined}
                        pageSlug={pageSlug}
                        parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                        parentStyles={object.styles}
                        styles={val.styles}
                        stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                    />
                )
            })}
            {dropdownTextComponent[0]?.active && (
                <div
                    className={`t-793-dropdown-content${isInLayoutTypePr ? ' layout-type-pr' : ''} ${deviceType}`}
                    style={{
                        left: reduxModalSite.languageId === 19 ? stylesNew?.right : stylesNew?.left,
                        right: reduxModalSite.languageId === 19 ? stylesNew?.left : stylesNew?.right,
                    }}
                >
                    {componentsArray.filter(obj => obj.active === true).map((val) => {
                        // @ts-ignore
                        const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                        const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                        return (
                            <Component
                                key={val.id}
                                blockId={contentBlockId}
                                content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                                detailId={detailId}
                                devId={devId}
                                disableInfiniteScroll={true}
                                hasMore={false}
                                inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                                isEditHovered={reduxFormSitehoveredBlockId === val.id}
                                isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                                isInDetailPage={isInDetailPage}
                                isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                                mainParentId={mainParentId || object.id}
                                object={val}
                                onSearchNext={undefined}
                                pageSlug={pageSlug}
                                parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                                parentDropdownId={dropdownTextComponent[0]?.id}
                                parentStyles={object.styles}
                                styles={val.styles}
                                stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
})
