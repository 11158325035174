// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template801SiteSerializer,
} from 'serializers/site'

// services
import {
    getHostOriginSite,
    getStylesNew,
    parseQuerySite,
} from 'services'

// props
type TemplateBlock801SiteProps = {
    blockId: string
    content: Template801SiteSerializer | Template801SiteSerializer[] | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock801Site: React.FC<TemplateBlock801SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const location = useLocation()
    const reduxCacheSiteportfoliois_multi_language = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.is_multi_language)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (Array.isArray(content)) {
        return (
            <React.Fragment>
                {object.filter_show_all_tab && (
                    <TemplateBlock801HelperSite
                        blockId={`${blockId}-all`}
                        content={{
                            absolute_site_url: getHostOriginSite() === 'prod' ? location.pathname.split('/')[reduxCacheSiteportfoliois_multi_language ? 2 : 1] : location.pathname.split('/')[reduxCacheSiteportfoliois_multi_language ? 3 : 2],
                            name: reduxText[7464],
                        }}
                        isEditHovered={isEditHovered}
                        isInComponent={isInComponent}
                        mainParentId={mainParentId}
                        object={object}
                        parentArray={parentArray}
                        parentStyles={parentStyles}
                        styles={styles}
                        stylesEdit={stylesEdit}
                    />
                )}
                {content.map((item, i) => (
                    <TemplateBlock801HelperSite
                        key={item.id}
                        blockId={`${blockId}-${item.id}`}
                        content={item}
                        isEditHovered={isEditHovered}
                        isInComponent={isInComponent}
                        mainParentId={mainParentId}
                        object={object}
                        parentArray={parentArray}
                        parentStyles={parentStyles}
                        styles={styles}
                        stylesEdit={stylesEdit}
                    />
                ))}
            </React.Fragment>
        )
    }
    return null
})

// helpers

// props
type TemplateBlock801HelperSiteProps = {
    blockId: string
    content: Template801SiteSerializer | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
const TemplateBlock801HelperSite: React.FC<TemplateBlock801HelperSiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNormal, setStylesNormal] = useState<{
        styles: CustomCSSProperties | undefined,
        stylesActive: {
            color: string | undefined,
            fontWeight: string | undefined,
            textDecorationColor: string | undefined,
            textDecorationLine: string | undefined,
            textDecorationStyle: string | undefined,
            textDecorationThickness: string | undefined,
            textUnderlineOffset: string | undefined,
        } | undefined,
    } | undefined>(applyStyles())

    useEffect(() => {
        setStylesNormal(applyStyles())
    }, [
        content?.name,
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                styles: aStyles,
                stylesActive: {
                    background: aStyles?.hoverBackground,
                    borderColor: aStyles?.hoverBorderColor,
                    color: aStyles?.hoverColor,
                    fontWeight: aStyles?.hoverFontWeight,
                    opacity: aStyles?.hoverOpacity,
                    textDecorationColor: aStyles?.hoverTextDecorationColor,
                    textDecorationLine: aStyles?.hoverTextDecorationLine,
                    textDecorationStyle: aStyles?.hoverTextDecorationStyle,
                    textDecorationThickness: aStyles?.hoverTextDecorationThickness,
                    textUnderlineOffset: aStyles?.hoverTextUnderlineOffset,
                },
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock801HelperSite',
                'applyStyles',
            ))
        }
    }

    function getIsActive() {
        const searchObject = parseQuerySite(location.search)
        const filter_query = searchObject.filter_query
        let canBeActive
        if (object.data_json?.filter_default_value && !filter_query) {
            canBeActive = (Number(content?.id) === Number(object.data_json?.filter_default_value))
        } else {
            const contentId = filter_query?.split('content_id')[1]?.split('-')[1]?.split(',')[0]
            if ((Number(contentId) === content?.filter_related_content)) {
                canBeActive = (content?.filter_name && content?.id) && filter_query?.includes(`${content?.filter_name}-${content?.id}`)
            }
        }
        if (canBeActive) return true
        return false
    }

    return (
        <LinkHelperSite
            id={blockId}
            action='page_link'
            className={`template-block-801${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            isActive={getIsActive()}
            onClick={() => undefined}
            span
            style={stylesNormal?.styles}
            // @ts-ignore
            styleActive={stylesNormal?.stylesActive}
            to={content?.absolute_site_url}
        >
            {content?.name}
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </LinkHelperSite>
    )
})
