// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesTicketSite,
} from 'data'

// serializers
import {
    Template816SiteSerializer,
    Variant816HelperSiteSerializer,
} from 'serializers/site'

// props
type VariantHelper816SiteProps = {
    index: number
    object: Variant816HelperSiteSerializer
    product: Template816SiteSerializer
}

// main
export const VariantHelper816Site: React.FC<VariantHelper816SiteProps> = React.memo(({
    index,
    object,
    product,
}) => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)

    const deviceType = reduxModalSite.deviceType
    const qty = reduxSalesSite.ticketData?.variants?.[index]?.qty || 0

    function onSetQty(value: number) {
        try {
            const toReturnData = {
                ...reduxSalesSite.ticketData,
                product: product,
                variants: {
                    ...reduxSalesSite.ticketData?.variants,
                    [index]: {
                        index: index,
                        variant: object,
                        qty: value,
                    },
                },
            }
            dispatch(reduxSalesTicketSite(toReturnData))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'VariantHelper816Site',
                'onSetQty',
            ))
        }
    }

    return (
        <div className={`t-816-variant ${deviceType}`}>
            <div className={`t-816-variant-name ${deviceType}`}>
                <span>{object.name}</span>
            </div>
            <div className={`t-816-variant-price-wrap ${deviceType}`}>
                <div className={`t-816-variant-price ${deviceType}`}>
                    <span>{object.unit_price} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                </div>
                <div className={`t-816-input-wrap ${deviceType}`}>
                    <input
                        min={0}
                        name='qty'
                        onChange={(e) => onSetQty(Number(e.target.value) >= 0 ? Number(e.target.value) : 0)}
                        type='number'
                        value={qty.toString() || ''}
                    />
                </div>
            </div>
        </div>
    )
})
