// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
} from 'serializers/site'

// props
type ButtonSiteProps = {
    action?: string
    buttonCssClass: string
    buttonStyle: CustomCSSProperties | undefined
    buttonWrapCssClass: string
    buttonWrapStyle: CustomCSSProperties | undefined
    deviceType: string
    disabled: boolean
    dowloadFile?: string
    hoverStyle: CustomCSSProperties | undefined
    onClick: (e: any) => void
    text: string | undefined
    to?: string
}

// main
export const ButtonSite: React.FC<ButtonSiteProps> = React.memo(({
    action,
    buttonCssClass,
    buttonStyle,
    buttonWrapCssClass,
    buttonWrapStyle,
    deviceType,
    disabled,
    dowloadFile,
    hoverStyle,
    onClick,
    text,
    to,
}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        if (buttonStyle) {
            setNewStyles(buttonStyle)
        } else {
            setNewStyles(undefined)
        }
    }, [
        buttonStyle,
        hoverStyle,
    ])

    const [newStyles, setNewStyles] = useState<any>(undefined)

    function onHover(direction: string) {
        try {
            if (hoverStyle) {
                if (direction === 'over') {
                    if (buttonStyle) {
                        const newnewStyle = Object.assign({}, buttonStyle)
                        if (hoverStyle.background) newnewStyle.background = hoverStyle.background
                        if (hoverStyle.borderColor) newnewStyle.borderColor = hoverStyle.borderColor
                        if (hoverStyle.color) newnewStyle.color = hoverStyle.color
                        setNewStyles(newnewStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                } else {
                    if (buttonStyle) {
                        setNewStyles(buttonStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ButtonSite',
                'onHover',
            ))
        }
    }

    return (
        <div
            className={`button-site ${deviceType} ${buttonWrapCssClass}`}
            style={buttonWrapStyle}
        >
            <div
                className={`bs-wrap ${deviceType} ${disabled ? 'disabled' : ''}`}
                onMouseLeave={() => onHover('leave')}
                onMouseOver={() => onHover('over')}
                style={{
                    width: buttonStyle?.width,
                }}
            >
                <LinkHelperSite
                    action={action}
                    className={`bs-button ${deviceType} ${disabled ? 'disabled' : ''} ${buttonCssClass}`}
                    dowloadFile={dowloadFile}
                    onClick={(e: any) => onClick(e)}
                    style={newStyles}
                    to={to}
                >
                    {text}
                </LinkHelperSite>
            </div>
        </div>
    )
})
