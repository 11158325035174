// react components
import React, {
    useEffect,
} from 'react'

// data
import {
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldSerializer,
} from 'serializers/site'

// props
type TemplateBlock797InputSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInputObj: FormFieldSerializer | undefined
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    maxLength?: number | undefined
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
    value: string | undefined
}

// main
export const TemplateBlock797InputSite: React.FC<TemplateBlock797InputSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInputObj,
    handleInputChange,
    isEditHovered,
    maxLength,
    reduxModalSite,
    stylesNew,
    tabIndex,
    value,
}) => {

    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            // @ts-ignore
            if (document.activeElement?.type === 'number') {
                // @ts-ignore
                document.activeElement.blur()
            }
        }
        window.addEventListener('wheel', handleWheel, { passive: true })
        return () => {
            window.removeEventListener('wheel', handleWheel)
        }
    }, [])

    return (
        <input
            id={blockId}
            className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            maxLength={maxLength}
            name={formInputObj?.name}
            onChange={(e) => handleInputChange(formInputObj?.name!, e.target.value, formInputObj?.label!, formInputObj?.stripe_field_type)}
            placeholder={formInputObj?.placeholder === 'true' ? formInputObj?.label : undefined}
            style={stylesNew}
            // tabIndex={tabIndex}  bug when mutpliple form in page (including footer or header)
            type={formInputObj?.field_type}
            value={value}
        />
    )
})
