// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxAuthAfterAuthUrlSetSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    TemplateBlock806LoginSite,
    TemplateBlock806PasswordResetSite,
    TemplateBlock806SignupSite,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template806SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

// props
type TemplateBlock806SiteProps = {
    blockId: string
    content: Template806SiteSerializer | undefined
    object: PortfolioPageContentListSiteSerializer
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock806Site: React.FC<TemplateBlock806SiteProps> = React.memo(({
    blockId,
    content,
    object,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [email, setEmail] = useState('')
    const [panel, setPanel] = useState<'login' | 'password-reset' | 'signup'>('login')

    const [stylesNew, setStylesNew] = useState<{
        loginButtonBackground: string | undefined,
        loginButtonBorderColor: string | undefined,
        loginButtonHoverBackground: string | undefined,
        loginButtonHoverBorderColor: string | undefined,
        loginButtonHoverTextColor: string | undefined,
        loginButtonTextColor: string | undefined,
        loginInputBackgroundColor: string | undefined,
        loginInputColor: string | undefined,
        loginTextColor: string | undefined,
    } | undefined>(applyStyles())
    const [buttonStyles, setButtonStyles] = useState<any>({
        background: stylesNew?.loginButtonBackground,
        borderColor: stylesNew?.loginButtonBorderColor,
        color: stylesNew?.loginButtonTextColor,
    })

    useEffect(() => {
        if (!['edit', 'navMixed', 'preview'].includes(reduxFormSitenavigationMode!)) {
            if (!reduxAuth?.afterAuthUrl) {
                dispatch(reduxAuthAfterAuthUrlSetSite(`${reduxModalSite.rootUrl}${content?.after_auth_url || ''}`))
            }
        }
    }, [])

    useEffect(() => {
        const tempStyles = applyStyles()
        setStylesNew(tempStyles)
        setButtonStyles({
            background: applyStyles()?.loginButtonBackground,
            borderColor: applyStyles()?.loginButtonBorderColor,
            color: applyStyles()?.loginButtonTextColor,
        })
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                loginButtonBackground: aStyles?.loginButtonBackground,
                loginButtonBorderColor: aStyles?.loginButtonBorderColor,
                loginButtonHoverBackground: aStyles?.loginButtonHoverBackground,
                loginButtonHoverBorderColor: aStyles?.loginButtonHoverBorderColor,
                loginButtonHoverTextColor: aStyles?.loginButtonHoverTextColor,
                loginButtonTextColor: aStyles?.loginButtonTextColor,
                loginInputBackgroundColor: aStyles?.loginInputBackgroundColor,
                loginInputColor: aStyles?.loginInputColor,
                loginTextColor: aStyles?.loginTextColor,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806Site',
                'applyStyles',
            ))
        }
    }

    function onHover(direction: string) {
        try {
            if (direction === 'over') {
                if (stylesNew) {
                    const newnewStyle: any = {}
                    if (stylesNew.loginButtonHoverBackground) newnewStyle.background = stylesNew.loginButtonHoverBackground
                    if (stylesNew.loginButtonHoverBorderColor) newnewStyle.borderColor = stylesNew.loginButtonHoverBorderColor
                    if (stylesNew.loginButtonHoverTextColor) newnewStyle.color = stylesNew.loginButtonHoverTextColor
                    setButtonStyles(newnewStyle)
                } else {
                    setButtonStyles(undefined)
                }
            } else {
                if (stylesNew) {
                    const newnewStyle: any = {}
                    newnewStyle.background = stylesNew.loginButtonBackground
                    newnewStyle.borderColor = stylesNew.loginButtonBorderColor
                    newnewStyle.color = stylesNew.loginButtonTextColor
                    setButtonStyles(newnewStyle)
                } else {
                    setButtonStyles(undefined)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806Site',
                'onHover',
            ))
        }
    }

    if (!reduxFormSitenavigationMode && reduxAuth?.authenticated) {
        if (reduxAuth.afterAuthUrl) {
            return <Redirect to={reduxAuth.afterAuthUrl} />
        }
        return <Redirect to={reduxModalSite.rootUrl || '/'} />
    }
    if (panel === 'login') {
        return (
            <TemplateBlock806LoginSite
                blockId={blockId}
                buttonStyles={buttonStyles}
                object={object}
                onHover={onHover}
                setEmail={setEmail}
                setPanel={setPanel}
                stylesNew={stylesNew}
            />
        )
    }
    if (panel === 'password-reset') {
        return (
            <TemplateBlock806PasswordResetSite
                blockId={blockId}
                buttonStyles={buttonStyles}
                email={email}
                object={object}
                onHover={onHover}
                setPanel={setPanel}
                stylesNew={stylesNew}
            />
        )
    }
    if (panel === 'signup') {
        return (
            <TemplateBlock806SignupSite
                blockId={blockId}
                buttonStyles={buttonStyles}
                email={email}
                object={object}
                onHover={onHover}
                setPanel={setPanel}
                stylesNew={stylesNew}
            />
        )
    }
    return null
})
