// react components
import React, {
    useState,
} from 'react'
import {
    useStripe,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LoaderSite,
} from 'components'

// data
import {
    api_url_portfolio_sales_stripe_create,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesSetInfoSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// props
type StripeCheckoutSiteProps = {
    billing_info: any
    contact_info: any
    setErrors: React.Dispatch<any>
    setHasError: React.Dispatch<boolean>
    setMainErrors: React.Dispatch<any>
    shipping_info: any
}

// main
export const StripeCheckoutSite: React.FC<StripeCheckoutSiteProps> = React.memo(({
    billing_info,
    contact_info,
    setErrors,
    setHasError,
    setMainErrors,
    shipping_info,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)
    const stripe = useStripe()

    const deviceType = reduxModalSite.deviceType

    const [isLoading, setIsLoading] = useState(false)

    function handleCheckData() {
        try {
            let okToPay = true
            const errors: any = {
                billing_info: {},
                contact_info: {},
                shipping_info: {},
            }
            Object.keys(contact_info).map((val) => {
                if (val !== 'phone' && !contact_info[val]) {
                    okToPay = false
                    errors.contact_info[val] = reduxText[7489]
                } else if (val === 'email') {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!re.test(contact_info[val])) {
                        okToPay = false
                        errors.contact_info[val] = reduxText[7490]
                    }
                }
                return false
            })
            Object.keys(shipping_info).map((val) => {
                const optionalFields = ['number']
                if (!optionalFields.includes(val) && !shipping_info[val]) {
                    okToPay = false
                    errors.shipping_info[val] = reduxText[7489]
                }
                return false
            })
            if (!billing_info.same_as_shipping) {
                const optionalFields = ['number']
                Object.keys(billing_info).map((val) => {
                    if (val !== 'same_as_shipping' && !optionalFields.includes(val) && !billing_info[val]) {
                        okToPay = false
                        errors.billing_info[val] = reduxText[7489]
                    }
                    return false
                })
            }
            setErrors(errors)
            setHasError(!okToPay)
            return okToPay
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'StripeCheckoutSite',
                'handleCheckData',
            ))
        }
    }

    function handleStripe() {
        try {
            if (!handleCheckData()) return
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) handleStripe')
            dispatch(reduxSalesSetInfoSite(
                billing_info,
                contact_info,
                shipping_info,
            ))
            const sales_order_lines: any = []
            reduxSalesSite.salesLines.map((val) => {
                const packageOptionArray: number[] = []
                val.package_option_items?.map((val2) => {
                    packageOptionArray.push(val2.id!)
                })
                sales_order_lines.push({
                    package_option_items: packageOptionArray,
                    product: val.product.id,
                    product_variant: val.variant?.id,
                    quantity: val.qty,
                    unit_price: val.product.unit_price,
                })
                return false
            })
            const same_as_shipping = billing_info.same_as_shipping
            const formData = {
                billing_address: same_as_shipping ? shipping_info.address : billing_info.address,
                billing_city: same_as_shipping ? shipping_info.city : billing_info.city,
                billing_country: same_as_shipping ? shipping_info.country.id : billing_info.country.id,
                billing_first_name: same_as_shipping ? contact_info.first_name : billing_info.first_name,
                billing_last_name: same_as_shipping ? contact_info.last_name : billing_info.last_name,
                billing_zip_code: same_as_shipping ? shipping_info.zip_code : billing_info.zip_code,
                customer_email: contact_info.email,
                customer_first_name: contact_info.first_name,
                customer_last_name: contact_info.last_name,
                customer_phone: contact_info.phone,
                portfolio: reduxCacheSite.portfolio?.id,
                sales_order_lines: sales_order_lines,
                shipping_address: shipping_info.address,
                shipping_city: shipping_info.city,
                shipping_country: shipping_info.country.id,
                shipping_zip_code: shipping_info.zip_code,
            }
            let postUrl = getApiUrlSite(api_url_portfolio_sales_stripe_create, reduxModalSite)
            if (reduxSalesSite.shippingService) {
                postUrl += `?shipping_service_id=${reduxSalesSite.shippingService}`
            }
            if (reduxModalSite.currencyId) {
                if ((postUrl).includes('?')) {
                    postUrl += `&currency_id=${reduxModalSite.currencyId}`
                } else {
                    postUrl += `?currency_id=${reduxModalSite.currencyId}`
                }
            }
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleStripe')
                    stripe!.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: response.data.stripe_session_id,
                    }).then((result: any) => {
                        if (process.env.NODE_ENV === 'development') console.log(result)
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                    })
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleStripe')
                    setMainErrors(error?.response?.data)
                    // thisState.setState({
                    // 	tabValue: 'payment-error',  // TODO
                    // })
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'StripeCheckoutSite',
                        dispatch,
                        error,
                        formFields: JSON.stringify(formData),
                        reduxAuth,
                        reduxModalSite,
                        reference: 'handleStripe',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'StripeCheckoutSite',
                'handleStripe',
            ))
        }
    }

    if (Number(reduxSalesSite.subTotal) === 0) {
        return (
            <div className={`portfolio-checkout-form ${deviceType}`}>
                <div className={`pc-validation ${deviceType}`}>
                    <button
                        className={`pc-validation-button error ${deviceType}`}
                        disabled
                    >
                        {reduxText[7466]}
                    </button>
                </div>
                <div className={`cf-text-stripe ${deviceType}`}>
                    <span>{reduxText[7492]}{' '}</span><a href='https://stripe.com/' target='_blank' rel='noopener noreferrer'>Stripe</a>
                </div>
                {isLoading && (
                    <LoaderSite isOpen />
                )}
            </div>
        )
    }

    return (
        <div className={`portfolio-checkout-form ${deviceType}`}>
            <div className={`pc-validation ${deviceType}`}>
                <button
                    className={`pc-validation-button ${deviceType}`}
                    onClick={handleStripe}
                >
                    {reduxText[7491]}{' '}{Number((Number(reduxSalesSite.subTotal) + Number(reduxSalesSite.shippingTotal || 0)).toFixed(2))} {reduxSalesSite.currency_symbol}
                </button>
            </div>
            <div className={`cf-text-stripe ${deviceType}`}>
                <span>{reduxText[7492]}{' '}</span><a href='https://stripe.com/' target='_blank' rel='noopener noreferrer'>Stripe</a>
            </div>
            {isLoading && (
                <LoaderSite isOpen />
            )}
        </div>
    )
})
