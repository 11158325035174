// react components
import React from 'react'
import axios from 'axios'

// data
import {
    playerTrackTypeSite,
    reduxAuthStateSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

export const PLAYER_ON_DISMISS_SITE = 'PLAYER_ON_DISMISS_SITE'
export const PLAYER_ON_PLAY_PAUSE_INLINE_SITE = 'PLAYER_ON_PLAY_PAUSE_INLINE_SITE'
export const PLAYER_ON_PLAY_PAUSE_SITE = 'PLAYER_ON_PLAY_PAUSE_SITE'
export const PLAYER_ON_READY_SITE = 'PLAYER_ON_READY_SITE'
export const PLAYER_SET_CURRENT_TRACK_ALBUM_SITE = 'PLAYER_SET_CURRENT_TRACK_ALBUM_SITE'
export const PLAYER_SET_CURRENT_TRACK_TRACK_SITE = 'PLAYER_SET_CURRENT_TRACK_TRACK_SITE'
export const PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM_SITE = 'PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM_SITE'
export const PLAYER_SET_NEXT_TRACK_ARRAY_TRACK_SITE = 'PLAYER_SET_NEXT_TRACK_ARRAY_TRACK_SITE'
export const PLAYER_SET_POSITION_SITE = 'PLAYER_SET_POSITION_SITE'

// actions
export function reduxPlayerOnDismissSite() {
    return {
        type: PLAYER_ON_DISMISS_SITE,
    }
}

export function reduxPlayerOnPlayPauseInlineSite(playingInlineId: number | undefined, keepNextBlocks?: boolean) {
    return {
        type: PLAYER_ON_PLAY_PAUSE_INLINE_SITE,
        payload: {
            keepNextBlocks: keepNextBlocks,
            playingInlineId: playingInlineId,
        },
    }
}

export function reduxPlayerOnPlayPauseSite(playOrPause?: any) {
    return {
        type: PLAYER_ON_PLAY_PAUSE_SITE,
        playOrPause: playOrPause,
    }
}

export function reduxPlayerOnReadySite() {
    return {
        type: PLAYER_ON_READY_SITE,
    }
}

export function reduxPlayerSetCurrentTrackSite(
    object: playerTrackTypeSite,
    reduxAuth: reduxAuthStateSite,
    reduxModalSite: reduxModalStateSite,
    dispatch2: React.Dispatch<any>,
    playerPosition: string | undefined,
    playerOrigin: string | undefined,
    items?: playerTrackTypeSite[],
    originalUrl?: string,
) {
    return function (dispatch: React.Dispatch<any>) {
        if (object?.medium_type && [1, 4].includes(object.medium_type)) {  // For album and series
            const getUrl = getApiUrlSite(`project/list/project/album_children/${object?.id}`, reduxModalSite)
            axios({
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const playableItemsArray: any[] = []
                    const newOriginalTrackArray: any[] = []
                    if (Array.isArray(items)) {
                        items.map((val: any) => {
                            if (val.id === object.id) { // Inject album tracks inside trackArray
                                response.data.results.map((val: any) => {
                                    playableItemsArray.push(val)
                                    return false
                                })
                            } else if (val.is_playable) { // Inject other tracks from trackArray
                                playableItemsArray.push(val)
                            }
                            if (val.is_playable) {
                                newOriginalTrackArray.push(val)
                            }
                            return false
                        })
                    }
                    dispatch({
                        type: PLAYER_SET_CURRENT_TRACK_ALBUM_SITE,
                        albumId: object.id,
                        albumNextUrl: response.data.next,
                        albumObject: object,
                        albumTrackArray: response.data.results,
                        currentTrack: response.data.results[0],
                        currentTrackIndex: playableItemsArray.findIndex((obj) => obj.id === response.data.results[0].id),
                        dispatch2: dispatch2,
                        originalTrackArray: newOriginalTrackArray,
                        originalUrl: originalUrl,
                        playerOrigin: playerOrigin,
                        playerPosition: playerPosition,
                        reduxAuth: reduxAuth,
                        reduxModalSite: reduxModalSite,
                        trackArray: playableItemsArray,
                    })
                })
                .catch((error) => {
                    axiosErrorHandlerSite({
                        apiUrl: getUrl,
                        component: 'reduxPlayer',
                        dispatch: dispatch2,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'PLAYER_SET_CURRENT_TRACK',
                    })
                })
        } else {
            const playableItemsArray: any[] = []
            if (Array.isArray(items)) {
                items.map((val: any) => {
                    if (val.is_playable) playableItemsArray.push(val)
                    return false
                })
            }
            dispatch({
                type: PLAYER_SET_CURRENT_TRACK_TRACK_SITE,
                currentTrack: object,
                currentTrackIndex: playableItemsArray.findIndex((obj) => obj.id === object.id),
                dispatch2: dispatch2,
                originalUrl: originalUrl,
                playerOrigin: playerOrigin,
                playerPosition: playerPosition,
                reduxAuth: reduxAuth,
                reduxModalSite: reduxModalSite,
                trackArray: playableItemsArray,
            })
        }
    }
}

export function reduxPlayerSetNextTrackArraySite(
    getUrl: string,
    playerOrigin: string,
    originalTrackArray: any[],
    trackArray: any[],
    reduxAuth: reduxAuthStateSite,
    reduxModalSite: reduxModalStateSite,
    dispatch2: React.Dispatch<any>,
    albumId?: number,
    albumTrackArray?: any[],
) {
    return function (dispatch: React.Dispatch<any>) {
        const formData: any = new FormData()
        const ids_to_skip: any[] = []
        originalTrackArray.map((val: any) => {
            ids_to_skip.push(val.id)
            return false
        })
        formData.append('player_origin', playerOrigin)
        formData.append('ids_to_skip', ids_to_skip.join(','))
        axios({
            data: formData,
            headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
            method: 'post',
            url: getUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                const playableItemsArray: any[] = []
                const newAlbumTrackArray: any[] = []
                if (albumId && albumTrackArray) {
                    originalTrackArray.map((val: any) => {
                        if (val.id === albumId) { // Inject album tracks inside trackArray
                            albumTrackArray.map((val: any) => {
                                playableItemsArray.push(val)
                                newAlbumTrackArray.push(val)
                                return false
                            })
                            response.data.results.map((val: any) => {
                                playableItemsArray.push(val)
                                newAlbumTrackArray.push(val)
                                return false
                            })
                        } else if (val.is_playable) { // Inject other tracks from trackArray
                            playableItemsArray.push(val)
                        }
                        return false
                    })
                    dispatch({
                        type: PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM_SITE,
                        newAlbumNextUrl: response.data.next,
                        newAlbumTrackArray: newAlbumTrackArray,
                        newTrackArray: playableItemsArray,
                        nextType: 'album',
                    })
                } else {
                    const playableItemsArray: any[] = []
                    response.data.results.map((val: any) => {
                        if (playerOrigin.includes('feedList')) {
                            if (val.content_object && val.content_object.is_playable) playableItemsArray.push(val.content_object)
                        } else {
                            if (val.is_playable) playableItemsArray.push(val)
                        }
                        return false
                    })
                    dispatch({
                        type: PLAYER_SET_NEXT_TRACK_ARRAY_TRACK_SITE,
                        newOriginalTrackArray: [...originalTrackArray, ...playableItemsArray],
                        newTrackArray: [...trackArray, ...playableItemsArray],
                        nextType: 'normal',
                    })
                }
            })
            .catch((error) => {
                axiosErrorHandlerSite({
                    apiUrl: getUrl,
                    component: 'reduxPlayer',
                    dispatch: dispatch2,
                    error,
                    reduxAuth,
                    reduxModalSite,
                    reference: 'PLAYER_SET_NEXT_TRACK_ARRAY',
                })
            })
    }
}

export function reduxPlayerSetPositionSite(val: string) {
    return {
        type: PLAYER_SET_POSITION_SITE,
        payload: val,
    }
}
