// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    GoogleLoginButtonSite,
    IconBlockSite,
    LinkHelperSite,
    LoaderSite,
} from 'components'

// data
import {
    api_url_auth_autorisation_check_site,
    defaultReduxState,
    reduxAuthLoginSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    validateEmailSite,
} from 'services'

// props
type TemplateBlock806LoginSiteProps = {
    blockId: string
    buttonStyles: any
    object: PortfolioPageContentListSiteSerializer
    onHover: any
    setEmail: React.Dispatch<React.SetStateAction<string>>
    setPanel: React.Dispatch<React.SetStateAction<'login' | 'password-reset' | 'signup'>>
    stylesNew: any
}

// main
export const TemplateBlock806LoginSite: React.FC<TemplateBlock806LoginSiteProps> = React.memo(({
    blockId,
    buttonStyles,
    object,
    onHover,
    setEmail,
    setPanel,
    stylesNew,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    type errorsType = {
        detail?: string
        non_field_errors?: string
        password?: string
        username?: string
    }
    type fieldsType = {
        password: string
        username: string
    }
    const errorsInitial = {}
    const fieldsInitial = {
        password: '',
        username: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [isMailAutorised, setIsMailAutorised] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    function handleInputChange(event: any) {
        try {
            const name = event.target.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.target.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806LoginSite',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            setButtonDisabled(true)
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) onSubmit')
            // @ts-ignore
            dispatch(reduxAuthLoginSite(
                fields.username,
                fields.password,
                reduxAuth,
                reduxModalSite,
                dispatch,
                setIsLoading,
                setFields,
                fieldsInitial,
                reduxCacheSiteportfolioid!,
                setErrors,
            ))
        } catch (error) {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806LoginSite',
                'onSubmit',
            ))
        }
    }

    function onCheckAutrorisedMail() {
        try {
            if (!validateEmailSite(fields.username)) {
                setErrors({ username: reduxText[7490] })
                return
            }
            setButtonDisabled(true)
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) onCheckAutrorisedMail')
            const formData: any = new FormData()
            formData.append('email', fields.username)
            formData.append('portfolioId', reduxCacheSiteportfolioid)
            const postUrl = getApiUrlSite(api_url_auth_autorisation_check_site, reduxModalSite)
            if (process.env.NODE_ENV === 'development') console.log(postUrl)
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (response.data.panel === 'login') {
                        setIsMailAutorised(true)
                        setEmail(fields.username)
                    } else if (response.data.panel === 'signup') {
                        setEmail(fields.username)
                        setPanel('signup')
                    }
                })
                .catch((error) => {
                    if (process.env.NODE_ENV === 'development') console.log(error)
                    // @ts-ignore
                    setErrors(error?.response?.data)
                    setIsLoading(false)
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'TemplateBlock783HelperSite',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reduxModalSite,
                        reference: 'submitContactForm',
                        skipAlertStatusCode: [403],
                        skipSendErrorStatusCode: [403],
                    })
                })

        } catch (error) {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onCheckAutrorisedMail')
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806LoginSite',
                'onCheckAutrorisedMail',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-806 ${deviceType}`}
        >
            {!object.data_json?.hide_google_button && (
                <React.Fragment>
                    <div className={`t-806-google ${deviceType}`}>
                        <GoogleLoginButtonSite
                            setIsLoading={setIsLoading}
                            signIn
                        />
                    </div>
                    <div className={`t-806-separation-block ${deviceType}`}>
                        <div className={`t-806-separation-line ${deviceType}`}>
                            <hr style={{ borderColor: stylesNew.loginTextColor }} />
                        </div>
                        <div className={`t-806-separation-text ${deviceType}`}>
                            <p style={{ color: stylesNew.loginTextColor }}>{reduxText[8419]}</p>
                        </div>
                        <div className={`t-806-separation-line ${deviceType}`}>
                            <hr style={{ borderColor: stylesNew.loginTextColor }} />
                        </div>
                    </div>
                </React.Fragment>
            )}
            <div className={`t-806-input-wrap ${deviceType}`}>
                <p
                    className={`t-806-label ${deviceType}`}
                    style={{ color: stylesNew.loginTextColor }}
                >
                    {reduxText[8421]}
                </p>
                <input
                    className={`t-806-input ${deviceType}`}
                    disabled={isMailAutorised}
                    name='username'
                    onChange={handleInputChange}
                    style={{
                        background: stylesNew.loginInputBackgroundColor,
                        color: stylesNew.loginInputColor,
                    }}
                    tabIndex={1}
                    type='text'
                />
                {errors?.username && (
                    <span className={`t-806-error ${deviceType}`}>{errors?.username}</span>
                )}
            </div>
            {isMailAutorised && (
                <div className={`t-806-input-wrap ${deviceType}`}>
                    <div className={`t-806-label-wrap ${deviceType}`}>
                        <p
                            className={`t-806-label ${deviceType}`}
                            style={{ color: stylesNew.loginTextColor }}
                        >
                            {reduxText[8422]}
                        </p>
                        <IconBlockSite
                            className={`t-806-icon ${deviceType}`}
                            iconClass={showPassword ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'}
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ color: stylesNew.loginTextColor }}
                        />
                    </div>
                    <input
                        className={`t-806-input ${deviceType}`}
                        name='password'
                        onChange={handleInputChange}
                        style={{
                            background: stylesNew.loginInputBackgroundColor,
                            color: stylesNew.loginInputColor,
                        }}
                        tabIndex={2}
                        type={showPassword ? 'text' : 'password'}
                    />
                    {errors?.password && (
                        <span className={`t-806-error ${deviceType}`}>{errors?.password}</span>
                    )}
                </div>
            )}
            <div className={`t-806-button-wrap ${deviceType}`}>
                <button
                    className={`t-806-button ${deviceType}`}
                    disabled={buttonDisabled || isLoading}
                    onClick={isMailAutorised ? onSubmit : onCheckAutrorisedMail}
                    onMouseLeave={() => onHover('leave')}
                    onMouseOver={() => onHover('over')}
                    style={buttonStyles}
                    type='button'
                >
                    {reduxText[8424]}
                </button>
                {(errors?.non_field_errors || errors?.detail) && (
                    <span className={`t-806-error ${deviceType}`}>{`${errors.non_field_errors || errors.detail}`}</span>
                )}
            </div>
            {isMailAutorised && (
                <div className={`t-806-link-wrap ${deviceType}`}>
                    <LinkHelperSite
                        action='page_link'
                        className={`t-806-link ${deviceType}`}
                        onClick={() => setPanel('password-reset')}
                        style={{ color: stylesNew.loginTextColor }}
                        to={undefined}
                    >
                        {reduxText[8532]}
                    </LinkHelperSite>
                </div>
            )}
            {isLoading && (
                <LoaderSite isOpen />
            )}
        </div>
    )
})
