// react components
import React from 'react'
import PhoneInput from 'react-phone-number-input'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// styles
import 'react-phone-number-input/style.css'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// props
type PhoneNumberInput819SiteProps = {
    error: string | undefined
    label: string
    name: string
    onChange: any
    value: string | number
}

// main
export const PhoneNumberInput819Site: React.FC<PhoneNumberInput819SiteProps> = React.memo(({
    error,
    label,
    name,
    onChange,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    function onInputChange(value: string) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PhoneNumberInput819Site',
                'onInputChange',
            ))
        }
    }

    return (
        <div className={`input-819 phone-number-input-819 ${deviceType}`}>
            <span className={`input-819-label ${deviceType}`}>{label}</span>
            <PhoneInput
                defaultCountry='FR'  // TODO dynamic
                // @ts-ignore
                onChange={onInputChange}
                // @ts-ignore
                value={value || ''}
            />
            {error && <span className='input-819-error'>{error}</span>}
        </div>
    )
})
