// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    api_url_portfolio_event_participant_form,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpenSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    CheckboxInput816Site,
    DateTimeInput816Site,
    EditBlockSite,
    LocationInput816Site,
    PhoneNumberInput816Site,
    TextAreaInput816Site,
    TextInput816Site,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template816SiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    validateEmailSite,
} from 'services'

// props
type Free816SiteProps = {
    blockId: string
    content: Template816SiteSerializer | undefined
    detailId: string | undefined
    isEditHovered: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: any
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const Free816Site: React.FC<Free816SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    isEditHovered,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [helpers, setHelpers] = useState<{
        buttonDisabled?: boolean
        isLoading?: boolean
        isSuccess?: boolean
        isSuccessText?: string
    }>({})
    const [errors, setErrors] = useState<{
        [key: string]: string
    }>({})
    const [fields, setFields] = useState<{
        [key: string]: any
    }>({})

    const deviceType = reduxModalSite.deviceType

    if (!content) return null

    function handleInputChange(e: any) {
        try {
            setErrors({})
            setFields({
                ...fields,
                [e.name]: e.value,
            })
            setHelpers({
                ...helpers,
                buttonDisabled: false,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'FreeParticipantHelper816Site',
                'handleInputChange',
            ))
        }
    }

    function handleSubmit() {
        try {
            const formData: any = new FormData()
            const postUrl = getApiUrlSite(api_url_portfolio_event_participant_form, reduxModalSite)
            if (object.is_mixed_media) {
                formData.append('mixed_block_id', object.id)
                formData.append('portfolio_id', reduxCacheSiteportfolioid)
            } else {
                formData.append('block_id', object.id)
            }
            formData.append('from', window.location.href)
            formData.append('event', detailId?.split('-')[0])
            const errorsData: {
                name: string
                errorText: string
            }[] = []
            content?.form_fields?.map((val) => {
                console.log('fields[val.name!]', fields[val.name!])
                if (val.required && !fields[val.name!]) {
                    errorsData.push({
                        errorText: reduxText[7489],
                        name: val.name!,
                    })
                }
                if (val.field_type === 'email' && fields[val.name!] && !validateEmailSite(fields[val.name!])) {
                    errorsData.push({
                        errorText: reduxText[7490],
                        name: val.name!,
                    })
                }
                if (val.field_type === 'fileinput') {
                    const fileField: any = fields[val.name!]
                    if (fileField?.type) {
                        const filetoUpload = fileField
                        let filetoUploadName = filetoUpload.name
                        if (filetoUploadName?.length! > 100) {
                            filetoUploadName = filetoUploadName?.slice(filetoUploadName.length - 100)
                        }
                        formData.append(val.name, filetoUpload, filetoUploadName)
                    }
                } else {
                    formData.append(val.name!, fields[val.name!])
                }
                return false
            })
            console.log('errorsData', errorsData)
            if (errorsData.length > 0) {
                const newErrors: {
                    [key: string]: string
                } = {}
                newErrors.main_error = reduxText[7534]
                errorsData.map((val2) => {
                    newErrors[val2.name] = val2.errorText
                    return false
                })
                setErrors(newErrors)
                setHelpers({
                    ...helpers,
                    buttonDisabled: true,
                })
            } else {
                setHelpers({
                    ...helpers,
                    buttonDisabled: true,
                    isLoading: true,
                })
                axios({
                    data: formData,
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        dispatch(reduxFormSetIsSuccessToastOpenSite(true, 'Inscription envoyée'))
                        setErrors({})
                        setFields({})
                        setHelpers({
                            ...helpers,
                            buttonDisabled: true,
                            isLoading: false,
                            isSuccess: true,
                            isSuccessText: content?.text2,
                        })
                    })
                    .catch((error) => {
                        if (process.env.NODE_ENV === 'development') console.log(error)
                        setErrors(error.response?.data)
                        setHelpers({
                            ...helpers,
                            buttonDisabled: true,
                            isLoading: false,
                        })
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock783HelperSite',
                            dispatch,
                            error,
                            formFields: JSON.stringify(fields),
                            reduxAuth,
                            reduxModalSite,
                            reference: 'handleSubmit',
                            skipAlertStatusCode: [400],
                        })
                    })
            }
        } catch (error) {
            setHelpers({
                ...helpers,
                isLoading: false,
            })
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783HelperSite',
                'handleSubmit',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-816${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
        >
            <div className={`t-816-participant ${deviceType}`}>
                <div className={`t-816-participant-wrap ${deviceType}`}>
                    <div className={`t-816-participant-inputs ${deviceType}`}>
                        {(content?.form_fields?.length! > 0)
                            ? (
                                <>
                                    {content?.form_fields?.map(val => {
                                        if (val.field_type === 'email') {
                                            return (
                                                <TextInput816Site
                                                    error={errors[val.name!]}
                                                    key={val.id}
                                                    label={val.label!}
                                                    name={val.name!}
                                                    onChange={handleInputChange}
                                                    value={fields[val.name!] || ''}
                                                />
                                            )
                                        }
                                        if (val.field_type === 'text') {
                                            return (
                                                <TextInput816Site
                                                    error={errors[val.name!]}
                                                    key={val.id}
                                                    label={val.label!}
                                                    name={val.name!}
                                                    onChange={handleInputChange}
                                                    value={fields[val.name!] || ''}
                                                />
                                            )
                                        }
                                        if (val.field_type === 'textarea') {
                                            return (
                                                <TextAreaInput816Site
                                                    error={errors[val.name!]}
                                                    key={val.id}
                                                    label={val.label!}
                                                    name={val.name!}
                                                    onChange={handleInputChange}
                                                    value={fields[val.name!] || ''}
                                                />
                                            )
                                        }
                                        if (val.field_type === 'checkbox') {
                                            return (
                                                <CheckboxInput816Site
                                                    error={errors[val.name!]}
                                                    key={val.id}
                                                    label={val.label!}
                                                    name={val.name!}
                                                    onChange={handleInputChange}
                                                    value={fields[val.name!] || ''}
                                                />
                                            )
                                        }
                                        if (val.field_type === 'datetime') {
                                            return (
                                                <DateTimeInput816Site
                                                    error={errors[val.name!]}
                                                    format='date'
                                                    key={val.id}
                                                    label={val.label!}
                                                    name={val.name!}
                                                    onChange={handleInputChange}
                                                    value={fields[val.name!] || ''}
                                                />
                                            )
                                        }
                                        if (val.field_type === 'location') {
                                            return (
                                                <LocationInput816Site
                                                    error={errors[val.name!]}
                                                    key={val.id}
                                                    label={val.label!}
                                                    name={val.name!}
                                                    onChange={handleInputChange}
                                                    value={fields[val.name!] || ''}
                                                />
                                            )
                                        }
                                        if (val.field_type === 'number') {
                                            return (
                                                <TextInput816Site
                                                    error={errors[val.name!]}
                                                    key={val.id}
                                                    label={val.label!}
                                                    name={val.name!}
                                                    onChange={handleInputChange}
                                                    type='number'
                                                    value={fields[val.name!] || ''}
                                                />
                                            )
                                        }
                                        if (val.field_type === 'phonenumber') {
                                            return (
                                                <PhoneNumberInput816Site
                                                    error={errors[val.name!]}
                                                    key={val.id}
                                                    label={val.label!}
                                                    name={val.name!}
                                                    onChange={handleInputChange}
                                                    value={fields[val.name!] || ''}
                                                />
                                            )
                                        }
                                    })}
                                </>
                            ) : (
                                <>
                                    <TextInput816Site
                                        error={errors.first_name}
                                        label={reduxText[8744]}
                                        name='first_name'
                                        onChange={handleInputChange}
                                        value={fields.first_name || ''}
                                    />
                                    {errors?.first_name && (
                                        <span className='t-797-error'>{errors.first_name}</span>
                                    )}
                                    <TextInput816Site
                                        error={errors.last_name}
                                        label={reduxText[8745]}
                                        name='last_name'
                                        onChange={handleInputChange}
                                        value={fields.last_name || ''}
                                    />
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={`t-816-button-next-wrap ${deviceType}`}>
                <LinkHelperSite
                    action={undefined}
                    className={`t-816-button-next${helpers.buttonDisabled ? ' disabled' : ''} ${deviceType}`}
                    onClick={handleSubmit}
                    to={undefined}
                >
                    <span>{reduxText[9953]}</span>
                </LinkHelperSite>
            </div>
            <EditBlockSite
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
