export function stringifyQuery(queryObject: {
    [key: string]: string
} | undefined) {
    let stringQuery = ''
    if (queryObject) {
        Object.getOwnPropertyNames(queryObject).map((val, i) => {
            if (queryObject[val]) {
                stringQuery += `${val}=${queryObject[val]}`
                if ((i + 1) < Object.getOwnPropertyNames(queryObject).length) {
                    stringQuery += '&'
                }
            }
        })
    }
    return stringQuery
}
