// react components
import React from 'react'

// data
import {
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldSerializer,
} from 'serializers/site'

// props
type TemplateBlock797CheckboxSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInputObj: FormFieldSerializer | undefined
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
    value: boolean | undefined
}

// main
export const TemplateBlock797CheckboxSite: React.FC<TemplateBlock797CheckboxSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInputObj,
    handleInputChange,
    isEditHovered,
    reduxModalSite,
    stylesNew,
    tabIndex,
    value,
}) => {
    return (
        <input
            id={blockId}
            className={`template-block-797 checkbox${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            name={formInputObj?.name}
            onChange={(e) => handleInputChange(formInputObj?.name!, e.target.checked, formInputObj?.label!, formInputObj?.stripe_field_type)}
            placeholder={formInputObj?.placeholder === 'true' ? formInputObj?.label : undefined}
            style={stylesNew}
            // tabIndex={tabIndex}
            type={formInputObj?.field_type}
            checked={value}
        />
    )
})
