// data
import {
    reduxAuthStateSite,
} from 'data'

// main
export function checkPorfolioUserGroups({
    pageUserGroups,
    portfolioId,
    reduxAuth,
}: {
    pageUserGroups: number[]
    portfolioId: number
    reduxAuth: reduxAuthStateSite
}) {
    const user_portfolios = reduxAuth?.settings?.user?.user_portfolios
    if (user_portfolios?.length === 0) return true

    const pageUserGroupsSet = new Set(pageUserGroups)
    const common_groups = reduxAuth?.settings?.user?.user_portfolios
        .some((setting) => {
            if (setting.portfolio === portfolioId) {
                const groups = setting.portfolio_user_groups
                if (setting.portfolio_user_groups.length === 0) return true
                return groups.some((group) => pageUserGroupsSet.has(group))
            }
            return false
        })
    return common_groups
}
