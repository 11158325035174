// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template789SiteSerializer,
} from 'serializers/site'

// services
import {
    getSocialIcon,
    getStylesNew,
} from 'services'

// props
type TemplateBlock789SiteProps = {
    blockId: string
    content: any
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock789Site: React.FC<TemplateBlock789SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    if (content?.social_links) {
        return (
            <React.Fragment>
                {content.social_links.map((item: any) => (
                    <TemplateBlock789HelperSite
                        key={item.id}
                        blockId={`${blockId}-${item.id}`}
                        content={item}
                        isEditHovered={isEditHovered}
                        isInComponent={isInComponent}
                        mainParentId={mainParentId}
                        object={object}
                        parentArray={parentArray}
                        parentStyles={parentStyles}
                        styles={styles}
                        stylesEdit={stylesEdit}
                    />
                ))}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {content?.map((item: any) => (
                <TemplateBlock789HelperSite
                    key={item.id}
                    blockId={`${blockId}-${item.id}`}
                    content={item}
                    isEditHovered={isEditHovered}
                    isInComponent={isInComponent}
                    mainParentId={mainParentId}
                    object={object}
                    parentArray={parentArray}
                    parentStyles={parentStyles}
                    styles={styles}
                    stylesEdit={stylesEdit}
                />
            ))}
        </React.Fragment>
    )
})

// helpers

// props
type TemplateBlock789HelperSiteProps = {
    blockId: string
    content: Template789SiteSerializer | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
const TemplateBlock789HelperSite: React.FC<TemplateBlock789HelperSiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesDefault, setStylesDefault] = useState<{
        styles: CustomCSSProperties | undefined
        stylesActive?: CustomCSSProperties | undefined
    } | undefined>(applyStyles())
    const [stylesNew, setStylesNew] = useState<{
        styles: CustomCSSProperties | undefined
        stylesActive?: CustomCSSProperties | undefined
    } | undefined>(applyStyles())

    useEffect(() => {
        setStylesDefault(applyStyles())
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            if (aStyles?.isIcon === 'true') {
                return {
                    styles: {
                        isIcon: aStyles?.isIcon,
                        color: aStyles?.color,
                        fontSize: aStyles?.fontSize,
                        margin: aStyles?.margin,
                    },
                    stylesActive: {
                        color: aStyles?.hoverColor,
                    },
                }
            } else {
                return {
                    styles: aStyles,
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock789HelperSite',
                'applyStyles',
            ))
        }
    }

    function onHover(direction: string) {
        try {
            if (stylesDefault?.stylesActive) {
                if (direction === 'over') {
                    if (stylesDefault.styles) {
                        const newnewStyle = Object.assign({}, stylesDefault.styles)
                        if (stylesDefault.stylesActive.color) newnewStyle.color = stylesDefault.stylesActive.color
                        setStylesNew({
                            styles: newnewStyle,
                            stylesActive: stylesDefault.stylesActive,
                        })
                    } else {
                        setStylesNew(undefined)
                    }
                } else {
                    if (stylesDefault.styles) {
                        setStylesNew(stylesDefault)
                    } else {
                        setStylesNew(undefined)
                    }
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'LinkHelperSite',
                'onHover',
            ))
        }
    }

    if (['edit', 'preview'].includes(reduxFormSitenavigationMode!)) {
        return (
            <span
                id={blockId}
                className={`template-block-789${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                style={
                    stylesNew?.styles?.isIcon === 'true'
                        ? (
                            {
                                margin: stylesNew?.styles?.margin,
                                width: 'auto',
                            }
                        )
                        : (
                            stylesNew?.styles
                        )
                }
            >
                {stylesNew?.styles?.isIcon === 'true'
                    ? (
                        <i
                            className={`t-789-icon mo-new-icon-socialnetwork-${content?.social_network || '5'}`}
                            onMouseLeave={() => onHover('leave')}
                            onMouseOver={() => onHover('over')}
                            style={{
                                color: stylesNew?.styles?.color,
                                fontSize: stylesNew?.styles?.fontSize,
                            }}
                        />
                    ) : (
                        <ImageHelperSite
                            allowRightClick
                            alt=''
                            className={`t-789-image ${deviceType}`}
                            dominant_color={undefined}
                            src={getSocialIcon(content?.social_network!, stylesNew?.styles?.socialColor)}
                        />
                    )
                }
                <EditBlockSite
                    isInComponent={isInComponent}
                    mainParentId={mainParentId || object.id!}
                    object={object}
                    parentArray={parentArray}
                    parentStyles={parentStyles}
                />
            </span>
        )
    }

    return (
        <a
            id={blockId}
            className={`template-block-789${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            href={content?.social_network === 2 ? `mailto:${content?.link_url}` : content?.link_url}
            rel='noopener noreferrer'
            target='_blank'
            style={
                stylesNew?.styles?.isIcon === 'true'
                    ? (
                        {
                            margin: stylesNew?.styles?.margin,
                            width: 'auto',
                        }
                    )
                    : (
                        stylesNew?.styles
                    )
            }
        >
            {stylesNew?.styles?.isIcon === 'true'
                ? (
                    <i
                        className={`t-789-icon mo-new-icon-socialnetwork-${content?.social_network || '5'}`}
                        onMouseLeave={() => onHover('leave')}
                        onMouseOver={() => onHover('over')}
                        style={{
                            color: stylesNew?.styles?.color,
                            fontSize: stylesNew?.styles?.fontSize,
                        }}
                    />
                ) : (
                    <ImageHelperSite
                        allowRightClick
                        alt=''
                        className={`t-789-image ${deviceType}`}
                        dominant_color={undefined}
                        src={getSocialIcon(content?.social_network!, stylesNew?.styles?.socialColor)}
                    />
                )
            }
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </a>
    )
})
