// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_portfolio_private_space_list_site,
    reduxAuthStateSite,
    reduxCacheStateSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    PortfolioDetailSiteSerializer,
} from 'serializers/site'


// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// main
export function getPrivateSpaceListDataSite({
    component,
    dispatch,
    portfolioObject,
    reduxAuth,
    reduxCacheSite,
    reduxModalSite,
    setPrivateSpaceList,
}: {
    component: string
    dispatch: React.Dispatch<any>
    portfolioObject: PortfolioDetailSiteSerializer
    reduxAuth: reduxAuthStateSite
    reduxCacheSite: reduxCacheStateSite
    reduxModalSite: reduxModalStateSite
    setPrivateSpaceList: any
}) {

    if (reduxCacheSite.privateSpaceList) {  // TODO
        setPrivateSpaceList(reduxCacheSite.privateSpaceList)
    }

    let getUrl = getApiUrlSite(`${api_url_portfolio_private_space_list_site}?portfolio_id=${portfolioObject.id}`, reduxModalSite)
    getUrl += `&language_id=${reduxModalSite.languageId}`
    if (process.env.NODE_ENV === 'development') console.log(getUrl)
    axios({
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: 'get',
        url: getUrl,
    })
        .then((pageResponse) => {
            if (process.env.NODE_ENV === 'development') console.log(pageResponse)
            setPrivateSpaceList(pageResponse.data)
            // dispatch(reduxCacheSetPageSite(pageResponse.data)) // TODO
        })
        .catch((error) => {
            axiosErrorHandlerSite({
                apiUrl: getUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'getPrivateSpaceListDataSite',
            })
        })
}
