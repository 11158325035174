// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Button,
    Snackbar,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalCookieConsentSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// main
export const ConsentBlockSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxCacheSiteportfoliogoogle_analytics = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.google_analytics)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [show, setShow] = useState(false)

    const deviceType = reduxModalSite.deviceType

    let wto: any
    useEffect(() => {
        setShow(false)
        clearTimeout(wto)
        wto = setTimeout(() => {
            setShow(true)
        }, 100)
        return () => {
            clearTimeout(wto)
        }
    }, [
        reduxText,
    ])

    if (!show) return null

    let hideCookieBanner = false
    if (reduxModalSite.cookieConsent?.isAccepted || reduxModalSite.cookieConsent?.isDeclined) {
        if (reduxCacheSiteportfoliogoogle_analytics && reduxModalSite.cookieConsent?.actualCookiesId !== 'google-analytics') {
            hideCookieBanner = false
        } else {
            hideCookieBanner = true
        }
    }

    if (hideCookieBanner) return null

    if (!reduxCacheSiteportfolioid || !reduxText[8412] || !reduxText[8413]) return null

    function onToastDismiss(role: string) {
        try {
            dispatch(reduxModalCookieConsentSite({
                actualCookiesId: reduxCacheSiteportfoliogoogle_analytics ? 'google-analytics' : 'no-cookies',
                isAccepted: role === 'ok',
                isDeclined: role === 'cancel',
            }))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ConsentBlockSite',
                'onToastDismiss',
            ))
        }
    }

    let action
    if (reduxCacheSiteportfoliogoogle_analytics) {
        action = (
            <React.Fragment>
                <Button
                    onClick={() => onToastDismiss('cancel')}
                    size='small'
                >
                    {reduxText[8410]}
                </Button>
                <Button
                    onClick={() => onToastDismiss('ok')}
                    size='small'
                >
                    {reduxText[8411]}
                </Button>
            </React.Fragment>
        )
    } else {
        action = (
            <Button
                onClick={() => onToastDismiss('ok')}
                size='small'
            >
                {reduxText[8409]}
            </Button>
        )
    }

    return (
        <Snackbar
            action={action}
            className={`consent-block-site p-${reduxCacheSiteportfolioid} ${deviceType}`}
            message={reduxCacheSiteportfoliogoogle_analytics ? reduxText[8413] : reduxText[8412]}
            open={true}
        />
    )
}
