// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    api_url_portfolio_hub_content_filter_list_site,
    reduxAuthStateSite,
    reduxCacheStateSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    PortfolioDetailSiteSerializer,
} from 'serializers/site'


// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// main
export function getHubContentFilterListDataSite({
    axiosCancelToken,
    component,
    dispatch,
    portfolioObject,
    reduxAuth,
    reduxCacheSite,
    reduxModalSite,
    setHubFilter,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    dispatch: React.Dispatch<any>
    portfolioObject: PortfolioDetailSiteSerializer
    reduxAuth: reduxAuthStateSite
    reduxCacheSite: reduxCacheStateSite
    reduxModalSite: reduxModalStateSite
    setHubFilter: any
}) {

    // if (reduxCacheSite.hubFilter) {
    // 	setHubFilter(reduxCacheSite.hubFilter)
    // }

    let getUrl = getApiUrlSite(`${api_url_portfolio_hub_content_filter_list_site}?portfolio_id=${portfolioObject.id}`, reduxModalSite)
    getUrl += `&language_id=${reduxModalSite.languageId}`
    if (process.env.NODE_ENV === 'development') console.log(getUrl)
    axios({
        // cancelToken: axiosCancelToken?.token,
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: 'get',
        url: getUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            setHubFilter(response.data)
            // dispatch(reduxCacheSetPageSite(response.data))
        })
        .catch((error) => {
            axiosErrorHandlerSite({
                apiUrl: getUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'getHubContentFilterListDataSite',
            })
        })
}
