// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    VariantHelper819Site,
} from 'pages'

// serializers
import {
    CourseHelper819SiteSerializer,
    Template819SiteSerializer,
} from 'serializers/site'

// props
type CourseHelper819SiteProps = {
    activity: Template819SiteSerializer
    object: CourseHelper819SiteSerializer
    setTotalAmount: React.Dispatch<React.SetStateAction<number>>
    setTotalQty: React.Dispatch<React.SetStateAction<number>>
    totalAmount: number
    totalQty: number
}

// main
export const CourseHelper819Site: React.FC<CourseHelper819SiteProps> = React.memo(({
    activity,
    object,
    setTotalAmount,
    setTotalQty,
    totalAmount,
    totalQty,
}) => {

    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    return (
        <div className={`course-helper-819 ${deviceType}`}>
            {/* <div className='ch-819-ch-info'>
                {courseLength > 1 && (
                    <div className={`ch-819-name ${deviceType}`}>
                        <span>{object.name}</span>
                    </div>
                )}
                <div className={`ch-819-schedule ${deviceType}`}>
                    <span>{object.schedule_day_1?.name}</span>
                    <span>{moment(object.schedule_time_start_1, 'HH:mm:ss').format('HH[h]mm')} - {moment(object.schedule_time_end_1, 'HH:mm:ss').format('HH[h]mm')}</span>
                </div>
            </div> */}
            <div className={`t-819-variant-wrap ${deviceType}`}>
                {object.product?.variants?.map(val => (
                    <VariantHelper819Site
                        key={val.id}
                        activity={activity}
                        course={object}
                        object={val}
                        setTotalAmount={setTotalAmount}
                        setTotalQty={setTotalQty}
                        totalAmount={totalAmount}
                        totalQty={totalQty}
                    />
                ))}
            </div>
        </div>
    )
})
